import React from "react";

import moment from "moment";
import { withSnackbar } from "notistack";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Button, Input } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MailUserSelector from "../Selectors/MailUserSelector";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import { retrieveAllPerimeters } from "../../Services/PerimetersService";
import { getUserProfiles } from "../../Services/UserProfilesService";
import { updateUserProfile } from "../../Services/UserProfilesService";
import { addLog } from "../../Services/LogsService";

//Set the fr language for moment
moment.locale("fr");

class AddEmailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      modalAddChannelOpen: this.props.visible,
      form_alarm_error: true,
      form_email_error: true,
      form_alarm: "",
      form_email: "",
      form_user: "",
      form_channel: "phone",
      user_list: [],
      form_beg_hour: new Date("2000-01-01T19:00:00"),
      form_end_hour: new Date("2000-01-01T09:00:00"),
      form_error: true,
      perimeter_list: []
    };
    this.updateGlobalFormError = this.updateGlobalFormError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.modalAddChannelOpen !== nextProps.visible) {
      this.setState({
        modalAddChannelOpen: nextProps.visible
      });
    }
  }

  handleSelectorChangeFormAccountId = e => {
    this.setState({
      form_account_id: e
    });
  };

  handleTextFieldChangeFormEmailName = e => {
    if (e.target.value.length > 0) {
      this.setState(
        {
          form_email_error: false
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    } else {
      this.setState(
        {
          form_email_error: true
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    }
    this.setState({
      form_email: e.target.value
    });
  };

  handleTextFieldChangeFormAlarmName = e => {
    if (e.target.value.length > 0) {
      this.setState(
        {
          form_alarm_error: false
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    } else {
      this.setState(
        {
          form_alarm_error: true
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    }
    this.setState({
      form_alarm: e.target.value
    });
  };

  handleSelectorChangeFormChannel = e => {
    this.setState({
      form_channel: e
    });
  };

  handleSelectorChangeFormUser = e => {
    this.setState({
      form_user: e
    });
  };

  handleTextFieldChangeFormBegHour = e => {
    this.setState({
      form_beg_hour: e
    });
  };

  handleTextFieldChangeFormEndHour = e => {
    this.setState({
      form_end_hour: e
    });
  };

  getAllPerimeters() {
    retrieveAllPerimeters()
      .then(response => {
        this.setState({
          perimeter_list: response
        });
        this.searchUserwithAppInPerimeter(this.props.app, this.props.env);
      })
      .catch(error =>
        this.props.enqueueSnackbar("Impossible de récupérer les périmètres", {
          variant: "error",
          autoHideDuration: 3000
        })
      );
  }

  searchUserwithAppInPerimeter(app, env) {
    let ls_key = Object.keys(localStorage).filter(key =>
      key.endsWith("LastAuthUser")
    );
    let ls_value = localStorage.getItem(ls_key);
    getUserProfiles().then(response => {
      const appName = app + "-" + env;
      const perimeterApp = this.state.perimeter_list.find(perimeter =>
        perimeter["Application Name"].includes(appName)
      );
      const user_list = response
        .filter(user => user.UserName.startsWith(`${ls_value}`+ "_smtp_rule")) // eslint-disable-line
        .filter(user => user.hasOwnProperty("Perimeters"))
        .filter(filtered_user =>
          filtered_user.Perimeters.includes(
            perimeterApp && perimeterApp["Perimetre Name"]
          )
        );

      this.setState({
        user_list: user_list,
        form_user: user_list && user_list[0] && user_list[0].UserName
      });
    });
  }

  componentDidMount() {
    this.getAllPerimeters();
  }

  handleValidateChannelClickOpen() {
    let account_id;

    if (this.state.form_account_id) {
      account_id = this.state.form_account_id;
    } else {
      account_id = this.state.account_id;
    }

    let time_description = "";

    updateUserProfile(this.state.form_user, {
      userEmail: this.state.form_email
    }).then(() => {
      const action_description =
        "Ajout d'un email sur le compte : " +
        account_id +
        " avec le nom " +
        this.state.form_email +
        " avec la règle " +
        this.state.form_user +
        time_description;

      const current_time = moment().format("dddd Do MMMM YYYY H: mm: ss");
      addLog(this.props.user_name, current_time, action_description);
      this.props.onClose();
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 10000
      });
    });
  }

  updateGlobalFormError() {
    if (
      this.state.form_account_id_error === true ||
      this.state.form_email_error === true ||
      this.state.user_list.length === 0
    ) {
      this.setState({
        form_error: true
      });
    } else {
      this.setState({
        form_error: false
      });
    }
  }

  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  closeModal = () => {
    // Update the user list before closing so we can have new value without reloading the page
    this.searchUserwithAppInPerimeter(this.props.app, this.props.env);
    this.props.onClose();
  };

  render() {
    const { classes } = this.props;
    const appFQ = this.state.app + "-" + this.state.env;
    return (
      <Dialog
        open={this.state.modalAddChannelOpen}
        onClose={this.closeModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            borderRadius: 30,
            fontFamily: "sans-serif",
            fontSize: 12,
            fontWeight: 600,
            color: "#009999"
          }
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            marginBottom: "10px",
            "text-transform": "uppercase",
            "font-size": "12px",
            "font-weight": "bold"
          }}
        >
          <Typography
            variant="body1"
            style={{
              "text-transform": "uppercase",
              "font-size": "16px",
              "font-weight": "bold",
              "text-align": "center",
              "border-radius": "30px",
              marginBottom: "10px",
              marginTop: "10px",
              color: "#009999"
            }}
          >
            Définir mon adresse Email générique sur {this.props.app}-
            {this.props.env}
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            "overflow-x": "hidden",
            color: "#009999"
          }}
        >
          {/*<AccountSelector*/}
          {/*  id="alarm-dashboard-account-selector"*/}
          {/*  onApplicationChange={this.handleSelectorChangeFormAccountId}*/}
          {/*  selectedAccount={this.state.account_id}*/}
          {/*  accountList={this.state.account_list}*/}
          {/*/>*/}
          <MailUserSelector
            id="alarm-dashboard-user-selector"
            onApplicationChange={this.handleSelectorChangeFormUser}
            selectedValue={this.state.form_user}
            userList={this.state.user_list}
          />
          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            {this.state.user_list.length === 0
              ? "Aucune utilisateur ne possède " +
                appFQ +
                " dans un de ses périmètres"
              : "Règle smtp associée à un utilisateur ayant " +
                appFQ +
                " dans un de ses périmètres"}{" "}
          </FormHelperText>

          <TextField
            autoFocus
            margin="dense"
            id="form-email-name"
            label="Email"
            type="text"
            error={this.state.form_email_error}
            value={this.state.form_email}
            onChange={this.handleTextFieldChangeFormEmailName}
            fullWidth
          />
          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            L'adresse email générique associée à la règle smtp
          </FormHelperText>

          <Input readOnly={true} value={this.state.account_id} />
          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            Le numéro à 12 chiffres du compte AWS
          </FormHelperText>

          <Paper
            className={this.state.classes.rootProfilUtilisateur}
            elevation={0}
          >
            <Grid
              container
              className={classes.grid}
              justify="flex-end"
              style={{ marginTop: "30px" }}
            >
              <Button
                onClick={this.closeModal.bind(this)}
                color="secondary"
                size="medium"
                variant="contained"
                style={{
                  backgroundColor: "secondary",
                  "box-shadow": "none",
                  "border-radius": "20px",
                  border: "none",
                  "font-weight": "bold",
                  color: "#ffffff",
                  margin: "10px"
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={() => this.handleValidateChannelClickOpen()}
                color="primary"
                disabled={this.state.form_error}
                key="add"
                size="medium"
                variant="contained"
                style={{
                  backgroundColor: "secondary",
                  "box-shadow": "none",
                  "border-radius": "20px",
                  border: "none",
                  "font-weight": "bold",
                  color: "#ffffff",
                  margin: "10px"
                }}
              >
                Modifier la règle
              </Button>
            </Grid>
          </Paper>

          {this.state.channel_add_reponse ? (
            <Grid container className={classes.grid} justify="space-around">
              <Paper>
                <Typography>{this.state.channel_add_reponse}</Typography>
              </Paper>
            </Grid>
          ) : (
            false
          )}
        </DialogContent>
        <DialogContent>
          {this.state.popup_loading ? (
            <CircularLoading> </CircularLoading>
          ) : (
            false
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(AddEmailDialog));
