import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const PipelineRevisions = ({ open, onClose, sourceRevisions }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="xl"
    PaperProps={{
      style: {
        borderRadius: 30,
        fontFamily: "sans-serif",
        fontSize: 12,
        fontWeight: 600,
        color: "#009999"
      }
    }}
  >
    <DialogTitle
      style={{
        marginBottom: "10px",
        "text-transform": "uppercase",
        "font-size": "12px",
        "font-weight": "bold"
      }}
    >
      <div style={{ color: "white" }}>Source revision details</div>
    </DialogTitle>
    <DialogContent>
      {sourceRevisions.map((sourceRevision, key) => {
        return (
          <span key={key}>
            <br />
            {sourceRevision.actionName === "CodeCommit-Integrator-Delivery" ? (
              <b>
                {sourceRevision.revisionId.slice(0, 8)}
                {" - "}CodeCommit-Integrator-Delivery{": "}
                {sourceRevision.revisionSummary}
              </b>
            ) : (
              <span>
                {sourceRevision.revisionId.slice(0, 8)}
                {" - "}
                {sourceRevision.actionName}
                {": "}
                {sourceRevision.revisionSummary}
              </span>
            )}
          </span>
        );
      })}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant={"contained"}
        style={{
          backgroundColor: "#32CD32"
        }}
      >
        <div
          style={{
            color: "white"
          }}
        >
          OK
        </div>
      </Button>
    </DialogActions>
  </Dialog>
);

export default PipelineRevisions;
