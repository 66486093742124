import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import { CircularLoading } from "../../Styles/ComponentStyles";
import ResourceTable from "../Tables/ResourceTable";
import ResourceTableSnapshots from "../Tables/ResourceTableSnapshots";
import { styles } from "../../Styles/ComponentStyles";
import EC2 from "../../Styles/Icons/EC2.png";
import ECS from "../../Styles/Icons/ECS.png";
import S3 from "../../Styles/Icons/S3.png";
import RDS from "../../Styles/Icons/RDS.png";
import LAMBDA from "../../Styles/Icons/LAMBDA.png";
import AUTOSCALING from "../../Styles/Icons/AUTOSCALING.png";
import SQS from "../../Styles/Icons/SQS.png";
import Config from "../../config.js";

const ExpansionTheme = createMuiTheme({
  overrides: {
    MuiExpansionPanel: {
      root: {
        "&:before": {
          display: "none"
        },
        background: "#f7faf9",
        "border-radius": "30px"
      }
    }
  }
});

class AWSResourcesDashboardExpansionPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      open: false,
      validation_value: "",
      userIdToken: props.userIdToken,
      aws_service_descriptions: Config.AWS_SERVICE_NAMES_DESCRIPTION,
      aws_service_descriptions_alias:
        Config.AWS_SERVICE_NAMES_DESCRIPTION_ALIAS,
      table_response: [],
      listResourcesInProgress: [],
      expanded_services: [],
      table_response_alias: [],
      listResourcesInProgress_alias: [],
      expanded_services_alias: []
    };

    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(
      this
    );
    this.handleExpansionPanelChangeSnapshots = this.handleExpansionPanelChangeSnapshots.bind(
      this
    );
    this.list_resources = this.list_resources.bind(this);
    this.list_resources_alias = this.list_resources_alias.bind(this);
    this.renderPanel = this.renderPanel.bind(this);
    this.renderPanelSnapshots = this.renderPanelSnapshots.bind(this);
    this.renderResourceTable = this.renderResourceTable.bind(this);
    this.renderResourceTableSnapshots = this.renderResourceTableSnapshots.bind(
      this
    );
  }

  componentWillReceiveProps(props) {
    this.setState({
      account_id: props.account_id
    });
  }

  list_resources(resource_service, resource_action) {
    if (this.state.listResourcesInProgress[resource_service]) {
      return;
    }
    // let tempListResourcesInProgress = this.state.listResourcesInProgress
    let tempListResourcesInProgress = {};
    tempListResourcesInProgress[resource_service] = true;
    this.setState(
      {
        listResourcesInProgress: tempListResourcesInProgress
      },
      () => {
        let temp_table_response = this.state.table_response;
        API.post("api-exsi-backend", "/realize", {
          queryStringParameters: {
            boto_service: resource_service,
            boto_method: resource_action,
            account_id: this.state.account_id
          },
          headers: {
            Authorization: this.state.userIdToken
          }
        }).then(response => {
          if (resource_service === "ecs") {
            API.post("api-exsi-backend", "/realize", {
              queryStringParameters: {
                boto_service: resource_service,
                boto_method: "list_clusters",
                account_id: this.state.account_id
              },
              headers: {
                Authorization: this.state.userIdToken
              }
            }).then(response_list_clusters => {
              let cluster_arn = response_list_clusters["clusterArns"][0];
              for (let i = 0; i < response["families"].length; i++) {
                realizeAction(
                  this.props.userIdToken,
                  this.state.account_id,
                  this.state.resource_service,
                  "describe_task_definition",
                  response["families"][i],
                  "taskDefinition",
                  false,
                  response => {
                    if (response instanceof Error) return;
                    if (
                      !("definitions" in temp_table_response[resource_service])
                    ) {
                      temp_table_response[resource_service]["definitions"] = [];
                    }
                    let temp_table = response["taskDefinition"];
                    let body = {
                      family: response["taskDefinition"]["family"],
                      cluster: cluster_arn
                    };
                    API.post("api-exsi-backend", "/realize", {
                      queryStringParameters: {
                        boto_service: "ecs",
                        boto_method: "list_tasks",
                        account_id: this.state.account_id,
                        region: "eu-west-1"
                      },
                      body: body
                    }).then(response => {
                      temp_table["tasks"] = response["taskArns"];
                      temp_table["task_number"] = response["taskArns"].length;
                      temp_table["running_task"] =
                        response["taskArns"].length === 0 ? "non" : "oui";
                      temp_table_response[resource_service]["definitions"].push(
                        temp_table
                      );
                      tempListResourcesInProgress[resource_service] = false;
                      this.setState({
                        listResourcesInProgress: tempListResourcesInProgress
                      });
                    });
                  },
                  null,
                  null,
                  null,
                  null,
                  null
                );
              }
            });
            this.setState({
              listResourcesInProgress: tempListResourcesInProgress,
              table_response: temp_table_response,
              seconds: 0
            });
            temp_table_response[resource_service] = response;
            this.setState({
              table_response: temp_table_response
            });
          } else {
            temp_table_response[resource_service] = response;
            this.setState({
              table_response: temp_table_response,
              seconds: 0,
              listResourcesInProgress: false
            });
          }
        });
      }
    );
  }

  list_resources_alias(resource_service, resource_action) {
    if (this.state.listResourcesInProgress_alias[resource_service]) {
      return;
    }
    // let tempListResourcesInProgress_alias = this.state.listResourcesInProgress_alias
    let tempListResourcesInProgress_alias = {};
    tempListResourcesInProgress_alias[resource_service] = true;
    this.setState(
      {
        listResourcesInProgress_alias: tempListResourcesInProgress_alias
      },
      () => {
        let temp_table_response_alias = this.state.table_response_alias;
        API.post("api-exsi-backend", "/realize", {
          queryStringParameters: {
            boto_service: resource_service,
            boto_method: resource_action,
            account_id: this.state.account_id
          },
          headers: {
            Authorization: this.state.userIdToken
          }
        }).then(response => {
          if (resource_service === "ecs") {
            API.post("api-exsi-backend", "/realize", {
              queryStringParameters: {
                boto_service: resource_service,
                boto_method: "list_clusters",
                account_id: this.state.account_id
              },
              headers: {
                Authorization: this.state.userIdToken
              }
            }).then(response_list_clusters => {
              let cluster_arn = response_list_clusters["clusterArns"][0];
              for (let i = 0; i < response["families"].length; i++) {
                realizeAction(
                  this.props.userIdToken,
                  this.state.account_id,
                  this.state.resource_service,
                  "describe_task_definition",
                  response["families"][i],
                  "taskDefinition",
                  false,
                  response => {
                    if (response instanceof Error) return;
                    if (
                      !(
                        "definitions" in
                        temp_table_response_alias[resource_service]
                      )
                    ) {
                      temp_table_response_alias[resource_service][
                        "definitions"
                      ] = [];
                    }
                    let temp_table = response["taskDefinition"];
                    let body = {
                      family: response["taskDefinition"]["family"],
                      cluster: cluster_arn
                    };
                    API.post("api-exsi-backend", "/realize", {
                      queryStringParameters: {
                        boto_service: "ecs",
                        boto_method: "list_tasks",
                        account_id: this.state.account_id,
                        region: "eu-west-1"
                      },
                      body: body
                    }).then(response => {
                      temp_table["tasks"] = response["taskArns"];
                      temp_table["task_number"] = response["taskArns"].length;
                      temp_table["running_task"] =
                        response["taskArns"].length === 0 ? "non" : "oui";
                      temp_table_response_alias[resource_service][
                        "definitions"
                      ].push(temp_table);
                      tempListResourcesInProgress_alias[
                        resource_service
                      ] = false;
                      this.setState({
                        listResourcesInProgress_alias: tempListResourcesInProgress_alias
                      });
                    });
                  },
                  null,
                  null,
                  null,
                  null,
                  null
                );
              }
            });
            this.setState({
              listResourcesInProgress_alias: tempListResourcesInProgress_alias,
              table_response_alias: temp_table_response_alias,
              seconds: 0
            });
            temp_table_response_alias[resource_service] = response;
            this.setState({
              table_response_alias: temp_table_response_alias
            });
          } else {
            temp_table_response_alias[resource_service] = response;
            this.setState({
              table_response_alias: temp_table_response_alias,
              seconds: 0,
              listResourcesInProgress_alias: false
            });
          }
        });
      }
    );
  }

  handleExpansionPanelChange = (service, event, expanded) => {
    let temp_expanded_services = this.state.expanded_services;
    if (expanded) {
      switch (service.toLowerCase().toString()) {
        case "ec2":
          this.list_resources("ec2", "describe_instances");
          this.setState({
            resource_service: "ec2",
            resource_action: "describe_instances"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "rds":
          this.list_resources("rds", "describe_db_instances");
          this.setState({
            resource_service: "rds",
            resource_action: "describe_db_instances"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "s3":
          this.list_resources("s3", "list_buckets");
          this.setState({
            resource_service: "s3",
            resource_action: "list_buckets"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "lambda":
          this.list_resources("lambda", "list_functions");
          this.setState({
            resource_service: "lambda",
            resource_action: "list_functions"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "autoscaling":
          this.list_resources("autoscaling", "describe_auto_scaling_groups");
          this.setState({
            resource_service: "autoscaling",
            resource_action: "describe_auto_scaling_groups"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "sqs":
          this.list_resources("sqs", "list_queues");
          this.setState({
            resource_service: "sqs",
            resource_action: "list_queues"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "ecs":
          this.list_resources("ecs", "list_task_definition_families");
          this.setState({
            resource_service: "ecs",
            resource_action: "list_task_definition_families"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        default:
          break;
      }
    } else if (!expanded) {
      let index = temp_expanded_services.indexOf(
        service.toLowerCase().toString()
      );
      temp_expanded_services.splice(index, 1);
    }

    this.setState({
      expanded_services: temp_expanded_services
    });
  };

  handleExpansionPanelChangeSnapshots = (service, event, expanded) => {
    let temp_expanded_services_snapshot = this.state.expanded_services_alias;
    if (expanded) {
      switch (service.toLowerCase().toString()) {
        case "rds":
          this.list_resources("rds", "describe_db_snapshots");
          this.setState({
            resource_service: "rds",
            resource_action: "describe_db_snapshots"
          });
          temp_expanded_services_snapshot.push(
            service.toLowerCase().toString()
          );
          break;
        default:
          break;
      }
    } else if (!expanded) {
      let index = temp_expanded_services_snapshot.indexOf(
        service.toLowerCase().toString()
      );
      temp_expanded_services_snapshot.splice(index, 1);
    }

    this.setState({
      expanded_services_alias: temp_expanded_services_snapshot
    });
  };

  renderResourceTable(resource_service, resource_action) {
    if (resource_service === this.state.resource_service) {
      if (this.state.listResourcesInProgress[this.state.resource_service]) {
        return (
          <div
            style={{
              border: "none",
              "overflow-x": "hidden",
              "overflow-y": "auto",
              height: "auto",
              "box-shadow": "none",
              background: "white"
            }}
          >
            <Typography
              style={{
                "text-transform": "uppercase",
                "font-size": "1px",
                "font-weight": "bold",
                "text-align": "center",
                "border-radius": "30px",
                marginBottom: "10px",
                marginTop: "10px",
                color: "#009999"
              }}
            >
              Récupération de l'état des ressources...
            </Typography>
            <CircularLoading> </CircularLoading>
          </div>
        );
      } else {
        return (
          <ResourceTable
            onRef={ref => (this.ResourceTable = ref)}
            account_id={this.state.account_id}
            user_name={this.props.user_name}
            userIdToken={this.state.userIdToken}
            resource_service={resource_service}
            resource_action={resource_action}
            mobile={this.state.mobile}
            table_response={this.state.table_response}
            expanded_services={this.state.expanded_services}
            current_service={this.state.resource_service}
            listResourcesInProgress={this.state.listResourcesInProgress}
            app={this.state.app}
            env={this.state.env}
          />
        );
      }
    } else if (this.state.resource_service !== undefined) {
      return (
        <ResourceTable
          onRef={ref => (this.ResourceTable = ref)}
          account_id={this.state.account_id}
          user_name={this.props.user_name}
          userIdToken={this.state.userIdToken}
          resource_service={this.state.resource_service}
          resource_action={this.state.resource_action}
          mobile={this.state.mobile}
          table_response={this.state.table_response}
          expanded_services={this.state.expanded_services}
          current_service={this.state.resource_service}
          listResourcesInProgress={this.state.listResourcesInProgress}
          app={this.state.app}
          env={this.state.env}
        />
      );
    }
  }

  renderResourceTableSnapshots(resource_service, resource_action) {
    if (resource_service === this.state.resource_service) {
      if (this.state.listResourcesInProgress_alias[ // eslint-disable-line
        this.state.resource_service // eslint-disable-line
      ]) { // eslint-disable-line
        return (
          <div
            style={{
              border: "none",
              "overflow-x": "hidden",
              "overflow-y": "auto",
              height: "auto",
              "box-shadow": "none",
              background: "white"
            }}
          >
            <Typography
              style={{
                "text-transform": "uppercase",
                "font-size": "1px",
                "font-weight": "bold",
                "text-align": "center",
                "border-radius": "30px",
                marginBottom: "10px",
                marginTop: "10px",
                color: "#009999"
              }}
            >
              Récupération de l'état des ressources...
            </Typography>
            <CircularLoading> </CircularLoading>
          </div>
        );
      } else {
        return (
          <ResourceTableSnapshots
            onRef={ref => (this.ResourceTableSnapshots = ref)}
            account_id={this.state.account_id}
            user_name={this.props.user_name}
            userIdToken={this.state.userIdToken}
            resource_service={resource_service}
            resource_action={resource_action}
            mobile={this.state.mobile}
            table_response={this.state.table_response}
            expanded_services_alias={this.state.expanded_services_alias}
            current_service={this.state.resource_service}
            listResourcesInProgress_alias={
              this.state.listResourcesInProgress_alias
            }
            app={this.state.app}
            env={this.state.env}
          />
        );
      }
    } else if (this.state.resource_service !== undefined) {
      return (
        <ResourceTableSnapshots
          onRef={ref => (this.ResourceTableSnapshots = ref)}
          account_id={this.state.account_id}
          user_name={this.props.user_name}
          userIdToken={this.state.userIdToken}
          resource_service={this.state.resource_service}
          resource_action={this.state.resource_action}
          mobile={this.state.mobile}
          table_response={this.state.table_response}
          expanded_services_alias={this.state.expanded_services_alias}
          current_service={this.state.resource_service}
          listResourcesInProgress_alias={
            this.state.listResourcesInProgress_alias
          }
          app={this.state.app}
          env={this.state.env}
        />
      );
    }
  }

  renderPanel() {
    if (this.state.account_id) {
      return this.state.aws_service_descriptions.map((row, index) => {
        if (row.SERVICE_NAME.toLowerCase().toString() in Config.calls) {
          return (
            <MuiThemeProvider theme={ExpansionTheme}>
              <ExpansionPanel
                key={row.SERVICE_NAME}
                onChange={this.handleExpansionPanelChange.bind(
                  this,
                  row.SERVICE_NAME
                )}
                elevation={0}
              >
                <ExpansionPanelSummary
                  style={{
                    border: "none",
                    "border-radius": "20px",
                    "margin-top": "5px",
                    "margin-bottom": "5px",
                    "margin-left": "80px",
                    "margin-right": "80px",
                    background: "white",
                    textTransform: "uppercase",
                    fontFamily: "sans-serif",
                    "font-weight": "bold"
                  }}
                >
                  <Typography
                    className={this.state.classes.headingPanel}
                    style={{
                      border: "none",
                      "border-radius": "30px",
                      color: "#48596e",
                      "font-size": "14px",
                      textTransform: "uppercase",
                      fontFamily: "sans-serif",
                      "font-weight": "bold"
                    }}
                  >
                    {row.SERVICE_NAME === "EC2" && (
                      <img
                        src={EC2}
                        alt={"EC2-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    {row.SERVICE_NAME === "ECS" && (
                      <img
                        src={ECS}
                        alt={"ECS-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    {row.SERVICE_NAME === "S3" && (
                      <img
                        src={S3}
                        alt={"S3-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    {row.SERVICE_NAME === "RDS" && (
                      <img
                        src={RDS}
                        alt={"RDS-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    {row.SERVICE_NAME === "Lambda" && (
                      <img
                        src={LAMBDA}
                        alt={"LAMBDA-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    {row.SERVICE_NAME === "AutoScaling" && (
                      <img
                        src={AUTOSCALING}
                        alt={"AUTOSCALING-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    {row.SERVICE_NAME === "SQS" && (
                      <img
                        src={SQS}
                        alt={"SQS-ressource"}
                        style={{
                          height: "30px",
                          width: "30px",
                          "margin-right": "10px",
                          "padding-left": "100px",
                          "vertical-align": "-50%"
                        }}
                      />
                    )}
                    <strong>{row.SERVICE_NAME}</strong>{" "}
                    {" - " + row.SERVICE_DESCRIPTION.toString()}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  style={{
                    border: "none",
                    "border-radius": "30px",
                    "margin-top": "5px",
                    "margin-bottom": "5px",
                    "margin-left": "60px",
                    "margin-right": "40px",
                    background: "white",
                    textTransform: "uppercase",
                    fontFamily: "sans-serif",
                    "font-weight": "bold"
                  }}
                >
                  <div>
                    {row.SERVICE_NAME.toLowerCase().toString() === "ec2"
                      ? this.renderResourceTable("ec2", "describe_instances")
                      : null}
                    {row.SERVICE_NAME.toLowerCase().toString() === "rds"
                      ? this.renderResourceTable("rds", "describe_db_instances")
                      : null}
                    {row.SERVICE_NAME.toLowerCase().toString() === "s3"
                      ? this.renderResourceTable("s3", "list_buckets")
                      : null}
                    {row.SERVICE_NAME.toLowerCase().toString() === "lambda"
                      ? this.renderResourceTable("lambda", "list_functions")
                      : null}
                    {row.SERVICE_NAME.toLowerCase().toString() === "autoscaling"
                      ? this.renderResourceTable(
                          "autoscaling",
                          "describe_auto_scaling_groups"
                        )
                      : null}
                    {row.SERVICE_NAME.toLowerCase().toString() === "sqs"
                      ? this.renderResourceTable("sqs", "list_queues")
                      : null}
                    {row.SERVICE_NAME.toLowerCase().toString() === "ecs"
                      ? this.renderResourceTable(
                          "ecs",
                          "list_task_definition_families"
                        )
                      : null}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </MuiThemeProvider>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    }
  }

  renderPanelSnapshots() {
    if (this.state.account_id) {
      return this.state.aws_service_descriptions_alias.map((row, index) => {
        if ("rds" in Config.calls_alias) {
          return (
            <MuiThemeProvider theme={ExpansionTheme}>
              <ExpansionPanel
                key={"rds_alias"}
                onChange={this.handleExpansionPanelChangeSnapshots.bind(
                  this,
                  "rds"
                )}
                elevation={0}
              >
                <ExpansionPanelSummary
                  style={{
                    border: "none",
                    "border-radius": "20px",
                    "margin-top": "5px",
                    "margin-bottom": "5px",
                    "margin-left": "80px",
                    "margin-right": "80px",
                    background: "white",
                    textTransform: "uppercase",
                    fontFamily: "sans-serif",
                    "font-weight": "bold"
                  }}
                >
                  <Typography
                    className={this.state.classes.headingPanel}
                    style={{
                      border: "none",
                      "border-radius": "30px",
                      color: "#48596e",
                      "font-size": "14px",
                      textTransform: "uppercase",
                      fontFamily: "sans-serif",
                      "font-weight": "bold"
                    }}
                  >
                    <img
                      src={RDS}
                      alt={"RDS-ressource"}
                      style={{
                        height: "30px",
                        width: "30px",
                        "margin-right": "10px",
                        "padding-left": "100px",
                        "vertical-align": "-50%"
                      }}
                    />
                    <strong>{"rds"}</strong> {" - " + "snapshots"}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  style={{
                    border: "none",
                    "border-radius": "30px",
                    "margin-top": "5px",
                    "margin-bottom": "5px",
                    "margin-left": "60px",
                    "margin-right": "40px",
                    background: "white",
                    textTransform: "uppercase",
                    fontFamily: "sans-serif",
                    "font-weight": "bold"
                  }}
                >
                  <div>
                    {this.renderResourceTableSnapshots(
                      "rds",
                      "describe_db_snapshots"
                    )}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </MuiThemeProvider>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    }
  }

  render() {
    return (
      <div
        className={this.state.classes.rootPanel}
        style={{
          "border-width": "0px",
          background: "#f7faf9",
          "border-radius": "30px"
        }}
      >
        {this.renderPanel()}
        {this.renderPanelSnapshots()}
      </div>
    );
  }
}

export default withStyles(styles)(AWSResourcesDashboardExpansionPanel);
