/* eslint-disable indent */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
//import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import { withSnackbar } from "notistack";
import moment from "moment";
import Moment from "moment";
import PropTypes from "prop-types";
import AddChannelDialog from "../Dialogs/AddChannelDialog";
import Circle from "../Geometric/Circle";
import {
  circleColor,
  CircularLoading,
  styles
} from "../../Styles/ComponentStyles";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import {
  getAccountAlarmLogs,
  getAccountState
} from "../../Services/AccountService";
import {
  LineChart,
  Line,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area
} from "recharts";
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from "@table-library/react-table-library/table";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useTheme as usetableTheme } from "@table-library/react-table-library/theme";

const THEME = {
  HeaderRow: `
    color: #003366;
    font-size: 14px;
    background-color: #eaf5fd;
  `,
  Row: `
    font-size: 14px;
    &:nth-child(odd) {
      background-color: #ffff;
    }
    &:nth-child(even) {
      background-color: #eaf5fd;
    }
  `,
  Cell: `
    border: 1px solid grey;
    font-size: 14px;
    &.pinleft {
      min-width: 20px;
      position: inital;
    }
  `
};

const THEME2 = useTheme({
  HeaderRow: `
    color: #199382;
    font-size: 12px;
    text-align: center;
    font-style: oblique;
    font-family: 'Lucida Console', Monaco, monospace;
    .th {
      opacity: 0.2
      border-bottom: 2px solid #95A1F9;
      border-top: 0px solid green;
      background-color: #E1F6EE;
    }
  `,
  BaseCell: `
    &:not(:last-of-type) {
      border-right: 0.1px solid #E2E3DD;
    }
    height: 40px;

    text-align: center;
    font-family: "Gill Sans", sans-serif;

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  `,
  Row: `
    color: black;
    font-size: 12px;
    font-weight: normal;
    &:nth-of-type(odd) {
      background-color: white;
    }

    &:nth-of-type(even) {
      background-color: #FAF9FF ;
    }
  `
});

const THEME3 = useTheme({
  HeaderRow: `
    text-align: center;
    font-size: 10px;
    color: black;
    background-color: #eaf5fd;
  `,
  Row: `
    color: #29293d;
    font-size: 12px;
    font-weight: bold;
    &:nth-of-type(odd) {
      background-color: white;
    }

    &:nth-of-type(even) {
      background-color: #eaf5fd;
    }
  `
});
const tableTheme = usetableTheme(THEME2);
//Set the fr language for moment
moment.locale("fr");
const month = moment().format("MMMM");// eslint-disable-line
const prevMonth1 = moment().subtract(1, "month").format("MMMM");// eslint-disable-line
const prevMonth2 = moment().subtract(2, "month").format("MMMM");// eslint-disable-line
const prevMonth3 = moment().subtract(3, "month").format("MMMM");// eslint-disable-line
const prevMonth4 = moment().subtract(4, "month").format("MMMM");// eslint-disable-line
const prevMonth5 = moment().subtract(5, "month").format("MMMM");// eslint-disable-line
//const endOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
const startOfMonth = moment().clone().subtract(5, "month").startOf('month').format('YYYY-MM-DD');// eslint-disable-line
const endOfMonth = moment().clone().add(1, 'months').startOf('month').format('YYYY-MM-DD');// eslint-disable-line
// const daily periods
const startDay = moment().clone().add(-31, "days").format('YYYY-MM-DD');// eslint-disable-line
const endDay = moment().clone().add(1, "days").format('YYYY-MM-DD');// eslint-disable-line
const prevDay31 = moment().clone().add(-31, "days").format('L');// eslint-disable-line
const prevDay30 = moment().clone().add(-30, "days").format('L');// eslint-disable-line
const prevDay29 = moment().clone().add(-29, "days").format('L');// eslint-disable-line
const prevDay28 = moment().clone().add(-28, "days").format('L');// eslint-disable-line
const prevDay27 = moment().clone().add(-27, "days").format('L');// eslint-disable-line
const prevDay26 = moment().clone().add(-26, "days").format('L');// eslint-disable-line
const prevDay25 = moment().clone().add(-25, "days").format('L');// eslint-disable-line
const prevDay24 = moment().clone().add(-24, "days").format('L');// eslint-disable-line
const prevDay23 = moment().clone().add(-23, "days").format('L');// eslint-disable-line
const prevDay22 = moment().clone().add(-22, "days").format('L');// eslint-disable-line
const prevDay21 = moment().clone().add(-21, "days").format('L');// eslint-disable-line
const prevDay20 = moment().clone().add(-20, "days").format('L');// eslint-disable-line
const prevDay19 = moment().clone().add(-19, "days").format('L');// eslint-disable-line
const prevDay18 = moment().clone().add(-18, "days").format('L');// eslint-disable-line
const prevDay17 = moment().clone().add(-17, "days").format('L');// eslint-disable-line
const prevDay16 = moment().clone().add(-16, "days").format('L');// eslint-disable-line
const prevDay15 = moment().clone().add(-15, "days").format('L');// eslint-disable-line
const prevDay14 = moment().clone().add(-14, "days").format('L');// eslint-disable-line
const prevDay13 = moment().clone().add(-13, "days").format('L');// eslint-disable-line
const prevDay12 = moment().clone().add(-12, "days").format('L');// eslint-disable-line
const prevDay11 = moment().clone().add(-11, "days").format('L');// eslint-disable-line
const prevDay10 = moment().clone().add(-10, "days").format('L');// eslint-disable-line
const prevDay9 = moment().clone().add(-9, "days").format('L');// eslint-disable-line
const prevDay8 = moment().clone().add(-8, "days").format('L');// eslint-disable-line
const prevDay7 = moment().clone().add(-7, "days").format('L');// eslint-disable-line
const prevDay6 = moment().clone().add(-6, "days").format('L');// eslint-disable-line
const prevDay5 = moment().clone().add(-5, "days").format('L');// eslint-disable-line
const prevDay4 = moment().clone().add(-4, "days").format('L');// eslint-disable-line
const prevDay3 = moment().clone().add(-3, "days").format('L');// eslint-disable-line
const prevDay2 = moment().clone().add(-2, "days").format('L');// eslint-disable-line
const prevDay1 = moment().clone().add(-1, "days").format('L');// eslint-disable-line
const prevDay = moment().clone().add(-0, "days").format('L');// eslint-disable-line

class FinopsDashboardTableMonth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      app: undefined,
      env: undefined,
      compliances: undefined,
      alarms: undefined,
      alarmsWithHistory: undefined,
      listAlarmInProgress: false,
      loading: false,
      surveillance: undefined,
      modalSurveillanceOpen: false,
      modalAddChannelOpen: false,
      modalHistoryOpen: false,
      current_alarm: undefined,
      form_account_id_error: false,
      seconds: 0,
      applicationState: undefined,
      listComplianceInProgress: false,
      listCostInProgress: false,
      listMonthlyCostInProgress: false,
      CostService: undefined,
      chartHeight: 400,
      chartWidth: 600,
      DailyTotalLine: [],
      MonthlyTotal: [],
      SixMonthlyTotal: 0,
      AmortizedCost: [],
      DailyByService: [],
      LambdaCost: 0,
      CloudTCost: 0,
      ConfigCost: 0,
      DirCntCost: 0,
      GlueCost: 0,
      KeyMgtCost: 0,
      SecMgrCost: 0,
      SecHubCost: 0,
      ShieldCost: 0,
      WafCost: 0,
      DynaCost: 0,
      RDSCost: 0,
      EC2OtCost: 0,
      ECCCost: 0,
      ELBCost: 0,
      EFSCost: 0,
      FSXCost: 0,
      GDCost: 0,
      InspCost: 0,
      SESCost: 0,
      SNSCost: 0,
      SQSCost: 0,
      SSSCost: 0,
      VPCCost: 0,
      WSCost: 0,
      CWCost: 0,
      ASCost: 0,
      barData: [12, 5, 6, 6, 9, 10],
      barWidth: 700,
      barHeight: 500,
      criticalNonCompliantCount: 0,
      highNonCompliantCount: 0,
      mediumNonCompliantCount: 0,
      lowNonCompliantCount: 0,
      informationalNonCompliantCount: 0,
      unspecifiedNonCompliantCount: 0,
      resourceId: [],
      resourceIdExecution: [],
      secopsDonutData: [],
      cost: 0
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.handleSurveillanceClose = this.handleSurveillanceClose.bind(this);
    this.handleHistoryClose = this.handleHistoryClose.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
    this.listCost = this.listCost.bind(this);
    this.listMonthlyCost = this.listMonthlyCost.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getModalStyle() {
    return {
      margin: "auto"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });

    if (nextProps.account_id !== undefined) {
      if (
        this.state.alarms === undefined ||
        this.state.account_id !== nextProps.account_id
      ) {
        this.setState({
          loading: true,
          listComplianceInProgress: true,
          listCostInProgress: true,
          listMonthlyCostInProgress: true
        });
        getAccountState(this.state.account_id).then(result => {
          this.setState({
            applicationState: result
          });
        });
        this.listAlarms(nextProps.account_id);
        this.listCost(nextProps.account_id, "ALL");
        this.listMonthlyCost(nextProps.account_id, "ALL");
      }
    }
  }

  listMonthlyCost(account_id, serviceName) {
    //let service = serviceName;
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ce",
        boto_method: "get_cost_and_usage",
        account_id: account_id
      },
      body: {
        TimePeriod: {
          Start: startDay,
          End: endDay
        },
        Granularity: "DAILY",
        Metrics: ["AmortizedCost"]
      }
    }).then(response => {
      let DailyTotalLine = [];
      let ThresholdArray = [];
      let op_Threshold = 0;
      let TotalCost = [];
      let op_SixMonthly = 0;
      let SixMonthlyTotal = 0;

      function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
      }

      if (response["ResultsByTime"].length > 0) {
        DailyTotalLine = [// eslint-disable-line
          parseFloat(response["ResultsByTime"][0].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][1].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][2].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][3].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][4].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][5].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][6].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][7].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][8].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][9].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][10].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][11].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][12].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][13].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][14].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][15].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][16].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][17].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][18].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][19].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][20].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][21].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][22].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][23].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][24].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][25].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][26].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][27].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][28].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][29].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][30].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][31].Total.AmortizedCost.Amount).toFixed(2)// eslint-disable-line
        ];// eslint-disable-line
        ThresholdArray = [// eslint-disable-line
          parseFloat(response["ResultsByTime"][0].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][1].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][2].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][3].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][4].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][5].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][6].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][7].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][8].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][9].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][10].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][11].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][12].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][13].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][14].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][15].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][16].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][17].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][18].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][19].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][20].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][21].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][22].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][23].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][24].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][25].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][26].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][27].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][28].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][29].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][30].Total.AmortizedCost.Amount).toFixed(2)// eslint-disable-line
        ];// eslint-disable-line
        op_Threshold = getMaxOfArray(ThresholdArray);
        TotalCost[0] = {
          shortname: prevDay31.slice(0, 5),
          name: prevDay31,
          total: parseFloat(
            response["ResultsByTime"][0].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][0].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[1] = {
          shortname: prevDay30.slice(0, 5),
          name: prevDay30,
          total: parseFloat(
            response["ResultsByTime"][1].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][1].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[2] = {
          shortname: prevDay29.slice(0, 5),
          name: prevDay29,
          total: parseFloat(
            response["ResultsByTime"][2].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][2].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[3] = {
          shortname: prevDay28.slice(0, 5),
          name: prevDay28,
          total: parseFloat(
            response["ResultsByTime"][3].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][3].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[4] = {
          shortname: prevDay27.slice(0, 5),
          name: prevDay27,
          total: parseFloat(
            response["ResultsByTime"][4].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][4].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[5] = {
          shortname: prevDay26.slice(0, 5),
          name: prevDay26,
          total: parseFloat(
            response["ResultsByTime"][5].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][5].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[6] = {
          shortname: prevDay25.slice(0, 5),
          name: prevDay25,
          total: parseFloat(
            response["ResultsByTime"][6].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][6].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[7] = {
          shortname: prevDay24.slice(0, 5),
          name: prevDay24,
          total: parseFloat(
            response["ResultsByTime"][7].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][7].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[8] = {
          shortname: prevDay23.slice(0, 5),
          name: prevDay23,
          total: parseFloat(
            response["ResultsByTime"][8].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][8].Total.AmortizedCost.Amount
          ).toFixed(),
          "max par jour": op_Threshold
        };
        TotalCost[9] = {
          shortname: prevDay22.slice(0, 5),
          name: prevDay22,
          total: parseFloat(
            response["ResultsByTime"][9].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][9].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[10] = {
          shortname: prevDay21.slice(0, 5),
          name: prevDay21,
          total: parseFloat(
            response["ResultsByTime"][10].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][10].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[11] = {
          shortname: prevDay20.slice(0, 5),
          name: prevDay20,
          total: parseFloat(
            response["ResultsByTime"][11].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][11].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[12] = {
          shortname: prevDay19.slice(0, 5),
          name: prevDay19,
          total: parseFloat(
            response["ResultsByTime"][12].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][12].Total.AmortizedCost.Amount
          ).toFixed(),
          "max par jour": op_Threshold
        };
        TotalCost[13] = {
          shortname: prevDay18.slice(0, 5),
          name: prevDay18,
          total: parseFloat(
            response["ResultsByTime"][13].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][13].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[14] = {
          shortname: prevDay17.slice(0, 5),
          name: prevDay17,
          total: parseFloat(
            response["ResultsByTime"][14].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][14].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par jour": op_Threshold
        };
        TotalCost[15] = {
          shortname: prevDay16.slice(0, 5),
          name: prevDay16,
          total: parseFloat(
            response["ResultsByTime"][15].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][15].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[16] = {
          shortname: prevDay15.slice(0, 5),
          name: prevDay15,
          total: parseFloat(
            response["ResultsByTime"][16].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][16].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[17] = {
          shortname: prevDay14.slice(0, 5),
          name: prevDay14,
          total: parseFloat(
            response["ResultsByTime"][17].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][17].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[18] = {
          shortname: prevDay13.slice(0, 5),
          name: prevDay13,
          total: parseFloat(
            response["ResultsByTime"][18].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][18].Total.AmortizedCost.Amount
          ).toFixed(),
          "max par jour": op_Threshold
        };
        TotalCost[19] = {
          shortname: prevDay12.slice(0, 5),
          name: prevDay12,
          total: parseFloat(
            response["ResultsByTime"][19].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][19].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[20] = {
          shortname: prevDay11.slice(0, 5),
          name: prevDay11,
          total: parseFloat(
            response["ResultsByTime"][20].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][20].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[21] = {
          shortname: prevDay10.slice(0, 5),
          name: prevDay10,
          total: parseFloat(
            response["ResultsByTime"][21].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][21].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[22] = {
          shortname: prevDay9.slice(0, 5),
          name: prevDay9,
          total: parseFloat(
            response["ResultsByTime"][22].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][22].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[23] = {
          shortname: prevDay8.slice(0, 5),
          name: prevDay8,
          total: parseFloat(
            response["ResultsByTime"][23].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][23].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[24] = {
          shortname: prevDay7.slice(0, 5),
          name: prevDay7,
          total: parseFloat(
            response["ResultsByTime"][24].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][24].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[25] = {
          shortname: prevDay6.slice(0, 5),
          name: prevDay6,
          total: parseFloat(
            response["ResultsByTime"][25].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][25].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[26] = {
          shortname: prevDay5.slice(0, 5),
          name: prevDay5,
          total: parseFloat(
            response["ResultsByTime"][26].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][26].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[27] = {
          shortname: prevDay4.slice(0, 5),
          name: prevDay4,
          total: parseFloat(
            response["ResultsByTime"][27].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][27].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[28] = {
          shortname: prevDay3.slice(0, 5),
          name: prevDay3,
          total: parseFloat(
            response["ResultsByTime"][28].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][28].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[29] = {
          shortname: prevDay2.slice(0, 5),
          name: prevDay2,
          total: parseFloat(
            response["ResultsByTime"][29].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][29].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[30] = {
          shortname: prevDay1.slice(0, 5),
          name: prevDay1,
          total: parseFloat(
            response["ResultsByTime"][30].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][30].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        TotalCost[31] = {
          shortname: prevDay.slice(0, 5),
          name: prevDay,
          total: parseFloat(
            response["ResultsByTime"][31].Total.AmortizedCost.Amount
          ).toFixed(0),
          "total du jour": parseFloat(
            response["ResultsByTime"][31].Total.AmortizedCost.Amount
          ).toFixed(0),
          "max par jour": op_Threshold
        };
        op_SixMonthly =
          parseFloat(response["ResultsByTime"][0].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][1].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][2].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][3].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][4].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][5].Total.AmortizedCost.Amount);
        SixMonthlyTotal = op_SixMonthly.toFixed(2);
      }
      this.setState({
        MonthlyTotal: TotalCost,
        DailyTotalLine: DailyTotalLine,
        SixMonthlyTotal: SixMonthlyTotal,
        listFinopsInProgress: false
      });
    });
  }

  listCost(account_id, serviceName) {
    let service = serviceName;
    let costObj = {};
    let costObj1 = {};
    let costObj2 = {};
    let costObj3 = {};
    let costObj4 = {};
    let costObj5 = {};
    let costObj6 = {};
    let costObj7 = {};
    let costObj8 = {};
    let costObj9 = {};
    let costObj10 = {};
    let costObj11 = {};
    let costObj12 = {};
    let costObj13 = {};
    let costObj14 = {};
    let costObj15 = {};
    let costObj16 = {};
    let costObj17 = {};
    let costObj18 = {};
    let costObj19 = {};
    let costObj20 = {};
    let costObj21 = {};
    let costObj22 = {};
    let costObj23 = {};
    let costObj24 = {};
    let costObj25 = {};
    let costObj26 = {};
    let costObj27 = {};
    let costObj28 = {};
    let costObj29 = {};
    let costObj30 = {};
    let costObj31 = {};
    let cost,
      cost1,
      cost2,
      cost3,
      cost4,
      cost5,
      cost6,
      cost7,
      cost8,
      cost9,
      cost10,
      cost11,
      cost12,
      cost13,
      cost14,
      cost15,
      cost16,
      cost17,
      cost18,
      cost19,
      cost20,
      cost21,
      cost22,
      cost23,
      cost24,
      cost25,
      cost26,
      cost27,
      cost28,
      cost29,
      cost30,
      cost31 = 0;
    let costLambda,
      costLambda1,
      costLambda2,
      costLambda3,
      costLambda4,
      costLambda5,
      costLambda6,
      costLambda7,
      costLambda8,
      costLambda9,
      costLambda10,
      costLambda11,
      costLambda12,
      costLambda13,
      costLambda14,
      costLambda15,
      costLambda16,
      costLambda17,
      costLambda18,
      costLambda19,
      costLambda20,
      costLambda21,
      costLambda22,
      costLambda23,
      costLambda24,
      costLambda25,
      costLambda26,
      costLambda27,
      costLambda28,
      costLambda29,
      costLambda30,
      costLambda31 = 0;
    let costCloudT,
      costCloudT1,
      costCloudT2,
      costCloudT3,
      costCloudT4,
      costCloudT5,
      costCloudT6,
      costCloudT7,
      costCloudT8,
      costCloudT9,
      costCloudT10,
      costCloudT11,
      costCloudT12,
      costCloudT13,
      costCloudT14,
      costCloudT15,
      costCloudT16,
      costCloudT17,
      costCloudT18,
      costCloudT19,
      costCloudT20,
      costCloudT21,
      costCloudT22,
      costCloudT23,
      costCloudT24,
      costCloudT25,
      costCloudT26,
      costCloudT27,
      costCloudT28,
      costCloudT29,
      costCloudT30,
      costCloudT31 = 0;
    let costConfig,
      costConfig1,
      costConfig2,
      costConfig3,
      costConfig4,
      costConfig5,
      costConfig6,
      costConfig7,
      costConfig8,
      costConfig9,
      costConfig10,
      costConfig11,
      costConfig12,
      costConfig13,
      costConfig14,
      costConfig15,
      costConfig16,
      costConfig17,
      costConfig18,
      costConfig19,
      costConfig20,
      costConfig21,
      costConfig22,
      costConfig23,
      costConfig24,
      costConfig25,
      costConfig26,
      costConfig27,
      costConfig28,
      costConfig29,
      costConfig30,
      costConfig31 = 0;
    let costDirCnt,
      costDirCnt1,
      costDirCnt2,
      costDirCnt3,
      costDirCnt4,
      costDirCnt5,
      costDirCnt6,
      costDirCnt7,
      costDirCnt8,
      costDirCnt9,
      costDirCnt10,
      costDirCnt11,
      costDirCnt12,
      costDirCnt13,
      costDirCnt14,
      costDirCnt15,
      costDirCnt16,
      costDirCnt17,
      costDirCnt18,
      costDirCnt19,
      costDirCnt20,
      costDirCnt21,
      costDirCnt22,
      costDirCnt23,
      costDirCnt24,
      costDirCnt25,
      costDirCnt26,
      costDirCnt27,
      costDirCnt28,
      costDirCnt29,
      costDirCnt30,
      costDirCnt31 = 0;
    let costGlue,
      costGlue1,
      costGlue2,
      costGlue3,
      costGlue4,
      costGlue5,
      costGlue6,
      costGlue7,
      costGlue8,
      costGlue9,
      costGlue10,
      costGlue11,
      costGlue12,
      costGlue13,
      costGlue14,
      costGlue15,
      costGlue16,
      costGlue17,
      costGlue18,
      costGlue19,
      costGlue20,
      costGlue21,
      costGlue22,
      costGlue23,
      costGlue24,
      costGlue25,
      costGlue26,
      costGlue27,
      costGlue28,
      costGlue29,
      costGlue30,
      costGlue31 = 0;
    let costKeyMgt,
      costKeyMgt1,
      costKeyMgt2,
      costKeyMgt3,
      costKeyMgt4,
      costKeyMgt5,
      costKeyMgt6,
      costKeyMgt7,
      costKeyMgt8,
      costKeyMgt9,
      costKeyMgt10,
      costKeyMgt11,
      costKeyMgt12,
      costKeyMgt13,
      costKeyMgt14,
      costKeyMgt15,
      costKeyMgt16,
      costKeyMgt17,
      costKeyMgt18,
      costKeyMgt19,
      costKeyMgt20,
      costKeyMgt21,
      costKeyMgt22,
      costKeyMgt23,
      costKeyMgt24,
      costKeyMgt25,
      costKeyMgt26,
      costKeyMgt27,
      costKeyMgt28,
      costKeyMgt29,
      costKeyMgt30,
      costKeyMgt31 = 0;
    let costSecMgr,
      costSecMgr1,
      costSecMgr2,
      costSecMgr3,
      costSecMgr4,
      costSecMgr5,
      costSecMgr6,
      costSecMgr7,
      costSecMgr8,
      costSecMgr9,
      costSecMgr10,
      costSecMgr11,
      costSecMgr12,
      costSecMgr13,
      costSecMgr14,
      costSecMgr15,
      costSecMgr16,
      costSecMgr17,
      costSecMgr18,
      costSecMgr19,
      costSecMgr20,
      costSecMgr21,
      costSecMgr22,
      costSecMgr23,
      costSecMgr24,
      costSecMgr25,
      costSecMgr26,
      costSecMgr27,
      costSecMgr28,
      costSecMgr29,
      costSecMgr30,
      costSecMgr31 = 0;
    let costSecHub,
      costSecHub1,
      costSecHub2,
      costSecHub3,
      costSecHub4,
      costSecHub5,
      costSecHub6,
      costSecHub7,
      costSecHub8,
      costSecHub9,
      costSecHub10,
      costSecHub11,
      costSecHub12,
      costSecHub13,
      costSecHub14,
      costSecHub15,
      costSecHub16,
      costSecHub17,
      costSecHub18,
      costSecHub19,
      costSecHub20,
      costSecHub21,
      costSecHub22,
      costSecHub23,
      costSecHub24,
      costSecHub25,
      costSecHub26,
      costSecHub27,
      costSecHub28,
      costSecHub29,
      costSecHub30,
      costSecHub31 = 0;
    let costShield,
      costShield1,
      costShield2,
      costShield3,
      costShield4,
      costShield5,
      costShield6,
      costShield7,
      costShield8,
      costShield9,
      costShield10,
      costShield11,
      costShield12,
      costShield13,
      costShield14,
      costShield15,
      costShield16,
      costShield17,
      costShield18,
      costShield19,
      costShield20,
      costShield21,
      costShield22,
      costShield23,
      costShield24,
      costShield25,
      costShield26,
      costShield27,
      costShield28,
      costShield29,
      costShield30,
      costShield31 = 0;
    let costWaf,
      costWaf1,
      costWaf2,
      costWaf3,
      costWaf4,
      costWaf5,
      costWaf6,
      costWaf7,
      costWaf8,
      costWaf9,
      costWaf10,
      costWaf11,
      costWaf12,
      costWaf13,
      costWaf14,
      costWaf15,
      costWaf16,
      costWaf17,
      costWaf18,
      costWaf19,
      costWaf20,
      costWaf21,
      costWaf22,
      costWaf23,
      costWaf24,
      costWaf25,
      costWaf26,
      costWaf27,
      costWaf28,
      costWaf29,
      costWaf30,
      costWaf31 = 0;
    let costDyna,
      costDyna1,
      costDyna2,
      costDyna3,
      costDyna4,
      costDyna5,
      costDyna6,
      costDyna7,
      costDyna8,
      costDyna9,
      costDyna10,
      costDyna11,
      costDyna12,
      costDyna13,
      costDyna14,
      costDyna15,
      costDyna16,
      costDyna17,
      costDyna18,
      costDyna19,
      costDyna20,
      costDyna21,
      costDyna22,
      costDyna23,
      costDyna24,
      costDyna25,
      costDyna26,
      costDyna27,
      costDyna28,
      costDyna29,
      costDyna30,
      costDyna31 = 0;
    let costRDS,
      costRDS1,
      costRDS2,
      costRDS3,
      costRDS4,
      costRDS5,
      costRDS6,
      costRDS7,
      costRDS8,
      costRDS9,
      costRDS10,
      costRDS11,
      costRDS12,
      costRDS13,
      costRDS14,
      costRDS15,
      costRDS16,
      costRDS17,
      costRDS18,
      costRDS19,
      costRDS20,
      costRDS21,
      costRDS22,
      costRDS23,
      costRDS24,
      costRDS25,
      costRDS26,
      costRDS27,
      costRDS28,
      costRDS29,
      costRDS30,
      costRDS31 = 0;
    let costEC2Ot,
      costEC2Ot1,
      costEC2Ot2,
      costEC2Ot3,
      costEC2Ot4,
      costEC2Ot5,
      costEC2Ot6,
      costEC2Ot7,
      costEC2Ot8,
      costEC2Ot9,
      costEC2Ot10,
      costEC2Ot11,
      costEC2Ot12,
      costEC2Ot13,
      costEC2Ot14,
      costEC2Ot15,
      costEC2Ot16,
      costEC2Ot17,
      costEC2Ot18,
      costEC2Ot19,
      costEC2Ot20,
      costEC2Ot21,
      costEC2Ot22,
      costEC2Ot23,
      costEC2Ot24,
      costEC2Ot25,
      costEC2Ot26,
      costEC2Ot27,
      costEC2Ot28,
      costEC2Ot29,
      costEC2Ot30,
      costEC2Ot31 = 0;
    let costECC,
      costECC1,
      costECC2,
      costECC3,
      costECC4,
      costECC5,
      costECC6,
      costECC7,
      costECC8,
      costECC9,
      costECC10,
      costECC11,
      costECC12,
      costECC13,
      costECC14,
      costECC15,
      costECC16,
      costECC17,
      costECC18,
      costECC19,
      costECC20,
      costECC21,
      costECC22,
      costECC23,
      costECC24,
      costECC25,
      costECC26,
      costECC27,
      costECC28,
      costECC29,
      costECC30,
      costECC31 = 0;
    let costELB,
      costELB1,
      costELB2,
      costELB3,
      costELB4,
      costELB5,
      costELB6,
      costELB7,
      costELB8,
      costELB9,
      costELB10,
      costELB11,
      costELB12,
      costELB13,
      costELB14,
      costELB15,
      costELB16,
      costELB17,
      costELB18,
      costELB19,
      costELB20,
      costELB21,
      costELB22,
      costELB23,
      costELB24,
      costELB25,
      costELB26,
      costELB27,
      costELB28,
      costELB29,
      costELB30,
      costELB31 = 0;
    let costEFS,
      costEFS1,
      costEFS2,
      costEFS3,
      costEFS4,
      costEFS5,
      costEFS6,
      costEFS7,
      costEFS8,
      costEFS9,
      costEFS10,
      costEFS11,
      costEFS12,
      costEFS13,
      costEFS14,
      costEFS15,
      costEFS16,
      costEFS17,
      costEFS18,
      costEFS19,
      costEFS20,
      costEFS21,
      costEFS22,
      costEFS23,
      costEFS24,
      costEFS25,
      costEFS26,
      costEFS27,
      costEFS28,
      costEFS29,
      costEFS30,
      costEFS31 = 0;
    let costFSX,
      costFSX1,
      costFSX2,
      costFSX3,
      costFSX4,
      costFSX5,
      costFSX6,
      costFSX7,
      costFSX8,
      costFSX9,
      costFSX10,
      costFSX11,
      costFSX12,
      costFSX13,
      costFSX14,
      costFSX15,
      costFSX16,
      costFSX17,
      costFSX18,
      costFSX19,
      costFSX20,
      costFSX21,
      costFSX22,
      costFSX23,
      costFSX24,
      costFSX25,
      costFSX26,
      costFSX27,
      costFSX28,
      costFSX29,
      costFSX30,
      costFSX31 = 0;
    let costGD,
      costGD1,
      costGD2,
      costGD3,
      costGD4,
      costGD5,
      costGD6,
      costGD7,
      costGD8,
      costGD9,
      costGD10,
      costGD11,
      costGD12,
      costGD13,
      costGD14,
      costGD15,
      costGD16,
      costGD17,
      costGD18,
      costGD19,
      costGD20,
      costGD21,
      costGD22,
      costGD23,
      costGD24,
      costGD25,
      costGD26,
      costGD27,
      costGD28,
      costGD29,
      costGD30,
      costGD31 = 0;
    let costInsp,
      costInsp1,
      costInsp2,
      costInsp3,
      costInsp4,
      costInsp5,
      costInsp6,
      costInsp7,
      costInsp8,
      costInsp9,
      costInsp10,
      costInsp11,
      costInsp12,
      costInsp13,
      costInsp14,
      costInsp15,
      costInsp16,
      costInsp17,
      costInsp18,
      costInsp19,
      costInsp20,
      costInsp21,
      costInsp22,
      costInsp23,
      costInsp24,
      costInsp25,
      costInsp26,
      costInsp27,
      costInsp28,
      costInsp29,
      costInsp30,
      costInsp31 = 0;
    let costSES,
      costSES1,
      costSES2,
      costSES3,
      costSES4,
      costSES5,
      costSES6,
      costSES7,
      costSES8,
      costSES9,
      costSES10,
      costSES11,
      costSES12,
      costSES13,
      costSES14,
      costSES15,
      costSES16,
      costSES17,
      costSES18,
      costSES19,
      costSES20,
      costSES21,
      costSES22,
      costSES23,
      costSES24,
      costSES25,
      costSES26,
      costSES27,
      costSES28,
      costSES29,
      costSES30,
      costSES31 = 0;
    let costSNS,
      costSNS1,
      costSNS2,
      costSNS3,
      costSNS4,
      costSNS5,
      costSNS6,
      costSNS7,
      costSNS8,
      costSNS9,
      costSNS10,
      costSNS11,
      costSNS12,
      costSNS13,
      costSNS14,
      costSNS15,
      costSNS16,
      costSNS17,
      costSNS18,
      costSNS19,
      costSNS20,
      costSNS21,
      costSNS22,
      costSNS23,
      costSNS24,
      costSNS25,
      costSNS26,
      costSNS27,
      costSNS28,
      costSNS29,
      costSNS30,
      costSNS31 = 0;
    let costSQS,
      costSQS1,
      costSQS2,
      costSQS3,
      costSQS4,
      costSQS5,
      costSQS6,
      costSQS7,
      costSQS8,
      costSQS9,
      costSQS10,
      costSQS11,
      costSQS12,
      costSQS13,
      costSQS14,
      costSQS15,
      costSQS16,
      costSQS17,
      costSQS18,
      costSQS19,
      costSQS20,
      costSQS21,
      costSQS22,
      costSQS23,
      costSQS24,
      costSQS25,
      costSQS26,
      costSQS27,
      costSQS28,
      costSQS29,
      costSQS30,
      costSQS31 = 0;
    let costSSS,
      costSSS1,
      costSSS2,
      costSSS3,
      costSSS4,
      costSSS5,
      costSSS6,
      costSSS7,
      costSSS8,
      costSSS9,
      costSSS10,
      costSSS11,
      costSSS12,
      costSSS13,
      costSSS14,
      costSSS15,
      costSSS16,
      costSSS17,
      costSSS18,
      costSSS19,
      costSSS20,
      costSSS21,
      costSSS22,
      costSSS23,
      costSSS24,
      costSSS25,
      costSSS26,
      costSSS27,
      costSSS28,
      costSSS29,
      costSSS30,
      costSSS31 = 0;
    let costVPC,
      costVPC1,
      costVPC2,
      costVPC3,
      costVPC4,
      costVPC5,
      costVPC6,
      costVPC7,
      costVPC8,
      costVPC9,
      costVPC10,
      costVPC11,
      costVPC12,
      costVPC13,
      costVPC14,
      costVPC15,
      costVPC16,
      costVPC17,
      costVPC18,
      costVPC19,
      costVPC20,
      costVPC21,
      costVPC22,
      costVPC23,
      costVPC24,
      costVPC25,
      costVPC26,
      costVPC27,
      costVPC28,
      costVPC29,
      costVPC30,
      costVPC31 = 0;
    let costWS,
      costWS1,
      costWS2,
      costWS3,
      costWS4,
      costWS5,
      costWS6,
      costWS7,
      costWS8,
      costWS9,
      costWS10,
      costWS11,
      costWS12,
      costWS13,
      costWS14,
      costWS15,
      costWS16,
      costWS17,
      costWS18,
      costWS19,
      costWS20,
      costWS21,
      costWS22,
      costWS23,
      costWS24,
      costWS25,
      costWS26,
      costWS27,
      costWS28,
      costWS29,
      costWS30,
      costWS31 = 0;
    let costCW,
      costCW1,
      costCW2,
      costCW3,
      costCW4,
      costCW5,
      costCW6,
      costCW7,
      costCW8,
      costCW9,
      costCW10,
      costCW11,
      costCW12,
      costCW13,
      costCW14,
      costCW15,
      costCW16,
      costCW17,
      costCW18,
      costCW19,
      costCW20,
      costCW21,
      costCW22,
      costCW23,
      costCW24,
      costCW25,
      costCW26,
      costCW27,
      costCW28,
      costCW29,
      costCW30,
      costCW31 = 0;
    let costCWE,
      costCWE1,
      costCWE2,
      costCWE3,
      costCWE4,
      costCWE5,
      costCWE6,
      costCWE7,
      costCWE8,
      costCWE9,
      costCWE10,
      costCWE11,
      costCWE12,
      costCWE13,
      costCWE14,
      costCWE15,
      costCWE16,
      costCWE17,
      costCWE18,
      costCWE19,
      costCWE20,
      costCWE21,
      costCWE22,
      costCWE23,
      costCWE24,
      costCWE25,
      costCWE26,
      costCWE27,
      costCWE28,
      costCWE29,
      costCWE30,
      costCWE31 = 0;
    let costCA,
      costCA1,
      costCA2,
      costCA3,
      costCA4,
      costCA5,
      costCA6,
      costCA7,
      costCA8,
      costCA9,
      costCA10,
      costCA11,
      costCA12,
      costCA13,
      costCA14,
      costCA15,
      costCA16,
      costCA17,
      costCA18,
      costCA19,
      costCA20,
      costCA21,
      costCA22,
      costCA23,
      costCA24,
      costCA25,
      costCA26,
      costCA27,
      costCA28,
      costCA29,
      costCA30,
      costCA31 = 0;
    let costSF,
      costSF1,
      costSF2,
      costSF3,
      costSF4,
      costSF5,
      costSF6,
      costSF7,
      costSF8,
      costSF9,
      costSF10,
      costSF11,
      costSF12,
      costSF13,
      costSF14,
      costSF15,
      costSF16,
      costSF17,
      costSF18,
      costSF19,
      costSF20,
      costSF21,
      costSF22,
      costSF23,
      costSF24,
      costSF25,
      costSF26,
      costSF27,
      costSF28,
      costSF29,
      costSF30,
      costSF31 = 0;
    let costECR,
      costECR1,
      costECR2,
      costECR3,
      costECR4,
      costECR5,
      costECR6,
      costECR7,
      costECR8,
      costECR9,
      costECR10,
      costECR11,
      costECR12,
      costECR13,
      costECR14,
      costECR15,
      costECR16,
      costECR17,
      costECR18,
      costECR19,
      costECR20,
      costECR21,
      costECR22,
      costECR23,
      costECR24,
      costECR25,
      costECR26,
      costECR27,
      costECR28,
      costECR29,
      costECR30,
      costECR31 = 0;
    let costTF,
      costTF1,
      costTF2,
      costTF3,
      costTF4,
      costTF5,
      costTF6,
      costTF7,
      costTF8,
      costTF9,
      costTF10,
      costTF11,
      costTF12,
      costTF13,
      costTF14,
      costTF15,
      costTF16,
      costTF17,
      costTF18,
      costTF19,
      costTF20,
      costTF21,
      costTF22,
      costTF23,
      costTF24,
      costTF25,
      costTF26,
      costTF27,
      costTF28,
      costTF29,
      costTF30,
      costTF31 = 0;
    let costAG,
      costAG1,
      costAG2,
      costAG3,
      costAG4,
      costAG5,
      costAG6,
      costAG7,
      costAG8,
      costAG9,
      costAG10,
      costAG11,
      costAG12,
      costAG13,
      costAG14,
      costAG15,
      costAG16,
      costAG17,
      costAG18,
      costAG19,
      costAG20,
      costAG21,
      costAG22,
      costAG23,
      costAG24,
      costAG25,
      costAG26,
      costAG27,
      costAG28,
      costAG29,
      costAG30,
      costAG31 = 0;
    let costAS,
      costAS1,
      costAS2,
      costAS3,
      costAS4,
      costAS5,
      costAS6,
      costAS7,
      costAS8,
      costAS9,
      costAS10,
      costAS11,
      costAS12,
      costAS13,
      costAS14,
      costAS15,
      costAS16,
      costAS17,
      costAS18,
      costAS19,
      costAS20,
      costAS21,
      costAS22,
      costAS23,
      costAS24,
      costAS25,
      costAS26,
      costAS27,
      costAS28,
      costAS29,
      costAS30,
      costAS31 = 0;
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ce",
        boto_method: "get_cost_and_usage",
        account_id: account_id
      },
      body: {
        TimePeriod: {
          Start: startDay,
          End: endDay
        },
        Granularity: "DAILY",
        GroupBy: [
          {
            Key: "SERVICE",
            Type: "DIMENSION"
          }
        ],
        Filter: {
          Dimensions: {
            Key: "REGION",
            MatchOptions: ["EQUALS"],
            Values: ["eu-west-1"]
          }
        },
        Metrics: ["AmortizedCost"]
      }
    }).then(async response => {
      let DailyCost = [];
      if (response["ResultsByTime"].length > 0) {
        cost = response["ResultsByTime"][0].Groups;
        cost.forEach((element, index, array) => {
          //console.log(element.Keys);
          if (element.Keys[0] === "AWS Lambda") {
            costLambda = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Lambda"] = costLambda;
            //console.log(costLambda);
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT = element.Metrics.AmortizedCost.Amount;
            costObj["AWS CloudTrail"] = costCloudT;
            //console.log(costCloudT);
          } else if (element.Keys[0] === "AWS Config") {
            costConfig = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Config"] = costConfig;
            //console.log(costConfig);
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Direct Connect"] = costDirCnt;
            //console.log(costDirCnt);
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Glue"] = costGlue;
            //console.log(costGlue);
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Key Management Service"] = costKeyMgt;
            //console.log(costKeyMgt);
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Secrets Manager"] = costSecMgr;
            //console.log(costSecMgr);
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Security Hub"] = costSecHub;
            //console.log(costSecHub);
          } else if (element.Keys[0] === "AWS Shield") {
            costShield = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Shield"] = costShield;
            //console.log(costShield);
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf = element.Metrics.AmortizedCost.Amount;
            costObj["AWS WAF"] = costWaf;
            //console.log(costWaf);
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon DynamoDB"] = costDyna;
            //console.log(costDyna);
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Relational Database Service"] = costRDS;
            //console.log(costDyna);
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot = element.Metrics.AmortizedCost.Amount;
            costObj["EC2 - Other"] = costEC2Ot;
            //console.log(costEC2Ot);
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Elastic Compute Cloud - Compute"] = costECC;
            //console.log(costECC);
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Elastic Load Balancing"] = costELB;
            //console.log(costELB);
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Elastic File System"] = costEFS;
            //console.log(costEFS);
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon FSx"] = costFSX;
            //console.log(costFSX);
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon GuardDuty"] = costGD;
            //console.log(costGD);
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Inspector"] = costInsp;
            //console.log(costInsp);
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Email Service"] = costSES;
            //console.log(costSNS);
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Notification Service"] = costSNS;
            //console.log(costSNS);
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Queue Service"] = costSQS;
            //console.log(costSQS);
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Storage Service"] = costSSS;
            //console.log(costSSS);
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Virtual Private Cloud"] = costVPC;
            //console.log(costVPC);
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon WorkSpaces"] = costWS;
            //console.log(costWS);
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW = element.Metrics.AmortizedCost.Amount;
            costObj["AmazonCloudWatch"] = costCW;
            //console.log(costCW);
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE = element.Metrics.AmortizedCost.Amount;
            costObj["CloudWatch Events"] = costCWE;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA = element.Metrics.AmortizedCost.Amount;
            costObj["AWS CodeArtifact"] = costCA;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Step Functions"] = costSF;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon EC2 Container Registry (ECR)"] = costECR;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Transfer Family"] = costTF;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon API Gateway"] = costAG;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon AppStream"] = costAS;
          }
        });
        cost1 = response["ResultsByTime"][1].Groups;
        cost1.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Lambda"] = costLambda1;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS CloudTrail"] = costCloudT1;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Config"] = costConfig1;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Direct Connect"] = costDirCnt1;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Glue"] = costGlue1;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Key Management Service"] = costKeyMgt1;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Secrets Manager"] = costSecMgr1;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Security Hub"] = costSecHub1;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Shield"] = costShield1;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS WAF"] = costWaf1;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon DynamoDB"] = costDyna1;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Relational Database Service"] = costRDS1;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot1 = element.Metrics.AmortizedCost.Amount;
            costObj1["EC2 - Other"] = costEC2Ot1;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Elastic Compute Cloud - Compute"] = costECC1;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Elastic Load Balancing"] = costELB1;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Elastic File System"] = costEFS1;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon FSx"] = costFSX1;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon GuardDuty"] = costGD1;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Inspector"] = costInsp1;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Email Service"] = costSES1;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Notification Service"] = costSNS1;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Queue Service"] = costSQS1;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Storage Service"] = costSSS1;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Virtual Private Cloud"] = costVPC1;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon WorkSpaces"] = costWS1;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AmazonCloudWatch"] = costCW1;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE1 = element.Metrics.AmortizedCost.Amount;
            costObj1["CloudWatch Events"] = costCWE1;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS CodeArtifact"] = costCA1;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Step Functions"] = costSF1;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon EC2 Container Registry (ECR)"] = costECR1;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Transfer Family"] = costTF1;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon API Gateway"] = costAG1;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon AppStream"] = costAS1;
          }
        });
        cost2 = response["ResultsByTime"][2].Groups;
        cost2.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Lambda"] = costLambda2;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS CloudTrail"] = costCloudT2;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Config"] = costConfig2;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Direct Connect"] = costDirCnt2;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Glue"] = costGlue2;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Key Management Service"] = costKeyMgt2;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Secrets Manager"] = costSecMgr2;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Security Hub"] = costSecHub2;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Shield"] = costShield2;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS WAF"] = costWaf2;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon DynamoDB"] = costDyna2;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Relational Database Service"] = costRDS2;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot2 = element.Metrics.AmortizedCost.Amount;
            costObj2["EC2 - Other"] = costEC2Ot2;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Elastic Compute Cloud - Compute"] = costECC2;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Elastic Load Balancing"] = costELB2;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Elastic File System"] = costEFS2;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon FSx"] = costFSX2;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon GuardDuty"] = costGD2;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Inspector"] = costInsp2;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Email Service"] = costSES2;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Notification Service"] = costSNS2;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Queue Service"] = costSQS2;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Storage Service"] = costSSS2;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Virtual Private Cloud"] = costVPC2;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon WorkSpaces"] = costWS2;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AmazonCloudWatch"] = costCW2;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE2 = element.Metrics.AmortizedCost.Amount;
            costObj2["CloudWatch Events"] = costCWE2;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS CodeArtifact"] = costCA2;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Step Functions"] = costSF2;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon EC2 Container Registry (ECR)"] = costECR2;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Transfer Family"] = costTF2;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon API Gateway"] = costAG2;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon AppStream"] = costAS2;
          }
        });
        cost3 = response["ResultsByTime"][3].Groups;
        cost3.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Lambda"] = costLambda3;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS CloudTrail"] = costCloudT3;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Config"] = costConfig3;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Direct Connect"] = costDirCnt3;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Glue"] = costGlue3;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Key Management Service"] = costKeyMgt3;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Secrets Manager"] = costSecMgr3;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Security Hub"] = costSecHub3;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Shield"] = costShield3;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS WAF"] = costWaf3;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon DynamoDB"] = costDyna3;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Relational Database Service"] = costRDS3;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot3 = element.Metrics.AmortizedCost.Amount;
            costObj3["EC2 - Other"] = costEC2Ot3;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Elastic Compute Cloud - Compute"] = costECC3;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Elastic Load Balancing"] = costELB3;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Elastic File System"] = costEFS3;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon FSx"] = costFSX3;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon GuardDuty"] = costGD3;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Inspector"] = costInsp3;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Email Service"] = costSES3;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Notification Service"] = costSNS3;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Queue Service"] = costSQS3;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Storage Service"] = costSSS3;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Virtual Private Cloud"] = costVPC3;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon WorkSpaces"] = costWS3;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AmazonCloudWatch"] = costCW3;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE3 = element.Metrics.AmortizedCost.Amount;
            costObj3["CloudWatch Events"] = costCWE3;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS CodeArtifact"] = costCA3;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Step Functions"] = costSF3;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon EC2 Container Registry (ECR)"] = costECR3;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Transfer Family"] = costTF3;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon API Gateway"] = costAG3;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon AppStream"] = costAS3;
          }
        });
        cost4 = response["ResultsByTime"][4].Groups;
        cost4.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Lambda"] = costLambda4;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS CloudTrail"] = costCloudT4;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Config"] = costConfig4;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Direct Connect"] = costDirCnt4;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Glue"] = costGlue4;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Key Management Service"] = costKeyMgt4;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Secrets Manager"] = costSecMgr4;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Security Hub"] = costSecHub4;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Shield"] = costShield4;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS WAF"] = costWaf4;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon DynamoDB"] = costDyna4;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Relational Database Service"] = costRDS4;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot4 = element.Metrics.AmortizedCost.Amount;
            costObj4["EC2 - Other"] = costEC2Ot4;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Elastic Compute Cloud - Compute"] = costECC4;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Elastic Load Balancing"] = costELB4;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Elastic File System"] = costEFS4;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon FSx"] = costFSX4;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon GuardDuty"] = costGD4;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Inspector"] = costInsp4;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Email Service"] = costSES4;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Notification Service"] = costSNS4;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Queue Service"] = costSQS4;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Storage Service"] = costSSS4;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Virtual Private Cloud"] = costVPC4;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon WorkSpaces"] = costWS4;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AmazonCloudWatch"] = costCW4;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE4 = element.Metrics.AmortizedCost.Amount;
            costObj4["CloudWatch Events"] = costCWE4;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS CodeArtifact"] = costCA4;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Step Functions"] = costSF4;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon EC2 Container Registry (ECR)"] = costECR4;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Transfer Family"] = costTF4;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon API Gateway"] = costAG4;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon AppStream"] = costAS4;
          }
        });
        cost5 = response["ResultsByTime"][5].Groups;
        cost5.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Lambda"] = costLambda5;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS CloudTrail"] = costCloudT5;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Config"] = costConfig5;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Direct Connect"] = costDirCnt5;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Glue"] = costGlue5;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Key Management Service"] = costKeyMgt5;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Secrets Manager"] = costSecMgr5;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Security Hub"] = costSecHub5;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Shield"] = costShield5;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS WAF"] = costWaf5;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon DynamoDB"] = costDyna5;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Relational Database Service"] = costRDS5;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot5 = element.Metrics.AmortizedCost.Amount;
            costObj5["EC2 - Other"] = costEC2Ot5;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Elastic Compute Cloud - Compute"] = costECC5;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Elastic Load Balancing"] = costELB5;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Elastic File System"] = costEFS5;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon FSx"] = costFSX5;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon GuardDuty"] = costGD5;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Inspector"] = costInsp5;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Email Service"] = costSES5;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Notification Service"] = costSNS5;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Queue Service"] = costSQS5;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Storage Service"] = costSSS5;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Virtual Private Cloud"] = costVPC5;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon WorkSpaces"] = costWS5;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AmazonCloudWatch"] = costCW5;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE5 = element.Metrics.AmortizedCost.Amount;
            costObj5["CloudWatch Events"] = costCWE5;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS CodeArtifact"] = costCA5;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Step Functions"] = costSF5;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon EC2 Container Registry (ECR)"] = costECR5;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Transfer Family"] = costTF5;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon API Gateway"] = costAG5;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon AppStream"] = costAS5;
          }
        });
        cost6 = response["ResultsByTime"][6].Groups;
        cost6.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Lambda"] = costLambda6;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS CloudTrail"] = costCloudT6;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Config"] = costConfig6;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Direct Connect"] = costDirCnt6;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Glue"] = costGlue6;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Key Management Service"] = costKeyMgt6;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Secrets Manager"] = costSecMgr6;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Security Hub"] = costSecHub6;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Shield"] = costShield6;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS WAF"] = costWaf6;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon DynamoDB"] = costDyna6;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Relational Database Service"] = costRDS6;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot6 = element.Metrics.AmortizedCost.Amount;
            costObj6["EC2 - Other"] = costEC2Ot6;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Elastic Compute Cloud - Compute"] = costECC6;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Elastic Load Balancing"] = costELB6;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Elastic File System"] = costEFS6;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon FSx"] = costFSX6;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon GuardDuty"] = costGD6;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Inspector"] = costInsp6;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Email Service"] = costSES6;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Notification Service"] = costSNS6;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Queue Service"] = costSQS6;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Storage Service"] = costSSS6;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Virtual Private Cloud"] = costVPC6;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon WorkSpaces"] = costWS6;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AmazonCloudWatch"] = costCW6;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE6 = element.Metrics.AmortizedCost.Amount;
            costObj6["CloudWatch Events"] = costCWE6;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS CodeArtifact"] = costCA6;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Step Functions"] = costSF6;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon EC2 Container Registry (ECR)"] = costECR6;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Transfer Family"] = costTF6;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon API Gateway"] = costAG6;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon AppStream"] = costAS6;
          }
        });
        cost7 = response["ResultsByTime"][7].Groups;
        cost7.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Lambda"] = costLambda7;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS CloudTrail"] = costCloudT7;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Config"] = costConfig7;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Direct Connect"] = costDirCnt7;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Glue"] = costGlue7;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Key Management Service"] = costKeyMgt7;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Secrets Manager"] = costSecMgr7;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Security Hub"] = costSecHub7;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Shield"] = costShield7;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS WAF"] = costWaf7;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon DynamoDB"] = costDyna7;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Relational Database Service"] = costRDS7;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot7 = element.Metrics.AmortizedCost.Amount;
            costObj7["EC2 - Other"] = costEC2Ot7;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Elastic Compute Cloud - Compute"] = costECC7;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Elastic Load Balancing"] = costELB7;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Elastic File System"] = costEFS7;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon FSx"] = costFSX7;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon GuardDuty"] = costGD7;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Inspector"] = costInsp7;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Email Service"] = costSES7;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Notification Service"] = costSNS7;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Queue Service"] = costSQS7;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Storage Service"] = costSSS7;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Virtual Private Cloud"] = costVPC7;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon WorkSpaces"] = costWS7;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AmazonCloudWatch"] = costCW7;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE7 = element.Metrics.AmortizedCost.Amount;
            costObj7["CloudWatch Events"] = costCWE7;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS CodeArtifact"] = costCA7;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Step Functions"] = costSF7;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon EC2 Container Registry (ECR)"] = costECR7;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Transfer Family"] = costTF7;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon API Gateway"] = costAG7;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon AppStream"] = costAS7;
          }
        });
        cost8 = response["ResultsByTime"][8].Groups;
        cost8.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Lambda"] = costLambda8;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS CloudTrail"] = costCloudT8;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Config"] = costConfig8;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Direct Connect"] = costDirCnt8;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Glue"] = costGlue8;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Key Management Service"] = costKeyMgt8;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Secrets Manager"] = costSecMgr8;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Security Hub"] = costSecHub8;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Shield"] = costShield8;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS WAF"] = costWaf8;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon DynamoDB"] = costDyna8;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Relational Database Service"] = costRDS8;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot8 = element.Metrics.AmortizedCost.Amount;
            costObj8["EC2 - Other"] = costEC2Ot8;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Elastic Compute Cloud - Compute"] = costECC8;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Elastic Load Balancing"] = costELB8;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Elastic File System"] = costEFS8;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon FSx"] = costFSX8;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon GuardDuty"] = costGD8;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Inspector"] = costInsp8;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Email Service"] = costSES8;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Notification Service"] = costSNS8;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Queue Service"] = costSQS8;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Storage Service"] = costSSS8;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Virtual Private Cloud"] = costVPC8;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon WorkSpaces"] = costWS8;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AmazonCloudWatch"] = costCW8;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE8 = element.Metrics.AmortizedCost.Amount;
            costObj8["CloudWatch Events"] = costCWE8;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS CodeArtifact"] = costCA8;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Step Functions"] = costSF8;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon EC2 Container Registry (ECR)"] = costECR8;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Transfer Family"] = costTF8;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon API Gateway"] = costAG8;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon AppStream"] = costAS8;
          }
        });
        cost9 = response["ResultsByTime"][9].Groups;
        cost9.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Lambda"] = costLambda9;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS CloudTrail"] = costCloudT9;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Config"] = costConfig9;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Direct Connect"] = costDirCnt9;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Glue"] = costGlue9;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Key Management Service"] = costKeyMgt9;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Secrets Manager"] = costSecMgr9;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Security Hub"] = costSecHub9;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Shield"] = costShield9;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS WAF"] = costWaf9;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon DynamoDB"] = costDyna9;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Relational Database Service"] = costRDS9;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot9 = element.Metrics.AmortizedCost.Amount;
            costObj9["EC2 - Other"] = costEC2Ot9;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Elastic Compute Cloud - Compute"] = costECC9;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Elastic Load Balancing"] = costELB9;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Elastic File System"] = costEFS9;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon FSx"] = costFSX9;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon GuardDuty"] = costGD9;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Inspector"] = costInsp9;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Email Service"] = costSES9;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Notification Service"] = costSNS9;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Queue Service"] = costSQS9;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Storage Service"] = costSSS9;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Virtual Private Cloud"] = costVPC9;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon WorkSpaces"] = costWS9;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AmazonCloudWatch"] = costCW9;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE9 = element.Metrics.AmortizedCost.Amount;
            costObj9["CloudWatch Events"] = costCWE9;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS CodeArtifact"] = costCA9;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Step Functions"] = costSF9;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon EC2 Container Registry (ECR)"] = costECR9;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Transfer Family"] = costTF9;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon API Gateway"] = costAG9;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon AppStream"] = costAS9;
          }
        });
        cost10 = response["ResultsByTime"][10].Groups;
        cost10.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Lambda"] = costLambda10;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS CloudTrail"] = costCloudT10;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Config"] = costConfig10;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Direct Connect"] = costDirCnt10;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Glue"] = costGlue10;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Key Management Service"] = costKeyMgt10;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Secrets Manager"] = costSecMgr10;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Security Hub"] = costSecHub10;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Shield"] = costShield10;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS WAF"] = costWaf10;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon DynamoDB"] = costDyna10;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Relational Database Service"] = costRDS10;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot10 = element.Metrics.AmortizedCost.Amount;
            costObj10["EC2 - Other"] = costEC2Ot10;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Elastic Compute Cloud - Compute"] = costECC10;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Elastic Load Balancing"] = costELB10;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Elastic File System"] = costEFS10;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon FSx"] = costFSX10;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon GuardDuty"] = costGD10;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Inspector"] = costInsp10;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Email Service"] = costSES10;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Notification Service"] = costSNS10;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Queue Service"] = costSQS10;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Storage Service"] = costSSS10;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Virtual Private Cloud"] = costVPC10;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon WorkSpaces"] = costWS10;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AmazonCloudWatch"] = costCW10;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE10 = element.Metrics.AmortizedCost.Amount;
            costObj10["CloudWatch Events"] = costCWE10;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS CodeArtifact"] = costCA10;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Step Functions"] = costSF10;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon EC2 Container Registry (ECR)"] = costECR10;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Transfer Family"] = costTF10;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon API Gateway"] = costAG10;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon AppStream"] = costAS10;
          }
        });
        cost11 = response["ResultsByTime"][11].Groups;
        cost11.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Lambda"] = costLambda11;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS CloudTrail"] = costCloudT11;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Config"] = costConfig11;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Direct Connect"] = costDirCnt11;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Glue"] = costGlue11;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Key Management Service"] = costKeyMgt11;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Secrets Manager"] = costSecMgr11;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Security Hub"] = costSecHub11;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Shield"] = costShield11;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS WAF"] = costWaf11;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon DynamoDB"] = costDyna11;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Relational Database Service"] = costRDS11;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot11 = element.Metrics.AmortizedCost.Amount;
            costObj11["EC2 - Other"] = costEC2Ot11;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Elastic Compute Cloud - Compute"] = costECC11;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Elastic Load Balancing"] = costELB11;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Elastic File System"] = costEFS11;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon FSx"] = costFSX11;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon GuardDuty"] = costGD11;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Inspector"] = costInsp11;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Email Service"] = costSES11;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Notification Service"] = costSNS11;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Queue Service"] = costSQS11;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Storage Service"] = costSSS11;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Virtual Private Cloud"] = costVPC11;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon WorkSpaces"] = costWS11;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AmazonCloudWatch"] = costCW11;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE11 = element.Metrics.AmortizedCost.Amount;
            costObj11["CloudWatch Events"] = costCWE11;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS CodeArtifact"] = costCA11;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Step Functions"] = costSF11;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon EC2 Container Registry (ECR)"] = costECR11;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Transfer Family"] = costTF11;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon API Gateway"] = costAG11;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon AppStream"] = costAS11;
          }
        });
        cost12 = response["ResultsByTime"][12].Groups;
        cost12.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Lambda"] = costLambda12;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS CloudTrail"] = costCloudT12;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Config"] = costConfig12;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Direct Connect"] = costDirCnt12;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Glue"] = costGlue12;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Key Management Service"] = costKeyMgt12;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Secrets Manager"] = costSecMgr12;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Security Hub"] = costSecHub12;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Shield"] = costShield12;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS WAF"] = costWaf12;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon DynamoDB"] = costDyna12;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Relational Database Service"] = costRDS12;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot12 = element.Metrics.AmortizedCost.Amount;
            costObj12["EC2 - Other"] = costEC2Ot12;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Elastic Compute Cloud - Compute"] = costECC12;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Elastic Load Balancing"] = costELB12;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Elastic File System"] = costEFS12;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon FSx"] = costFSX12;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon GuardDuty"] = costGD12;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Inspector"] = costInsp12;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Simple Email Service"] = costSES12;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Simple Notification Service"] = costSNS12;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Simple Queue Service"] = costSQS12;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Simple Storage Service"] = costSSS12;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon Virtual Private Cloud"] = costVPC12;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon WorkSpaces"] = costWS12;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AmazonCloudWatch"] = costCW12;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE12 = element.Metrics.AmortizedCost.Amount;
            costObj12["CloudWatch Events"] = costCWE12;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS CodeArtifact"] = costCA12;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Step Functions"] = costSF12;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon EC2 Container Registry (ECR)"] = costECR12;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF12 = element.Metrics.AmortizedCost.Amount;
            costObj12["AWS Transfer Family"] = costTF12;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon API Gateway"] = costAG12;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS12 = element.Metrics.AmortizedCost.Amount;
            costObj12["Amazon AppStream"] = costAS12;
          }
        });
        cost13 = response["ResultsByTime"][13].Groups;
        cost13.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Lambda"] = costLambda13;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS CloudTrail"] = costCloudT13;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Config"] = costConfig13;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Direct Connect"] = costDirCnt13;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Glue"] = costGlue13;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Key Management Service"] = costKeyMgt13;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Secrets Manager"] = costSecMgr13;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Security Hub"] = costSecHub13;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Shield"] = costShield13;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS WAF"] = costWaf13;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon DynamoDB"] = costDyna13;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Relational Database Service"] = costRDS13;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot13 = element.Metrics.AmortizedCost.Amount;
            costObj13["EC2 - Other"] = costEC2Ot13;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Elastic Compute Cloud - Compute"] = costECC13;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Elastic Load Balancing"] = costELB13;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Elastic File System"] = costEFS13;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon FSx"] = costFSX13;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon GuardDuty"] = costGD13;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Inspector"] = costInsp13;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Simple Email Service"] = costSES13;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Simple Notification Service"] = costSNS13;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Simple Queue Service"] = costSQS13;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Simple Storage Service"] = costSSS13;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon Virtual Private Cloud"] = costVPC13;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon WorkSpaces"] = costWS13;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AmazonCloudWatch"] = costCW13;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE13 = element.Metrics.AmortizedCost.Amount;
            costObj13["CloudWatch Events"] = costCWE13;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS CodeArtifact"] = costCA13;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Step Functions"] = costSF13;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon EC2 Container Registry (ECR)"] = costECR13;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF13 = element.Metrics.AmortizedCost.Amount;
            costObj13["AWS Transfer Family"] = costTF13;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon API Gateway"] = costAG13;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS13 = element.Metrics.AmortizedCost.Amount;
            costObj13["Amazon AppStream"] = costAS13;
          }
        });
        cost14 = response["ResultsByTime"][14].Groups;
        cost14.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Lambda"] = costLambda14;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS CloudTrail"] = costCloudT14;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Config"] = costConfig14;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Direct Connect"] = costDirCnt14;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Glue"] = costGlue14;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Key Management Service"] = costKeyMgt14;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Secrets Manager"] = costSecMgr14;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Security Hub"] = costSecHub14;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Shield"] = costShield14;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS WAF"] = costWaf14;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon DynamoDB"] = costDyna14;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Relational Database Service"] = costRDS14;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot14 = element.Metrics.AmortizedCost.Amount;
            costObj14["EC2 - Other"] = costEC2Ot14;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Elastic Compute Cloud - Compute"] = costECC14;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Elastic Load Balancing"] = costELB14;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Elastic File System"] = costEFS14;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon FSx"] = costFSX14;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon GuardDuty"] = costGD14;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Inspector"] = costInsp14;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Simple Email Service"] = costSES14;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Simple Notification Service"] = costSNS14;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Simple Queue Service"] = costSQS14;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Simple Storage Service"] = costSSS14;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon Virtual Private Cloud"] = costVPC14;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon WorkSpaces"] = costWS14;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AmazonCloudWatch"] = costCW14;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE14 = element.Metrics.AmortizedCost.Amount;
            costObj14["CloudWatch Events"] = costCWE14;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS CodeArtifact"] = costCA14;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Step Functions"] = costSF14;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon EC2 Container Registry (ECR)"] = costECR14;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF14 = element.Metrics.AmortizedCost.Amount;
            costObj14["AWS Transfer Family"] = costTF14;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon API Gateway"] = costAG14;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS14 = element.Metrics.AmortizedCost.Amount;
            costObj14["Amazon AppStream"] = costAS14;
          }
        });
        cost15 = response["ResultsByTime"][15].Groups;
        cost15.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Lambda"] = costLambda15;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS CloudTrail"] = costCloudT15;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Config"] = costConfig15;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Direct Connect"] = costDirCnt15;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Glue"] = costGlue15;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Key Management Service"] = costKeyMgt15;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Secrets Manager"] = costSecMgr15;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Security Hub"] = costSecHub15;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Shield"] = costShield15;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS WAF"] = costWaf15;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon DynamoDB"] = costDyna15;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Relational Database Service"] = costRDS15;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot15 = element.Metrics.AmortizedCost.Amount;
            costObj15["EC2 - Other"] = costEC2Ot15;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Elastic Compute Cloud - Compute"] = costECC15;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Elastic Load Balancing"] = costELB15;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Elastic File System"] = costEFS15;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon FSx"] = costFSX15;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon GuardDuty"] = costGD15;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Inspector"] = costInsp15;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Simple Email Service"] = costSES15;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Simple Notification Service"] = costSNS15;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Simple Queue Service"] = costSQS15;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Simple Storage Service"] = costSSS15;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon Virtual Private Cloud"] = costVPC15;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon WorkSpaces"] = costWS15;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AmazonCloudWatch"] = costCW15;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE15 = element.Metrics.AmortizedCost.Amount;
            costObj15["CloudWatch Events"] = costCWE15;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS CodeArtifact"] = costCA15;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Step Functions"] = costSF15;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon EC2 Container Registry (ECR)"] = costECR15;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF15 = element.Metrics.AmortizedCost.Amount;
            costObj15["AWS Transfer Family"] = costTF15;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon API Gateway"] = costAG15;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS15 = element.Metrics.AmortizedCost.Amount;
            costObj15["Amazon AppStream"] = costAS15;
          }
        });
        cost16 = response["ResultsByTime"][16].Groups;
        cost16.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Lambda"] = costLambda16;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS CloudTrail"] = costCloudT16;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Config"] = costConfig16;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Direct Connect"] = costDirCnt16;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Glue"] = costGlue16;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Key Management Service"] = costKeyMgt16;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Secrets Manager"] = costSecMgr16;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Security Hub"] = costSecHub16;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Shield"] = costShield16;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS WAF"] = costWaf16;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon DynamoDB"] = costDyna16;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Relational Database Service"] = costRDS16;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot16 = element.Metrics.AmortizedCost.Amount;
            costObj16["EC2 - Other"] = costEC2Ot16;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Elastic Compute Cloud - Compute"] = costECC16;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Elastic Load Balancing"] = costELB16;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Elastic File System"] = costEFS16;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon FSx"] = costFSX16;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon GuardDuty"] = costGD16;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Inspector"] = costInsp16;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Simple Email Service"] = costSES16;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Simple Notification Service"] = costSNS16;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Simple Queue Service"] = costSQS16;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Simple Storage Service"] = costSSS16;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon Virtual Private Cloud"] = costVPC16;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon WorkSpaces"] = costWS16;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AmazonCloudWatch"] = costCW16;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE16 = element.Metrics.AmortizedCost.Amount;
            costObj16["CloudWatch Events"] = costCWE16;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS CodeArtifact"] = costCA16;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Step Functions"] = costSF16;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon EC2 Container Registry (ECR)"] = costECR16;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF16 = element.Metrics.AmortizedCost.Amount;
            costObj16["AWS Transfer Family"] = costTF16;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon API Gateway"] = costAG16;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS16 = element.Metrics.AmortizedCost.Amount;
            costObj16["Amazon AppStream"] = costAS16;
          }
        });
        cost17 = response["ResultsByTime"][17].Groups;
        cost17.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Lambda"] = costLambda17;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS CloudTrail"] = costCloudT17;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Config"] = costConfig17;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Direct Connect"] = costDirCnt17;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Glue"] = costGlue17;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Key Management Service"] = costKeyMgt17;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Secrets Manager"] = costSecMgr17;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Security Hub"] = costSecHub17;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Shield"] = costShield17;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS WAF"] = costWaf17;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon DynamoDB"] = costDyna17;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Relational Database Service"] = costRDS17;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot17 = element.Metrics.AmortizedCost.Amount;
            costObj17["EC2 - Other"] = costEC2Ot17;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Elastic Compute Cloud - Compute"] = costECC17;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Elastic Load Balancing"] = costELB17;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Elastic File System"] = costEFS17;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon FSx"] = costFSX17;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon GuardDuty"] = costGD17;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Inspector"] = costInsp17;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Simple Email Service"] = costSES17;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Simple Notification Service"] = costSNS17;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Simple Queue Service"] = costSQS17;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Simple Storage Service"] = costSSS17;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon Virtual Private Cloud"] = costVPC17;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon WorkSpaces"] = costWS17;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AmazonCloudWatch"] = costCW17;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE17 = element.Metrics.AmortizedCost.Amount;
            costObj17["CloudWatch Events"] = costCWE17;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS CodeArtifact"] = costCA17;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Step Functions"] = costSF17;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon EC2 Container Registry (ECR)"] = costECR17;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF17 = element.Metrics.AmortizedCost.Amount;
            costObj17["AWS Transfer Family"] = costTF17;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon API Gateway"] = costAG17;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS17 = element.Metrics.AmortizedCost.Amount;
            costObj17["Amazon AppStream"] = costAS17;
          }
        });
        cost18 = response["ResultsByTime"][18].Groups;
        cost18.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Lambda"] = costLambda18;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS CloudTrail"] = costCloudT18;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Config"] = costConfig18;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Direct Connect"] = costDirCnt18;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Glue"] = costGlue18;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Key Management Service"] = costKeyMgt18;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Secrets Manager"] = costSecMgr18;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Security Hub"] = costSecHub18;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Shield"] = costShield18;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS WAF"] = costWaf18;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon DynamoDB"] = costDyna18;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Relational Database Service"] = costRDS18;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot18 = element.Metrics.AmortizedCost.Amount;
            costObj18["EC2 - Other"] = costEC2Ot18;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Elastic Compute Cloud - Compute"] = costECC18;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Elastic Load Balancing"] = costELB18;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Elastic File System"] = costEFS18;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon FSx"] = costFSX18;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon GuardDuty"] = costGD18;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Inspector"] = costInsp18;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Simple Email Service"] = costSES18;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Simple Notification Service"] = costSNS18;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Simple Queue Service"] = costSQS18;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Simple Storage Service"] = costSSS18;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon Virtual Private Cloud"] = costVPC18;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon WorkSpaces"] = costWS18;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AmazonCloudWatch"] = costCW18;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE18 = element.Metrics.AmortizedCost.Amount;
            costObj18["CloudWatch Events"] = costCWE18;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS CodeArtifact"] = costCA18;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Step Functions"] = costSF18;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon EC2 Container Registry (ECR)"] = costECR18;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF18 = element.Metrics.AmortizedCost.Amount;
            costObj18["AWS Transfer Family"] = costTF18;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon API Gateway"] = costAG18;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS18 = element.Metrics.AmortizedCost.Amount;
            costObj18["Amazon AppStream"] = costAS18;
          }
        });
        cost19 = response["ResultsByTime"][19].Groups;
        cost19.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Lambda"] = costLambda19;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS CloudTrail"] = costCloudT19;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Config"] = costConfig19;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Direct Connect"] = costDirCnt19;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Glue"] = costGlue19;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Key Management Service"] = costKeyMgt19;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Secrets Manager"] = costSecMgr19;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Security Hub"] = costSecHub19;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Shield"] = costShield19;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS WAF"] = costWaf19;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon DynamoDB"] = costDyna19;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Relational Database Service"] = costRDS19;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot19 = element.Metrics.AmortizedCost.Amount;
            costObj19["EC2 - Other"] = costEC2Ot19;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Elastic Compute Cloud - Compute"] = costECC19;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Elastic Load Balancing"] = costELB19;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Elastic File System"] = costEFS19;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon FSx"] = costFSX19;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon GuardDuty"] = costGD19;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Inspector"] = costInsp19;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Simple Email Service"] = costSES19;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Simple Notification Service"] = costSNS19;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Simple Queue Service"] = costSQS19;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Simple Storage Service"] = costSSS19;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon Virtual Private Cloud"] = costVPC19;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon WorkSpaces"] = costWS19;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AmazonCloudWatch"] = costCW19;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE19 = element.Metrics.AmortizedCost.Amount;
            costObj19["CloudWatch Events"] = costCWE19;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS CodeArtifact"] = costCA19;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Step Functions"] = costSF19;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon EC2 Container Registry (ECR)"] = costECR19;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF19 = element.Metrics.AmortizedCost.Amount;
            costObj19["AWS Transfer Family"] = costTF19;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon API Gateway"] = costAG19;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS19 = element.Metrics.AmortizedCost.Amount;
            costObj19["Amazon AppStream"] = costAS19;
          }
        });
        cost20 = response["ResultsByTime"][20].Groups;
        cost20.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Lambda"] = costLambda20;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS CloudTrail"] = costCloudT20;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Config"] = costConfig20;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Direct Connect"] = costDirCnt20;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Glue"] = costGlue20;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Key Management Service"] = costKeyMgt20;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Secrets Manager"] = costSecMgr20;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Security Hub"] = costSecHub20;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Shield"] = costShield20;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS WAF"] = costWaf20;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon DynamoDB"] = costDyna20;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Relational Database Service"] = costRDS20;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot20 = element.Metrics.AmortizedCost.Amount;
            costObj20["EC2 - Other"] = costEC2Ot20;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Elastic Compute Cloud - Compute"] = costECC20;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Elastic Load Balancing"] = costELB20;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Elastic File System"] = costEFS20;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon FSx"] = costFSX20;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon GuardDuty"] = costGD20;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Inspector"] = costInsp20;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Simple Email Service"] = costSES20;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Simple Notification Service"] = costSNS20;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Simple Queue Service"] = costSQS20;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Simple Storage Service"] = costSSS20;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon Virtual Private Cloud"] = costVPC20;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon WorkSpaces"] = costWS20;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AmazonCloudWatch"] = costCW20;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE20 = element.Metrics.AmortizedCost.Amount;
            costObj20["CloudWatch Events"] = costCWE20;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS CodeArtifact"] = costCA20;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Step Functions"] = costSF20;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon EC2 Container Registry (ECR)"] = costECR20;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF20 = element.Metrics.AmortizedCost.Amount;
            costObj20["AWS Transfer Family"] = costTF20;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon API Gateway"] = costAG20;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS20 = element.Metrics.AmortizedCost.Amount;
            costObj20["Amazon AppStream"] = costAS20;
          }
        });
        cost21 = response["ResultsByTime"][21].Groups;
        cost21.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Lambda"] = costLambda21;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS CloudTrail"] = costCloudT21;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Config"] = costConfig21;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Direct Connect"] = costDirCnt21;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Glue"] = costGlue21;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Key Management Service"] = costKeyMgt21;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Secrets Manager"] = costSecMgr21;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Security Hub"] = costSecHub21;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Shield"] = costShield21;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS WAF"] = costWaf21;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon DynamoDB"] = costDyna21;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Relational Database Service"] = costRDS21;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot21 = element.Metrics.AmortizedCost.Amount;
            costObj21["EC2 - Other"] = costEC2Ot21;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Elastic Compute Cloud - Compute"] = costECC21;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Elastic Load Balancing"] = costELB21;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Elastic File System"] = costEFS21;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon FSx"] = costFSX21;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon GuardDuty"] = costGD21;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Inspector"] = costInsp21;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Simple Email Service"] = costSES21;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Simple Notification Service"] = costSNS21;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Simple Queue Service"] = costSQS21;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Simple Storage Service"] = costSSS21;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Virtual Private Cloud"] = costVPC21;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon WorkSpaces"] = costWS21;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AmazonCloudWatch"] = costCW21;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE21 = element.Metrics.AmortizedCost.Amount;
            costObj21["CloudWatch Events"] = costCWE21;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS CodeArtifact"] = costCA21;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Step Functions"] = costSF21;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon EC2 Container Registry (ECR)"] = costECR21;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF21 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Transfer Family"] = costTF21;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon API Gateway"] = costAG21;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS21 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon AppStream"] = costAS21;
          }
        });
        cost22 = response["ResultsByTime"][22].Groups;
        cost22.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Lambda"] = costLambda22;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS CloudTrail"] = costCloudT22;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Config"] = costConfig22;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Direct Connect"] = costDirCnt22;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Glue"] = costGlue22;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Key Management Service"] = costKeyMgt22;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Secrets Manager"] = costSecMgr22;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Security Hub"] = costSecHub22;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Shield"] = costShield22;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS WAF"] = costWaf22;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon DynamoDB"] = costDyna22;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Relational Database Service"] = costRDS22;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot22 = element.Metrics.AmortizedCost.Amount;
            costObj22["EC2 - Other"] = costEC2Ot22;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Elastic Compute Cloud - Compute"] = costECC22;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Elastic Load Balancing"] = costELB22;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Elastic File System"] = costEFS22;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon FSx"] = costFSX22;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon GuardDuty"] = costGD22;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Inspector"] = costInsp22;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Simple Email Service"] = costSES22;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Simple Notification Service"] = costSNS22;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Simple Queue Service"] = costSQS22;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Simple Storage Service"] = costSSS22;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon Virtual Private Cloud"] = costVPC22;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon WorkSpaces"] = costWS22;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AmazonCloudWatch"] = costCW22;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE22 = element.Metrics.AmortizedCost.Amount;
            costObj22["CloudWatch Events"] = costCWE22;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS CodeArtifact"] = costCA22;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Step Functions"] = costSF22;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon EC2 Container Registry (ECR)"] = costECR22;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF22 = element.Metrics.AmortizedCost.Amount;
            costObj22["AWS Transfer Family"] = costTF22;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon API Gateway"] = costAG22;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS22 = element.Metrics.AmortizedCost.Amount;
            costObj22["Amazon AppStream"] = costAS22;
          }
        });
        cost23 = response["ResultsByTime"][23].Groups;
        cost23.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Lambda"] = costLambda23;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS CloudTrail"] = costCloudT23;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Config"] = costConfig23;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Direct Connect"] = costDirCnt23;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Glue"] = costGlue23;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Key Management Service"] = costKeyMgt23;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Secrets Manager"] = costSecMgr23;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Security Hub"] = costSecHub23;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Shield"] = costShield23;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS WAF"] = costWaf23;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon DynamoDB"] = costDyna23;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Relational Database Service"] = costRDS23;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot23 = element.Metrics.AmortizedCost.Amount;
            costObj23["EC2 - Other"] = costEC2Ot23;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Elastic Compute Cloud - Compute"] = costECC23;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Elastic Load Balancing"] = costELB23;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Elastic File System"] = costEFS23;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon FSx"] = costFSX23;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon GuardDuty"] = costGD23;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Inspector"] = costInsp23;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Simple Email Service"] = costSES23;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Simple Notification Service"] = costSNS23;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Simple Queue Service"] = costSQS23;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Simple Storage Service"] = costSSS23;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon Virtual Private Cloud"] = costVPC23;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon WorkSpaces"] = costWS23;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AmazonCloudWatch"] = costCW23;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE23 = element.Metrics.AmortizedCost.Amount;
            costObj23["CloudWatch Events"] = costCWE23;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS CodeArtifact"] = costCA23;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Step Functions"] = costSF23;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon EC2 Container Registry (ECR)"] = costECR23;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF23 = element.Metrics.AmortizedCost.Amount;
            costObj23["AWS Transfer Family"] = costTF23;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon API Gateway"] = costAG23;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS23 = element.Metrics.AmortizedCost.Amount;
            costObj23["Amazon AppStream"] = costAS23;
          }
        });
        cost24 = response["ResultsByTime"][24].Groups;
        cost24.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Lambda"] = costLambda24;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS CloudTrail"] = costCloudT24;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Config"] = costConfig24;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Direct Connect"] = costDirCnt24;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Glue"] = costGlue24;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Key Management Service"] = costKeyMgt24;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Secrets Manager"] = costSecMgr24;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Security Hub"] = costSecHub24;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Shield"] = costShield24;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS WAF"] = costWaf24;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon DynamoDB"] = costDyna24;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Relational Database Service"] = costRDS24;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot24 = element.Metrics.AmortizedCost.Amount;
            costObj24["EC2 - Other"] = costEC2Ot24;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Elastic Compute Cloud - Compute"] = costECC24;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Elastic Load Balancing"] = costELB24;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Elastic File System"] = costEFS24;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon FSx"] = costFSX24;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon GuardDuty"] = costGD24;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Inspector"] = costInsp24;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Simple Email Service"] = costSES24;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Simple Notification Service"] = costSNS24;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Simple Queue Service"] = costSQS24;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Simple Storage Service"] = costSSS24;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon Virtual Private Cloud"] = costVPC24;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon WorkSpaces"] = costWS24;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AmazonCloudWatch"] = costCW24;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE24 = element.Metrics.AmortizedCost.Amount;
            costObj24["CloudWatch Events"] = costCWE24;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS CodeArtifact"] = costCA24;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Step Functions"] = costSF24;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon EC2 Container Registry (ECR)"] = costECR24;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF24 = element.Metrics.AmortizedCost.Amount;
            costObj24["AWS Transfer Family"] = costTF24;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon API Gateway"] = costAG24;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS24 = element.Metrics.AmortizedCost.Amount;
            costObj24["Amazon AppStream"] = costAS24;
          }
        });
        cost25 = response["ResultsByTime"][25].Groups;
        cost25.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Lambda"] = costLambda25;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS CloudTrail"] = costCloudT25;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Config"] = costConfig25;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Direct Connect"] = costDirCnt25;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Glue"] = costGlue25;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Key Management Service"] = costKeyMgt25;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Secrets Manager"] = costSecMgr25;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Security Hub"] = costSecHub25;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Shield"] = costShield25;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS WAF"] = costWaf25;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon DynamoDB"] = costDyna25;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Relational Database Service"] = costRDS25;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot25 = element.Metrics.AmortizedCost.Amount;
            costObj25["EC2 - Other"] = costEC2Ot25;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Elastic Compute Cloud - Compute"] = costECC25;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Elastic Load Balancing"] = costELB25;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Elastic File System"] = costEFS25;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon FSx"] = costFSX25;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon GuardDuty"] = costGD25;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Inspector"] = costInsp25;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Simple Email Service"] = costSES25;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Simple Notification Service"] = costSNS25;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Simple Queue Service"] = costSQS25;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Simple Storage Service"] = costSSS25;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon Virtual Private Cloud"] = costVPC25;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon WorkSpaces"] = costWS25;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AmazonCloudWatch"] = costCW25;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE25 = element.Metrics.AmortizedCost.Amount;
            costObj25["CloudWatch Events"] = costCWE25;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS CodeArtifact"] = costCA25;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Step Functions"] = costSF25;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon EC2 Container Registry (ECR)"] = costECR25;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF25 = element.Metrics.AmortizedCost.Amount;
            costObj25["AWS Transfer Family"] = costTF25;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon API Gateway"] = costAG25;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS25 = element.Metrics.AmortizedCost.Amount;
            costObj25["Amazon AppStream"] = costAS25;
          }
        });
        cost26 = response["ResultsByTime"][26].Groups;
        cost26.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Lambda"] = costLambda26;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS CloudTrail"] = costCloudT26;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Config"] = costConfig26;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Direct Connect"] = costDirCnt26;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Glue"] = costGlue26;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Key Management Service"] = costKeyMgt26;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Secrets Manager"] = costSecMgr26;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Security Hub"] = costSecHub26;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Shield"] = costShield26;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS WAF"] = costWaf26;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon DynamoDB"] = costDyna26;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Relational Database Service"] = costRDS26;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot26 = element.Metrics.AmortizedCost.Amount;
            costObj26["EC2 - Other"] = costEC2Ot26;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Elastic Compute Cloud - Compute"] = costECC26;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Elastic Load Balancing"] = costELB26;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Elastic File System"] = costEFS26;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon FSx"] = costFSX26;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon GuardDuty"] = costGD26;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Inspector"] = costInsp26;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Simple Email Service"] = costSES26;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Simple Notification Service"] = costSNS26;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Simple Queue Service"] = costSQS26;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Simple Storage Service"] = costSSS26;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon Virtual Private Cloud"] = costVPC26;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon WorkSpaces"] = costWS26;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AmazonCloudWatch"] = costCW26;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE26 = element.Metrics.AmortizedCost.Amount;
            costObj26["CloudWatch Events"] = costCWE26;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS CodeArtifact"] = costCA26;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Step Functions"] = costSF26;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon EC2 Container Registry (ECR)"] = costECR26;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF26 = element.Metrics.AmortizedCost.Amount;
            costObj26["AWS Transfer Family"] = costTF26;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon API Gateway"] = costAG26;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS26 = element.Metrics.AmortizedCost.Amount;
            costObj26["Amazon AppStream"] = costAS26;
          }
        });
        cost27 = response["ResultsByTime"][27].Groups;
        cost27.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Lambda"] = costLambda27;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS CloudTrail"] = costCloudT27;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Config"] = costConfig27;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Direct Connect"] = costDirCnt27;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Glue"] = costGlue27;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Key Management Service"] = costKeyMgt27;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Secrets Manager"] = costSecMgr27;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Security Hub"] = costSecHub27;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield27 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Shield"] = costShield27;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS WAF"] = costWaf27;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon DynamoDB"] = costDyna27;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Relational Database Service"] = costRDS27;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot27 = element.Metrics.AmortizedCost.Amount;
            costObj27["EC2 - Other"] = costEC2Ot27;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Elastic Compute Cloud - Compute"] = costECC27;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Elastic Load Balancing"] = costELB27;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Elastic File System"] = costEFS27;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon FSx"] = costFSX27;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon GuardDuty"] = costGD27;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Inspector"] = costInsp27;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Simple Email Service"] = costSES27;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Simple Notification Service"] = costSNS27;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Simple Queue Service"] = costSQS27;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Simple Storage Service"] = costSSS27;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon Virtual Private Cloud"] = costVPC27;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon WorkSpaces"] = costWS27;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AmazonCloudWatch"] = costCW27;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE27 = element.Metrics.AmortizedCost.Amount;
            costObj27["CloudWatch Events"] = costCWE27;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS CodeArtifact"] = costCA27;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Step Functions"] = costSF27;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon EC2 Container Registry (ECR)"] = costECR27;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF27 = element.Metrics.AmortizedCost.Amount;
            costObj27["AWS Transfer Family"] = costTF27;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon API Gateway"] = costAG27;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS27 = element.Metrics.AmortizedCost.Amount;
            costObj27["Amazon AppStream"] = costAS27;
          }
        });
        cost28 = response["ResultsByTime"][28].Groups;
        cost28.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Lambda"] = costLambda28;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS CloudTrail"] = costCloudT28;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Config"] = costConfig28;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Direct Connect"] = costDirCnt28;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Glue"] = costGlue28;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Key Management Service"] = costKeyMgt28;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Secrets Manager"] = costSecMgr28;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Security Hub"] = costSecHub28;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Shield"] = costShield28;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS WAF"] = costWaf28;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon DynamoDB"] = costDyna28;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Relational Database Service"] = costRDS28;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot28 = element.Metrics.AmortizedCost.Amount;
            costObj28["EC2 - Other"] = costEC2Ot28;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Elastic Compute Cloud - Compute"] = costECC28;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Elastic Load Balancing"] = costELB28;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Elastic File System"] = costEFS28;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon FSx"] = costFSX28;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon GuardDuty"] = costGD28;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Inspector"] = costInsp28;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Simple Email Service"] = costSES28;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Simple Notification Service"] = costSNS28;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Simple Queue Service"] = costSQS28;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Simple Storage Service"] = costSSS28;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon Virtual Private Cloud"] = costVPC28;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon WorkSpaces"] = costWS28;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AmazonCloudWatch"] = costCW28;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE28 = element.Metrics.AmortizedCost.Amount;
            costObj28["CloudWatch Events"] = costCWE28;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS CodeArtifact"] = costCA28;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Step Functions"] = costSF28;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon EC2 Container Registry (ECR)"] = costECR28;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF28 = element.Metrics.AmortizedCost.Amount;
            costObj28["AWS Transfer Family"] = costTF28;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon API Gateway"] = costAG28;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS28 = element.Metrics.AmortizedCost.Amount;
            costObj28["Amazon AppStream"] = costAS28;
          }
        });
        cost29 = response["ResultsByTime"][29].Groups;
        cost29.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Lambda"] = costLambda29;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS CloudTrail"] = costCloudT29;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Config"] = costConfig29;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Direct Connect"] = costDirCnt29;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Glue"] = costGlue29;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Key Management Service"] = costKeyMgt29;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Secrets Manager"] = costSecMgr29;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Security Hub"] = costSecHub29;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Shield"] = costShield29;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS WAF"] = costWaf29;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon DynamoDB"] = costDyna29;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Relational Database Service"] = costRDS29;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot29 = element.Metrics.AmortizedCost.Amount;
            costObj29["EC2 - Other"] = costEC2Ot29;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Elastic Compute Cloud - Compute"] = costECC29;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Elastic Load Balancing"] = costELB29;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Elastic File System"] = costEFS29;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon FSx"] = costFSX29;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon GuardDuty"] = costGD29;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Inspector"] = costInsp29;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES29 = element.Metrics.AmortizedCost.Amount;
            costObj21["Amazon Simple Email Service"] = costSES29;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Simple Notification Service"] = costSNS29;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Simple Queue Service"] = costSQS29;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Simple Storage Service"] = costSSS29;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon Virtual Private Cloud"] = costVPC29;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon WorkSpaces"] = costWS29;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AmazonCloudWatch"] = costCW29;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE29 = element.Metrics.AmortizedCost.Amount;
            costObj29["CloudWatch Events"] = costCWE29;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS CodeArtifact"] = costCA29;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Step Functions"] = costSF29;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon EC2 Container Registry (ECR)"] = costECR29;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF29 = element.Metrics.AmortizedCost.Amount;
            costObj29["AWS Transfer Family"] = costTF29;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon API Gateway"] = costAG29;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS29 = element.Metrics.AmortizedCost.Amount;
            costObj29["Amazon AppStream"] = costAS29;
          }
        });
        cost30 = response["ResultsByTime"][30].Groups;
        cost30.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Lambda"] = costLambda30;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS CloudTrail"] = costCloudT30;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Config"] = costConfig30;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Direct Connect"] = costDirCnt30;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Glue"] = costGlue30;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Key Management Service"] = costKeyMgt30;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Secrets Manager"] = costSecMgr30;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub30 = element.Metrics.AmortizedCost.Amount;
            costObj21["AWS Security Hub"] = costSecHub30;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Shield"] = costShield30;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS WAF"] = costWaf30;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon DynamoDB"] = costDyna30;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Relational Database Service"] = costRDS30;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot30 = element.Metrics.AmortizedCost.Amount;
            costObj30["EC2 - Other"] = costEC2Ot30;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Elastic Compute Cloud - Compute"] = costECC30;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Elastic Load Balancing"] = costELB30;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Elastic File System"] = costEFS30;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon FSx"] = costFSX30;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon GuardDuty"] = costGD30;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Inspector"] = costInsp30;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Simple Email Service"] = costSES30;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Simple Notification Service"] = costSNS30;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Simple Queue Service"] = costSQS30;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Simple Storage Service"] = costSSS30;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon Virtual Private Cloud"] = costVPC30;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon WorkSpaces"] = costWS30;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AmazonCloudWatch"] = costCW30;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE30 = element.Metrics.AmortizedCost.Amount;
            costObj30["CloudWatch Events"] = costCWE30;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS CodeArtifact"] = costCA30;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Step Functions"] = costSF30;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon EC2 Container Registry (ECR)"] = costECR30;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF30 = element.Metrics.AmortizedCost.Amount;
            costObj30["AWS Transfer Family"] = costTF30;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon API Gateway"] = costAG30;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS30 = element.Metrics.AmortizedCost.Amount;
            costObj30["Amazon AppStream"] = costAS30;
          }
        });
        cost31 = response["ResultsByTime"][31].Groups;
        cost31.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Lambda"] = costLambda31;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS CloudTrail"] = costCloudT31;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Config"] = costConfig31;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Direct Connect"] = costDirCnt31;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Glue"] = costGlue31;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Key Management Service"] = costKeyMgt31;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Secrets Manager"] = costSecMgr31;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Security Hub"] = costSecHub31;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Shield"] = costShield31;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS WAF"] = costWaf31;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon DynamoDB"] = costDyna31;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Relational Database Service"] = costRDS31;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot31 = element.Metrics.AmortizedCost.Amount;
            costObj31["EC2 - Other"] = costEC2Ot31;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Elastic Compute Cloud - Compute"] = costECC31;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Elastic Load Balancing"] = costELB31;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Elastic File System"] = costEFS31;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon FSx"] = costFSX31;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon GuardDuty"] = costGD31;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Inspector"] = costInsp31;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Simple Email Service"] = costSES31;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Simple Notification Service"] = costSNS31;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Simple Queue Service"] = costSQS31;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Simple Storage Service"] = costSSS31;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon Virtual Private Cloud"] = costVPC31;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon WorkSpaces"] = costWS31;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AmazonCloudWatch"] = costCW31;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE31 = element.Metrics.AmortizedCost.Amount;
            costObj31["CloudWatch Events"] = costCWE31;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS CodeArtifact"] = costCA31;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Step Functions"] = costSF31;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon EC2 Container Registry (ECR)"] = costECR31;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF31 = element.Metrics.AmortizedCost.Amount;
            costObj31["AWS Transfer Family"] = costTF31;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon API Gateway"] = costAG31;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS31 = element.Metrics.AmortizedCost.Amount;
            costObj31["Amazon AppStream"] = costAS31;
          }
        });
        DailyCost[0] = {// eslint-disable-line
          shortname: prevDay31.slice(0,5),// eslint-disable-line
          name: prevDay31,// eslint-disable-line
          EC2Ot: parseFloat(costObj["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[1] = {// eslint-disable-line
          shortname: prevDay30.slice(0,5),// eslint-disable-line
          name: prevDay30,// eslint-disable-line
          EC2Ot: parseFloat(costObj1["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj1["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj1["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj1["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj1["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj1["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj1["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj1["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj1["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj1["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj1["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj1["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj1["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj1["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj1["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj1["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj1["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj1["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj1["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj1["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj1["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj1["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj1["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj1["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj1["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj1["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj1["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj1["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj1["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj1["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj1["AWS Transfer Family"]).toFixed(1),
          ApiG: parseFloat(costObj1["Amazon API Gateway"]).toFixed(1),
          AS: parseFloat(costObj1["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[2] = {// eslint-disable-line
          shortname: prevDay29.slice(0,5),// eslint-disable-line
          name: prevDay29,// eslint-disable-line
          EC2Ot: parseFloat(costObj2["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj2["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj2["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj2["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj2["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj2["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj2["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj2["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj2["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj2["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj2["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj2["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj2["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj2["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj2["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj2["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj2["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj2["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj2["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj2["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj2["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj2["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj2["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj2["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj2["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj2["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj2["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj2["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj2["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj2["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj2["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj2["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj2["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[3] = {// eslint-disable-line
          shortname: prevDay28.slice(0,5),// eslint-disable-line
          name: prevDay28,// eslint-disable-line
          EC2Ot: parseFloat(costObj3["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj3["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj3["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj3["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj3["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj3["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj3["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj3["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj3["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj3["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj3["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj3["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj3["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj3["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj3["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj3["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj3["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj3["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj3["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj3["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj3["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj3["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj3["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj3["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj3["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj3["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj3["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj3["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj3["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj3["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj3["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj3["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj3["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[4] = {// eslint-disable-line
          shortname: prevDay27.slice(0,5),// eslint-disable-line
          name: prevDay27,// eslint-disable-line
          EC2Ot: parseFloat(costObj4["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj4["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj4["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj4["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj4["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj4["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj4["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj4["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj4["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj4["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj4["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj4["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj4["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj4["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj4["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj4["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj4["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj4["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj4["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj4["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj4["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj4["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj4["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj4["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj4["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj4["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj4["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj4["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj4["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj4["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj4["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj4["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj4["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[5] = {// eslint-disable-line
          shortname: prevDay26.slice(0,5),// eslint-disable-line
          name: prevDay26,// eslint-disable-line
          EC2Ot: parseFloat(costObj5["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj5["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj5["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj5["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj5["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj5["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj5["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj5["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj5["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj5["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj5["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj5["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj5["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj5["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj5["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj5["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj5["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj5["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj5["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj5["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj5["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj5["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj5["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj5["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj5["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj5["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj5["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj5["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj5["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj5["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj5["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj5["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj5["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[6] = {// eslint-disable-line
          shortname: prevDay25.slice(0,5),// eslint-disable-line
          name: prevDay25,// eslint-disable-line
          EC2Ot: parseFloat(costObj6["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj6["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj6["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj6["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj6["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj6["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj6["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj6["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj6["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj6["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj6["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj6["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj6["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj6["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj6["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj6["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj6["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj6["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj6["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj6["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj6["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj6["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj6["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj6["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj6["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj6["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj6["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj6["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj6["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj6["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj6["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj6["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj6["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[7] = {// eslint-disable-line
          shortname: prevDay24.slice(0,5),// eslint-disable-line
          name: prevDay24,// eslint-disable-line
          EC2Ot: parseFloat(costObj7["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj7["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj7["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj7["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj7["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj7["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj7["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj7["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj7["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj7["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj7["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj7["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj7["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj7["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj7["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj7["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj7["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj7["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj7["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj7["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj7["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj7["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj7["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj7["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj7["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj7["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj7["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj7["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj7["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj7["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj7["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj7["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj7["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[8] = {// eslint-disable-line
          shortname: prevDay23.slice(0,5),// eslint-disable-line
          name: prevDay23,// eslint-disable-line
          EC2Ot: parseFloat(costObj8["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj8["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj8["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj8["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj8["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj8["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj8["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj8["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj8["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj8["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj8["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj8["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj8["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj8["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj8["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj8["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj8["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj8["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj8["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj8["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj8["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj8["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj8["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj8["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj8["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj8["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj8["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj8["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj8["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj8["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj8["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj8["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj8["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[9] = {// eslint-disable-line
          shortname: prevDay22.slice(0,5),// eslint-disable-line
          name: prevDay22,// eslint-disable-line
          EC2Ot: parseFloat(costObj9["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj9["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj9["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj9["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj9["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj9["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj9["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj9["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj9["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj9["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj9["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj9["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj9["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj9["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj9["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj9["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj9["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj9["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj9["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj9["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj9["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj9["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj9["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj9["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj9["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj9["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj9["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj9["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj9["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj9["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj9["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj9["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj9["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[10] = {// eslint-disable-line
          shortname: prevDay21.slice(0,5),// eslint-disable-line
          name: prevDay21,// eslint-disable-line
          EC2Ot: parseFloat(costObj10["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj10["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj10["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj10["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj10["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj10["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj10["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj10["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj10["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj10["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj10["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj10["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj10["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj10["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj10["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj10["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj10["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj10["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj10["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj10["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj10["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj10["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj10["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj10["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj10["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj10["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj10["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj10["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj10["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj10["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj10["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj10["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj10["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[11] = {// eslint-disable-line
          shortname: prevDay20.slice(0,5),// eslint-disable-line
          name: prevDay20,// eslint-disable-line
          EC2Ot: parseFloat(costObj11["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj11["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj11["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj11["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj11["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj11["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj11["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj11["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj11["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj11["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj11["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj11["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj11["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj11["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj11["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj11["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj11["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj11["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj11["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj11["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj11["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj11["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj11["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj11["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj11["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj11["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj11["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj11["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj11["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj11["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj11["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj11["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj11["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[12] = {// eslint-disable-line
          shortname: prevDay19.slice(0,5),// eslint-disable-line
          name: prevDay19,// eslint-disable-line
          EC2Ot: parseFloat(costObj12["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj12["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj12["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj12["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj12["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj12["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj12["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj12["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj12["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj12["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj12["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj12["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj12["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj12["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj12["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj12["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj12["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj12["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj12["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj12["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj12["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj12["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj12["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj12["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj12["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj12["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj12["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj12["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj12["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj12["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj12["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj12["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj12["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[13] = {// eslint-disable-line
          shortname: prevDay18.slice(0,5),// eslint-disable-line
          name: prevDay18,// eslint-disable-line
          EC2Ot: parseFloat(costObj13["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj13["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj13["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj13["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj13["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj13["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj13["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj13["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj13["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj13["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj13["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj13["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj13["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj13["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj13["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj13["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj13["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj13["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj13["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj13["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj13["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj13["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj13["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj13["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj13["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj13["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj13["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj13["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj13["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj13["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj13["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj13["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj13["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[14] = {// eslint-disable-line
          shortname: prevDay17.slice(0,5),// eslint-disable-line
          name: prevDay17,// eslint-disable-line
          EC2Ot: parseFloat(costObj14["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj14["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj14["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj14["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj14["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj14["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj14["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj14["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj14["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj14["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj14["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj14["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj14["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj14["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj14["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj14["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj14["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj14["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj14["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj14["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj14["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj14["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj14["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj14["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj14["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj14["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj14["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj14["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj14["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj14["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj14["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj14["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj14["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[15] = {// eslint-disable-line
          shortname: prevDay16.slice(0,5),// eslint-disable-line
          name: prevDay16,// eslint-disable-line
          EC2Ot: parseFloat(costObj15["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj15["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj15["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj15["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj15["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj15["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj15["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj15["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj15["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj15["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj15["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj15["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj15["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj15["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj15["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj15["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj15["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj15["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj15["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj15["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj15["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj15["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj15["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj15["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj15["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj15["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj15["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj15["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj15["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj15["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj15["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj15["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj15["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[16] = {// eslint-disable-line
          shortname: prevDay15.slice(0,5),// eslint-disable-line
          name: prevDay15,// eslint-disable-line
          EC2Ot: parseFloat(costObj16["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj16["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj16["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj16["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj16["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj16["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj16["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj16["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj16["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj16["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj16["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj16["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj16["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj16["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj16["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj16["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj16["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj16["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj16["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj16["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj16["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj16["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj16["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj16["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj16["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj16["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj16["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj16["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj16["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj16["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj16["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj16["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj16["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[17] = {// eslint-disable-line
          shortname: prevDay14.slice(0,5),// eslint-disable-line
          name: prevDay14,// eslint-disable-line
          EC2Ot: parseFloat(costObj17["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj17["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj17["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj17["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj17["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj17["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj17["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj17["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj17["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj17["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj17["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj17["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj17["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj17["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj17["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj17["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj17["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj17["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj17["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj17["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj17["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj17["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj17["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj17["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj17["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj17["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj17["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj17["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj17["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj17["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj17["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj17["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj17["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[18] = {// eslint-disable-line
          shortname: prevDay13.slice(0,5),// eslint-disable-line
          name: prevDay13,// eslint-disable-line
          EC2Ot: parseFloat(costObj18["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj18["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj18["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj18["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj18["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj18["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj18["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj18["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj18["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj18["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj18["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj18["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj18["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj18["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj18["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj18["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj18["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj18["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj18["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj18["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj18["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj18["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj18["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj18["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj18["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj18["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj18["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj18["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj18["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj18["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj18["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj18["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj18["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[19] = {// eslint-disable-line
          shortname: prevDay12.slice(0,5),// eslint-disable-line
          name: prevDay12,// eslint-disable-line
          EC2Ot: parseFloat(costObj19["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj19["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj19["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj19["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj19["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj19["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj19["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj19["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj19["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj19["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj19["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj19["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj19["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj19["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj19["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj19["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj19["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj19["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj19["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj19["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj19["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj19["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj19["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj19["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj19["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj19["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj19["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj19["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj19["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj19["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj19["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj19["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj19["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[20] = {// eslint-disable-line
          shortname: prevDay11.slice(0,5),// eslint-disable-line
          name: prevDay11,// eslint-disable-line
          EC2Ot: parseFloat(costObj20["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj20["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj20["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj20["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj20["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj20["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj20["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj20["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj20["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj20["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj20["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj20["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj20["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj20["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj20["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj20["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj20["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj20["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj20["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj20["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj20["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj20["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj20["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj20["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj20["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj20["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj20["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj20["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj20["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj20["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj20["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj20["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj20["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[21] = {// eslint-disable-line
          shortname: prevDay10.slice(0,5),// eslint-disable-line
          name: prevDay10,// eslint-disable-line
          EC2Ot: parseFloat(costObj21["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj21["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj21["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj21["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj21["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj21["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj21["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj21["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj21["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj21["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj21["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj21["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj21["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj21["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj21["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj21["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj21["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj21["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj21["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj21["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj21["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj21["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj21["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj21["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj21["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj21["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj21["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj21["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj21["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj21["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj21["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj21["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj21["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[22] = {// eslint-disable-line
          shortname: prevDay9.slice(0,5),// eslint-disable-line
          name: prevDay9,// eslint-disable-line
          EC2Ot: parseFloat(costObj22["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj22["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj22["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj22["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj22["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj22["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj22["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj22["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj22["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj22["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj22["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj22["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj22["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj22["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj22["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj22["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj22["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj22["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj22["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj22["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj22["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj22["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj22["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj22["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj22["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj22["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj22["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj22["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj22["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj22["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj22["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj22["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj22["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[23] = {// eslint-disable-line
          shortname: prevDay8.slice(0,5),// eslint-disable-line
          name: prevDay8,// eslint-disable-line
          EC2Ot: parseFloat(costObj23["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj23["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj23["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj23["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj23["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj23["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj23["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj23["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj23["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj23["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj23["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj23["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj23["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj23["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj23["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj23["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj23["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj23["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj23["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj23["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj23["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj23["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj23["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj23["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj23["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj23["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj23["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj23["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj23["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj23["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj23["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj23["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj23["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[24] = {// eslint-disable-line
          shortname: prevDay7.slice(0,5),// eslint-disable-line
          name: prevDay7,// eslint-disable-line
          EC2Ot: parseFloat(costObj24["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj24["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj24["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj24["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj24["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj24["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj24["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj24["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj24["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj24["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj24["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj24["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj24["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj24["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj24["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj24["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj24["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj24["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj24["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj24["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj24["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj24["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj24["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj24["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj24["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj24["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj24["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj24["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj24["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj24["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj24["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj24["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj24["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[25] = {// eslint-disable-line
          shortname: prevDay6.slice(0,5),// eslint-disable-line
          name: prevDay6,// eslint-disable-line
          EC2Ot: parseFloat(costObj25["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj25["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj25["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj25["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj25["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj25["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj25["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj25["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj25["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj25["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj25["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj25["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj25["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj25["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj25["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj25["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj25["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj25["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj25["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj25["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj25["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj25["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj25["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj25["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj25["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj25["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj25["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj25["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj25["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj25["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj25["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj25["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj25["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[26] = {// eslint-disable-line
          shortname: prevDay5.slice(0,5),// eslint-disable-line
          name: prevDay5,// eslint-disable-line
          EC2Ot: parseFloat(costObj26["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj26["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj26["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj26["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj26["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj26["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj26["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj26["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj26["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj26["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj26["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj26["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj26["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj26["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj26["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj26["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj26["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj26["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj26["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj26["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj26["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj26["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj26["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj26["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj26["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj26["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj26["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj26["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj26["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj26["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj26["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj26["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj26["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[27] = {// eslint-disable-line
          shortname: prevDay4.slice(0,5),// eslint-disable-line
          name: prevDay4,// eslint-disable-line
          EC2Ot: parseFloat(costObj27["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj27["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj27["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj27["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj27["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj27["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj27["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj27["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj27["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj27["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj27["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj27["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj27["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj27["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj27["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj27["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj27["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj27["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj27["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj27["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj27["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj27["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj27["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj27["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj27["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj27["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj27["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj27["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj27["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj27["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj27["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj27["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj27["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[28] = {// eslint-disable-line
          shortname: prevDay3.slice(0,5),// eslint-disable-line
          name: prevDay3,// eslint-disable-line
          EC2Ot: parseFloat(costObj28["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj28["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj28["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj28["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj28["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj28["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj28["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj28["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj28["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj28["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj28["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj28["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj28["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj28["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj28["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj28["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj28["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj28["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj28["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj28["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj28["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj28["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj28["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj28["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj28["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj28["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj28["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj28["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj28["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj28["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj28["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj28["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj28["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[29] = {// eslint-disable-line
          shortname: prevDay2.slice(0,5),// eslint-disable-line
          name: prevDay2,// eslint-disable-line
          EC2Ot: parseFloat(costObj29["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj29["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj29["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj29["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj29["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj29["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj29["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj29["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj29["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj29["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj29["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj29["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj29["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj29["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj29["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj29["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj29["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj29["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj29["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj29["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj29["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj29["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj29["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj29["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj29["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj29["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj29["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj29["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj29["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj29["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj29["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj29["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj29["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[30] = {// eslint-disable-line
          shortname: prevDay1.slice(0,5),// eslint-disable-line
          name: prevDay1,// eslint-disable-line
          EC2Ot: parseFloat(costObj30["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj30["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj30["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj30["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj30["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj30["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj30["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj30["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj30["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj30["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj30["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj30["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj30["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj30["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj30["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj30["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj30["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj30["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj30["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj30["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj30["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj30["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj30["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj30["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj30["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj30["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj30["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj30["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj30["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj30["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj30["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj30["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj30["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
        DailyCost[31] = {// eslint-disable-line
          shortname: prevDay.slice(0,5),// eslint-disable-line
          name: prevDay,// eslint-disable-line
          EC2Ot: parseFloat(costObj31["EC2 - Other"]).toFixed(1),// eslint-disable-line
          ECC: parseFloat(costObj31["Amazon Elastic Compute Cloud - Compute"]).toFixed(1),// eslint-disable-line
          Lambda: parseFloat(costObj31["AWS Lambda"]).toFixed(1),// eslint-disable-line
          CloudT: parseFloat(costObj31["AWS CloudTrail"]).toFixed(1),// eslint-disable-line
          RDS: parseFloat(costObj31["Amazon Relational Database Service"]).toFixed(1),// eslint-disable-line
          VPC: parseFloat(costObj31["Amazon Virtual Private Cloud"]).toFixed(1),// eslint-disable-line
          Waf: parseFloat(costObj31["AWS WAF"]).toFixed(1),// eslint-disable-line
          EFS: parseFloat(costObj31["Amazon Elastic File System"]).toFixed(1),// eslint-disable-line
          Config: parseFloat(costObj31["AWS Config"]).toFixed(1),// eslint-disable-line
          DirCnt: parseFloat(costObj31["AWS Direct Connect"]).toFixed(1),// eslint-disable-line
          Glue: parseFloat(costObj31["AWS Glue"]).toFixed(1),// eslint-disable-line
          KeyMgt: parseFloat(costObj31["AWS Key Management Service"]).toFixed(1),// eslint-disable-line
          SecMgr: parseFloat(costObj31["AWS Secrets Manager"]).toFixed(1),// eslint-disable-line
          SecHub: parseFloat(costObj31["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Shield: parseFloat(costObj31["AWS Security Hub"]).toFixed(1),// eslint-disable-line
          Dyna: parseFloat(costObj31["Amazon DynamoDB"]).toFixed(1),// eslint-disable-line
          ELB: parseFloat(costObj31["Amazon Elastic Load Balancing"]).toFixed(1),// eslint-disable-line
          FSX: parseFloat(costObj31["Amazon FSx"]).toFixed(1),// eslint-disable-line
          GD: parseFloat(costObj31["Amazon GuardDuty"]).toFixed(1),// eslint-disable-line
          Insp: parseFloat(costObj31["Amazon Inspector"]).toFixed(1),// eslint-disable-line
          SES: parseFloat(costObj31["Amazon Simple Email Service"]).toFixed(1),// eslint-disable-line
          SNS: parseFloat(costObj31["Amazon Simple Notification Service"]).toFixed(1),// eslint-disable-line
          SQS: parseFloat(costObj31["Amazon Simple Queue Service"]).toFixed(1),// eslint-disable-line
          SSS: parseFloat(costObj31["Amazon Simple Storage Service"]).toFixed(1),// eslint-disable-line
          WS: parseFloat(costObj31["Amazon WorkSpaces"]).toFixed(1),// eslint-disable-line
          CW: parseFloat(costObj31["AmazonCloudWatch"]).toFixed(1),// eslint-disable-line
          CWE: parseFloat(costObj31["CloudWatch Events"]).toFixed(1),// eslint-disable-line
          CA: parseFloat(costObj31["AWS CodeArtifact"]).toFixed(1),// eslint-disable-line
          SFS: parseFloat(costObj31["AWS Step Functions"]).toFixed(1),// eslint-disable-line
          ECR: parseFloat(costObj31["Amazon EC2 Container Registry (ECR)"]).toFixed(1),// eslint-disable-line
          TF: parseFloat(costObj31["AWS Transfer Family"]).toFixed(1),// eslint-disable-line
          ApiG: parseFloat(costObj31["Amazon API Gateway"]).toFixed(1),// eslint-disable-line
          AS: parseFloat(costObj31["Amazon AppStream"]).toFixed(1)// eslint-disable-line
        };// eslint-disable-line
      }// eslint-disable-line
      this.setState({
        DailyByService: DailyCost,
        CostService: service,
        LambdaCost: costLambda,
        CloudTCost: costCloudT,
        ConfigCost: costConfig,
        DirCntCost: costDirCnt,
        GlueCost: costGlue,
        KeyMgtCost: costKeyMgt,
        SecMgrCost: costSecMgr,
        SecHubCost: costSecHub,
        ShieldCost: costShield,
        WafCost: costWaf,
        DynaCost: costDyna,
        RDSCost: costRDS,
        EC2OtCost: costEC2Ot,
        ECCCost: costECC,
        ELBCost: costELB,
        EFSCost: costEFS,
        FSXCost: costFSX,
        GDCost: costGD,
        InspCost: costInsp,
        SESCost: costSES,
        SNSCost: costSNS,
        SQSCost: costSQS,
        SSSCost: costSSS,
        VPCCost: costVPC,
        WSCost: costWS,
        CWCost: costCW,
        ASCost: costAS,
        AmortizedCost: [
          {
            RId: "0",
            RType: "Lambda",
            TotalAmount: (parseFloat(costLambda) + parseFloat(costLambda1) + parseFloat(costLambda2) + parseFloat(costLambda3) + parseFloat(costLambda4) + parseFloat(costLambda5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costLambda).toFixed(2),
            Amount1: parseFloat(costLambda1).toFixed(2),
            Amount2: parseFloat(costLambda2).toFixed(2),
            Amount3: parseFloat(costLambda3).toFixed(2),
            Amount4: parseFloat(costLambda4).toFixed(2),
            Amount5: parseFloat(costLambda5).toFixed(2)
          },
          {
            RId: "1",
            RType: "Config",
            TotalAmount: (parseFloat(costConfig) + parseFloat(costConfig1) + parseFloat(costConfig2) + parseFloat(costConfig3) + parseFloat(costConfig4) + parseFloat(costConfig5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costConfig).toFixed(2),
            Amount1: parseFloat(costConfig1).toFixed(2),
            Amount2: parseFloat(costConfig2).toFixed(2),
            Amount3: parseFloat(costConfig3).toFixed(2),
            Amount4: parseFloat(costConfig4).toFixed(2),
            Amount5: parseFloat(costConfig5).toFixed(2)
          },
          {
            RId: "2",
            RType: "CloudTrail",
            TotalAmount: (parseFloat(costCloudT) + parseFloat(costCloudT1) + parseFloat(costCloudT2) + parseFloat(costCloudT3) + parseFloat(costCloudT4) + parseFloat(costCloudT5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCloudT).toFixed(2),
            Amount1: parseFloat(costCloudT1).toFixed(2),
            Amount2: parseFloat(costCloudT2).toFixed(2),
            Amount3: parseFloat(costCloudT3).toFixed(2),
            Amount4: parseFloat(costCloudT4).toFixed(2),
            Amount5: parseFloat(costCloudT5).toFixed(2)
          },
          {
            RId: "3",
            RType: "Dir Connect",
            TotalAmount: (parseFloat(costDirCnt) + parseFloat(costDirCnt1) + parseFloat(costDirCnt2) + parseFloat(costDirCnt3) + parseFloat(costDirCnt4) + parseFloat(costDirCnt5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costDirCnt).toFixed(2),
            Amount1: parseFloat(costDirCnt1).toFixed(2),
            Amount2: parseFloat(costDirCnt2).toFixed(2),
            Amount3: parseFloat(costDirCnt3).toFixed(2),
            Amount4: parseFloat(costDirCnt4).toFixed(2),
            Amount5: parseFloat(costDirCnt5).toFixed(2)
          },
          {
            RId: "4",
            RType: "Glue",
            TotalAmount: (parseFloat(costGlue) + parseFloat(costGlue1) + parseFloat(costGlue2) + parseFloat(costGlue3) + parseFloat(costGlue4) + parseFloat(costGlue5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costGlue).toFixed(2),
            Amount1: parseFloat(costGlue1).toFixed(2),
            Amount2: parseFloat(costGlue2).toFixed(2),
            Amount3: parseFloat(costGlue3).toFixed(2),
            Amount4: parseFloat(costGlue4).toFixed(2),
            Amount5: parseFloat(costGlue5).toFixed(2)
          },
          {
            RId: "5",
            RType: "KMS",
            TotalAmount: (parseFloat(costKeyMgt) + parseFloat(costKeyMgt2) + parseFloat(costKeyMgt3) + parseFloat(costKeyMgt4) + parseFloat(costKeyMgt5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costKeyMgt).toFixed(2),
            Amount1: parseFloat(costKeyMgt1).toFixed(2),
            Amount2: parseFloat(costKeyMgt2).toFixed(2),
            Amount3: parseFloat(costKeyMgt3).toFixed(2),
            Amount4: parseFloat(costKeyMgt4).toFixed(2),
            Amount5: parseFloat(costKeyMgt5).toFixed(2)
          },
          {
            RId: "6",
            RType: "Sec Manager",
            TotalAmount: (parseFloat(costSecMgr) + parseFloat(costSecMgr1) + parseFloat(costSecMgr2) + parseFloat(costSecMgr3)+ parseFloat(costSecMgr4) + parseFloat(costSecMgr5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSecMgr).toFixed(2),
            Amount1: parseFloat(costSecMgr1).toFixed(2),
            Amount2: parseFloat(costSecMgr2).toFixed(2),
            Amount3: parseFloat(costSecMgr3).toFixed(2),
            Amount4: parseFloat(costSecMgr4).toFixed(2),
            Amount5: parseFloat(costSecMgr5).toFixed(2)
          },
          {
            RId: "7",
            RType: "Sec Hub",
            TotalAmount: (parseFloat(costSecHub) + parseFloat(costSecHub2) + parseFloat(costSecHub2) + parseFloat(costSecHub3) + parseFloat(costSecHub4) + parseFloat(costSecHub5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSecHub).toFixed(2),
            Amount1: parseFloat(costSecHub1).toFixed(2),
            Amount2: parseFloat(costSecHub2).toFixed(2),
            Amount3: parseFloat(costSecHub3).toFixed(2),
            Amount4: parseFloat(costSecHub4).toFixed(2),
            Amount5: parseFloat(costSecHub5).toFixed(2)
          },
          {
            RId: "8",
            RType: "Shield",
            TotalAmount: (parseFloat(costShield) + parseFloat(costShield2) + parseFloat(costShield3) + parseFloat(costShield4) + parseFloat(costShield5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costShield).toFixed(2),
            Amount1: parseFloat(costShield1).toFixed(2),
            Amount2: parseFloat(costShield2).toFixed(2),
            Amount3: parseFloat(costShield3).toFixed(2),
            Amount4: parseFloat(costShield4).toFixed(2),
            Amount5: parseFloat(costShield5).toFixed(2)
          },
          {
            RId: "9",
            RType: "WAF",
            TotalAmount: (parseFloat(costLambda) + parseFloat(costLambda1) + parseFloat(costLambda2) + parseFloat(costLambda3) + parseFloat(costLambda4) + parseFloat(costLambda5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costLambda).toFixed(2),
            Amount1: parseFloat(costLambda1).toFixed(2),
            Amount2: parseFloat(costLambda2).toFixed(2),
            Amount3: parseFloat(costLambda3).toFixed(2),
            Amount4: parseFloat(costLambda4).toFixed(2),
            Amount5: parseFloat(costLambda5).toFixed(2)
          },
          {
            RId: "10",
            RType: "DynamoDB",
            TotalAmount: (parseFloat(costDyna) + parseFloat(costDyna1) + parseFloat(costDyna2) + parseFloat(costDyna3) + parseFloat(costDyna4) + parseFloat(costDyna5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costDyna).toFixed(2),
            Amount1: parseFloat(costDyna1).toFixed(2),
            Amount2: parseFloat(costDyna2).toFixed(2),
            Amount3: parseFloat(costDyna3).toFixed(2),
            Amount4: parseFloat(costDyna4).toFixed(2),
            Amount5: parseFloat(costDyna5).toFixed(2)
          },
          {
            RId: "11",
            RType: "RDS",
            TotalAmount: (parseFloat(costRDS)+ parseFloat(costRDS1) + parseFloat(costRDS2) + parseFloat(costRDS3) + parseFloat(costRDS4) + parseFloat(costRDS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costRDS).toFixed(2),
            Amount1: parseFloat(costRDS1).toFixed(2),
            Amount2: parseFloat(costRDS2).toFixed(2),
            Amount3: parseFloat(costRDS3).toFixed(2),
            Amount4: parseFloat(costRDS4).toFixed(2),
            Amount5: parseFloat(costRDS5).toFixed(2)
          },
          {
            RId: "12",
            RType: "EC2-Other",
            TotalAmount: (parseFloat(costEC2Ot) + parseFloat(costEC2Ot1) + parseFloat(costEC2Ot2) + parseFloat(costEC2Ot3) + parseFloat(costEC2Ot4) + parseFloat(costEC2Ot5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costEC2Ot).toFixed(2),
            Amount1: parseFloat(costEC2Ot1).toFixed(2),
            Amount2: parseFloat(costEC2Ot2).toFixed(2),
            Amount3: parseFloat(costEC2Ot3).toFixed(2),
            Amount4: parseFloat(costEC2Ot4).toFixed(2),
            Amount5: parseFloat(costEC2Ot5).toFixed(2)
          },
          {
            RId: "13",
            RType: "EC2-Compute",
            TotalAmount: (parseFloat(costECC) + parseFloat(costECC1) + parseFloat(costECC2) + parseFloat(costECC3) + parseFloat(costECC4) + parseFloat(costECC5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costECC).toFixed(2),
            Amount1: parseFloat(costECC1).toFixed(2),
            Amount2: parseFloat(costECC2).toFixed(2),
            Amount3: parseFloat(costECC3).toFixed(2),
            Amount4: parseFloat(costECC4).toFixed(2),
            Amount5: parseFloat(costECC5).toFixed(2)
          },
          {
            RId: "14",
            RType: "ELB",
            TotalAmount: (parseFloat(costELB) + parseFloat(costELB1) + parseFloat(costELB2) + parseFloat(costELB4) + parseFloat(costELB5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costELB).toFixed(2),
            Amount1: parseFloat(costELB1).toFixed(2),
            Amount2: parseFloat(costELB2).toFixed(2),
            Amount3: parseFloat(costELB3).toFixed(2),
            Amount4: parseFloat(costELB4).toFixed(2),
            Amount5: parseFloat(costELB5).toFixed(2)
          },
          {
            RId: "15",
            RType: "File System",
            TotalAmount: (parseFloat(costEFS) + parseFloat(costEFS1) + parseFloat(costEFS2) + parseFloat(costEFS3) + parseFloat(costEFS4) + parseFloat(costEFS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costEFS).toFixed(2),
            Amount1: parseFloat(costEFS1).toFixed(2),
            Amount2: parseFloat(costEFS2).toFixed(2),
            Amount3: parseFloat(costEFS3).toFixed(2),
            Amount4: parseFloat(costEFS4).toFixed(2),
            Amount5: parseFloat(costEFS5).toFixed(2)
          },
          {
            RId: "16",
            RType: "FSx",
            TotalAmount: (parseFloat(costFSX) + parseFloat(costFSX1) + parseFloat(costFSX1) + parseFloat(costFSX2) + parseFloat(costFSX3) + parseFloat(costFSX4) + parseFloat(costFSX5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costFSX).toFixed(2),
            Amount1: parseFloat(costFSX1).toFixed(2),
            Amount2: parseFloat(costFSX2).toFixed(2),
            Amount3: parseFloat(costFSX3).toFixed(2),
            Amount4: parseFloat(costFSX4).toFixed(2),
            Amount5: parseFloat(costFSX5).toFixed(2)
          },
          {
            RId: "17",
            RType: "Guard Duty",
            TotalAmount: (parseFloat(costGD) + parseFloat(costGD1) + parseFloat(costGD2) + parseFloat(costGD3) + parseFloat(costGD4) + parseFloat(costGD5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costGD).toFixed(2),
            Amount1: parseFloat(costGD1).toFixed(2),
            Amount2: parseFloat(costGD2).toFixed(2),
            Amount3: parseFloat(costGD3).toFixed(2),
            Amount4: parseFloat(costGD4).toFixed(2),
            Amount5: parseFloat(costGD5).toFixed(2)
          },
          {
            RId: "18",
            RType: "Inspector",
            TotalAmount: (parseFloat(costInsp) + parseFloat(costInsp1) + parseFloat(costInsp2) + parseFloat(costInsp3) + parseFloat(costInsp4) + parseFloat(costInsp5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costInsp).toFixed(2),
            Amount1: parseFloat(costInsp1).toFixed(2),
            Amount2: parseFloat(costInsp2).toFixed(2),
            Amount3: parseFloat(costInsp3).toFixed(2),
            Amount4: parseFloat(costInsp4).toFixed(2),
            Amount5: parseFloat(costInsp5).toFixed(2)
          },
          {
            RId: "19",
            RType: "SES",
            TotalAmount: (parseFloat(costSES) + parseFloat(costSES1) + parseFloat(costSES2) + parseFloat(costSES3) + parseFloat(costSES4) + parseFloat(costSES5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSES).toFixed(2),
            Amount1: parseFloat(costSES1).toFixed(2),
            Amount2: parseFloat(costSES2).toFixed(2),
            Amount3: parseFloat(costSES3).toFixed(2),
            Amount4: parseFloat(costSES4).toFixed(2),
            Amount5: parseFloat(costSES5).toFixed(2)
          },
          {
            RId: "20",
            RType: "SNS",
            TotalAmount: (parseFloat(costSNS) + parseFloat(costSNS1) + parseFloat(costSNS2) + parseFloat(costSNS3) + parseFloat(costSNS4) + parseFloat(costSNS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSNS).toFixed(2),
            Amount1: parseFloat(costSNS1).toFixed(2),
            Amount2: parseFloat(costSNS2).toFixed(2),
            Amount3: parseFloat(costSNS3).toFixed(2),
            Amount4: parseFloat(costSNS4).toFixed(2),
            Amount5: parseFloat(costSNS5).toFixed(2)
          },
          {
            RId: "21",
            RType: "SQS",
            TotalAmount: (parseFloat(costSQS) + parseFloat(costSQS1) + parseFloat(costSQS2) + parseFloat(costSQS3) + parseFloat(costSQS4) + parseFloat(costSQS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSQS).toFixed(2),
            Amount1: parseFloat(costSQS1).toFixed(2),
            Amount2: parseFloat(costSQS2).toFixed(2),
            Amount3: parseFloat(costSQS3).toFixed(2),
            Amount4: parseFloat(costSQS4).toFixed(2),
            Amount5: parseFloat(costSQS5).toFixed(2)
          },
          {
            RId: "22",
            RType: "S3",
            TotalAmount: (parseFloat(costSSS) + parseFloat(costSSS1) + parseFloat(costSSS2) + parseFloat(costSSS3) + parseFloat(costSSS4) + parseFloat(costSSS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSSS).toFixed(2),
            Amount1: parseFloat(costSSS1).toFixed(2),
            Amount2: parseFloat(costSSS2).toFixed(2),
            Amount3: parseFloat(costSSS3).toFixed(2),
            Amount4: parseFloat(costSSS4).toFixed(2),
            Amount5: parseFloat(costSSS5).toFixed(2)
          },
          {
            RId: "23",
            RType: "VPC",
            TotalAmount: (parseFloat(costVPC) + parseFloat(costVPC1) + parseFloat(costVPC2) + parseFloat(costVPC3) + parseFloat(costVPC4) + parseFloat(costVPC5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costVPC).toFixed(2),
            Amount1: parseFloat(costVPC1).toFixed(2),
            Amount2: parseFloat(costVPC2).toFixed(2),
            Amount3: parseFloat(costVPC3).toFixed(2),
            Amount4: parseFloat(costVPC4).toFixed(2),
            Amount5: parseFloat(costVPC5).toFixed(2)
          },
          {
            RId: "24",
            RType: "WorkSpaces",
            TotalAmount: (parseFloat(costWS) + parseFloat(costWS1) + parseFloat(costWS2) + parseFloat(costWS3) + parseFloat(costWS4) + parseFloat(costWS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costWS).toFixed(2),
            Amount1: parseFloat(costWS1).toFixed(2),
            Amount2: parseFloat(costWS2).toFixed(2),
            Amount3: parseFloat(costWS3).toFixed(2),
            Amount4: parseFloat(costWS4).toFixed(2),
            Amount5: parseFloat(costWS5).toFixed(2)
          },
          {
            RId: "25",
            RType: "CloudWatch",
            TotalAmount: (parseFloat(costCW) + parseFloat(costCW1) + parseFloat(costCW2) + parseFloat(costCW3) + parseFloat(costCW4) + parseFloat(costCW5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCW).toFixed(2),
            Amount1: parseFloat(costCW1).toFixed(2),
            Amount2: parseFloat(costCW2).toFixed(2),
            Amount3: parseFloat(costCW3).toFixed(2),
            Amount4: parseFloat(costCW4).toFixed(2),
            Amount5: parseFloat(costCW5).toFixed(2)
          },
          {
            RId: "26",
            RType: "CWatch Events",
            TotalAmount: (parseFloat(costCWE) + parseFloat(costCWE1) + parseFloat(costCWE2) + parseFloat(costCWE3) + parseFloat(costCWE4) + parseFloat(costCWE5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCWE).toFixed(2),
            Amount1: parseFloat(costCWE1).toFixed(2),
            Amount2: parseFloat(costCWE2).toFixed(2),
            Amount3: parseFloat(costCWE3).toFixed(2),
            Amount4: parseFloat(costCWE4).toFixed(2),
            Amount5: parseFloat(costCWE5).toFixed(2)
          },
          {
            RId: "27",
            RType: "Code Artifact",
            TotalAmount: (parseFloat(costCA) + parseFloat(costCA1) + parseFloat(costCA2) + parseFloat(costCA3) + parseFloat(costCA4) + parseFloat(costCA5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCA).toFixed(2),
            Amount1: parseFloat(costCA1).toFixed(2),
            Amount2: parseFloat(costCA2).toFixed(2),
            Amount3: parseFloat(costCA3).toFixed(2),
            Amount4: parseFloat(costCA4).toFixed(2),
            Amount5: parseFloat(costCA5).toFixed(2)
          },
          {
            RId: "28",
            RType: "Step Functions",
            TotalAmount: (parseFloat(costSF) + parseFloat(costSF1) + parseFloat(costSF2) + parseFloat(costSF3) + parseFloat(costSF4) + parseFloat(costSF5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSF).toFixed(2),
            Amount1: parseFloat(costSF1).toFixed(2),
            Amount2: parseFloat(costSF2).toFixed(2),
            Amount3: parseFloat(costSF3).toFixed(2),
            Amount4: parseFloat(costSF4).toFixed(2),
            Amount5: parseFloat(costSF5).toFixed(2)
          },
          {
            RId: "29",
            RType: "ECR",
            TotalAmount: (parseFloat(costECR) + parseFloat(costECR1) + parseFloat(costECR2) + parseFloat(costECR3) + parseFloat(costECR4) + parseFloat(costECR5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costECR).toFixed(2),
            Amount1: parseFloat(costECR1).toFixed(2),
            Amount2: parseFloat(costECR2).toFixed(2),
            Amount3: parseFloat(costECR3).toFixed(2),
            Amount4: parseFloat(costECR4).toFixed(2),
            Amount5: parseFloat(costECR5).toFixed(2)
          },
          {
            RId: "30",
            RType: "Transfer Family",
            TotalAmount: (parseFloat(costTF) + parseFloat(costTF1) + parseFloat(costTF2) + parseFloat(costTF3) + parseFloat(costTF4) + parseFloat(costTF5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costTF).toFixed(2),
            Amount1: parseFloat(costTF1).toFixed(2),
            Amount2: parseFloat(costTF2).toFixed(2),
            Amount3: parseFloat(costTF3).toFixed(2),
            Amount4: parseFloat(costTF4).toFixed(2),
            Amount5: parseFloat(costTF5).toFixed(2)
          },
          {
            RId: "31",
            RType: "API Gateway",
            TotalAmount: (parseFloat(costAG) + parseFloat(costAG1) + parseFloat(costAG2) + parseFloat(costAG3) + parseFloat(costAG4) + parseFloat(costAG5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costAG).toFixed(2),
            Amount1: parseFloat(costAG1).toFixed(2),
            Amount2: parseFloat(costAG2).toFixed(2),
            Amount3: parseFloat(costAG3).toFixed(2),
            Amount4: parseFloat(costAG4).toFixed(2),
            Amount5: parseFloat(costAG5).toFixed(2)
          },
          {
            RId: "32",
            RType: "App Stream",
            TotalAmount: (parseFloat(costAS) + parseFloat(costAS1) + parseFloat(costAS2) + parseFloat(costAS3) + parseFloat(costAS4) + parseFloat(costAS5)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costAS).toFixed(2),
            Amount1: parseFloat(costAS1).toFixed(2),
            Amount2: parseFloat(costAS2).toFixed(2),
            Amount3: parseFloat(costAS3).toFixed(2),
            Amount4: parseFloat(costAS4).toFixed(2),
            Amount5: parseFloat(costAS5).toFixed(2)
          }
        ],
        listCostInProgress: false
      });
    });
  }

  resfreshAllData() {
    this.listAlarms(this.state.account_id);
    this.listCost(this.state.account_id, "ALL");
  }

  getAlarmsListCallback(response) {
    //debugger;
    this.setState({
      alarms: response["MetricAlarms"],
      listAlarmInProgress: false,
      seconds: 0
    });
    this.getHistory(this.state.account_id);
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          null,
          null,
          null,
          null,
          null,
          "eu-west-1",
          {
            MaxRecords: 100
          }
        );
      }
    );
  }

  isAlarmSetUp = (alarm_action, ok_action) =>
    [
      "arn:aws:sns:eu-west-1:182726513909:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:815704857845:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:726054927466:snt-exsi-notifications"
    ]
      .map(
        sns_arn => alarm_action.includes(sns_arn) && ok_action.includes(sns_arn)
      )
      .find(value => !value) === undefined;

  renderCircle(status, alarm_action, ok_action, classes) {
    if (!this.isAlarmSetUp(alarm_action, ok_action)) {
      return (
        <Grid container spacing={8} className={classes.gridContainer}>
          <Grid item xs={3}>
            <Circle bgColor={circleColor.yellow} circleSize={20} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                "font-family": "sans-serif",
                "font-size": "14px",
                "font-weight": "bold",
                color: "#3c577a"
              }}
            >
              aucune configuration
            </Typography>
          </Grid>
        </Grid>
      );
    }

    switch (status) {
      case "ALARM":
        return <Circle bgColor={circleColor.red} circleSize={20} />;

      case "OK":
        return <Circle bgColor={circleColor.green} circleSize={20} />;

      default:
        return <Circle bgColor={circleColor.gray} circleSize={20} />;
    }
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
  }

  handleSurveillanceClickOpen(alarm_name) {
    this.setState({
      modalSurveillanceOpen: true,
      current_alarm: alarm_name
    });
  }

  handleSurveillanceClose() {
    this.setState({
      modalSurveillanceOpen: false
    });
  }

  handleHistoryClickOpen(alarm_name, history) {
    this.setState({
      modalHistoryOpen: true,
      current_alarm: alarm_name,
      current_history: history
    });
  }

  handleHistoryClose() {
    this.setState({
      modalHistoryOpen: false,
      current_history: undefined
    });
  }

  getHistory(account_id) {
    if (this.state.getHistoryInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        getHistoryInProgress: true
      },
      () => {
        getAccountAlarmLogs(account_id).then(response => {
          const alarmsWithHistory = this.state.alarms
            .map(alarm => ({
              alarm,
              history: response
                .filter(
                  item =>
                    item["AlarmName"] && item["AlarmName"] === alarm.AlarmName
                )
                .sort((a, b) => {
                  const hasAGoodTimeStamp = a["ts"] && a["ts"];
                  const hasBGoodTimeStamp = b["ts"] && b["ts"];
                  if (!hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return 0;
                  }

                  if (hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return -1;
                  }

                  if (!hasAGoodTimeStamp && hasBGoodTimeStamp) {
                    return 1;
                  }
                  return a["ts"] > b["ts"] ? -1 : 1;
                })
            }))
            .sort((a, b) => {
              const hasAHistory = a.history.length > 0;
              const hasBHistory = b.history.length > 0;

              if (!hasAHistory && !hasBHistory) {
                return 0;
              }

              if (hasAHistory && !hasBHistory) {
                return -1;
              }

              if (!hasAHistory && hasBHistory) {
                return 1;
              }

              return a.history[0]["ts"] > b.history[0]["ts"] ? -1 : 1;
            });
          this.setState({
            alarmsWithHistory: alarmsWithHistory,
            getHistoryInProgress: false,
            loading: false
          });
        });
      }
    );
  }

  renderSurveillance(alarm_name) {
    let channel_counter = 0;
    if (this.state.surveillance) {
      if ("Items" in this.state.surveillance) {
        for (let i = 0; i < this.state.surveillance["Items"].length; i++) {
          let regex = this.state.surveillance.Items[i]["AlarmName"].replace(
            "*",
            ".*"
          );
          if (alarm_name.match(regex)) {
            channel_counter++;
          }
        }
      }
    }
    if (channel_counter === 0) {
      return "Aucun canal";
    } else if (channel_counter === 1) {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canal
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canaux
        </Button>
      );
    }
  }

  renderHistory(alarm_name, history) {
    return history.length > 0 ? (
      <Button
        onClick={() => this.handleHistoryClickOpen(alarm_name, history)}
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          "border-radius": "30px",
          "padding-top": "0px",
          "padding-bottom": "0px",
          textTransform: "lowercase",
          background: "#edfaf7",
          color: "#3c577a"
        }}
      >
        {Moment(new Date(history[0].ts * 1000)).format(
          "ddd Do MMMM YYYY, HH:mm:ss"
        )}{" "}
        ({history.length} évenements)
      </Button>
    ) : (
      <Button
        disabled
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          textTransform: "lowercase",
          "background-color": "white",
          color: "#3c577a"
        }}
      >
        Aucun évenement
      </Button>
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account_id}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const champs = [
      "EC2Ot",
      "ECC",
      "Lambda",
      "CloudT",
      "RDS",
      "VPC",
      "Waf",
      "EFS",
      "Config",
      "DirCnt",
      "Glue",
      "KeyMgt",
      "SecMgr",
      "SecHub",
      "Shield",
      "Dyna",
      "ELB",
      "FSX",
      "GD",
      "Insp",
      "SES",
      "SNS",
      "SQS",
      "SSS",
      "WS",
      "CW",
      "CWE",
      "CA",
      "SFS",
      "ECR",
      "TF",
      "ApiG",
      "AS"
    ];
    if (!this.state.applicationState) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else if (this.state.applicationState === "down") {
      return (
        <div>
          <Circle bgColor={circleColor.black} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement a été éteint, vous pouvez le rallumer avec l'action
            laf-env-controller-start.
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.alarms &&
      this.state.alarms.length > 0 &&
      this.state.alarmsWithHistory
    ) {
      return (
        <div>
          <Paper
            className={classes.root}
            style={{
              background: "#f7faf9",
              "margin-left": "20px",
              "margin-right": "20px",
              "margin-bottom": "20px",
              "box-shadow": "none"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh"
              }}
            >
              <AreaChart
                width={1350}
                height={640}
                data={this.state.MonthlyTotal}
                margin={{ top: 120, right: 100, left: 0, bottom: 150 }}
              >
                <defs>
                  <linearGradient id="colorMax" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorTv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" hide="true" />
                <YAxis domain={[0, dataMax => dataMax * 2]} />
                <CartesianGrid strokeDasharray="1 1" />
                <Tooltip
                  offset={-100}
                  cursor={{ stroke: "#99ccff", strokeWidth: 2 }}
                  itemStyle={{ border: "none" }}
                  contentStyle={{ "font-weight": "600" }}
                  wrapperStyle={{ border: "none" }}
                  labelStyle={{ border: "none" }}
                />
                <Area
                  type="monotone"
                  dataKey="total du jour"
                  stroke="#009999"
                  fillOpacity={1}
                  fill="url(#colorTv)"
                />
                <Area
                  type="monotone"
                  dataKey="max par jour"
                  stroke="#8884d8"
                  fillOpacity={0.1}
                  fill="url(#colorMax)"
                />
              </AreaChart>
            </div>
            <Button
              size="small"
              color="primary"
              style={{
                "font-size": "12px",
                "font-weight": "600",
                color: "#009999",
                "box-shadow": "none",
                "border-radius": "5px",
                border: "0px solid #C1B1FB",
                "font-family": "'Lucida Console', Monaco, monospace",
                marginBottom: "30px",
                backgroundColor: "#E9FBF1"
              }}
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                    this.listCost(this.state.account_id, "ALL");
                  }
                );
              }}
            >
              <Cached /> Refresh ({this.state.seconds} s)
            </Button>
            <Table
              data={{ nodes: this.state.MonthlyTotal }}
              theme={tableTheme}
              style={{
                border: "0px solid #C1B1FB",
                "border-radius": "20px 20px 0px 0px",
                "border-bottom": "0px"
              }}
            >
              {tableList => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell></HeaderCell>
                      {tableList.map(item => (
                        <HeaderCell key={item.id} item={item} resize>
                          {item.shortname}
                        </HeaderCell>
                      ))}
                    </HeaderRow>
                  </Header>
                  <Body>
                    <Row>
                      <Cell style={{ "background-color": "#F9FAF5" }}>
                        Total
                      </Cell>
                      {tableList.map(item => (
                        <Cell
                          style={{ "background-color": "#F9FAF5" }}
                          key={item.id}
                          item={item}
                          resize
                        >
                          {item.total}
                        </Cell>
                      ))}
                    </Row>
                  </Body>
                </>
              )}
            </Table>
            <Table
              data={{ nodes: this.state.DailyByService }}
              theme={tableTheme}
              style={{
                border: "0px solid #C1B1FB",
                "border-radius": "0px 0px 20px 20px",
                "border-top": "0px"
              }}
            >
              {tableList => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell
                        style={{
                          height: "0px",
                          "margin-bottom": "-40px",
                          opacity: "0"
                        }}
                      ></HeaderCell>
                      {tableList.map(item => (
                        <HeaderCell
                          style={{
                            height: "0px",
                            "margin-bottom": "-40px",
                            opacity: "0"
                          }}
                          key={item.id}
                          item={item}
                          resize
                        >
                          {item.shortname}
                        </HeaderCell>
                      ))}
                    </HeaderRow>
                  </Header>
                  <Body>
                    {champs.map(champ => (
                      <Row key={champ}>
                        <Cell>{champ}</Cell>
                        {tableList.map(item => (
                          <Cell key={item.id} item={item} resize>
                            {isNaN(item[champ]) ? "0.0" : item[champ]}
                          </Cell>
                        ))}
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </Paper>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return "";
    }
  }
}

FinopsDashboardTableMonth.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(FinopsDashboardTableMonth));
