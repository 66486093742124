import React, { useEffect, useState } from "react";
import {
  filterPipelines,
  getAllPipelines,
  reversePipelineNamesAppMap
} from "../../Services/PipelinesService";
//import { CircularLoading } from "../../Styles/ComponentStyles";
import PipelineGeneral from "./PipelineGeneral";
import { groupAccountsByApp } from "../../Services/AccountService";

const PipelinesDashboard = ({ app, accountList }) => {
  const [appPipelineNames, setAppPipelineNames] = useState(undefined);
  const [appEnvIdList] = useState(
    Object.values(groupAccountsByApp(accountList)[app])
  );
  const [pipelineNameAccountIdMap, setPipelineNameAccountIdMap] = useState(
    undefined
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAppPipelineNames = async () => {
      Promise.all([
        getAllPipelines(),
        ...appEnvIdList.map(async accountId => ({
          [accountId]: await getAllPipelines(accountId)
        }))
      ]).then(responses => {
        const [
          pipelineNamesSharPrd,
          ...accountIdPipelineNamesAppMapList
        ] = responses;
        const reversedPipelineNamesAppMap = reversePipelineNamesAppMap(
          accountIdPipelineNamesAppMapList
        );
        setPipelineNameAccountIdMap(reversedPipelineNamesAppMap);
        setAppPipelineNames(
          filterPipelines(
            pipelineNamesSharPrd.concat(
              Object.keys(reversedPipelineNamesAppMap)
            ),
            accountList,
            app
          )
        );
      });
    };

    fetchAppPipelineNames();
  }, [app, accountList, appEnvIdList]);

  return !appPipelineNames || !pipelineNameAccountIdMap ? (
    <div
      style={{
        minHeight: "1300px",
        background: "#f7faf9",
        marginTop: "0px"
      }}
    >
      <svg viewBox="0 0 800 800">
        <path
          d="M2,295 C363,348 395,4 800,47"
          fill="none"
          stroke="#0ec289"
          stroke-width="0.5"
          stroke-dashoffset="1000"
        />
        <path
          d="M0,204 C377,367 504,22 797,225"
          fill="none"
          stroke="#0ec289"
          stroke-width="0.5"
        />
      </svg>
    </div>
  ) : appPipelineNames.length === 0 ? (
    <div
      style={{
        minHeight: "1300px",
        background: "#f7faf9",
        marginTop: "0px"
      }}
    >
      <h1
        style={{
          "text-transform": "uppercase",
          "font-family": "sans-serif",
          "font-size": "16px",
          "font-weight": "600",
          color: "#6c80ba"
        }}
      >
        Aucun pipeline disponible
      </h1>
    </div>
  ) : (
    appPipelineNames.map(appPipelineName => {
      return (
        <PipelineGeneral
          key={appPipelineName}
          pipelineName={appPipelineName}
          app={app}
          accountId={pipelineNameAccountIdMap[appPipelineName]}
        />
      );
    })
  );
};

export default PipelinesDashboard;
