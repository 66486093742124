/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  getPipelineExecutions,
  getPipelineState
} from "../../Services/PipelinesService";
import { circleColor, CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { ArrowRightIcon } from "material-ui-pickers/_shared/icons/ArrowRightIcon";
import Circle from "../Geometric/Circle";
import Card from "@material-ui/core/Card";
import history from "../../history";
import Button from "@material-ui/core/Button";

const PipelineGeneral = ({ pipelineName, app, accountId }) => {
  const [pipelineState, setPipelineState] = useState(undefined);
  const [pipelineExecutions, setPipelineExecutions] = useState(undefined);

  useEffect(() => {
    const fetchPipelineState = async () => {
      setPipelineState(await getPipelineState(pipelineName, accountId));
    };

    fetchPipelineState();
  }, [accountId, pipelineName]);

  useEffect(() => {
    async function fetchPipelineExecutions() {
      setPipelineExecutions(
        await getPipelineExecutions(pipelineName, accountId)
      );
    }

    fetchPipelineExecutions();
  }, [accountId, pipelineName]);

  const renderCircle = status => {
    switch (status) {
      case "InProgress":
        return circleColor.blue;
      case "Succeeded":
        return circleColor.green;
      case "Failed":
        return circleColor.red;
      case "Stopped":
        return circleColor.amber;
      default:
        return circleColor.gray;
    }
  };

  return pipelineExecutions && pipelineState ? (
    <Card
      elevation={0}
      style={{
        marginBottom: 10,
        marginTop: 10,
        marginRight: 80,
        marginLeft: 80,
        minHeight: 80,
        "border-radius": "30px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "margin-top": "10px"
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <div style={{ "margin-left": "20px", marginRight: "0.5em" }}>
              <Circle
                bgColor={renderCircle(
                  pipelineExecutions.pipelineExecutionSummaries[0]
                    ? pipelineExecutions.pipelineExecutionSummaries[0].status
                    : ""
                )}
                circleSize={25}
              />
            </div>
            <div
              style={{
                "text-transform": "uppercase",
                "font-family": "sans-serif",
                "font-size": "16px",
                "font-weight": "600",
                "margin-top": "10px",
                "margin-left": "20px",
                color: "#6c80ba"
              }}
            >
              <b>{pipelineName}</b>
            </div>
          </div>
          {pipelineExecutions.pipelineExecutionSummaries[0] && (
            <div
              style={{
                "text-transform": "uppercase",
                "font-family": "sans-serif",
                "font-size": "12px",
                "font-weight": "bold",
                "margin-top": "10px",
                "margin-left": "20px",
                color: "#6c80ba"
              }}
            >
              Dernière exécution:{" "}
              {moment(
                pipelineExecutions.pipelineExecutionSummaries[0].lastUpdateTime
              ).format("ddd Do MMMM YYYY, HH:mm:ss")}
            </div>
          )}
        </div>
        <div
          style={{
            "text-transform": "uppercase",
            "font-family": "sans-serif",
            "font-size": "12px",
            "font-weight": "bold",
            "margin-top": "20px",
            "margin-right": "30px",
            "margin-left": "50px",
            "max-width": "200px",
            color: "#6c80ba"
          }}
        >
          <b>{pipelineState.stageStates.length}</b> étapes de déploiement
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Button
            size="small"
            variant="contained"
            style={{ 
              fontSize: "13px",
              backgroundColor: "#00BFFF", // Vert-bleu
              borderRadius: "20px", // Bords arrondis
              color: "white", // Texte en blanc
              boxShadow: "none",
              fontWeight: 'bold', // Pas d'ombre
              marginRight: "10px"
            }}
            onClick={() => {
              history.push({
                pathname: "/pipeline/" + pipelineName,
                state: {
                  app,
                  accountId
                }
              });
            }}
          >
            ETAPES
          </Button>
          {/* Bouton History */}
          <Button
            size="small"
            variant="contained"
            style={{ 
              fontSize: "13px",
              backgroundColor: "#00BFFF", // Vert-bleu
              borderRadius: "20px", // Bords arrondis
              color: "white", // Texte en blanc
              boxShadow: "none",
              fontWeight: 'bold', // Pas d'ombre
            }}
            onClick={() => {
              history.push({
                pathname: "/pipeline/history-" + pipelineName,
                state: {
                  app,
                  accountId
                }
              });
            }}
          >
            Historique
          </Button>
        </div>
      </div>
    </Card>
  ) : (
    <div>
      <CircularLoading />
    </div>
  );
};

export default PipelineGeneral;
