/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReportSelector from "../Selectors/ReportSelector";
import ReportBuilderSelector from "../Selectors/ReportBuilderSelector";
import ReportFinopsSelector from "../Selectors/ReportFinopsSelector";
import ReportSecopsSelector from "../Selectors/ReportSecopsSelector";
const styles = theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function getSteps() {
  return ['BUILDER', 'FINOPS', 'SECOPS'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return '';
    case 1:
      return '';
    case 2:
      return '';
    default:
      return 'Unknown stepIndex';
  }
}

class ReportStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>TOUTES LES ETAPES SONT COMPLETES</Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={this.handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
        <Typography
          style={{
            marginTop: "-36px"
          }}
        >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>
                <Typography
                  style={{
                    marginLeft: "120px"
                  }}
                >
                  {label === "BUILDER" &&
                    <ReportBuilderSelector />
                  }
                  {label === "FINOPS" &&
                    <ReportFinopsSelector />
                  }
                  {label === "SECOPS" &&
                    <ReportSecopsSelector />
                  }               
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        </Typography>
      </div>
    );
  }
}

ReportStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ReportStepper);