/* eslint-disable indent */
import React from "react";
import { Button, TableBody, TableCell, TableHead } from "@material-ui/core";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import "moment/locale/fr";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";

import { withStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import EC2 from "../../Styles/Icons/EC2.png";
import ECS from "../../Styles/Icons/ECS.png";
import RDS from "../../Styles/Icons/RDS.png";
import LAMBDA from "../../Styles/Icons/LAMBDA.png";
import { realizeAction } from "../../Helpers/realize_action";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import config from "../../config";
import { putAccountState } from "../../Services/AccountService";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//push

const GreenRadio = withStyles({
  root: {
    color: grey[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

class LambdaActionsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      checkStatus: false,
      response_element: [],
      response: {},
      selected: {},
      inputs: {},
      inputsInitRadio: {},
      inputsInitList: {},
      inputsDisplayRadio: {},
      inputsDisplayList: {},
      getLambdaListInProgress: false,
      formValidated: false,
      actionLaunched: false,
      actionEnded: false,
      actionResult: "",
      displayDependentParameter: false,
      responseDependentParameter: undefined,
      responseKeyDependentParameter: undefined,
      responseKeyListDependentParameter: undefined,
      dependentParameterName: undefined
    };
    this.getLambdaListCallback = this.getLambdaListCallback.bind(this);
    this.invokeCallback = this.invokeCallback.bind(this);
    this.realizeTimeSensativeAction = this.realizeTimeSensativeAction.bind(this);// eslint-disable-line
    this.realizeStatusTimeSensativeAction = this.realizeStatusTimeSensativeAction.bind(this);// eslint-disable-line
    this.sleep = this.sleep.bind(this);
    this.CheckRDSStatus = this.CheckRDSStatus.bind(this);
    this.getParameterList = this.getParameterList.bind(this);
    this.renderParameters = this.renderParameters.bind(this);
    this.getParameterCallback = this.getParameterCallback.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account_id && nextProps.userIdToken) {
      this.setState(
        {
          ...nextProps
        },
        () => {
          if (!this.state.functions) {
            this.getLambdaList();
          }
        }
      );
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  CheckRDSStatus() {
    setInterval(this.realizeStatusTimeSensativeAction, 30000);
  }

  getLambdaListCallback(response) {
    let functions_array = [];
    let role_array = [];
    let local_storage_value = [];

    response.forEach(element => {
      if ("Environment" in element) {
        if ("Variables" in element.Environment) {
          if ("EXSI" in element.Environment.Variables) {
            if ("Role" in element) {
              role_array = element.Role.split("-");
              if (role_array.indexOf("vpnz") > -1) {
                if (
                  element.Environment.Variables.EXSI === "Start EC2 Instance" ||
                  element.Environment.Variables.EXSI === "Stop EC2 Instance"
                ) {
                  functions_array.push(element);
                  console.log(functions_array);
                }
              } else if (role_array.indexOf("ange") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("annu") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("apiz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("appf") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("avig") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("azur") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("acnz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("base") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("call") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("cane") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("capgemini") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("capt") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("cari") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("case") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("cecc") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("dahi") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("digi") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("dirc") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("dqmz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("dsct") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("dsop") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("edrz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("etrc") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("etrz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("fast") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("faci") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("gabi") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("gali") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("gara") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("geof") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("gmat") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("gvdc") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("hpcz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("hysy") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("impa") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("inca") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("indu") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("inno") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("intr") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("iris") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("ithr") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("lims") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("mciz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("mode") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("mobi") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("netw") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("noma") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("obel") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("oimz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("opcz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("onea") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("open") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("oper") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("osig") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("paco") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("paio") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("pems") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("pkit") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("prec") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("prot") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("puma") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("pvpr") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("rexp") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("rice") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("riob") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("rioc") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("riot") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("rioz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("rogz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("scal") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("sepm") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("sdwa") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("shar") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("sict") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("simo") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("sire") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("sirh") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("spar") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("spli") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("srio") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("star") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("suri") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("swin") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("shsb") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("taoz") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("toga") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("tool") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("twin") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("valo") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("vdog") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("visu") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("sido") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("outi") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("smar") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("tran") > -1) {
                functions_array.push(element);

                for (let i = 0; i < localStorage.length; i++) {
                  let key = localStorage.key(i);
                  local_storage_value.push(localStorage.getItem(key));
                }
              } else if (role_array.indexOf("prod") > -1) {
                functions_array.push(element);
              } else if (role_array.indexOf("stg") > -1) {
                functions_array.push(element);
              } else if (role_array.indexOf("dev") > -1) {
                functions_array.push(element);
              }
            } else {
              functions_array.push(element);
            }
          }
        }
      }
    });

    //Write a condition with a component that launch option orderBy and this.setState
    this.setState({
      functions: functions_array.sort((a, b) => {
        const functionNameA = this.retrieveFunctionName(a).toLowerCase();
        const functionNameB = this.retrieveFunctionName(b).toLowerCase();
        return functionNameA > functionNameB
          ? 1
          : functionNameB > functionNameA
          ? -1
          : 0;
      }),
      getLambdaListInProgress: false
    });
  }

  retrieveFunctionName = action =>
    action.Environment.Variables.EXSI === "true" ||
    action.Environment.Variables.EXSI === "True"
      ? action.FunctionName
      : action.Environment.Variables.EXSI;

  getLambdaList() {
    if (this.state.getLambdaListInProgress) {
      return;
    }
    this.setState(
      {
        getLambdaListInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          this.state.account_id,
          "lambda",
          "list_functions",
          null,
          null,
          null,
          this.getLambdaListCallback,
          "Marker",
          "NextMarker",
          null,
          [],
          "Functions"
        );
      }
    );
  }

  handleClickOpen = (
    function_name,
    function_arn,
    function_real_name,
    function_notice,
    function_exec_mode
  ) => {
    this.setState(
      {
        open: true,
        function_name: function_name,
        function_arn: function_arn,
        action_name: function_real_name,
        function_notice: function_notice,
        function_exec_mode: function_exec_mode || "SYNC"
      },
      () => {
        this.getParameterList();
      }
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
      actionLaunched: false,
      actionEnded: false,
      actionResult: "",
      selected: {},
      inputs: {},
      response: {},
      response_element: []
    });
  };

  async realizeStatusTimeSensativeAction() {
    realizeAction(
      this.props.userIdToken,
      this.state.account_id,
      "lambda",
      "invoke",
      "laf-env-controller-start-status",
      "FunctionName",
      false,
      this.invokeCallback,
      null,
      null,
      null,
      null,
      null,
      "eu-west-1",
      {
        Payload: JSON.stringify(
          Object.assign(this.state.selected, this.state.inputs)
        ),
        InvocationType:
          this.state.function_exec_mode === "SYNC" ? "RequestResponse" : "Event"
      }
    );
  }

  async realizeTimeSensativeAction() {
    realizeAction(
      this.props.userIdToken,
      this.state.account_id,
      "lambda",
      "invoke",
      "laf-env-controller-start-rds",
      "FunctionName",
      false,
      this.invokeCallback,
      null,
      null,
      null,
      null,
      null,
      "eu-west-1",
      {
        Payload: JSON.stringify(
          Object.assign(this.state.selected, this.state.inputs)
        ),
        InvocationType:
          this.state.function_exec_mode === "SYNC" ? "RequestResponse" : "Event"
      }
    );
    this.CheckRDSStatus();
    //il n'y a pas besoin de valider le check en front on lance l set interval et check le statu directement en back pour envoyer
    //la lambda
    /*const TimeStepper = this.sleep(15 * 60000); //wait 5 * 1min = 5 * 60seconds = 5 * 60000ms
    await TimeStepper;
    realizeAction(
      this.props.userIdToken,
      this.state.account_id,
      "lambda",
      "invoke",
      "laf-env-controller-start",
      "FunctionName",
      false,
      this.invokeCallback,
      null,
      null,
      null,
      null,
      null,
      "eu-west-1",
      {
        Payload: JSON.stringify(
          Object.assign(this.state.selected, this.state.inputs)
        ),
        InvocationType:
          this.state.function_exec_mode === "SYNC" ? "RequestResponse" : "Event"
      }
    );
    clearTimeout(TimeStepper);*/
  }

  async invokeCallback(response) {
    if (this.state.function_exec_mode === "SYNC") {
      if (response.StatusCode === 200) {
        if (
          this.state.function_name.startsWith("laf-env-controller-start-status")
        ) {
          if (response.Payload === "available") {
            realizeAction(
              this.props.userIdToken,
              this.state.account_id,
              "lambda",
              "invoke",
              "laf-env-controller-start",
              "FunctionName",
              false,
              this.invokeCallback,
              null,
              null,
              null,
              null,
              null,
              "eu-west-1",
              {
                Payload: JSON.stringify(
                  Object.assign(this.state.selected, this.state.inputs)
                ),
                InvocationType:
                  this.state.function_exec_mode === "SYNC" ? "RequestResponse" : "Event"// eslint-disable-line
              }
            );
            //sclearTimeout(TimeStepper);
          }
        } else if (this.state.function_name.startsWith("laf-env-controller")) {
          const isStart = this.state.function_name.endsWith("start");
          await putAccountState(this.state.account_id, isStart ? "up" : "down");
          const startMessage = isStart ? "Démarrage" : "Arrêt";
          const actionDescription =
            startMessage +
            " de " +
            this.state.app +
            "-" +
            this.state.env +
            " (" +
            this.state.account_id +
            ").";
          addLog(this.state.userName, moment().unix() - 1, actionDescription);
        }
        this.setState({
          actionEnded: true,
          actionResult: "Action réalisée avec succès: " + response.Payload
        });
      } else {
        this.setState({
          actionEnded: true,
          actionResult:
            "Problème lors de l'éxecution de l'action: " + response.Payload
        });
      }
      const actionDescription =
        "Résultat de la lambda (mode synchrone) " +
        this.state.function_name +
        " sur " +
        this.state.app +
        "-" +
        this.state.env +
        " (" +
        this.state.account_id +
        ").";
      addLog(
        this.state.userName,
        moment().unix(),
        JSON.stringify({
          logDescription: actionDescription,
          response
        })
      );
    } else {
      this.setState({
        actionEnded: true,
        actionResult: this.state.function_notice
      });
    }
    this.cleanDependentParameterState();
  }

  handleValidate = () => {
    if (
      this.state.validation_value ===
      this.state.good_function_name_validation_value
    ) {
      const actionDescription =
        "Déclenchement de la lambda " +
        this.state.function_name +
        " sur " +
        this.state.app +
        "-" +
        this.state.env +
        " (" +
        this.state.account_id +
        ")";
      addLog(this.state.userName, moment().unix(), actionDescription);
      realizeAction(
        this.props.userIdToken,
        this.state.account_id,
        "lambda",
        "invoke",
        this.state.function_name,
        "FunctionName",
        false,
        this.invokeCallback,
        null,
        null,
        null,
        null,
        null,
        "eu-west-1",
        {
          Payload: JSON.stringify(
            Object.assign(this.state.selected, this.state.inputs)
          ),
          InvocationType:
            this.state.function_exec_mode === "SYNC"
              ? "RequestResponse"
              : "Event"
        }
      );
      if (this.state.function_name.startsWith("laf-env-controller-start-rds")) {
        this.realizeTimeSensativeAction();
      }
    } else {
      console.log("Wrong code");
    }
    this.setState({
      actionLaunched: true
    });
  };

  handleTextFieldChange = (e, validation_value) => {
    this.setState(
      {
        [validation_value]: e.target.value
      },
      () => {
        if (
          this.state.validation_function_name_value ===
            this.state.function_name &&
          this.state.validation_env_value === this.state.env &&
          this.state.validation_app_value === this.state.app
        ) {
          this.setState({
            formValidated: true
          });
        } else {
          this.setState({
            formValidated: false
          });
        }
      }
    );
  };

  renderButton(row) {
    const function_name = this.retrieveFunctionName(row);
    const actions = {
      STARTEC2I: "Start EC2 Instance",
      STOPEC2I: "Stop EC2 Instance",
      REDEPLOYECS: "Redeploy ECS Service",
      STARTRDS: "Start RDS Instance",
      STOPRDS: "Stop RDS Instance",
      REBOOTRDS: "Reboot RDS Instance",
      DISABLEBATCHQ: "Disable Batch Queue",
      ENABLEBATCHQ: "Enable Batch Queue",
      STARTWE: "Start Whole Environment",
      STOPWE: "Stop Whole Environment"
    };
    return (
      <Typography>
        {function_name === "Start EC2 Instance" && (
          <img
            src={EC2}
            alt={"EC2-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Stop EC2 Instance" && (
          <img
            src={EC2}
            alt={"EC2-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Redeploy ECS Service" && (
          <img
            src={ECS}
            alt={"ECS-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Start RDS Instance" && (
          <img
            src={RDS}
            alt={"RDS-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Stop RDS Instance" && (
          <img
            src={RDS}
            alt={"RDS-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Reboot RDS Instance" && (
          <img
            src={RDS}
            alt={"RDS-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Disable Batch Queue" && (
          <img
            src={LAMBDA}
            alt={"LAMBDA-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Enable Batch Queue" && (
          <img
            src={LAMBDA}
            alt={"LAMBDA-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Start Whole Environment" && (
          <img
            src={LAMBDA}
            alt={"LAMBDA-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name === "Stop Whole Environment" && (
          <img
            src={LAMBDA}
            alt={"LAMBDA-action"}
            style={{
              height: "30px",
              width: "30px",
              "margin-right": "10px",
              "padding-left": "120px",
              "vertical-align": "-50%"
            }}
          />
        )}
        {function_name !== actions.STARTEC2I &&
          function_name !== actions.STOPEC2I &&
          function_name !== actions.STARTRDS &&
          function_name !== actions.STOPRDS &&
          function_name !== actions.REBOOTRDS &&
          function_name !== actions.REDEPLOYECS &&
          function_name !== actions.DISABLEBATCHQ &&
          function_name !== actions.ENABLEBATCHQ &&
          function_name !== actions.STARTWE &&
          function_name !== actions.STOPWE && (
            <img
              src={LAMBDA}
              alt={"LAMBDA-action"}
              style={{
                height: "30px",
                width: "30px",
                "margin-right": "10px",
                "padding-left": "120px",
                "vertical-align": "-50%"
              }}
            />
          )}

        <Button
          onClick={() =>
            this.handleClickOpen(
              row.FunctionName,
              row.FunctionArn,
              function_name,
              row.Environment.Variables.EXSI_NOTICE,
              row.Environment.Variables.EXSI_EXEC_MODE
            )
          }
          key="button"
          size="medium"
          style={{
            "box-shadow": "none",
            "border-radius": "20px",
            border: "none",
            "font-weight": "bold",
            color: "#326b60",
            margin: "10px"
          }}
        >
          {function_name}
        </Button>
      </Typography>
    );
  }

  getParameterCallback(response) {
    let final_parameter = Object.assign({}, this.state.response, response);
    this.setState({
      response: final_parameter
    });
  }

  getParameterList() {
    if (Object.keys(this.state.response).length === 0) {
      this.state.functions.forEach(element => {
        if (element.FunctionArn === this.state.function_arn) {
          if ("Environment" in element) {
            if ("Variables" in element.Environment) {
              for (let key in element.Environment.Variables) {
                if (key.startsWith("param_")) {
                  let service = element.Environment.Variables[key].split(
                    "::"
                  )[0];
                  let call = element.Environment.Variables[key].split("::")[1];
                  if (
                    element.Environment.Variables[key].split("::").length === 5
                  ) {
                    this.state.response_element.push(
                      key.split("_")[1] +
                        "::" +
                        element.Environment.Variables[key].split("::")[2] +
                        "::" +
                        element.Environment.Variables[key].split("::")[3] +
                        "::" +
                        element.Environment.Variables[key].split("::")[4]
                    );
                  } else {
                    this.state.response_element.push(
                      key.split("_")[1] +
                        "::" +
                        element.Environment.Variables[key].split("::")[2] +
                        "::" +
                        element.Environment.Variables[key].split("::")[3]
                    );
                  }
                  const body =
                    service === "ssm" && call === "list_documents"
                      ? {
                          DocumentFilterList: [
                            {
                              key: "Owner",
                              value: "Self"
                            }
                          ],
                          MaxResults: 50
                        }
                      : null;
                  realizeAction(
                    this.props.userIdToken,
                    this.state.account_id,
                    service,
                    call,
                    null,
                    null,
                    false,
                    this.getParameterCallback,
                    null,
                    null,
                    null,
                    null,
                    null,
                    "eu-west-1",
                    body
                  );
                }

                if (key.startsWith("input_")) {
                  this.setState({
                    inputs: Object.assign(this.state.inputs, {
                      [key.split("input_")[1]]:
                        element.Environment.Variables[key]
                    })
                  });
                }
                if (key.startsWith("radio_")) {
                  this.setState({
                    inputs: Object.assign(this.state.inputs, {
                      [key.split("radio_")[1]]: ""
                    })
                  });
                }
                if (key.startsWith("radio_")) {
                  this.setState({
                    // eslint-disable-next-line
                    inputsInitRadio: Object.assign(this.state.inputsInitRadio, {
                      [key.split("radio_")[1]]:
                        element.Environment.Variables[key]
                    })
                  });
                }
                if (key.startsWith("radio_")) {
                  this.setState({
                    // eslint-disable-next-line
                    inputsDisplayRadio: Object.assign(this.state.inputsDisplayRadio, {
                      [key.split("radio_")[1]]: "true"// eslint-disable-line
                    })// eslint-disable-line
                  });
                }
                if (key.startsWith("list_")) {
                  this.setState({
                    // eslint-disable-next-line
                    inputs: Object.assign(this.state.inputs, { 
                      [key.split("list_")[1]]: ""
                    })
                  });
                }
                if (key.startsWith("list_")) {
                  this.setState({
                    // eslint-disable-next-line
                    inputsInitList: Object.assign(this.state.inputsInitList, { 
                      [key.split("list_")[1]]:
                        element.Environment.Variables[key]
                    })
                  });
                }
                if (key.startsWith("list_")) {
                  this.setState({
                    // eslint-disable-next-line
                    inputsDisplayList: Object.assign(this.state.inputsDisplayList, { 
                      [key.split("list_")[1]]: "true"// eslint-disable-line
                    })// eslint-disable-line
                  });
                }
              }
            }
          }
        }
      });
    }
  }

  handleParameterChange = event => {
    const value = event.target.value;
    if (
      this.state.function_name === "laf-exsi-batch-queue-controller-disable" ||
      this.state.function_name === "laf-exsi-batch-queue-controller-enable"
    ) {
      this.setState({
        selected: {
          queueArn:
            value === "allQueues"
              ? this.state.response.jobQueues.map(
                  jobQueue => jobQueue.jobQueueArn
                )
              : [value]
        }
      });
    } else {
      const temp_state = this.state.selected;
      temp_state[event.target.id] = value;
      this.setState({
        selected: temp_state
      });
      this.getDependentParameter();
    }
  };

  handleInputsChange = event => {
    this.setState({
      inputs: Object.assign(this.state.inputs, {
        [event.target.id]: event.target.value
      })
    });
    event.preventDefault();
  };

  handleRadioChange = (event, count) => {
    this.setState({
      inputs: Object.assign(this.state.inputs, {
        [event.target.name]: event.target.value
      })
    });
    this.setState({
      count: this.state.count + 1
    });
    event.preventDefault();
  };

  handleListChange = event => {
    this.setState({
      inputs: Object.assign(this.state.inputs, {
        [event.target.name]: event.target.value
      })
    });
    event.preventDefault();
  };

  renderParameterOptions(select, param, sub_element) {
    let response_param = param;
    let response_element = sub_element;
    if (select.split("::").length === 4) {
      response_param =
        param.length > 0
          ? param.map(currentParam => currentParam[select.split("::")[2]][0])
          : [];
      response_element = select.split("::")[3];
    }
    if (
      select.split("::").length === 3 &&
      select.split("::")[2] === "undefined"
    ) {
      response_param = param;
      response_element = undefined;
    }
    let optionList;
    if (
      this.state.function_name === "laf-exsi-ec2-controller-stop" ||
      this.state.function_name === "laf-exsi-ec2-controller-start"
    ) {
      optionList = response_param
        /*
        .filter(option =>
          option.Tags.find(
            tag =>
              tag.Key === config.actionStopStartEc2.tagName &&
              tag.Value === "true"
          )
        )
      */
        .map(option => {
          const tagName = option.Tags.find(tag => tag.Key === "Name");
          const name = tagName ? tagName.Value : "";
          const stateName = option.State.Name.toUpperCase() + "_INSTANCE_";
          return (
            <option
              key={option[response_element]}
              value={option[response_element]}
            >
              {stateName}
              {name} {name !== "" && "("}
              {option[response_element]}
              {name !== "" && ")"}
            </option>
          );
        });
    } else if (response_element) {
      optionList = response_param.map(option => (
        <option key={option[response_element]} value={option[response_element]}>
          {option[response_element]}
        </option>
      ));
    } else {
      optionList = response_param.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    }
    if (
      this.state.function_name === "laf-exsi-batch-queue-controller-disable" ||
      this.state.function_name === "laf-exsi-batch-queue-controller-enable"
    ) {
      if (optionList && optionList.length > 0) {
        optionList.unshift(
          <option key="allQueues" value="allQueues">
            Toutes les job queues
          </option>
        );
      }
    }
    optionList.unshift(
      <option key="Sélectionner une valeur" value="Sélectionner une valeur">
        Sélectionner une valeur
      </option>
    );
    return [optionList];
  }

  renderParameters() {
    if (Object.keys(this.state.response).length !== 0) {
      let selectList = this.state.response_element.map(select => {
        let param_name = select.split("::")[0];
        let root_element = select.split("::")[1];
        let sub_element = select.split("::")[2];
        if (root_element in this.state.response) {
          return (
            <div>
              <br />
              <section key={root_element}>
                <FormControl key={root_element}>
                  <InputLabel htmlFor={root_element}>
                    {select.split("::").length === 4
                      ? select.split("::")[2]
                      : root_element}
                  </InputLabel>
                  <Select
                    native
                    key={root_element}
                    value={this.state.selected[root_element]}
                    id={param_name}
                    onChange={this.handleParameterChange}
                  >
                    {this.renderParameterOptions(
                      select,
                      this.state.response[root_element],
                      sub_element
                    )}
                  </Select>
                </FormControl>
              </section>
            </div>
          );
        }
        return "";
      });
      return [selectList];
    }
  }

  getDependentParameter() {
    const currentFunction = this.state.functions.find(
      element => element.FunctionArn === this.state.function_arn
    );
    if ("Environment" in currentFunction) {
      if ("Variables" in currentFunction.Environment) {
        for (let key in currentFunction.Environment.Variables) {
          if (key.startsWith("dependent_param_")) {
            const dependentService = currentFunction.Environment.Variables[
              key
            ].split("::")[0];
            const service = currentFunction.Environment.Variables[key].split(
              "::"
            )[1];
            const call = currentFunction.Environment.Variables[key].split(
              "::"
            )[2];
            const responseKey = currentFunction.Environment.Variables[
              key
            ].split("::")[3];
            const responseKeyList = currentFunction.Environment.Variables[
              key
            ].split("::")[4];
            if (
              this.state.selected.hasOwnProperty(dependentService) &&
              this.state.selected[dependentService] !==
                "Sélectionner une valeur"
            ) {
              realizeAction(
                this.props.userIdToken,
                this.state.account_id,
                service,
                call,
                null,
                null,
                false,
                response => {
                  this.setState({
                    responseDependentParameter: response,
                    responseKeyDependentParameter: responseKey,
                    responseKeyListDependentParameter: responseKeyList,
                    dependentParameterName: key.split("_")[2],
                    displayDependentParameter: true
                  });
                },
                null,
                null,
                null,
                null,
                null,
                "eu-west-1",
                {
                  [dependentService]: this.state.selected[dependentService]
                }
              );
            } else {
              this.cleanDependentParameterState();
            }
          }
        }
      }
    }
  }

  renderDependentParameter = () => (
    <div>
      <br />
      <section key={this.state.dependentParameterName}>
        <FormControl key={this.state.dependentParameterName}>
          <InputLabel htmlFor={this.state.dependentParameterName}>
            {this.state.responseKeyListDependentParameter
              ? this.state.responseKeyListDependentParameter
              : this.state.responseKeyDependentParameter}
          </InputLabel>
          <Select
            native
            key={this.state.dependentParameterName}
            value={this.state.selected[this.state.dependentParameterName]}
            id={this.state.dependentParameterName}
            onChange={this.handleParameterChange}
          >
            {this.renderDependentParameterOptions(
              this.state.responseDependentParameter[
                this.state.responseKeyDependentParameter
              ],
              this.state.responseKeyListDependentParameter
            )}
          </Select>
        </FormControl>
      </section>
    </div>
  );

  renderDependentParameterOptions = (options, responseKeyList) => {
    let optionList;
    if (responseKeyList) {
      optionList = options.map(option => (
        <option key={option[responseKeyList]} value={option[responseKeyList]}>
          {option[responseKeyList]}
        </option>
      ));
    } else {
      optionList = options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    }
    optionList.unshift(
      <option key="Sélectionner une valeur" value="Sélectionner une valeur">
        Sélectionner une valeur
      </option>
    );

    return [optionList];
  };

  cleanDependentParameterState = () => {
    this.setState({
      responseDependentParameter: undefined,
      responseKeyDependentParameter: undefined,
      responseKeyListDependentParameter: undefined,
      dependentParameterName: undefined,
      displayDependentParameter: false
    });
  };

  renderInputs() {
    // slet initial_radios_value = Object.entries(this.state.inputsInitRadio);
    //initial_radios_value[0].split('"').join('');
    let initRadio = this.state.inputsInitRadio;
    let initList = this.state.inputsInitList;

    return Object.keys(this.state.inputs)
      .sort()
      .map(input => (
        <div>
          <p style={{ display: "none" }}>
            {/*console.log(this.state.inputs)*/}
            {/*console.log((`${this.state.inputsInitRadio[input]}`).split("|"))*/}
          </p>
          <br />
          {`${input}` !== "" && (
            <div>
              {initList.hasOwnProperty(input) &&
                `${this.state.inputsInitList[input]}`.split("|").length >= 2 &&
                this.state.inputsDisplayList[input] === "true" && (
                  <FormControl>
                    <InputLabel
                      htmlFor="input-native-simple"
                      style={{ "font-size": "18px" }}
                    >
                      {input}
                    </InputLabel>
                    <NativeSelect
                      value={this.state.inputs[input]}
                      onChange={this.handleListChange}
                      name={`${input}`}
                    >
                      <option value=""></option>
                      {`${this.state.inputsInitList[input]}`.split("|")[0] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[0]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[0]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[1] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[1]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[1]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[2] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[2]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[2]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[3] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[3]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[3]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[4] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[4]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[4]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[5] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[5]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[5]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[6] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[6]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[6]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[7] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[7]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[7]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[8] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[8]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[8]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[9] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[9]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[9]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[10] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[10]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[10]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[11] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[11]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[11]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[12] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[12]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[12]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[13] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[13]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[13]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[14] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[14]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[14]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[15] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[15]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[15]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[16] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[16]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[16]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[17] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[17]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[17]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[18] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[18]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[18]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[19] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[19]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[19]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[20] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[20]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[20]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[21] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[21]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[21]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[22] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[22]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[22]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[23] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[23]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[23]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[24] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[24]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[24]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[25] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[25]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[25]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[26] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[26]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[26]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[27] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[27]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[27]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[28] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[28]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[28]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[29] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[29]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[29]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[30] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[30]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[30]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[31] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[31]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[31]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[32] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[32]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[32]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[33] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[33]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[33]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[34] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[34]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[34]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[35] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[35]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[35]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[36] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[36]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[36]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[37] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[37]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[37]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[38] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[38]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[38]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[39] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[39]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[39]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[40] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[40]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[40]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[41] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[41]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[41]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[42] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[42]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[42]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[43] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[43]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[43]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[44] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[44]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[44]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[45] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[45]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[45]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[46] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[46]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[46]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[47] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[47]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[47]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[48] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[48]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[48]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[49] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[49]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[49]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[50] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[50]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[50]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[51] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[51]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[51]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[52] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[52]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[52]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[53] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[53]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[53]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[54] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[54]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[54]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[55] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[55]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[55]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[56] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[56]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[56]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[57] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[57]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[57]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[58] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[58]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[58]}
                        </option>
                      )}
                      {`${this.state.inputsInitList[input]}`.split("|")[59] !==
                        undefined && (
                        <option
                          value={
                            `${this.state.inputsInitList[input]}`.split("|")[59]
                          }
                        >
                          {`${this.state.inputsInitList[input]}`.split("|")[59]}
                        </option>
                      )}
                    </NativeSelect>
                    <FormHelperText>
                      Select a value for label : {input} or leave empty
                    </FormHelperText>
                  </FormControl>
                )}
              {initRadio.hasOwnProperty(input) &&
                `${this.state.inputsInitRadio[input]}`.split("|").length >= 2 &&
                this.state.inputsDisplayRadio[input] === "true" && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{input}</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name={`${input}`}
                      onChange={this.handleRadioChange}
                    >
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        1 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[0] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[0] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        2 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[1] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[1] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        3 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[2] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[2] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        4 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[3] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[3] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        5 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[4] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[4] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        6 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[5] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[5] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        7 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[6] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[6] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        8 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[7] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[7] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        9 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[8] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[8] // eslint-disable-line
                            }`}
                          />
                        )}
                      {`${this.state.inputsInitRadio[input]}`.split("|").length >= // eslint-disable-line
                        10 && // eslint-disable-line
                        `${this.state.inputsInitRadio[input]}`.split("|").length !== // eslint-disable-line
                          undefined && ( // eslint-disable-line
                          <FormControlLabel
                            value={
                              `${this.state.inputsInitRadio[input]}`.split("|")[9] // eslint-disable-line
                            }
                            control={<GreenRadio />}
                            label={`${
                              `${this.state.inputsInitRadio[input]}`.split("|")[9] // eslint-disable-line
                            }`}
                          />
                        )}
                    </RadioGroup>
                  </FormControl>
                )}
            </div>
          )}
          {`${input}` !== "" &&
            !initList.hasOwnProperty(input) &&
            !initRadio.hasOwnProperty(input) && (
              <TextField
                id={input}
                label={input}
                variant="outlined"
                defaultValue={this.state.inputs[input]}
                onChange={this.handleInputsChange}
                fullWidth
                margin="dense"
              />
            )}
        </div>
      ));
  }

  renderDialogContent() {
    return (
      <div>
        <DialogContent
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            "overflow-x": "hidden",
            color: "#009999"
          }}
        >
          <DialogContentText
            style={{
              "text-transform": "uppercase",
              "font-size": "12px",
              "font-weight": "bold",
              "text-align": "left",
              marginBottom: "10px",
              marginTop: "10px",
              "maw-witdh": "100px",
              "overflow-x": "hidden",
              color: "#009999"
            }}
          >
            <div
              style={{
                "max-width": "600px",
                "overflow-wrap": "break-word"
              }}
            >
              ATTENTION - Vous êtes sur le point de réaliser{" "}
              <b>{this.state.action_name}</b> sur le compte{" "}
              <b>
                {this.state.account_id}({this.state.app}-{this.state.env})
              </b>
              . <br />
              Pour valider, veuillez remplir ces trois champs ci-dessous.
              L'action sera réalisée dès que vous cliquerez sur "Valider".
              <br />
              <br />
              <Typography
                variant="body1"
                style={{
                  "text-transform": "uppercase",
                  "font-size": "12px",
                  "font-weight": "bold",
                  "text-align": "left",
                  "border-radius": "30px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  "white-space": "pre-wrap",
                  color: "grey"
                }}
              >
                {this.state.function_notice}
              </Typography>
            </div>
          </DialogContentText>
          <br />
          <br />
          <DialogContentText>
            <b> {this.state.app}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Confirmation de l'application: " + this.state.app}
            type="text"
            onChange={event =>
              this.handleTextFieldChange(event, "validation_app_value")
            }
            fullWidth
          />
          <br />
          <br />
          <DialogContentText>
            <b> {this.state.env}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Confirmation de l'environement: " + this.state.env}
            type="text"
            onChange={event =>
              this.handleTextFieldChange(event, "validation_env_value")
            }
            fullWidth
          />
          <br />
          <br />
          <DialogContentText>
            <b> {this.state.function_name}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Confirmation de l'action: " + this.state.function_name}
            type="text"
            onChange={event =>
              this.handleTextFieldChange(
                event,
                "validation_function_name_value"
              )
            }
            fullWidth
          />
          <br />
          <br />
          <form autoComplete="off">
            {this.renderParameters()}
            {this.state.displayDependentParameter &&
              this.renderDependentParameter()}
            {this.renderInputs()}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary"
            size="medium"
            variant="contained"
            style={{
              backgroundColor: "secondary",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={this.handleValidate}
            color="primary"
            disabled={!this.state.formValidated}
            size="medium"
            variant="contained"
            style={{
              backgroundColor: "secondary",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
          >
            Valider
          </Button>
        </DialogActions>
      </div>
    );
  }

  renderDialogContentDuringAction() {
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            Vous venez de lancer <b>{this.state.action_name}</b> sur le compte{" "}
            <b>
              {this.state.account_id}({this.state.app}-{this.state.env})
            </b>
          </DialogContentText>

          <DialogContentText>
            <br />
            {this.state.function_exec_mode === "SYNC" && (
              <div>
                <b>Résultats de l'action lancée:</b>
              </div>
            )}
            <br />
            {this.state.actionEnded ? (
              <div style={{ whiteSpace: "pre-line" }}>
                {this.state.actionResult}
              </div>
            ) : (
              <CircularLoading />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.state.actionEnded && (
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          )}
        </DialogActions>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.functions) {
      return (
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={
              this.state.actionLaunched && !this.state.actionEnded
            }
            maxWidth="xl"
            PaperProps={{
              style: {
                borderRadius: 30,
                fontFamily: "sans-serif",
                fontSize: 12,
                fontWeight: 600
              }
            }}
          >
            <DialogTitle
              id="form-dialog-title"
              style={{
                "text-align": "center",
                "text-transform": "uppercase",
                "font-size": "12px",
                "font-weight": "bold"
              }}
            >
              <Typography
                variant="body1"
                style={{
                  "text-transform": "uppercase",
                  "font-size": "16px",
                  "font-weight": "bold",
                  "text-align": "center",
                  "border-radius": "30px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  color: "#009999"
                }}
              >
                {this.state.action_name}
              </Typography>
            </DialogTitle>
            {!this.state.actionLaunched && this.renderDialogContent()}
            {this.state.actionLaunched &&
              this.renderDialogContentDuringAction()}
          </Dialog>
          {
            <Paper className={classes.paperActionList}>
              <Table
                style={{
                  "font-family": "sans-serif",
                  "border-radius": "30px",
                  "font-size": "12px",
                  "font-weight": "600",
                  color: "#009999",
                  "box-shadow": "none",
                  overflow: "hidden",
                  border: "none"
                }}
              >
                <TableHead
                  style={{
                    border: "none",
                    overflow: "hidden",
                    height: "10px"
                  }}
                >
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "30px 0 0 0",
                      "margin-top": "0px",
                      "margin-bottom": "0px",
                      "padding-top": "10px",
                      "padding-bottom": "10px",
                      "margin-right": "10px",
                      "margin-left": "10px",
                      background: "white",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "uppercase",
                      textAlign: "center"
                    }}
                  >
                    {""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 30px 0 0",
                      "margin-top": "0px",
                      "margin-bottom": "0px",
                      "padding-top": "10px",
                      "padding-bottom": "10px",
                      "margin-right": "10px",
                      "margin-left": "10px",
                      background: "white",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "uppercase",
                      textAlign: "center"
                    }}
                  >
                    {""}
                  </TableCell>
                </TableHead>
                <TableBody>
                  {this.state.functions.map(row => (
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {this.renderButton(row)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {row.Description || <i>No description available</i>}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          }
        </div>
      );
    } else {
      return <CircularLoading />;
    }
  }
}

export default withSnackbar(withStyles(styles)(LambdaActionsGrid));
/* eslint-disable indent */
