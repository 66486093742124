import API from "@aws-amplify/api/lib";

export const getAllPipelines = (accountId = undefined) =>
  API.get("api-exsi-backend", "/pipelines", {
    ...(accountId && {
      queryStringParameters: {
        accountId
      }
    })
  });

export const getPipelineState = (pipelineName, accountId = undefined) =>
  API.get("api-exsi-backend", "/pipelines/" + pipelineName + "/state", {
    ...(accountId && {
      queryStringParameters: {
        accountId
      }
    })
  });

export const getPipelineExecutions = (pipelineName, accountId = undefined) =>
  API.get("api-exsi-backend", "/pipelines/" + pipelineName + "/executions", {
    ...(accountId && {
      queryStringParameters: {
        accountId
      }
    })
  });

export const getPipelineExecution = (
  pipelineName,
  pipelineExecutionId,
  accountId = undefined
) =>
  API.get(
    "api-exsi-backend",
    "/pipelines/" + pipelineName + "/executions/" + pipelineExecutionId,
    {
      ...(accountId && {
        queryStringParameters: {
          accountId
        }
      })
    }
  );

export const putPipelineApproval = (
  pipelineName,
  approvalToken,
  approvalActionName,
  approvalStageName,
  approvalStatus,
  approvalMessage,
  userName,
  accountId = undefined
) =>
  API.put("api-exsi-backend", "/pipelines/" + pipelineName + "/approval", {
    body: {
      approvalToken,
      approvalActionName,
      approvalStageName,
      approvalStatus,
      approvalMessage,
      userName
    },
    ...(accountId && {
      queryStringParameters: {
        accountId
      }
    })
  });

export const retryPipelineStageExecution = (
  pipelineName,
  stageName,
  pipelineExecutionId,
  userName,
  accountId = undefined
) =>
  API.post("api-exsi-backend", "/pipelines/" + pipelineName + "/retryStage", {
    body: {
      stageName,
      pipelineExecutionId,
      userName
    },
    ...(accountId && {
      queryStringParameters: {
        accountId
      }
    })
  });

export const stopPipelineExecution = (
  pipelineName,
  pipelineExecutionId,
  userName,
  accountId = undefined
) =>
  API.post(
    "api-exsi-backend",
    "/pipelines/" + pipelineName + "/stopExecution",
    {
      body: {
        pipelineExecutionId,
        userName
      },
      ...(accountId && {
        queryStringParameters: {
          accountId
        }
      })
    }
  );

export const filterPipelines = (pipelines, accountList, appFromQueryString) =>
  pipelines
    .filter(pipeline => pipeline.includes(appFromQueryString))
    .filter(filtered_pipeline =>
      Object.keys(accountList)
        .map(account_item => account_item.split("-"))
        .map(
          account =>
            (filtered_pipeline.includes(account[0]) &&
              filtered_pipeline.includes(account[1])) ||
            (!filtered_pipeline.includes("dev") &&
              !filtered_pipeline.includes("stg") &&
              !filtered_pipeline.includes("prd") &&
              filtered_pipeline.includes(account[0]))
        )
    );

export const retrieveRelevantPipelineExecutions = (
  pipelineExecutions,
  stageLatestExecutionPipelineExecutionId
) => {
  if (pipelineExecutions.length === 0) {
    return [];
  }
  if (pipelineExecutions.length === 1) {
    return [pipelineExecutions[0]];
  }

  let slicePipelineExecutions = [...pipelineExecutions];
  let pipelineStageExecutionIndex = 0;
  if (stageLatestExecutionPipelineExecutionId) {
    pipelineStageExecutionIndex = pipelineExecutions.findIndex(
      pipelineExecution =>
        pipelineExecution.pipelineExecutionId ===
        stageLatestExecutionPipelineExecutionId
    );
    slicePipelineExecutions.slice(
      pipelineStageExecutionIndex,
      pipelineExecutions.length
    );
  }
  const lastSucceededExecution = slicePipelineExecutions
    .slice(1, pipelineExecutions.length)
    .find(execution => execution.status === "Succeeded");
  if (lastSucceededExecution) {
    return [
      slicePipelineExecutions[pipelineStageExecutionIndex],
      lastSucceededExecution
    ];
  }

  return [pipelineExecutions[pipelineStageExecutionIndex]];
};

export const renderStatus = status => {
  switch (status) {
    case "InProgress":
      return "#2caddb";
    case "Succeeded":
      return "#2cdb95";
    case "Stopped":
      return "#ff8000";
    case "Failed":
      return "#b92020";
    default:
      return "none";
  }
};

export const reversePipelineNamesAppMap = pipelineNamesAppMapList =>
  Object.fromEntries(
    pipelineNamesAppMapList.flatMap(pipelineNamesAppMap =>
      Object.entries(
        pipelineNamesAppMap
      ).flatMap(([accountId, pipelineNames]) =>
        pipelineNames.map(name => [name, accountId])
      )
    )
  );

export const findCorrectSourceRevision = sourceRevisions => {
  const hasCodeCommitIntegratorDeliverySource = sourceRevisions.find(
    sourceRevision =>
      sourceRevision.actionName === "CodeCommit-Integrator-Delivery"
  );
  return hasCodeCommitIntegratorDeliverySource
    ? hasCodeCommitIntegratorDeliverySource
    : sourceRevisions[0];
};
