import React, { Component } from "react";
import "./App.css";
import Amplify, { Auth, Hub } from "aws-amplify";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import OAuthButton from "./OAuthButton";
import GRT from "./Styles/Icons/GRTtrans.png";

import PrimarySearchAppBar from "./Components/Searches/PrimarySearchAppBar";
import HighLevelDashboard from "./Screens/HighLevelDashboard";
import LowLevelDashboard from "./Screens/LowLevelDashboard";
import ComplianceDashboard from "./Screens/ComplianceDashboard";
import FinopsDashboard from "./Screens/FinopsDashboard";
import ReportDashboard from "./Screens/ReportDashboard";
import Logs from "./Screens/Logs";
import Astreintes from "./Screens/Astreintes";
import ProfilUtilisateur from "./Screens/ProfilUtilisateur";
import NoApps from "./Components/Warnings/NoApps";
import history from "./history";
import Config from "./config.js";
import { updateUserProfileFromCognitoPayload } from "./Services/UserProfilesService";
import HuntGroupsManagement from "./Screens/HuntGroupsManagement";
//import BrowserError from "./Components/Errors/BrowserError";
//import { CircularLoading } from "./Styles/ComponentStyles";
//import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import PipelinesDashboard from "./Components/Pipelines/PipelinesDashboard";
import PipelineStatesDashboard from "./Components/Pipelines/PipelineStatesDashboard";
import PipelineHistoryDashboard from "./Components/Pipelines/PipelineHistoryDashboard.js";
import UsersManagementGeneral from "./Components/UsersManagement/UsersManagementGeneral";
import PerimetersManagementGeneral from "./Components/PerimetersManagement/PerimetersManagementGeneral";
import BillingGeneral from "./Components/Billing/BillingGeneral";
import { getAllAccounts } from "./Services/AccountService";
import { retrieveUserApplications } from "./Services/UsersService";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
//Set the fr language for moment
//test pipeline KMS sns topic
moment.locale("fr");

let auth_config = {};
if ("OAUTH_REDIRECT" in Config.cognito) {
  auth_config = {
    region: "eu-west-1",
    userPoolId: Config.cognito.USER_POOL_ID,
    userPoolWebClientId: Config.cognito.USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: Config.cognito.OAUTH_DOMAIN,
      scope: ["openid"],
      redirectSignIn: Config.cognito.OAUTH_REDIRECT,
      redirectSignOut: Config.cognito.OAUTH_REDIRECT,
      responseType: "code"
    }
  };
} else {
  auth_config = {
    identityPoolId: Config.cognito.IDENTITY_POOL_ID,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    userPoolWebClientId: Config.cognito.WEB_CLIENT_ID
  };
}

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "api-exsi-backend",
        endpoint: Config.apiGateway.URL,
        region: Config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          };
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      },
      {
        name: "api-exsi-backend-v2",
        endpoint: Config.apiGatewayV2.URL,
        region: Config.apiGatewayV2.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          };
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    ]
  },
  Auth: auth_config
});

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      account_list: {},
      user_app_names: [],
      list_instances: [],
      selected_application: null,
      environment: null,
      currentPage: "mes-applications",
      userName: "",
      currentScreen: "",
      cognitoUserRole: "",
      userAuthTime: "",
      userAccessToken: "",
      userIdToken: "",
      noApps: false,
      authState: "loading",
      authData: null,
      authError: null,
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      isAccountListFetched: false
    };

    // this.setUserInfo();
    this.dashboardGlobale = this.dashboardGlobale.bind(this);
    this.setAccountList = this.setAccountList.bind(this);
    this.setUserInfo = this.setUserInfo.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.authCallback = this.authCallback.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen("auth", this.authCallback);
  }

  authCallback(data) {
    if (data !== undefined) {
      switch (data.payload.event) {
        case "signIn":
          this.setUserInfo();
          break;
        case "signIn_failure":
          this.setState({ authState: "signIn" });
          this.setState({ authData: null });
          this.setState({ authError: data.payload.data });
          break;
        default:
          break;
      }
    }
  }

  componentDidMount() {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser()
      .then(async user => {
        this.setUserInfo();
      })
      .catch(e => {
        this.setState({ authState: "signIn" });
      });
  }

  handleEnvironmentChange = (event, env_props) => {
    this.setState(state => ({
      environment: event
    }));
  };

  handleApplicationChange = (event, app_props) => {
    if (event.length !== 0) {
      this.setState(state => ({
        selected_application: event,
        environment: null
      }));
    } else {
      this.setState(state => ({
        selected_application: null,
        environment: null
      }));
    }
  };

  handleLeftMenuClick = event => {
    this.setState(state => ({
      currentPage: event
    }));
  };

  setAccountList() {
    getAllAccounts().then(res => {
      this.setState({
        account_list_full: res
      });
      retrieveUserApplications(this.state.userName).then(app_names => {
        if (!app_names.length) {
          this.setState(state => ({
            noApps: true
          }));
          history.push({
            pathname: "/alerte-apps"
          });
          return null;
        }
        this.setState({
          user_app_names: app_names
        });

        res.map(row => {
          if (
            row["ACCOUNT_NAME"].length === 8 &&
            row["ACCOUNT_NAME"].includes("-")
          ) {
            if (app_names.includes(row["ACCOUNT_NAME"])) {
              this.setState({
                account_list: Object.assign(
                  { [row["ACCOUNT_NAME"]]: row["ACCOUNT_ID"] },
                  this.state.account_list
                )
              });
            }
          }
          return null;
        });
        this.setState({
          isAccountListFetched: true
        });
      });
    });
  }

  setUserInfo() {
    return Amplify.Auth.currentSession()
      .then(async data => {
        updateUserProfileFromCognitoPayload(data.getIdToken().payload)
          .then(userUpdated => {
            const { UserName, FirstName, LastName, Email } = userUpdated;
            this.setState({
              userName: UserName,
              currentScreen: "Admin",
              cognitoUserRole: data.idToken.payload["cognito:roles"],
              userAuthTime: moment().unix(),
              userAccessToken: data.accessToken.jwtToken,
              userIdToken: data.idToken.jwtToken,
              authState: "signedIn",
              userFirstName: FirstName,
              userLastName: LastName,
              userEmail: Email
            });
          })
          .then(() => this.setAccountList());
        if (
          JSON.parse(sessionStorage.getItem("authenticated")) === null ||
          JSON.parse(sessionStorage.getItem("authenticated")) === false
        ) {
          sessionStorage.setItem("authenticated", true);
        }
      })
      .catch(err => console.log(err));
  }

  primarySearchAppBar() {
    return (
      <PrimarySearchAppBar
        currentPage={this.state.currentPage}
        user_name={this.state.userName}
        userIdToken={this.state.userIdToken}
        selected_application={this.state.selected_application}
        environment={this.state.environment}
        handleApplicationChange={this.handleApplicationChange}
        userFirstName={this.state.userFirstName}
        userLastName={this.state.userLastName}
      />
    );
  }

  dashboardGlobale() {
    return (
      <Route
        path="/mes-applications"
        render={props => (
          <HighLevelDashboard
            {...props}
            account_list={this.state.account_list}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
            noApps={this.state.noApps}
          />
        )}
      />
    );
  }

  dashboardReport() {
    return (
      <Route
        path="/rapports"
        render={props => (
          <ReportDashboard
            {...props}
            mobile={this.state.mobile}
            handleApplicationChange={this.handleApplicationChange}
            account_list={this.state.account_list}
            handleEnvironmentChange={this.handleEnvironmentChange}
            environment={this.state.environment}
            selected_application={this.state.selected_application}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
          />
        )}
      />
    );
  }

  dashboardFinops() {
    return (
      <Route
        path="/finops"
        render={props => (
          <FinopsDashboard
            {...props}
            mobile={this.state.mobile}
            handleApplicationChange={this.handleApplicationChange}
            account_list={this.state.account_list}
            handleEnvironmentChange={this.handleEnvironmentChange}
            environment={this.state.environment}
            selected_application={this.state.selected_application}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
          />
        )}
      />
    );
  }

  dashboardCompliance() {
    return (
      <Route
        path="/compliance"
        render={props => (
          <ComplianceDashboard
            {...props}
            mobile={this.state.mobile}
            handleApplicationChange={this.handleApplicationChange}
            account_list={this.state.account_list}
            handleEnvironmentChange={this.handleEnvironmentChange}
            environment={this.state.environment}
            selected_application={this.state.selected_application}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
          />
        )}
      />
    );
  }

  dashboardSpecifique() {
    return (
      <Route
        path="/application"
        render={props => (
          <LowLevelDashboard
            {...props}
            mobile={this.state.mobile}
            handleApplicationChange={this.handleApplicationChange}
            account_list={this.state.account_list}
            handleEnvironmentChange={this.handleEnvironmentChange}
            environment={this.state.environment}
            selected_application={this.state.selected_application}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
          />
        )}
      />
    );
  }

  logsActions() {
    return (
      <Route
        path="/logs"
        render={() => <Logs userIdToken={this.state.userIdToken} />}
      />
    );
  }

  astreintesRoute() {
    return (
      <Route
        path="/astreintes"
        render={() => (
          <Astreintes
            userIdToken={this.state.userIdToken}
            user_name={this.state.userName}
          />
        )}
      />
    );
  }

  dashboardFacturation() {
    return (
      <Route
        path="/facturation"
        render={() => <BillingGeneral accountList={this.state.account_list} />}
      />
    );
  }

  dashboardPerimetres() {
    return (
      <Route
        path="/gestion-perimetres"
        render={props => (
          <PerimetersManagementGeneral
            {...props}
            accountList={this.state.account_list_full}
            userName={this.state.userName}
          />
        )}
      />
    );
  }

  pipelinesDashboard() {
    return (
      <Route
        path="/livraison/:app"
        render={({ match }) => (
          <div style={{ minHeight: "1000px", background: "#f7faf9" }}>
            <PipelinesDashboard
              app={match.params.app}
              accountList={this.state.account_list}
              userName={this.state.userName}
            />
          </div>
        )}
      />
    );
  }

  pipelineStatesDashboard() {
    return (
      <Route
        path="/pipeline/:pipeline_name"
        render={({ match, location }) => {
          const isHistory = match.params.pipeline_name.startsWith("history-");
          let pipelineName = match.params.pipeline_name;
          if (isHistory) {
            pipelineName = pipelineName.replace("history-", "");
          }
          return (
            <div
              style={{
                minHeight: "1000px",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              {isHistory ? (
                <PipelineHistoryDashboard
                  pipelineName={pipelineName}
                  userName={this.state.userName}
                  app={location.state.app}
                  accountId={location.state.accountId}
                />
              ) : (
                <PipelineStatesDashboard
                  pipelineName={pipelineName}
                  userName={this.state.userName}
                  app={location.state.app}
                  accountId={location.state.accountId}
                />
              )}
            </div>
          );
        }}
      />
    );
  }

  profilUtilisateur() {
    return (
      <Route
        path="/profil-utilisateur"
        render={props => (
          <ProfilUtilisateur {...props} userIdToken={this.state.userIdToken} />
        )}
      />
    );
  }

  userManagement() {
    return (
      <Route
        path="/gestion-utilisateurs"
        render={() => <UsersManagementGeneral userName={this.state.userName} />}
      />
    );
  }

  huntGroupsManagement() {
    return (
      <Route
        path="/gestion-hunt-group"
        render={() => (
          <HuntGroupsManagement
            classes={this.state.classes}
            userIdToken={this.state.userIdToken}
            userName={this.state.userName}
          />
        )}
      />
    );
  }

  noApps() {
    return (
      <Route path="/alerte-apps" render={props => <NoApps {...props} />} />
    );
  }

  renderSwitch(screenName) {
    switch (screenName) {
      case "Admin":
        return (
          <Switch>
            {this.dashboardGlobale()}
            {this.dashboardFinops()}
            {this.dashboardReport()}
            {this.dashboardCompliance()}
            {this.dashboardSpecifique()}
            {this.logsActions()}
            {this.astreintesRoute()}
            {this.dashboardFacturation()}
            {this.dashboardPerimetres()}
            {this.pipelinesDashboard()}
            {this.pipelineStatesDashboard()}
            {this.profilUtilisateur()}
            {this.userManagement()}
            {this.huntGroupsManagement()}
            {this.noApps()}
            <Redirect from="/" to="mes-applications" />
          </Switch>
        );

      case "dashboardGlobale":
        return (
          <Switch>
            {this.dashboardGlobale()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      case "dashboardFinops":
        return (
          <Switch>
            {this.dashboardFinops()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      case "dashboardCompliance":
        return (
          <Switch>
            {this.dashboardCompliance()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      case "dashboardSpecifique":
        return (
          <Switch>
            {this.dashboardSpecifique()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      case "logsActions":
        return (
          <Switch>
            {this.logsActions()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      default:
        return null;
    }
  }

  renderHome() {
    return (
      <div
        style={{
          "text-align": "center",
          "margin-top": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          background: "#f7faf9"
        }}
      >
        {this.primarySearchAppBar()}
        <Router history={history}>
          {this.state.isAccountListFetched || this.state.noApps ? (
            <div className="content" style={{ animation: "fadeIn 1s" }}>
              {this.renderSwitch(this.state.currentScreen.toString())}
            </div>
          ) : (
            <div style={{ background: "#ffffff" }}>
              <LinearProgress
                color="primary"
                style={{
                  height: "2px",
                  display: "flex",
                  "text-align": "center"
                }}
              />
              <img
                src={GRT}
                alt={"APP-icon"}
                style={{
                  height: "100px",
                  marginTop: "50px",
                  verticalAlign: "middle",
                  animation: "fadeIn 4s"
                }}
              />
            </div>
          )}
        </Router>
      </div>
    );
  }

  render() {
    const { authState } = this.state;
    // const isBrowserChromeOrSafari =
    // navigator.userAgent.includes("Chrome") ||
    // navigator.userAgent.includes("Safari") ||
    // navigator.userAgent.includes("CriOS");
    return (
      <div className="App">
        {/*
        {!isBrowserChromeOrSafari && (
          <BrowserError classes={this.state.classes} />
        )}
      */}
        {authState === "loading" && (
          <div classeName="App-fade">
            <svg viewBox="0 0 800 800">
              <path
                class="path"
                d="M2,295 C363,348 395,4 800,47"
                fill="none"
                stroke="#0ec289"
                stroke-width="0.5"
                stroke-dashoffset="1000"
                id="motion1"
              />
              <path
                d="M0,204 C377,367 504,22 797,225"
                fill="none"
                stroke="#0ec289"
                stroke-width="0.5"
                id="motion2"
              />
              <circle
                class="circle"
                cx="284.3733028649552"
                cy="241.81299508566542"
                r="5"
                stroke="#f7faf9"
                fill="#f7faf9"
                stroke-width="0"
              />
              <circle
                class="circle"
                cx="0"
                cy="0"
                r="1"
                stroke="#f7faf9"
                fill="#0ec289"
                stroke-width="0"
              >
                <animateMotion dur="40s" repeatCount="indefinite">
                  <mpath xlinkHref="#motion1" />
                </animateMotion>
              </circle>
              <circle
                class="circle"
                cx="0"
                cy="0"
                r="1"
                stroke="#f7faf9"
                fill="#0ec289"
                stroke-width="0"
              >
                <animateMotion dur="30s" repeatCount="indefinite">
                  <mpath xlinkHref="#motion2" />
                </animateMotion>
              </circle>
              <line
                x1="284.3733028649552"
                x2="284.3733028649552"
                y1="241.81299508566542"
                y2="245.5"
                stroke="#0ec289"
                stroke-width="1.5"
              />
              <line
                x1="284.3733028649552"
                x2="288"
                y1="241.81299508566542"
                y2="241.81299508566542"
                stroke="#0ec289"
                stroke-width="1.5"
              />
              <line
                x1="284.3733028649552"
                x2="284.3733028649552"
                y1="241.81299508566542"
                y2="238"
                stroke="#0ec289"
                stroke-width="1.5"
              />
              <line
                x1="284.3733028649552"
                x2="281"
                y1="241.81299508566542"
                y2="241.81299508566542"
                stroke="#0ec289"
                stroke-width="1.5"
              />
            </svg>
          </div>
        )}
        {authState === "signIn" && <OAuthButton />}
        {authState === "signedIn" && this.renderHome()}
      </div>
    );
  }
}

export default App;
