import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { ArrowDropDown } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {
  findCorrectSourceRevision,
  putPipelineApproval
} from "../../Services/PipelinesService";
import history from "../../history";

const PipelineStageInProgressApproval = ({
  open,
  onClose,
  lastTwoExecutions,
  pipelineName,
  stageName,
  actionName,
  token,
  userName,
  app,
  accountId
}) => {
  const [isValidateButtonDisabled, setIsValidateButtonDisabled] = useState(
    true
  );
  const [approbationMessage] = useState(
    "Je livre la version " +
      findCorrectSourceRevision(
        lastTwoExecutions[0].sourceRevisions
      ).revisionId.slice(0, 8) +
      " sur " +
      stageName
  );
  const [approbationMessageCaptcha, setApprobationMessageCaptcha] = useState(
    ""
  );
  const [approbationComment, setApprobationComment] = useState("");

  useEffect(() => {
    setIsValidateButtonDisabled(
      !(approbationMessage === approbationMessageCaptcha && approbationComment)
    );
  }, [approbationComment, approbationMessage, approbationMessageCaptcha]);

  const handleTextFieldChangeApprobation = event => {
    setApprobationMessageCaptcha(event.target.value);
  };

  const handleTextFieldChangeComment = event => {
    setApprobationComment(event.target.value);
  };

  const validateApprovalPipeline = async message => {
    await putPipelineApproval(
      pipelineName,
      token,
      actionName,
      stageName,
      message,
      approbationComment,
      userName,
      accountId
    );
    history.push({
      pathname: "/livraison/" + app
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 30,
          fontFamily: "sans-serif",
          fontSize: 12,
          fontWeight: 600,
          color: "#009999"
        }
      }}
    >
      <DialogTitle
        style={{
          backgroundColor: "#6f8dd6",
          marginBottom: "10px",
          "text-transform": "uppercase",
          "font-size": "12px",
          "font-weight": "bold"
        }}
      >
        <div style={{ color: "white" }}>
          Livrer la version{" "}
          <b>
            {findCorrectSourceRevision(
              lastTwoExecutions[0].sourceRevisions
            ).revisionId.slice(0, 8)}
          </b>{" "}
          sur <b>{stageName}</b>
        </div>
      </DialogTitle>
      <DialogContent>
        {lastTwoExecutions.length === 2 ? (
          findCorrectSourceRevision(lastTwoExecutions[1].sourceRevisions)
            .revisionId !==
          findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
            .revisionId ? (
            <div>
              <div
                style={{
                  padding: "0.2em",
                  margin: "0.2em",
                  //border: "1px solid black",
                  border: "none",
                  "border-radius": "5px",
                  "background-color": "#fafafa"
                }}
              >
                <div>
                  {findCorrectSourceRevision(
                    lastTwoExecutions[1].sourceRevisions
                  ).revisionId.slice(0, 8)}{" "}
                  -{" "}
                  {
                    findCorrectSourceRevision(
                      lastTwoExecutions[1].sourceRevisions
                    ).actionName
                  }
                  :{" "}
                  {
                    findCorrectSourceRevision(
                      lastTwoExecutions[1].sourceRevisions
                    ).revisionSummary
                  }
                </div>
              </div>
              <div
                style={{
                  padding: "0.2em",
                  margin: "0.2em",
                  width: "100%",
                  textAlign: "center"
                }}
              >
                <ArrowDropDown
                  nativeColor={"green"}
                  style={{ transform: "scale(5)" }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "0.2em",
                margin: "0.2em",
                width: "100%",
                textAlign: "center"
              }}
            >
              Version identique
            </div>
          )
        ) : (
          <div
            style={{
              padding: "0.2em",
              margin: "0.2em",
              width: "100%",
              textAlign: "center"
            }}
          >
            Aucune version précédente
          </div>
        )}
        <div
          style={{
            padding: "0.2em",
            margin: "0.2em",
            border: "1px solid black"
          }}
        >
          <div>
            {findCorrectSourceRevision(
              lastTwoExecutions[0].sourceRevisions
            ).revisionId.slice(0, 8)}{" "}
            -{" "}
            {
              findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
                .actionName
            }
            :{" "}
            {
              findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
                .revisionSummary
            }
          </div>
        </div>
        <br />
        <TextField
          autoFocus
          margin="dense"
          id="approval"
          label={approbationMessage}
          type="text"
          onChange={event => handleTextFieldChangeApprobation(event)}
          fullWidth
        />
        <br />
        <br />
        <TextField
          autoFocus
          margin="dense"
          id="commentaire"
          label={"Ajouter un commentaire"}
          type="text"
          onChange={event => handleTextFieldChangeComment(event)}
          fullWidth
        />
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={onClose}
            variant={"contained"}
            style={{
              backgroundColor: "secondary",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
          >
            <div
              style={{
                color: "white"
              }}
            >
              Annuler
            </div>
          </Button>
          <Button
            onClick={() => validateApprovalPipeline("Rejected")}
            variant={"contained"}
            style={{
              backgroundColor: isValidateButtonDisabled ? "gray" : "#ff0000",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
            disabled={isValidateButtonDisabled}
          >
            <div
              style={{
                color: "white"
              }}
            >
              Refuser
            </div>
          </Button>
          <Button
            onClick={() => validateApprovalPipeline("Approved")}
            variant={"contained"}
            style={{
              backgroundColor: isValidateButtonDisabled ? "gray" : "#32CD32",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
            disabled={isValidateButtonDisabled}
          >
            <div
              style={{
                color: "white"
              }}
            >
              Valider
            </div>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PipelineStageInProgressApproval;
