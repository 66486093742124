import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ComponentStyles";

const BillingEnv = ({ envName, envBilling, classes }) => {
  return (
    <TableRow key={envName}>
      <TableCell
        align="center"
        style={{
          border: "none",
          padding: "1em",
          textTransform: "lowercase",
          fontFamily: "sans-serif",
          fontSize: "16px",
          fontWeight: "300",
          color: "#566fcc"
        }}
      >
        <b>{envName}</b>
      </TableCell>
      <TableCell
        align="center"
        style={{
          border: "none",
          padding: "1em",
          textTransform: "lowercase",
          fontFamily: "sans-serif",
          fontSize: "16px",
          fontWeight: "400",
          color: "#566fcc"
        }}
      >
        {envBilling["previousMonth"]} $
      </TableCell>
      <TableCell
        align="center"
        style={{
          border: "none",
          padding: "1em",
          textAlign: "center",
          textTransform: "lowercase",
          fontFamily: "sans-serif",
          fontSize: "16px",
          fontWeight: "400",
          color: "#566fcc"
        }}
      >
        {envBilling["monthToDate"]} $
      </TableCell>
      <TableCell
        align="center"
        style={{
          border: "none",
          padding: "1em",
          textTransform: "lowercase",
          fontFamily: "sans-serif",
          fontSize: "16px",
          fontWeight: "400",
          color: "#566fcc"
        }}
      >
        {envBilling["forecastEvolution"] && envBilling["forecastEvolution"] > 0
          ? "+" + envBilling["forecastEvolution"]
          : envBilling["forecastEvolution"]}
        {envBilling["forecastEvolution"] &&
          envBilling["forecastEvolution"] === "N/A" && (
            <Tooltip
              title={
                "Il n'est pas possible de calculer les prévisions le dernier jour du mois."
              }
              classes={{ tooltip: classes.lightTooltip }}
            >
              <b>*</b>
            </Tooltip>
          )}
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(BillingEnv);
