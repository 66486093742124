import React, { useCallback, useEffect, useState } from "react";
import { retrieveAllPerimeters } from "../../Services/PerimetersService";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import PerimetersManagementRow from "./PerimetersManagementRow";
//import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import PerimetersManagementCreate from "./PerimetersManagementCreate";

const PerimetersManagementDashboard = ({ accountList }) => {
  const [perimeters, setPerimeters] = useState(undefined);
  const [isCreatePerimeterOpened, setIsCreatePerimeterOpened] = useState(false);
  const [applications] = useState(
    accountList
      .map(account => account["ACCOUNT_NAME"])
      .filter(accountName => accountName.includes("-"))
      .sort()
  );

  const handleCreatePerimeterClose = () => setIsCreatePerimeterOpened(false);

  useEffect(() => {
    const retrievePerimeters = async () => {
      setPerimeters(await retrieveAllPerimeters());
    };

    retrievePerimeters();
  }, []);

  const perimetersReloadCallBack = useCallback(async () => {
    setIsCreatePerimeterOpened(false);
    setPerimeters(undefined);
    setPerimeters(await retrieveAllPerimeters());
  }, []);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Périmètre</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Applications</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Téléphone astreinte</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Email</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Modifier</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Supprimer</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {perimeters &&
            perimeters.map((perimeter, index) => (
              <PerimetersManagementRow
                key={index}
                perimeter={perimeter}
                perimetersReloadCallBack={perimetersReloadCallBack}
                applications={applications}
              />
            ))}
        </TableBody>
      </Table>
      {!perimeters && (
        <div>
          <svg viewBox="0 0 800 800">
            <path
              d="M2,295 C363,348 395,4 800,47"
              fill="none"
              stroke="#0ec289"
              stroke-width="0.5"
              stroke-dashoffset="1000"
            />
            <path
              d="M0,204 C377,367 504,22 797,225"
              fill="none"
              stroke="#0ec289"
              stroke-width="0.5"
            />
          </svg>
        </div>
      )}
      <Button
        color="primary"
        size="medium"
        variant="contained"
        style={{
          backgroundColor: "secondary",
          boxShadow: "none",
          borderRadius: "20px",
          border: "none",
          fontWeight: "bold",
          color: "#ffffff",
          margin: "10px"
        }}
        onClick={() => setIsCreatePerimeterOpened(true)}
      >
        Ajouter un périmètre
      </Button>
      <PerimetersManagementCreate
        open={isCreatePerimeterOpened}
        onClose={handleCreatePerimeterClose}
        onValidate={perimetersReloadCallBack}
        applications={applications}
      ></PerimetersManagementCreate>
    </div>
  );
};

export default PerimetersManagementDashboard;
