import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import "moment/locale/fr";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import {
  CircularLoading,
  CustomTableCell,
  styles
} from "../../Styles/ComponentStyles";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";
import Cached from "@material-ui/icons/Cached";
import Config from "../../config.js";

import { Button } from "@material-ui/core";
import { compareFunctionOnKey } from "../../Services/utils";
import { getColorFromStatus } from "../../Services/ResourcesService";

//Set the fr language for moment
moment.locale("fr");

class ResourceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      listResourcesInProgress: false,
      listParametersInProgress: false,
      config: Config["calls"][props.resource_service],
      table_response: this.props.table_response,
      parameters_response: [],
      parameter_values: {},
      current_service: this.props.current_service,
      current_action: "NO ACTION",
      current_resource: "NO RESOURCE",
      popup_loading: false,
      seconds: 0,
      expanded_services: this.props.expanded_services
    };
    this.list_resources = this.list_resources.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  list_resources() {
    if (this.state.listResourcesInProgress) {
      return;
    }
    if (this.state.resource_service === this.state.current_service) {
      this.setState(
        {
          listResourcesInProgress: true
        },
        () => {
          if (
            this.state.expanded_services.indexOf(this.state.resource_service) >
            -1
          ) {
            let temp_table_response = [];
            if (this.state.table_response !== null) {
              temp_table_response = this.state.table_response;
            }
            API.post("api-exsi-backend", "/realize", {
              queryStringParameters: {
                boto_service: this.state.resource_service,
                boto_method: this.state.resource_action,
                account_id: this.state.account_id
              },
              headers: {
                Authorization: this.state.userIdToken
              }
            }).then(response => {
              if (this.state.resource_service === "ecs") {
                API.post("api-exsi-backend", "/realize", {
                  queryStringParameters: {
                    boto_service: this.state.resource_service,
                    boto_method: "list_clusters",
                    account_id: this.state.account_id
                  },
                  headers: {
                    Authorization: this.state.userIdToken
                  }
                }).then(response_list_clusters => {
                  let cluster_arn = response_list_clusters["clusterArns"][0];
                  for (let i = 0; i < response["families"].length; i++) {
                    realizeAction(
                      this.props.userIdToken,
                      this.state.account_id,
                      this.state.resource_service,
                      "describe_task_definition",
                      response["families"][i],
                      "taskDefinition",
                      false,
                      response => {
                        if (
                          !(
                            "definitions" in
                            temp_table_response[this.state.resource_service]
                          )
                        ) {
                          temp_table_response[this.state.resource_service][
                            "definitions"
                          ] = [];
                        }
                        let temp_table = response["taskDefinition"];
                        let body = {
                          family: response["taskDefinition"]["family"],
                          cluster: cluster_arn
                        };
                        API.post("api-exsi-backend", "/realize", {
                          queryStringParameters: {
                            boto_service: "ecs",
                            boto_method: "list_tasks",
                            account_id: this.state.account_id,
                            region: "eu-west-1"
                          },
                          body: body
                        }).then(response => {
                          temp_table["tasks"] = response["taskArns"];
                          temp_table["task_number"] =
                            response["taskArns"].length;
                          temp_table["running_task"] =
                            response["taskArns"].length === 0 ? "non" : "oui";
                          temp_table_response[this.state.resource_service][
                            "definitions"
                          ].push(temp_table);
                          this.setState({
                            table_response: temp_table_response,
                            seconds: 0,
                            listResourcesInProgress: false
                          });
                        });
                      },
                      null,
                      null,
                      null,
                      null,
                      null
                    );
                  }
                });
                this.setState({
                  table_response: temp_table_response,
                  seconds: 0,
                  listResourcesInProgress: false
                });
              } else {
                temp_table_response[this.state.resource_service] = response;
                this.setState({
                  table_response: temp_table_response,
                  seconds: 0,
                  listResourcesInProgress: false
                });
              }
              temp_table_response[this.state.resource_service] = response;
              this.setState({
                table_response: temp_table_response
              });
            });
          }
        }
      );
    }
  }

  getValue(source_array, path_array) {
    if (Array.isArray(path_array)) {
      let new_array = source_array;
      for (let key in path_array) {
        if (
          path_array.hasOwnProperty(key) &&
          /^0$|^[1-9]\d*$/.test(key) &&
          key <= 4294967294
        ) {
          //This is for specific Tags gathering use case
          if (typeof path_array[key] === "string") {
            if (path_array[key].includes("~~")) {
              for (let tag in new_array) {
                if (new_array.hasOwnProperty(key)) {
                  if (new_array[tag]["Key"] === "Name") {
                    return new_array[tag]["Value"];
                  }
                }
              }
              return "<Pas de tag Name>";
            }
          }
          if (new_array[path_array[key]] !== undefined) {
            new_array = new_array[path_array[key]];
          }
        }
      }
      return new_array;
    } else {
      return source_array[path_array];
    }
  }

  renderResourceRow(row) {
    let element = [];
    let index = 0;
    for (let key in this.state.config.RESPONSE_ELEMENTS) {
      if (this.state.config.RESPONSE_ELEMENTS.hasOwnProperty(key)) {
        const value =
          key !== "EC2/Fargate"
            ? this.getValue(row, this.state.config.RESPONSE_ELEMENTS[key])
            : row;
        element.push(
          <CustomTableCell
            style={{
              border: "none",
              "border-radius": "30px",
              color: "#48596e",
              "font-weight": "600",
              "font-size": "14px",
              textTransform: "lowercase",
              textAlign: "center",
              backgroundColor:
                key === "État" ? getColorFromStatus(value) : "None"
            }}
            key={index}
          >
            {typeof value === "object"
              ? value["compatibilities"] && value["compatibilities"].length > 0
                ? value["compatibilities"][0]
                : value["requiresCompatibilities"] &&
                  value["requiresCompatibilities"].length > 0
                ? value["requiresCompatibilities"][0]
                : "?"
              : value}
          </CustomTableCell>
        );
      }
      index++;
    }
    return element;
  }

  renderResourceRows() {
    if (this.state.table_response.hasOwnProperty(this.state.resource_service)) {
      let root_response = this.state.table_response[
        this.state.resource_service
      ][this.state.config.RESPONSE_ROOT_ELEMENT];

      if (this.state.current_service === "ec2") {
        root_response.sort((a, b) =>
          compareFunctionOnKey(
            a["Instances"][0]["Tags"],
            b["Instances"][0]["Tags"],
            "Name"
          )
        );
      }
      /*if (this.state.current_service === "rds") {
        root_response.sort((a, b) =>
          a.DBInstanceIdentifier.localeCompare(b.DBInstanceIdentifier)
        );
      }*/
      let element = [];
      for (let key in root_response) {
        if (
          root_response.hasOwnProperty(key) &&
          /^0$|^[1-9]\d*$/.test(key) &&
          key <= 4294967294
        ) {
          element.push(
            <TableRow key={key}>
              {this.renderResourceRow(root_response[key])}
            </TableRow>
          );
        }
      }
      return element;
    } else {
      return null;
    }
  }

  renderResourceHeader() {
    let element = [];
    for (let key in this.state.config.RESPONSE_ELEMENTS) {
      if (this.state.config.RESPONSE_ELEMENTS.hasOwnProperty(key)) {
        element.push(
          <TableCell
            key={key}
            style={{
              border: "none",
              background: "white",
              color: "#48596e",
              "font-weight": "bold",
              "font-size": "14px",
              textTransform: "uppercase",
              textAlign: "center"
            }}
          >
            {key}
          </TableCell>
        );
      }
    }
    return element;
  }

  render() {
    if (this.state.table_response !== null) {
      if (this.state.table_response[this.state.resource_service] !== null) {
        return (
          <div>
            <Paper
              className="paperTable"
              elevation={0}
              style={{
                border: "none",
                "border-radius": "30px",
                "margin-top": "5px",
                "margin-bottom": "5px",
                "padding-left": "100px",
                background: "white",
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                "font-weight": "bold"
              }}
            >
              <Button
                size="small"
                color="primary"
                style={{
                  "font-family": "sans-serif",
                  "font-size": "12px",
                  "font-weight": "600",
                  background: "#edfaf7",
                  color: "#009999",
                  "box-shadow": "none",
                  "border-radius": "30px",
                  border: "none"
                }}
                onClick={() => {
                  let temp_table_response = this.state.table_response;
                  temp_table_response[this.state.resource_service] = null;
                  this.setState(
                    {
                      seconds: 0,
                      table_response: temp_table_response
                    },
                    () => {
                      this.list_resources();
                    }
                  );
                }}
              >
                <Cached /> Rafraîchir ({this.state.seconds} s)
              </Button>
              {this.state.listResourcesInProgress ? (
                <div>
                  <Typography
                    style={{
                      "text-transform": "uppercase",
                      "font-size": "12px",
                      "font-weight": "bold",
                      "text-align": "center",
                      "border-radius": "30px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      color: "#009999"
                    }}
                  >
                    Votre action est en cours de réalisation... Merci de
                    patienter...
                  </Typography>
                  <CircularLoading> </CircularLoading>
                </div>
              ) : (
                <Table
                  className="tableTable"
                  style={{
                    "font-family": "sans-serif",
                    "font-size": "12px",
                    "font-weight": "600",
                    color: "#009999",
                    "box-shadow": "none",
                    border: "none"
                  }}
                >
                  <TableHead>
                    <TableRow>{this.renderResourceHeader()}</TableRow>
                  </TableHead>
                  <TableBody>{this.renderResourceRows()}</TableBody>
                </Table>
              )}
            </Paper>
          </div>
        );
      } else {
        return <CircularLoading></CircularLoading>;
      }
    } else {
      return <CircularLoading></CircularLoading>;
    }
  }
}

export default withSnackbar(withStyles(styles)(ResourceTable));
