import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
import moment from "moment";
import "moment/locale/fr";
import { CircularLoadingButton, styles } from "../../Styles/ComponentStyles";
import history from "../../history";
import Grid from "@material-ui/core/Grid";
import green from "@material-ui/core/colors/green";
import { getAccountState } from "../../Services/AccountService";

//Set the fr language for moment
moment.locale("fr");

const ButtonTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

class AppCardCompliance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      app: props.app,
      environment: props.environment,
      account_id: props.account_id,
      classes: props.classes,
      redirect: false,
      status: "GATHERING",
      app_clickable: props.app_clickable,
      listComplianceInProgress: false,
      nonCompliantCount: 0,
      applicationState: undefined
    };

    if (props.account_id !== undefined) {
      getAccountState(props.account_id).then(result => {
        if (result === "up") {
          this.listComplianceSum(props.account_id);
        } else {
          this.setState({
            status: result,
            listComplianceInProgress: false
          });
        }
      });
    }
  }

  listComplianceSum(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(response => {
      let result = 0;
      let nonCompliantCount = 0;
      for (let i = 0; i < response["ComplianceSummaryItems"].length; i++) {
        if (response["ComplianceSummaryItems"][i].ComplianceType === "Patch") {
          nonCompliantCount =
            response["ComplianceSummaryItems"][i].NonCompliantSummary
              .NonCompliantCount;
          result = nonCompliantCount;
        }
      }
      this.setState({
        nonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  render() {
    const { app, environment } = this.state;

    let bgColor = "default";
    let compliance = "patched";
    let mgLeft = "0px";
    let btVariant = "contained";
    let btTextColor = "#ffffff";
    let ftWeight = "bold";
    let btBorder = "2px solid gray";
    if (this.state.app_clickable) {
      if (this.state.nonCompliantCount === 0) {
        this.setState({ nonCompliantCount: compliance });
      } else if (this.state.nonCompliantCount > 0) {
        btVariant = "contained";
        btBorder = "none";
        bgColor = "secondary";
        ftWeight = "bold";
        btTextColor = "#ffffff";
        mgLeft = "0px";
      } else {
        btVariant = "outlined";
        btBorder = "2px solid #9de0b2";
        bgColor = "primary";
        ftWeight = "bold";
        mgLeft = "-4px";
        btTextColor = "#7e9fed";
      }
    }

    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify="flex-start"
        className={this.state.classes.gridContainer}
      >
        <Grid item xs={2} elevation={0}>
          <MuiThemeProvider theme={ButtonTheme}>
            {this.state.nonCompliantCount > 0 && (
              <Button
                size="small"
                color={bgColor}
                variant={btVariant}
                style={{
                  backgroundColor: bgColor,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: btBorder,
                  marginLeft: mgLeft,
                  marginTop: "-20px",
                  fontWeight: ftWeight,
                  fontSize: 14,
                  color: btTextColor,
                  height: "20px"
                }}
                onClick={() => {
                  history.push({
                    pathname: "/compliance",
                    search: "?app=" + app + "&env=" + environment
                  });
                }}
              >
                {this.state.nonCompliantCount}
                {this.state.status === "GATHERING" &&
                this.state.nonCompliantCount === "undefined" ? (
                  <CircularLoadingButton />
                ) : (
                  false
                )}
              </Button>
            )}
            {this.state.nonCompliantCount === "patched" && (
              <Button
                size="small"
                disabled="true"
                color={bgColor}
                variant={btVariant}
                style={{
                  backgroundColor: bgColor,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: btBorder,
                  marginLeft: mgLeft,
                  marginTop: "-20px",
                  fontWeight: ftWeight,
                  fontSize: 12,
                  color: btTextColor,
                  height: "20px"
                }}
              >
                {this.state.nonCompliantCount}
                {this.state.status === "GATHERING" &&
                this.state.nonCompliantCount === "undefined" ? (
                  <CircularLoadingButton />
                ) : (
                  false
                )}
              </Button>
            )}
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AppCardCompliance);
