import React, { useEffect, useState } from "react";
import PipelineStage from "./PipelineStage";
import {
  getPipelineExecutions,
  getPipelineState,
  retrieveRelevantPipelineExecutions
} from "../../Services/PipelinesService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

const PipeTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue[300]
    }
  }
});

const PipelineStatesDashboard = ({
  pipelineName,
  app,
  userName,
  accountId
}) => {
  const [stageStatesFetched, setStageStatesFetched] = useState(undefined);
  const [allExecutionsFetched, setAllExecutionsFetched] = useState(undefined);

  useEffect(() => {
    const fetchPipelineState = async () => {
      if (!stageStatesFetched) {
        const state = await getPipelineState(pipelineName, accountId);
        setStageStatesFetched(state.stageStates);
      }
    };

    fetchPipelineState();
  }, [pipelineName, stageStatesFetched, setStageStatesFetched, accountId]);

  useEffect(() => {
    const fetchPipelineExecutions = async () => {
      if (!allExecutionsFetched) {
        const executions = await getPipelineExecutions(pipelineName, accountId);
        setAllExecutionsFetched(executions.pipelineExecutionSummaries);
      }
    };

    fetchPipelineExecutions();
  }, [pipelineName, allExecutionsFetched, setAllExecutionsFetched, accountId]);

  return (
    <MuiThemeProvider theme={PipeTheme}>
      <div style={{ marginTop: "0px" }}>
        <h1
          style={{
            "text-transform": "uppercase",
            "font-family": "sans-serif",
            "font-size": "24px",
            "font-weight": "600",
            color: "white"
          }}
        >
          <Button
            size="small"
            variant={"contained"}
            color={"primary"}
            elevation={0}
            style={{
              "border-radius": "30px",
              "box-shadow": "none",
              color: "white"
            }}
            key={"button_refresh_page"}
            onClick={() => {
              setAllExecutionsFetched(undefined);
              setStageStatesFetched(undefined);
            }}
          >
            {pipelineName}
            {" | update all"}
            <ReplayIcon style={{ width: "30px", height: "20px" }} />
          </Button>
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "spaceAround",
            flexWrap: "wrap",
            "text-transform": "uppercase",
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "300",
            color: "#00838f"
          }}
        >
          {stageStatesFetched && allExecutionsFetched ? (
            stageStatesFetched.map((stageState, index) => (
              <PipelineStage
                key={index}
                pipelineName={pipelineName}
                stageState={stageState}
                lastTwoExecutions={retrieveRelevantPipelineExecutions(
                  allExecutionsFetched,
                  stageState.latestExecution &&
                    stageState.latestExecution.pipelineExecutionId
                    ? stageState.latestExecution.pipelineExecutionId
                    : undefined
                )}
                userName={userName}
                app={app}
                accountId={accountId}
              />
            ))
          ) : (
            <div style={{ "align-self": "center" }}>
              <CircularLoading />
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default PipelineStatesDashboard;
