/* eslint-disable indent */
import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import { withSnackbar } from "notistack";
import moment from "moment";
import Moment from "moment";
import PropTypes from "prop-types";

import AddChannelDialog from "../Dialogs/AddChannelDialog";
import Circle from "../Geometric/Circle";
import {
  circleColor,
  CircularLoading,
  styles
} from "../../Styles/ComponentStyles";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import {
  getAccountAlarmLogs,
  getAccountState
} from "../../Services/AccountService";
import DonutChart from "./charts/DonutChart.js";

//Set the fr language for moment
moment.locale("fr");

class SecopsDashboardTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      app: undefined,
      env: undefined,
      compliances: undefined,
      alarms: undefined,
      alarmsWithHistory: undefined,
      listAlarmInProgress: false,
      loading: false,
      surveillance: undefined,
      modalSurveillanceOpen: false,
      modalAddChannelOpen: false,
      modalHistoryOpen: false,
      current_alarm: undefined,
      form_account_id_error: false,
      seconds: 0,
      applicationState: undefined,
      listComplianceInProgress: false,
      criticalNonCompliantCount: 0,
      highNonCompliantCount: 0,
      mediumNonCompliantCount: 0,
      lowNonCompliantCount: 0,
      informationalNonCompliantCount: 0,
      unspecifiedNonCompliantCount: 0,
      resourceId: [],
      resourceIdExecution: [],
      secopsDonutData: []
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.handleSurveillanceClose = this.handleSurveillanceClose.bind(this);
    this.handleHistoryClose = this.handleHistoryClose.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
    this.listResourceCompliance = this.listResourceCompliance.bind(this);
    this.listComplianceSum = this.listComplianceSum.bind(this);
    this.listCriticalCompliancePatches = this.listCriticalCompliancePatches.bind(this);// eslint-disable-line
    this.listHighCompliancePatches = this.listHighCompliancePatches.bind(this);
    this.listMediumCompliancePatches = this.listMediumCompliancePatches.bind(this);// eslint-disable-line
    this.listLowCompliancePatches = this.listLowCompliancePatches.bind(this);
    this.listInformationalCompliancePatches = this.listInformationalCompliancePatches.bind(this);// eslint-disable-line
    this.listUnspecifiedCompliancePatches = this.listUnspecifiedCompliancePatches.bind(this);// eslint-disable-line
    this.setSecopsDonutData = this.setSecopsDonutData.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
    this.setSecopsDonutData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getModalStyle() {
    return {
      margin: "auto"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });

    if (nextProps.account_id !== undefined) {
      if (
        this.state.alarms === undefined ||
        this.state.account_id !== nextProps.account_id
      ) {
        this.setState({
          loading: true,
          listComplianceInProgress: true
        });
        getAccountState(this.state.account_id).then(result => {
          this.setState({
            applicationState: result
          });
        });
        this.listAlarms(nextProps.account_id);
        this.listResourceCompliance(nextProps.account_id);
        this.listComplianceSum(nextProps.account_id);
        this.listCriticalCompliancePatches(nextProps.account_id);
        this.listHighCompliancePatches(nextProps.account_id);
        this.listMediumCompliancePatches(nextProps.account_id);
        this.listLowCompliancePatches(nextProps.account_id);
        this.listInformationalCompliancePatches(nextProps.account_id);
        this.listUnspecifiedCompliancePatches(nextProps.account_id);
      }
    }
  }

  listResourceCompliance(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_resource_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let resourceId = [];
      for (
        let i = 0;
        i < (await response["ResourceComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ResourceComplianceSummaryItems"][i]
            .ComplianceType) === "Patch" &&
          response["ResourceComplianceSummaryItems"][i].Status ===
            "NON_COMPLIANT"
        ) {
          //resourceId.push(response["ResourceComplianceSummaryItems"][i].ResourceId);
          //resourceIdExecution.push(response["ResourceComplianceSummaryItems"][i].ExecutionSummary.ExecutionTime);
          resourceId.push(await response["ResourceComplianceSummaryItems"][i]);
        }
      }
      this.setState({
        resourceId: [...resourceId],
        listComplianceInProgress: false
      });
    });
  }

  listCriticalCompliancePatches(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let criticalNonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          criticalNonCompliantCount = await response["ComplianceSummaryItems"][
            i
          ].NonCompliantSummary.SeveritySummary.CriticalCount;
          result = criticalNonCompliantCount;
        }
      }
      this.setState({
        criticalNonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  listHighCompliancePatches(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let highNonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          highNonCompliantCount = await response["ComplianceSummaryItems"][i]
            .NonCompliantSummary.SeveritySummary.HighCount;
          result = highNonCompliantCount;
        }
      }
      this.setState({
        highNonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  listMediumCompliancePatches(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let mediumNonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          mediumNonCompliantCount = await response["ComplianceSummaryItems"][i]
            .NonCompliantSummary.SeveritySummary.MediumCount;
          result = mediumNonCompliantCount;
        }
      }
      this.setState({
        mediumNonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  listLowCompliancePatches(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let lowNonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          lowNonCompliantCount = await response["ComplianceSummaryItems"][i]
            .NonCompliantSummary.SeveritySummary.LowCount;
          result = lowNonCompliantCount;
        }
      }
      this.setState({
        lowNonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  listInformationalCompliancePatches(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let informationalNonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          informationalNonCompliantCount = await response[
            "ComplianceSummaryItems"
          ][i].NonCompliantSummary.SeveritySummary.InformationalCount;
          result = informationalNonCompliantCount;
        }
      }
      this.setState({
        informationalNonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  listUnspecifiedCompliancePatches(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let unspecifiedNonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          unspecifiedNonCompliantCount = await response[
            "ComplianceSummaryItems"
          ][i].NonCompliantSummary.SeveritySummary.UnspecifiedCount;
          result = unspecifiedNonCompliantCount;
        }
      }
      this.setState({
        unspecifiedNonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  listComplianceSum(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_compliance_summaries",
        account_id: account_id
      }
    }).then(async response => {
      let result = 0;
      let nonCompliantCount = 0;
      for (
        let i = 0;
        i < (await response["ComplianceSummaryItems"].length);
        i++
      ) {
        if (
          (await response["ComplianceSummaryItems"][i].ComplianceType) ===
          "Patch"
        ) {
          nonCompliantCount = await response["ComplianceSummaryItems"][i]
            .NonCompliantSummary.NonCompliantCount;
          result = nonCompliantCount;
        }
      }
      this.setState({
        nonCompliantCount: result,
        listComplianceInProgress: false
      });
    });
  }

  setSecopsDonutData() {
    let secopsDonutData = [
      { name: "crit", value: this.state.criticalNonCompliantCount },
      { name: "high", value: this.state.highNonCompliantCount },
      { name: "med", value: this.state.mediumNonCompliantCount },
      { name: "low", value: this.state.lowNonCompliantCount },
      { name: "info", value: this.state.informationalNonCompliantCount },
      { name: "unsp", value: this.state.unspecifiedNonCompliantCount }
    ];
    this.setState({
      secopsDonutData: [...secopsDonutData]
    });
  }

  resfreshAllData() {
    this.listAlarms(this.state.account_id);
    this.listResourceCompliance(this.state.account_id);
    this.listComplianceSum(this.state.account_id);
    this.listCriticalCompliancePatches(this.state.account_id);
    this.listHighCompliancePatches(this.state.account_id);
    this.listMediumCompliancePatches(this.state.account_id);
    this.listLowCompliancePatches(this.state.account_id);
    this.listInformationalCompliancePatches(this.state.account_id);
    this.listUnspecifiedCompliancePatches(this.state.account_id);
    this.setSecopsDonutData();
  }

  getAlarmsListCallback(response) {
    debugger;
    this.setState({
      alarms: response["MetricAlarms"],
      listAlarmInProgress: false,
      seconds: 0
    });
    this.getHistory(this.state.account_id);
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          null,
          null,
          null,
          null,
          null,
          "eu-west-1",
          {
            MaxRecords: 100
          }
        );
      }
    );
  }

  isAlarmSetUp = (alarm_action, ok_action) =>
    [
      "arn:aws:sns:eu-west-1:182726513909:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:815704857845:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:726054927466:snt-exsi-notifications"
    ]
      .map(
        sns_arn => alarm_action.includes(sns_arn) && ok_action.includes(sns_arn)
      )
      .find(value => !value) === undefined;

  renderCircle(status, alarm_action, ok_action, classes) {
    if (!this.isAlarmSetUp(alarm_action, ok_action)) {
      return (
        <Grid container spacing={8} className={classes.gridContainer}>
          <Grid item xs={3}>
            <Circle bgColor={circleColor.yellow} circleSize={20} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                "font-family": "sans-serif",
                "font-size": "14px",
                "font-weight": "bold",
                color: "#3c577a"
              }}
            >
              aucune configuration
            </Typography>
          </Grid>
        </Grid>
      );
    }

    switch (status) {
      case "ALARM":
        return <Circle bgColor={circleColor.red} circleSize={20} />;

      case "OK":
        return <Circle bgColor={circleColor.green} circleSize={20} />;

      default:
        return <Circle bgColor={circleColor.gray} circleSize={20} />;
    }
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
  }

  handleSurveillanceClickOpen(alarm_name) {
    this.setState({
      modalSurveillanceOpen: true,
      current_alarm: alarm_name
    });
  }

  handleSurveillanceClose() {
    this.setState({
      modalSurveillanceOpen: false
    });
  }

  handleHistoryClickOpen(alarm_name, history) {
    this.setState({
      modalHistoryOpen: true,
      current_alarm: alarm_name,
      current_history: history
    });
  }

  handleHistoryClose() {
    this.setState({
      modalHistoryOpen: false,
      current_history: undefined
    });
  }

  getHistory(account_id) {
    if (this.state.getHistoryInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        getHistoryInProgress: true
      },
      () => {
        getAccountAlarmLogs(account_id).then(response => {
          const alarmsWithHistory = this.state.alarms
            .map(alarm => ({
              alarm,
              history: response
                .filter(
                  item =>
                    item["AlarmName"] && item["AlarmName"] === alarm.AlarmName
                )
                .sort((a, b) => {
                  const hasAGoodTimeStamp = a["ts"] && a["ts"];
                  const hasBGoodTimeStamp = b["ts"] && b["ts"];
                  if (!hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return 0;
                  }

                  if (hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return -1;
                  }

                  if (!hasAGoodTimeStamp && hasBGoodTimeStamp) {
                    return 1;
                  }
                  return a["ts"] > b["ts"] ? -1 : 1;
                })
            }))
            .sort((a, b) => {
              const hasAHistory = a.history.length > 0;
              const hasBHistory = b.history.length > 0;

              if (!hasAHistory && !hasBHistory) {
                return 0;
              }

              if (hasAHistory && !hasBHistory) {
                return -1;
              }

              if (!hasAHistory && hasBHistory) {
                return 1;
              }

              return a.history[0]["ts"] > b.history[0]["ts"] ? -1 : 1;
            });
          this.setState({
            alarmsWithHistory: alarmsWithHistory,
            getHistoryInProgress: false,
            loading: false
          });
        });
      }
    );
  }

  renderSurveillance(alarm_name) {
    let channel_counter = 0;
    if (this.state.surveillance) {
      if ("Items" in this.state.surveillance) {
        for (let i = 0; i < this.state.surveillance["Items"].length; i++) {
          let regex = this.state.surveillance.Items[i]["AlarmName"].replace(
            "*",
            ".*"
          );
          if (alarm_name.match(regex)) {
            channel_counter++;
          }
        }
      }
    }
    if (channel_counter === 0) {
      return "Aucun canal";
    } else if (channel_counter === 1) {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canal
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canaux
        </Button>
      );
    }
  }

  renderHistory(alarm_name, history) {
    return history.length > 0 ? (
      <Button
        onClick={() => this.handleHistoryClickOpen(alarm_name, history)}
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          "border-radius": "30px",
          "padding-top": "0px",
          "padding-bottom": "0px",
          textTransform: "lowercase",
          background: "#edfaf7",
          color: "#3c577a"
        }}
      >
        {Moment(new Date(history[0].ts * 1000)).format(
          "ddd Do MMMM YYYY, HH:mm:ss"
        )}{" "}
        ({history.length} évenements)
      </Button>
    ) : (
      <Button
        disabled
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          textTransform: "lowercase",
          "background-color": "white",
          color: "#3c577a"
        }}
      >
        Aucun évenement
      </Button>
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account_id}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.applicationState) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else if (this.state.applicationState === "down") {
      return (
        <div>
          <Circle bgColor={circleColor.black} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement a été éteint, vous pouvez le rallumer avec l'action
            laf-env-controller-start.
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.alarms &&
      this.state.alarms.length > 0 &&
      this.state.alarmsWithHistory
    ) {
      return (
        <div>
          <Paper
            className={classes.root}
            style={{
              background: "#f7faf9",
              "margin-left": "20px",
              "margin-right": "20px",
              "margin-bottom": "20px",
              "box-shadow": "none"
            }}
          >
            <Button
              size="small"
              color="primary"
              style={{
                "font-family": "sans-serif",
                "font-size": "12px",
                "font-weight": "600",
                background: "#edfaf7",
                color: "#009999",
                "box-shadow": "none",
                "border-radius": "30px",
                border: "none",
                marginBottom: "30px"
              }}
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                    this.listResourceCompliance(this.state.account_id);
                    this.listComplianceSum(this.state.account_id);
                    this.listCriticalCompliancePatches(this.state.account_id);
                    this.listHighCompliancePatches(this.state.account_id);
                    this.listMediumCompliancePatches(this.state.account_id);
                    this.listLowCompliancePatches(this.state.account_id);
                    this.listInformationalCompliancePatches(
                      this.state.account_id
                    );
                    this.listUnspecifiedCompliancePatches(
                      this.state.account_id
                    );
                    this.setSecopsDonutData();
                  }
                );
              }}
            >
              <Cached /> Refresh ({this.state.seconds} s)
            </Button>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    border: "none",
                    height: "40px"
                  }}
                >
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "30px 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "10px",
                      "margin-left": "10px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>
                      {" "}
                      Pacths by severity{" "}
                    </span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 30px 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}></span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  style={{
                    border: "none",
                    height: "300px"
                  }}
                >
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 30px",
                      "margin-top": "0px",
                      "margin-bottom": "0px",
                      "margin-right": "10px",
                      "margin-left": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "padding-left": "200px",
                      "padding-right": "0px",
                      background: "#ffffff",
                      color: "#636599",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "left",
                      minWidth: "145px",
                      minHeight: "545px"
                    }}
                  >
                    <section
                      style={{
                        border: "none",
                        height: "30px"
                      }}
                    >
                      <p>
                        <span
                          style={{
                            border: "none",
                            height: "30px",
                            "margin-right": "10px",
                            background: "#ff4136",
                            color: "#ff4136",
                            opacity: 0.6
                          }}
                        >
                          {"__"}
                        </span>
                        <span>Critical non compliant Patchs :</span>
                        <span>{this.state.criticalNonCompliantCount}</span>
                      </p>
                    </section>
                    <section
                      style={{
                        border: "none",
                        height: "30px"
                      }}
                    >
                      <p>
                        <span
                          style={{
                            border: "none",
                            height: "30px",
                            "margin-right": "10px",
                            background: "#ff8800",
                            color: "#ff8800",
                            opacity: 0.6
                          }}
                        >
                          {"__"}
                        </span>
                        <span>High non compliant Patchs :</span>
                        <span>{this.state.highNonCompliantCount}</span>
                      </p>
                    </section>
                    <section
                      style={{
                        border: "none",
                        height: "30px"
                      }}
                    >
                      <p>
                        <span
                          style={{
                            border: "none",
                            height: "30px",
                            "margin-right": "10px",
                            background: "#f2fa05",
                            color: "#f2fa05",
                            opacity: 0.6
                          }}
                        >
                          {"__"}
                        </span>
                        <span>Medium non compliant Patchs :</span>
                        <span>{this.state.mediumNonCompliantCount}</span>
                      </p>
                    </section>
                    <section
                      style={{
                        border: "none",
                        height: "30px"
                      }}
                    >
                      <p>
                        <span
                          style={{
                            border: "none",
                            height: "30px",
                            "margin-right": "10px",
                            background: "#27acaa",
                            color: "#27acaa",
                            opacity: 0.6
                          }}
                        >
                          {"__"}
                        </span>
                        <span>Low non compliant Patchs :</span>
                        <span>{this.state.lowNonCompliantCount}</span>
                      </p>
                    </section>
                    <section
                      style={{
                        border: "none",
                        height: "30px"
                      }}
                    >
                      <p>
                        <span
                          style={{
                            border: "none",
                            height: "30px",
                            "margin-right": "10px",
                            background: "#57e188",
                            color: "#57e188",
                            opacity: 0.6
                          }}
                        >
                          {"__"}
                        </span>
                        <span>Informational non compliant Patchs :</span>
                        <span>{this.state.informationalNonCompliantCount}</span>
                      </p>
                    </section>
                    <section
                      style={{
                        border: "none",
                        height: "30px"
                      }}
                    >
                      <p>
                        <span
                          style={{
                            border: "none",
                            height: "30px",
                            "margin-right": "10px",
                            background: "#725ae8",
                            color: "#725ae8",
                            opacity: 0.6
                          }}
                        >
                          {"__"}
                        </span>
                        <span>Unspecified non compliant Patchs :</span>
                        <span>{this.state.unspecifiedNonCompliantCount}</span>
                      </p>
                    </section>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 30px 0",
                      textAlign: "center",
                      minWidth: "145px",
                      minHeight: "545px",
                      background: "#f5fcfa"
                    }}
                  >
                    <DonutChart
                      data={[
                        {
                          name: "crit",
                          value: this.state.criticalNonCompliantCount
                        },
                        {
                          name: "high",
                          value: this.state.highNonCompliantCount
                        },
                        {
                          name: "med",
                          value: this.state.mediumNonCompliantCount
                        },
                        { name: "low", value: this.state.lowNonCompliantCount },
                        {
                          name: "info",
                          value: this.state.informationalNonCompliantCount
                        },
                        {
                          name: "unsp",
                          value: this.state.unspecifiedNonCompliantCount
                        }
                      ]}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return "";
    }
  }
}

SecopsDashboardTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(SecopsDashboardTable));
