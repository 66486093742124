import React from "react";
import { Redirect } from "react-router";
import { withSnackbar } from "notistack";

import AWSResourcesDashboardExpansionPanel from "../Components/Panels/AWSResourcesDashboardExpansionPanel";
import AlarmDashboardTable from "../Components/Tables/AlarmDashboardTable";
import SimpleAlarmDashboardTable from "../Components/Tables/SimpleAlarmDashboardTable";
import AlarmChannelsTable from "../Components/Tables/AlarmChannelsTable";
import AddChannelDialog from "../Components/Dialogs/AddChannelDialog";
import AddChannelDialogGenMail from "../Components/Dialogs/AddChannelDialogGenMail";
import AddEmailDialog from "../Components/Dialogs/AddEmailDialog";
import LambdaActionsGrid from "../Components/Grids/LambdaActionsGrid";
import { CircularLoading, styles } from "../Styles/ComponentStyles";
import history from "../history";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
//import { ShapeInfo, Intersection } from "kld-intersections";

import moment from "moment";
import "moment/locale/fr";

import ETAT from "../Styles/Icons/Etat.png";
import ALARMES from "../Styles/Icons/Alarmes.png";
import CANAUX from "../Styles/Icons/CanauxAlertes.png";
import RESSOURCES from "../Styles/Icons/Ressources.png";
import ACTIONS from "../Styles/Icons/Actions.png";
import LOGS from "../Styles/Icons/Logs.png";
import ELK from "../Styles/Icons/elastic.png";
import GRT from "../Styles/Icons/GRTtrans.png";
import { blue, teal } from "@material-ui/core/colors";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  deleteChannel,
  retrieveChannelsWithParameters
} from "../Services/ChannelsService";
import { addLog } from "../Services/LogsService";
import HeadlineGeneral from "../Components/Headline/HeadlineGeneral";

const queryString = require("query-string");

//Set the fr language for moment
moment.locale("fr");

const TabsTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: teal["A400"]
    },
    secondary: {
      main: blue[500]
    }
  },
  background: {
    color: "#f7faf9"
  }
});

//const path1 = ShapeInfo.path("M2,295 C363,348 395,4 800,47");
//const path2 = ShapeInfo.path("M0,204 C377,367 504,22 797,225");
//const intersections = Intersection.intersect(path1, path2);

//intersections.points.forEach(console.log);

class LowLevelDashboard extends React.Component {
  constructor(props) {
    super(props);

    let parsed = queryString.parse(props.location.search);
    let app = parsed.app;
    let env = parsed.env;
    if (app) {
      props.handleApplicationChange(app, this.props);
    }
    if (env) {
      props.handleEnvironmentChange(env, this.props);
    }

    this.state = {
      ...props,
      app: app,
      env: env,
      account: null,
      noApps: this.props.noApps,
      tab_index: 1,
      getActualSurveillanceInProgress: false,
      modalAddChannelOpen: false,
      modalAddChannelGenMailOpen: false,
      modalAddEmailOpen: false,
      seconds: 0
    };

    this.getActualSurveillance = this.getActualSurveillance.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.handleAddChannelGenMailClose = this.handleAddChannelGenMailClose.bind(this); // eslint-disable-line
    this.handleAddEmailClose = this.handleAddEmailClose.bind(this);
    this.delete_channel_callback = this.delete_channel_callback.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  componentWillReceiveProps(props) {
    let temp_account = null;
    if (
      props.selected_application !== "None" &&
      props.selected_application !== null
    ) {
      if (props.environment != null) {
        temp_account = props.selected_application + "-" + props.environment;
        if (temp_account in props.account_list) {
          this.setState({
            app: props.selected_application
          });
        }
      }
    }
    this.setState({
      account: props.account_list[temp_account]
    });
    this.getActualSurveillance(props.account_list[temp_account]);
  }

  //To update state in response to a prop change. It is invoked just before rendering when new props or state are being received.
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      aws_account_resources: []
    });
  }

  handleAddEmailClickOpen() {
    this.setState({
      modalAddEmailOpen: true
    });
  }

  handleAddEmailClose() {
    this.setState({
      modalAddEmailOpen: false
    });
    this.componentWillReceiveProps(this.props);
  }

  handleAddChannelGenMailClickOpen() {
    this.setState({
      modalAddChannelGenMailOpen: true
    });
  }

  handleAddChannelGenMailClose() {
    this.setState({
      modalAddChannelGenMailOpen: false
    });
    this.componentWillReceiveProps(this.props);
  }

  handleAddChannelClickOpen() {
    this.setState({
      modalAddChannelOpen: true
    });
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
    this.componentWillReceiveProps(this.props);
  }

  getActualSurveillance(accountId) {
    if (this.state.getActualSurveillanceInProgress || !accountId) {
      return;
    }
    this.setState(
      {
        getActualSurveillanceInProgress: true
      },
      () => {
        retrieveChannelsWithParameters({ accountId }).then(response => {
          this.setState({
            surveillance: response,
            getActualSurveillanceInProgress: false
          });
        });
      }
    );
  }

  delete_channel_callback(account_id, alarm_name, uuid) {
    deleteChannel(account_id, uuid).then(() => {
      let action_description =
        "Suppression d'un channel sur le compte : " +
        account_id +
        " avec le nom " +
        alarm_name;
      let current_time = moment().format("dddd Do MMMM YYYY H: mm: ss");
      addLog(this.props.user_name, current_time, action_description);
      this.getActualSurveillance(this.state.account_id);
      this.componentWillReceiveProps(this.props);
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 5000
      });
    });
  }

  handleTabChange = (event, value) => {
    if (value === 0) {
      this.props.handleApplicationChange("", this.props);
      history.push({
        pathname: "/mes-applications"
      });
    }

    this.setState({
      tab_index: value
    });
  };

  renderResources() {
    return (
      <div
        style={{
          "overflow-x": "hidden",
          "overflow-y": "auto",
          height: "auto",
          "box-shadow": "none",
          background: "#f7faf9"
        }}
      >
        <AWSResourcesDashboardExpansionPanel
          account_list={this.props.account_list}
          account_id={this.state.account}
          user_name={this.props.user_name}
          userIdToken={this.state.userIdToken}
          mobile={this.state.mobile}
          env={this.state.env}
          app={this.state.app}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <LambdaActionsGrid
        account_id={this.state.account}
        userIdToken={this.state.userIdToken}
        app={this.state.app}
        env={this.state.env}
        userName={this.state.user_name}
      />
    );
  }

  renderHeadline = () => (
    <HeadlineGeneral selectedApplication={this.state.app} />
  );

  renderSimpleAlarmDashboard() {
    return (
      <SimpleAlarmDashboardTable
        account_id={this.state.account}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
      />
    );
  }

  renderAlarmDashboard() {
    return (
      <AlarmDashboardTable
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        app={this.state.app}
        env={this.state.env}
      />
    );
  }

  renderChannelsTable() {
    if (
      this.state.listAlarmInProgress ||
      this.state.getActualSurveillanceInProgress
    ) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return (
        <AlarmChannelsTable
          surveillance={this.state.surveillance}
          alarm_name={this.state.current_alarm}
          delete_channel_callback={this.delete_channel_callback}
          match_alarm={false}
        />
      );
    }
  }

  renderLogButton() {
    //let app = this.state.app;
    //let environment = this.state.env;
    return (
      <Button
        size="small"
        color="sceondary"
        style={{
          backgroundColor: "#5dc2b4",
          "box-shadow": "none",
          "border-radius": "20px",
          border: "none",
          "font-weight": "bold",
          color: "#ffffff",
          margin: "10px"
        }}
        onClick={() => {
          let location = "https://search.grtgaz.cloud/_dashboards/app/home#/";
          window.open(location, "_blank");
        }}
      >
        Consulter les logs sur OpenSearch
      </Button>
    );
  }

  renderCustomLogButton(domain, location) {
    //let app = this.state.app;
    //let environment = this.state.env;
    return (
      <Button
        size="small"
        color="secondary"
        style={{
          backgroundColor: "#5dc2b4",
          "box-shadow": "none",
          "border-radius": "20px",
          border: "none",
          "font-weight": "bold",
          color: "#ffffff",
          margin: "10px"
        }}
        onClick={() => {
          window.open(location, "_blank");
        }}
      >
        Consulter les logs sur Kibana ({domain})
      </Button>
    );
  }

  renderAddEmailDialog() {
    return (
      <AddEmailDialog
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddEmailOpen}
        onClose={this.handleAddEmailClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  renderAddChannelGenMailDialog() {
    return (
      <AddChannelDialogGenMail
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelGenMailOpen}
        onClose={this.handleAddChannelGenMailClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
        style={{
          "font-family": "sans-serif",
          "font-size": "12px",
          "font-weight": "600",
          color: "#009999",
          "box-shadow": "none",
          border: "none",
          "border-radius": "30px 30px 30px 30px !important"
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;

    if (this.state.noPermissions) {
      return (
        <div style={{ height: "100%" }}>
          <Grid item xs={12}>
            <Paper className={classes.paperAppSelect} elevation={0}>
              <Typography
                variant="h5"
                component="h2"
                style={{ paddingBottom: 40 }}
              >
                Vous ne devriez pas être ici.
              </Typography>
            </Paper>
          </Grid>
        </div>
      );
    }
    if (!this.state.noApps) {
      return (
        <div className={classes.root}>
          <MuiThemeProvider theme={TabsTheme}>
            <AppBar
              position="static"
              className={classes.dashboardAppBar}
              elevation={0}
            >
              <Tabs
                value={this.state.tab_index}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    height: "2px",
                    "border-radius": "50px"
                  }
                }}
              >
                <Tab
                  icon={<ArrowBackIcon />}
                  className={classes.dashboardTabArrow}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={ETAT}
                        alt={"APP-icon"}
                        style={{
                          height: "25px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      État
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={ALARMES}
                        alt={"APP-icon"}
                        style={{
                          height: "25px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Alarmes
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={CANAUX}
                        alt={"APP-icon"}
                        style={{
                          height: "25px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Canaux d'Alerte
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={RESSOURCES}
                        alt={"APP-icon"}
                        style={{
                          height: "30px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Ressources
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={ACTIONS}
                        alt={"APP-icon"}
                        style={{
                          height: "35px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Actions
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={LOGS}
                        alt={"APP-icon"}
                        style={{
                          height: "28px",
                          color: "#08bfb0",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Logs
                    </div>
                  }
                  className={classes.dashboardTab}
                />
              </Tabs>
            </AppBar>
          </MuiThemeProvider>
          {this.state.tab_index === 1 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                height: "auto",
                "box-shadow": "none",
                marginTop: "0px",
                background: "#f7faf9"
              }}
            >
              <Grid item xs={12} style={{ background: "#f7faf9" }}>
                <Paper
                  className={classes.paperAppSelectAlarmDashboard}
                  elevation={0}
                >
                  {/* <Typography variant="h5" component="h2" style={{paddingBottom:40}}>
                    L'état actuel pour <b>{this.state.app}/{this.state.env}</b>
                  </Typography> */}
                  {this.renderSimpleAlarmDashboard()}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  <section
                    style={{
                      "text-transform": "uppercase",
                      "font-family": "sans-serif",
                      "font-size": "14px",
                      "font-weight": "400",
                      "text-align": "center",
                      "min-height": "100%",
                      paddingTop: 40,
                      paddingBottom: 40,
                      color: "#009999"
                    }}
                  >
                    {this.renderHeadline()}
                  </section>
                  <Typography
                    variant="body1"
                    style={{
                      paddingBottom: 40,
                      "text-transform": "lowercase",
                      "font-size": "12px",
                      "font-weight": "bold",
                      "text-align": "center",
                      color: "#009999"
                    }}
                  >
                    Un problème ? Vérifier l'état des services AWS
                    <Button
                      size="small"
                      color="primary"
                      style={{
                        "font-family": "sans-serif",
                        "font-size": "10px",
                        "font-weight": "600",
                        background: "#5dc2b4",
                        marginLeft: "10px",
                        color: "white",
                        "box-shadow": "none",
                        "border-radius": "30px",
                        border: "none"
                      }}
                      onClick={() => {
                        let location =
                          "https://phd.aws.amazon.com/phd/home#/dashboard/open-issues";
                        window.open(location, "_blank");
                      }}
                    >
                      Dashboard AWS
                    </Button>
                  </Typography>
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 2 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "margin-right": "0px",
                "margin-left": "0px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  {this.renderAlarmDashboard()}
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 3 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "margin-right": "0px",
                "margin-left": "0px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  <Typography
                    variant="h8"
                    style={{
                      "border-radius": "30px",
                      "margin-top": "10px",
                      "padding-top": "10px",
                      "margin-bottom": "10px",
                      "padding-bottom": "10px",
                      "margin-right": "450px",
                      "margin-left": "450px",
                      background: "#00b9e3",
                      color: "white",
                      textTransform: "uppercase",
                      fontFamily: "sans-serif",
                      "font-weight": "bold"
                    }}
                  >
                    Les canaux d'alerte activés
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    style={{
                      "font-family": "sans-serif",
                      "font-size": "12px",
                      "font-weight": "600",
                      background: "#edfaf7",
                      color: "#009999",
                      "box-shadow": "none",
                      "border-radius": "30px",
                      border: "none"
                    }}
                    onClick={() => {
                      this.setState(
                        {
                          seconds: 0
                        },
                        () => {
                          this.setState(
                            {
                              seconds: 0
                            },
                            () => {
                              this.setState({
                                getActualSurveillanceInProgress: true
                              });
                              this.getActualSurveillance(this.state.account);
                            }
                          );
                        }
                      );
                    }}
                  >
                    <Cached /> Rafraîchir ({this.state.seconds} s)
                  </Button>
                  <MuiThemeProvider theme={TabsTheme}>
                    {this.renderChannelsTable()}
                    {this.renderAddChannelDialog()}
                    {this.renderAddChannelGenMailDialog()}
                    {this.renderAddEmailDialog()}
                    <Button
                      onClick={() => this.handleAddChannelClickOpen()}
                      color="secondary"
                      key="add"
                      size="medium"
                      variant="contained"
                      style={{
                        backgroundColor: "secondary",
                        "box-shadow": "none",
                        "border-radius": "20px",
                        border: "none",
                        "font-weight": "bold",
                        color: "#ffffff",
                        margin: "10px"
                      }}
                    >
                      Ajouter un canal
                    </Button>
                    <Button
                      onClick={() => this.handleAddChannelGenMailClickOpen()}
                      color="secondary"
                      key="add"
                      variant="contained"
                      style={{
                        backgroundColor: "secondary",
                        "box-shadow": "none",
                        "border-radius": "20px",
                        border: "none",
                        "font-weight": "bold",
                        color: "#ffffff",
                        margin: "10px"
                      }}
                    >
                      Canal proxy
                    </Button>
                    <Button
                      onClick={() => this.handleAddEmailClickOpen()}
                      color="secondary"
                      key="add"
                      variant="contained"
                      style={{
                        backgroundColor: "secondary",
                        "box-shadow": "none",
                        "border-radius": "20px",
                        border: "none",
                        "font-weight": "bold",
                        color: "#ffffff",
                        margin: "10px"
                      }}
                    >
                      email sync
                    </Button>
                  </MuiThemeProvider>
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 4 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "margin-right": "0px",
                "margin-left": "0px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  {this.renderResources()}
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 5 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "hidden",
                "margin-right": "0px",
                "margin-left": "0px",
                "padding-right": "40px",
                "padding-left": "40px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  {this.renderActions()}
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 6 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "margin-right": "0px",
                "margin-left": "0px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12} style={{ background: "#f7faf9" }}>
                <Typography
                  variant="h8"
                  style={{
                    "border-radius": "30px",
                    "margin-top": "60px",
                    "padding-top": "10px",
                    "margin-bottom": "0px",
                    "padding-bottom": "10px",
                    "margin-right": "450px",
                    "margin-left": "450px",
                    background: "#00b9e3",
                    color: "white",
                    textTransform: "uppercase",
                    fontFamily: "sans-serif",
                    "font-weight": "bold"
                  }}
                >
                  Les Logs OpenSearch
                </Typography>
                <Paper
                  className={classes.paperAppSelectFirstLogs}
                  elevation={0}
                >
                  <img
                    src={ELK}
                    alt={"ELK-Logs"}
                    style={{
                      height: "32px",
                      width: "32px",
                      "margin-right": "0px",
                      "padding-left": "0px",
                      "vertical-align": "-60%"
                    }}
                  />
                  {this.renderLogButton()}
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
                {/*
                <Paper className={classes.paperAppSelectLogs} elevation={0}>
                  <img
                    src={ELK}
                    alt={"ELK-Logs"}
                    style={{
                      height: "32px",
                      width: "32px",
                      "margin-right": "0px",
                      "padding-left": "0px",
                      "vertical-align": "-60%"
                    }}
                  />
                  {this.renderCustomLogButton(
                    "OnePoint",
                    "https://search-es-logs-onepoint-gdnmcsdgo6hpvos63g7sn4dp4u.eu-west-1.es.amazonaws.com/_plugin/kibana/"
                  )}
                </Paper>
                <Paper className={classes.paperAppSelectLogs} elevation={0}>
                  <img
                    src={ELK}
                    alt={"ELK-Logs"}
                    style={{
                      height: "32px",
                      width: "32px",
                      "margin-right": "0px",
                      "padding-left": "0px",
                      "vertical-align": "-60%"
                    }}
                  />
                  {this.renderCustomLogButton(
                    "Accenture",
                    "https://search-es-logs-accenture-ju7bckuu2ymfxotg3mr5vb2bwi.eu-west-1.es.amazonaws.com/_plugin/kibana/"
                  )}
                </Paper>
                <Paper className={classes.paperAppSelectLastLogs} elevation={0}>
                  <img
                    src={ELK}
                    alt={"ELK-Logs"}
                    style={{
                      height: "32px",
                      width: "32px",
                      "margin-right": "0px",
                      "padding-left": "0px",
                      "vertical-align": "-60%"
                    }}
                  />
                  {this.renderCustomLogButton(
                    "Pkit",
                    "https://search-es-logs-pkit-drwzwnkpe7atstngrn5ligqffq.eu-west-1.es.amazonaws.com/_plugin/kibana/"
                  )}
                </Paper>
                */}
              </Grid>
            </div>
          )}
        </div>
      );
    } else {
      return <Redirect to="/alerte-apps" />;
    }
  }
}

export default withRouter(withSnackbar(withStyles(styles)(LowLevelDashboard)));
