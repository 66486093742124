import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {
  actionsStyles,
  CircularLoading,
  styles
} from "../../Styles/ComponentStyles";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import "moment/locale/fr";
import ReactJson from "react-json-view";

//Set the fr language for moment
moment.locale("fr");

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

class AlarmChannelsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      history: undefined,
      rows: [],
      page: 0,
      rowsPerPage: 5,
      loadData: true,
      searchInputValue: ""
    };
  }

  componentDidMount() {
    this.setState({
      history: this.props.history.slice(0, 200)
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      page: 0
    });
  };

  handleOnChangeInputSearch = event => {
    this.setState({
      searchInputValue: event.target.value,
      loadData: true
    });
  };
  handleOnClickSearch = event => {
    this.createData();
  };

  componentDidUpdate() {
    if (this.state.loadData === true) {
      this.createData();
    }
  }

  createData() {
    if (this.state.history) {
      let db_array_items = [];
      if (this.state.searchInputValue.length === 0) {
        this.state.history.forEach(item => {
          let itemToPush = {
            Horodatage: moment(item.ts * 1000).format(
              "ddd Do MMMM YYYY, HH:mm:ss"
            ),
            Etat: item.State
          };
          if (item.RawMessage) {
            itemToPush["Message"] = item.RawMessage;
          }
          db_array_items.push(itemToPush);
          this.setState({
            rows: db_array_items.sort((a, b) =>
              moment(b.Horodatage, "ddd Do MMMM YYYY, HH:mm:ss").format("x") >
              moment(a.Horodatage, "ddd Do MMMM YYYY, HH: mm: ss").format("x")
                ? 1
                : -1
            ),
            loadData: false
          });
        });
        return db_array_items;
      } else if (this.state.searchInputValue.length > 0) {
        this.state.history.forEach(item => {
          if (
            (item.ts &&
              moment(item.ts * 1000)
                .format("ddd Do MMMM YYYY, HH:mm:ss")
                .toLowerCase()
                .includes(this.state.searchInputValue.toLowerCase())) ||
            (item.State &&
              item.State.toLowerCase().includes(
                this.state.searchInputValue.toLowerCase()
              ))
          ) {
            let itemToPush = {
              Horodatage: moment(item.ts * 1000).format(
                "ddd Do MMMM YYYY, HH:mm:ss"
              ),
              Etat: item.State
            };
            if (item.RawMessage) {
              itemToPush["Message"] = item.RawMessage;
            }
            db_array_items.push(itemToPush);
            this.setState({
              rows: db_array_items.sort((a, b) =>
                moment(b.Horodatage, "ddd Do MMMM YYYY, HH:mm:ss").format("x") >
                moment(a.Horodatage, "ddd Do MMMM YYYY, HH: mm: ss").format("x")
                  ? 1
                  : -1
              ),
              loadData: false
            });
          }
        });
        return db_array_items;
      }
    }
  }

  render() {
    const { classes } = this.state;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    if (this.state.history !== null) {
      return (
        <Paper className={classes.rootPaginate}>
          <div className={classes.tableWrapperPaginate}>
            <Table className={classes.tablePaginate}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "30px 0 0 0",
                      "margin-top": "0px",
                      "margin-bottom": "0px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#139aa1",
                      "font-weight": "bold",
                      "font-size": "14px",
                      maxWidth: "60px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    Horodatage
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "0px",
                      "margin-bottom": "0px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#139aa1",
                      "font-weight": "bold",
                      "font-size": "14px",
                      maxWidth: "60px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    Nouvel état
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 30px 0 0",
                      "margin-top": "0px",
                      "margin-bottom": "0px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#139aa1",
                      "font-weight": "bold",
                      "font-size": "14px",
                      maxWidth: "60px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    Message
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row.Horodatage}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {row.Horodatage}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {row.Etat}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {row.Message && (
                          <ReactJson
                            collapsed={true}
                            src={JSON.parse(row.Message)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      style={{
                        border: "none",
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "font-weight": "bold",
                        background: "white",
                        color: "#3c577a"
                      }}
                    >
                      aucun historique
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      "font-family": "sans-serif",
                      "font-size": "14px",
                      "font-weight": "bold",
                      background: "white",
                      color: "#3c577a"
                    }}
                  >
                    <InputBase
                      placeholder="Recherche…"
                      style={{ marginTop: 15, marginLeft: 50 }}
                      onChange={this.handleOnChangeInputSearch}
                    />
                  </TableCell>
                  <TablePagination
                    rowsPerPageOptions={[] /*[5, 10, 25, 50]*/}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Lignes par page"
                    labelDisplayedRows={({ from, to, count }) =>
                      `Logs de ${from} à ${to} sur un total de ${count} enregistrements`
                    }
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                    style={{ paddingRight: "20%" }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      );
    } else {
      return <CircularLoading></CircularLoading>;
    }
  }
}

export default withStyles(styles)(AlarmChannelsTable);
