import React from "react";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

const dev = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://1glv0xan10.execute-api.eu-west-1.amazonaws.com/deployment"
  },
  apiGatewayV2: {
    REGION: "eu-west-1",
    URL: "https://bwhos2w5d7.execute-api.eu-west-1.amazonaws.com/deployment"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_RRGPBoW4X",
    WEB_CLIENT_ID: "8v0vufp4guk534gt40bm11390",
    USER_POOL_WEB_CLIENT_ID: "8v0vufp4guk534gt40bm11390",
    OAUTH_DOMAIN: "exsi-dev.auth.eu-west-1.amazoncognito.com",
    OAUTH_REDIRECT: "http://localhost:3000"
  }
};

const stg = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://zszwb1xfm5.execute-api.eu-west-1.amazonaws.com/deployment"
  },
  apiGatewayV2: {
    REGION: "eu-west-1",
    URL: "https://8t277gaiia.execute-api.eu-west-1.amazonaws.com/deployment"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_WpxJRMc8M",
    WEB_CLIENT_ID: "3i5e5s881mfsp3pikltdrhrbni",
    USER_POOL_WEB_CLIENT_ID: "3i5e5s881mfsp3pikltdrhrbni",
    OAUTH_DOMAIN: "exsi-stg.auth.eu-west-1.amazoncognito.com",
    OAUTH_REDIRECT: "https://exsi-stg.cloudgaz.com"
  }
};

const prd = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://ef31m0bz7h.execute-api.eu-west-1.amazonaws.com/deployment"
  },
  apiGatewayV2: {
    REGION: "eu-west-1",
    URL: "https://xl8ds0el07.execute-api.eu-west-1.amazonaws.com/deployment"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_IpzkhN1tr",
    WEB_CLIENT_ID: "353v9adab29ecbt58qr9k6f5dn",
    USER_POOL_WEB_CLIENT_ID: "353v9adab29ecbt58qr9k6f5dn",
    OAUTH_DOMAIN: "exsi-prd.auth.eu-west-1.amazoncognito.com",
    OAUTH_REDIRECT: "https://exsi.cloudgaz.com"
  }
};

const translation = {
  translation: {
    issue: "incident",
    accountNotification: "notification",
    open: "en cours",
    closed: "terminé",
    upcoming: "à venir",
    TimeBegin: "début",
    TimeEnd: "fin",
    Method: "Méthode",
    Channel: "Canal",
    AccountID: "Compte",
    AlarmName: "Alarme",
    UserID: "Utilisateur",
    OnCall: "Astreinte"
  }
};

const generic_config = {
  aws_service_status: ["eu-west-1", "global"],
  user_management_admins: ["2701CQ", "0106VK"],
  calls_alias: {
    rds: {
      BOTO_CALL: "describe_db_snapshots",
      RESPONSE_ROOT_ELEMENT: "DBSnapshots",
      RESPONSE_ELEMENTS: {
        Identifiant: "DBSnapshotIdentifier",
        "Version Moteur": "EngineVersion",
        Date: "SnapshotCreateTime",
        État: "Status"
      }
    },
    ec2: {
      BOTO_CALL: "describe_instances",
      RESPONSE_ROOT_ELEMENT: "Reservations",
      RESPONSE_ELEMENTS: {
        Identifiant: ["Instances", 0, "InstanceId"],
        Nom: ["Instances", 0, "Tags", "~~Name"],
        "Type d'instance": ["Instances", 0, "InstanceType"],
        État: ["Instances", 0, "State", "Name"]
      }
    },
    sqs: {
      BOTO_CALL: "list_queues",
      RESPONSE_ROOT_ELEMENT: "QueueUrls",
      RESPONSE_ELEMENTS: {
        "Queue URL": ["QueueUrls"]
      }
    },
    s3: {
      BOTO_CALL: "list_buckets",
      RESPONSE_ROOT_ELEMENT: "Buckets",
      RESPONSE_ELEMENTS: {
        "Nom du bucket": "Name",
        "Date de création": "CreationDate"
      }
    },
    lambda: {
      BOTO_CALL: "list_functions",
      RESPONSE_ROOT_ELEMENT: "Functions",
      RESPONSE_ELEMENTS: {
        Nom: "FunctionName",
        Langage: "Runtime",
        "Dernière modification": "LastModified"
      }
    },
    autoscaling: {
      BOTO_CALL: "describe_auto_scaling_groups",
      RESPONSE_ROOT_ELEMENT: "AutoScalingGroups",
      RESPONSE_ELEMENTS: {
        Nom: "AutoScalingGroupName",
        "Taille mini": "MinSize",
        "Taille souhaitée": "DesiredCapacity",
        "Taille max": "MaxSize"
      }
    },
    ecs: {
      BOTO_CALL: "list_task_definition_families",
      RESPONSE_ROOT_ELEMENT: "definitions",
      RESPONSE_RECALL: "describe_task_definition",
      RESPONSE_ELEMENTS: {
        Nom: "family",
        "EC2/Fargate": ["requiresCompatibilities", 0],
        "# de révision": "revision",
        Status: "status",
        "# de tâches lancées": "task_number",
        "tâches lancées": "running_task"
      }
    }
  },
  calls: {
    rds: {
      BOTO_CALL: "describe_db_instances",
      RESPONSE_ROOT_ELEMENT: "DBInstances",
      RESPONSE_ELEMENTS: {
        Identifiant: "DBInstanceIdentifier",
        "Moteur BDD": "Engine",
        "Type d'instance": "DBInstanceClass",
        État: "DBInstanceStatus"
      }
    },
    ec2: {
      BOTO_CALL: "describe_instances",
      RESPONSE_ROOT_ELEMENT: "Reservations",
      RESPONSE_ELEMENTS: {
        Identifiant: ["Instances", 0, "InstanceId"],
        Nom: ["Instances", 0, "Tags", "~~Name"],
        "Type d'instance": ["Instances", 0, "InstanceType"],
        État: ["Instances", 0, "State", "Name"]
      }
    },
    sqs: {
      BOTO_CALL: "list_queues",
      RESPONSE_ROOT_ELEMENT: "QueueUrls",
      RESPONSE_ELEMENTS: {
        "Queue URL": ["QueueUrls"]
      }
    },
    s3: {
      BOTO_CALL: "list_buckets",
      RESPONSE_ROOT_ELEMENT: "Buckets",
      RESPONSE_ELEMENTS: {
        "Nom du bucket": "Name",
        "Date de création": "CreationDate"
      }
    },
    lambda: {
      BOTO_CALL: "list_functions",
      RESPONSE_ROOT_ELEMENT: "Functions",
      RESPONSE_ELEMENTS: {
        Nom: "FunctionName",
        Langage: "Runtime",
        "Dernière modification": "LastModified"
      }
    },
    autoscaling: {
      BOTO_CALL: "describe_auto_scaling_groups",
      RESPONSE_ROOT_ELEMENT: "AutoScalingGroups",
      RESPONSE_ELEMENTS: {
        Nom: "AutoScalingGroupName",
        "Taille mini": "MinSize",
        "Taille souhaitée": "DesiredCapacity",
        "Taille max": "MaxSize"
      }
    },
    ecs: {
      BOTO_CALL: "list_task_definition_families",
      RESPONSE_ROOT_ELEMENT: "definitions",
      RESPONSE_RECALL: "describe_task_definition",
      RESPONSE_ELEMENTS: {
        Nom: "family",
        "EC2/Fargate": ["requiresCompatibilities", 0],
        "# de révision": "revision",
        Status: "status",
        "# de tâches lancées": "task_number",
        "tâches lancées": "running_task"
      }
    }
  },
  headlines: {
    mciz: (
      <List>
        <ListItem>
          Runbook MCI:
          <Button
            size="small"
            color="primary"
            onClick={() => {
              let location =
                "https://phabricator.cloudgaz.com/w/applications/mci/#runbook";
              window.open(location, "_blank");
            }}
          >
            Phabricator
          </Button>
        </ListItem>
      </List>
    ),
    exsi: (
      <List>
        <ListItem>L'application ExSI est très autonome</ListItem>
      </List>
    )
  }
};

const aws_resource_details = {
  AWS_SERVICE_NAMES_DESCRIPTION_ALIAS: [
    {
      SERVICE_NAME: "RDS",
      SERVICE_DESCRIPTION: "Snapshots"
    }
  ],
  AWS_SERVICE_NAMES_DESCRIPTION: [
    {
      SERVICE_NAME: "EC2",
      SERVICE_DESCRIPTION: "Machines virtuelles"
    },
    {
      SERVICE_NAME: "ECS",
      SERVICE_DESCRIPTION: "Containeurs et tâches"
    },
    {
      SERVICE_NAME: "CloudWatch",
      SERVICE_DESCRIPTION: "Surveillance et monitoring des resources cloud"
    },
    {
      SERVICE_NAME: "S3",
      SERVICE_DESCRIPTION: "Stockage évolutif dans le cloud"
    },
    {
      SERVICE_NAME: "RDS",
      SERVICE_DESCRIPTION: "Bases de données"
    },
    {
      SERVICE_NAME: "Lambda",
      SERVICE_DESCRIPTION:
        "Exécuter du code sans avoir à mettre en service ou gérer des serveurs (Serverless)"
    },
    {
      SERVICE_NAME: "IAM",
      SERVICE_DESCRIPTION: "Gestion d'accès aux resources cloud"
    },
    {
      SERVICE_NAME: "ACM",
      SERVICE_DESCRIPTION:
        "Allocation, gestion et déploiement de certificats SSL / TLS"
    },
    {
      SERVICE_NAME: "AutoScaling",
      SERVICE_DESCRIPTION:
        "Mettre à l'échelle plusieurs ressources pour répondre à la demande"
    },
    {
      SERVICE_NAME: "ElasticLoadBalancing",
      SERVICE_DESCRIPTION:
        "Distribution de trafic entrant vers plusieurs cibles"
    },
    {
      SERVICE_NAME: "ElasticLoadBalancingV2",
      SERVICE_DESCRIPTION:
        "Distribution de trafic entrant vers plusieurs cibles"
    },
    {
      SERVICE_NAME: "SSM",
      SERVICE_DESCRIPTION:
        "Bénéficier d'un maximum de visibilité et de contrôle sur votre infrastructure sur AWS."
    },
    {
      SERVICE_NAME: "Config",
      SERVICE_DESCRIPTION:
        "Suivre l'inventaire des ressources et des modifications"
    },
    {
      SERVICE_NAME: "CloudFormation",
      SERVICE_DESCRIPTION:
        "Faire de l'Infrastructure As Code avec des templates JSON ou YAML"
    },
    {
      SERVICE_NAME: "CloudTrail",
      SERVICE_DESCRIPTION:
        "Suivre l'activité des utilisateurs et l'utilisation des API"
    },
    {
      SERVICE_NAME: "CodePipeline",
      SERVICE_DESCRIPTION: "Faire des Pipelines CI/CD"
    },
    {
      SERVICE_NAME: "CodeBuild",
      SERVICE_DESCRIPTION: "Création et test de code"
    },
    {
      SERVICE_NAME: "CodeDeploy",
      SERVICE_DESCRIPTION: "Automatisez le déploiement de code"
    },
    {
      SERVICE_NAME: "CodeCommit",
      SERVICE_DESCRIPTION: "Stocker du code dans des repositories Git privés"
    },
    {
      SERVICE_NAME: "DynamoDB",
      SERVICE_DESCRIPTION: "Bases de données NoSQL gérées"
    },
    {
      SERVICE_NAME: "ElasticBeanstalk",
      SERVICE_DESCRIPTION:
        "Déployer et mettre à l'échelle des applications et services Web"
    },
    {
      SERVICE_NAME: "ApiGateway",
      SERVICE_DESCRIPTION: "Création, déploiement et gestion des API"
    },
    {
      SERVICE_NAME: "Cognito",
      SERVICE_DESCRIPTION:
        "Inscription, connexion et contrôle d'accès simples et sécurisés pour les utilisateurs"
    },
    {
      SERVICE_NAME: "Elasticsearch",
      SERVICE_DESCRIPTION:
        "Exécution et dimensionnement de clusters Elasticsearch"
    },
    {
      SERVICE_NAME: "Inspector",
      SERVICE_DESCRIPTION: "Analyse de la sécurité des applications"
    },
    {
      SERVICE_NAME: "Kinesis",
      SERVICE_DESCRIPTION: "Capturer, traiter et stocker des flux de données"
    },
    {
      SERVICE_NAME: "KinesisFirehose",
      SERVICE_DESCRIPTION:
        "Charger des flux de données dans le stockage de données AWS"
    },
    {
      SERVICE_NAME: "KinesisAnalytics",
      SERVICE_DESCRIPTION: "Analyser des flux de données avec SQL ou Java"
    },
    {
      SERVICE_NAME: "KMS",
      SERVICE_DESCRIPTION:
        "Création et contrôle supervisés des clés de chiffrement"
    },
    {
      SERVICE_NAME: "Route53",
      SERVICE_DESCRIPTION: "Système de noms de domaine scalable"
    },
    {
      SERVICE_NAME: "SNS",
      SERVICE_DESCRIPTION: "Service managé de messagerie pub/sub"
    },
    {
      SERVICE_NAME: "SES",
      SERVICE_DESCRIPTION: "service managé d'envoi et de récetpion d'e-mails"
    },
    {
      SERVICE_NAME: "SQS",
      SERVICE_DESCRIPTION: "Service managé de file d'attente de messagerie"
    },
    {
      SERVICE_NAME: "WAF",
      SERVICE_DESCRIPTION: "Pare-feu d'application Web"
    },
    {
      SERVICE_NAME: "CloudFront",
      SERVICE_DESCRIPTION:
        "Réseau de diffusion de contenu (CDN) rapide fortement sécurisé et programmable"
    }
  ]
};

const actionStopStartEc2 = {
  tagName: "StopStart"
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case "PRD":
    config = prd;
    break;
  case "STG":
    config = stg;
    break;
  case "DEV":
    config = dev;
    break;
  default:
    config = prd;
    break;
}

export default {
  ...config,
  ...generic_config,
  ...translation,
  ...aws_resource_details,
  actionStopStartEc2
};
