/* eslint-disable */
import API from "@aws-amplify/api/lib";

export const retrieveAdmins = async requestedPage => {
  const apiResult = await API.get("api-exsi-backend-v2", "/admin", {
    queryStringParameters: {
      page: requestedPage
    }
  });
  return apiResult.split(",");
};

export const regenerateMfaCode = async (userEmail) => {
  try {
    const response = await API.post("api-exsi-backend-v2", "/admin/regenerate", {
      body: { phoneNumber: '+33616693994', userEmail: userEmail, action: 'create' }
    });
    return response; // La réponse peut inclure un message de succès ou d'erreur
  } catch (error) {
    console.error("Error regenerating MFA code: ", error);
    throw error; // Vous pouvez choisir de renvoyer une erreur personnalisée
  }
};

export const verifyMfaCode = async (userEmail, code) => {
  try {
    const response = await API.post("api-exsi-backend-v2", "/verify", {
      body: { userEmail: userEmail.userEmail, code: code, action: 'verify' }
    });
    return response; // La réponse doit indiquer si le code est valide ou non
  } catch (error) {
    console.error("Error verifying MFA code: ", error);
    throw error;
  }
};

export const getUserEmailByUsername = async (username) => {
  try {
    const response = await API.get("api-exsi-backend-v2", "/admin" + username, { 
      params: { user_name: username },
      headers: { 'Access-Control-Allow-Origin': '*' }
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de l'adresse e-mail: ", error);
    throw error;
  }
};
