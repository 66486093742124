import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { retryPipelineStageExecution } from "../../Services/PipelinesService";
import history from "../../history";

const PipelineStageFailedRetry = ({
  open,
  onClose,
  pipelineName,
  stageName,
  pipelineExecutionId,
  userName,
  revisionId,
  app,
  accountId
}) => {
  const [isValidateButtonDisabled, setIsValidateButtonDisabled] = useState(
    true
  );
  const [approbationMessage] = useState(
    "Je valide le relancement de la version " + revisionId + " sur " + stageName
  );

  const handleTextFieldChangeApprobation = event => {
    event.target.value === approbationMessage
      ? setIsValidateButtonDisabled(false)
      : setIsValidateButtonDisabled(true);
  };

  const validateRetryPipeline = async () => {
    await retryPipelineStageExecution(
      pipelineName,
      stageName,
      pipelineExecutionId,
      userName,
      accountId
    );
    history.push({
      pathname: "/livraison/" + app
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 30,
          fontFamily: "sans-serif",
          fontSize: 12,
          fontWeight: 600,
          color: "#009999"
        }
      }}
    >
      <DialogTitle
        style={{
          backgroundColor: "#b92020",
          textAlign: "center",
          marginBottom: "10px",
          "text-transform": "uppercase",
          "font-size": "12px",
          "font-weight": "bold"
        }}
      >
        <div style={{ color: "white" }}>Réaliser une nouvelle tentative</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            textAlign: "left",
            color: "black"
          }}
        >
          <br />
          Si vous validez, la livraison de la version <b>{revisionId}</b> sera
          relancée depuis le début sur <b>{stageName}</b>
        </DialogContentText>
        <br />
        <TextField
          autoFocus
          margin="dense"
          id="approval"
          label={"Message d'approbation: " + approbationMessage}
          type="text"
          onChange={event => handleTextFieldChangeApprobation(event)}
          fullWidth
        />
        <br />
        <br />
        <DialogActions>
          <Button onClick={onClose} variant={"contained"}>
            <div
              style={{
                color: "white"
              }}
            >
              Annuler
            </div>
          </Button>
          <Button
            onClick={validateRetryPipeline}
            variant={"contained"}
            style={{
              backgroundColor: isValidateButtonDisabled ? "gray" : "#32CD32"
            }}
            disabled={isValidateButtonDisabled}
          >
            <div
              style={{
                color: "white"
              }}
            >
              Valider
            </div>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PipelineStageFailedRetry;
