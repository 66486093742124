import React from "react";
import { Redirect } from "react-router";
import { withSnackbar } from "notistack";

import ComplianceDashboardTable from "../Components/Tables/ComplianceDashboardTable";
import InspectorDashboardTable from "../Components/Tables/InspectorDashboardTable";
import SecopsDashboardTable from "../Components/Tables/SecopsDashboardTable";
import SimpleAlarmDashboardTable from "../Components/Tables/SimpleAlarmDashboardTable";
import { styles } from "../Styles/ComponentStyles";
import history from "../history";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
//import { ShapeInfo, Intersection } from "kld-intersections";

import moment from "moment";
import "moment/locale/fr";

import GRT from "../Styles/Icons/GRTtrans.png";
import PATCHS from "../Styles/Icons/patchs.png";
import MALWARE from "../Styles/Icons/malware.png";
import SHIELD from "../Styles/Icons/shield.png";
import { blue, teal } from "@material-ui/core/colors";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import HeadlineGeneral from "../Components/Headline/HeadlineGeneral";

const queryString = require("query-string");

//Set the fr language for moment
moment.locale("fr");

const TabsTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: teal["A400"]
    },
    secondary: {
      main: blue[500]
    }
  },
  background: {
    color: "#f7faf9"
  }
});

//const path1 = ShapeInfo.path("M2,295 C363,348 395,4 800,47");
//const path2 = ShapeInfo.path("M0,204 C377,367 504,22 797,225");
//const intersections = Intersection.intersect(path1, path2);

//intersections.points.forEach(console.log);

class ComplianceDashboard extends React.Component {
  constructor(props) {
    super(props);

    let parsed = queryString.parse(props.location.search);
    let app = parsed.app;
    let env = parsed.env;
    if (app) {
      props.handleApplicationChange(app, this.props);
    }
    if (env) {
      props.handleEnvironmentChange(env, this.props);
    }

    this.state = {
      ...props,
      app: app,
      env: env,
      account: null,
      noApps: this.props.noApps,
      tab_index: 1,
      getActualSurveillanceInProgress: false,
      seconds: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  componentWillReceiveProps(props) {
    let temp_account = null;
    if (
      props.selected_application !== "None" &&
      props.selected_application !== null
    ) {
      if (props.environment != null) {
        temp_account = props.selected_application + "-" + props.environment;
        if (temp_account in props.account_list) {
          this.setState({
            app: props.selected_application
          });
        }
      }
    }
    this.setState({
      account: props.account_list[temp_account]
    });
    //this.getActualSurveillance(props.account_list[temp_account]); <- replace with compliance
  }

  //To update state in response to a prop change. It is invoked just before rendering when new props or state are being received.
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      aws_account_resources: []
    });
  }

  handleTabChange = (event, value) => {
    if (value === 0) {
      this.props.handleApplicationChange("", this.props);
      history.push({
        pathname: "/mes-applications"
      });
    }

    this.setState({
      tab_index: value
    });
  };

  renderHeadline = () => (
    <HeadlineGeneral selectedApplication={this.state.app} />
  );

  renderSimpleAlarmDashboard() {
    return (
      <SimpleAlarmDashboardTable
        account_id={this.state.account}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
      />
    );
  }

  renderSecopsDashboard() {
    return (
      <SecopsDashboardTable
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        app={this.state.app}
        env={this.state.env}
      />
    );
  }

  renderComplianceDashboard() {
    return (
      <ComplianceDashboardTable
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        app={this.state.app}
        env={this.state.env}
      />
    );
  }

  renderInspectorDashboard() {
    return (
      <InspectorDashboardTable
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        app={this.state.app}
        env={this.state.env}
      />
    );
  }

  render() {
    const { classes } = this.props;

    if (this.state.noPermissions) {
      return (
        <div style={{ height: "100%" }}>
          <Grid item xs={12}>
            <Paper className={classes.paperAppSelect} elevation={0}>
              <Typography
                variant="h5"
                component="h2"
                style={{ paddingBottom: 40 }}
              >
                Vous ne devriez pas être ici.
              </Typography>
            </Paper>
          </Grid>
        </div>
      );
    }
    if (!this.state.noApps) {
      return (
        <div className={classes.root}>
          <MuiThemeProvider theme={TabsTheme}>
            <AppBar
              position="static"
              className={classes.dashboardAppBar}
              elevation={0}
            >
              <Tabs
                value={this.state.tab_index}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    height: "2px",
                    "border-radius": "50px"
                  }
                }}
              >
                <Tab
                  icon={<ArrowBackIcon />}
                  className={classes.dashboardTabArrow}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={SHIELD}
                        alt={"SHIELD-icon"}
                        style={{
                          height: "25px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      SECOPS
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={PATCHS}
                        alt={"PATCHS-icon"}
                        style={{
                          height: "25px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Patchs
                    </div>
                  }
                  className={classes.dashboardTab}
                />
                <Tab
                  label={
                    <div>
                      <img
                        src={MALWARE}
                        alt={"MALWARE-icon"}
                        style={{
                          height: "25px",
                          marginRight: "10px",
                          verticalAlign: "middle"
                        }}
                      />
                      Vulnerabilities
                    </div>
                  }
                  className={classes.dashboardTab}
                />
              </Tabs>
            </AppBar>
          </MuiThemeProvider>
          {this.state.tab_index === 1 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                height: "auto",
                "box-shadow": "none",
                marginTop: "0px",
                background: "#f7faf9"
              }}
            >
              <Paper className={classes.paperAppSelect} elevation={0}>
                <section
                  style={{
                    "text-transform": "uppercase",
                    "font-family": "sans-serif",
                    "font-size": "14px",
                    "font-weight": "400",
                    "text-align": "center",
                    "min-height": "100%",
                    paddingTop: 40,
                    paddingBottom: 40,
                    color: "#009999"
                  }}
                >
                  {this.renderSecopsDashboard()}
                </section>
                <svg viewBox="0 0 800 800">
                  <path
                    class="path"
                    d="M2,295 C363,348 395,4 800,47"
                    fill="none"
                    stroke="#0ec289"
                    stroke-width="0.5"
                    stroke-dashoffset="1000"
                    id="motion1"
                  />
                  <path
                    d="M0,204 C377,367 504,22 797,225"
                    fill="none"
                    stroke="#0ec289"
                    stroke-width="0.5"
                    id="motion2"
                  />
                  <circle
                    class="circle"
                    cx="284.3733028649552"
                    cy="241.81299508566542"
                    r="5"
                    stroke="#f7faf9"
                    fill="#f7faf9"
                    stroke-width="0"
                  />
                  <circle
                    class="circle"
                    cx="0"
                    cy="0"
                    r="1"
                    stroke="#f7faf9"
                    fill="#0ec289"
                    stroke-width="0"
                  >
                    <animateMotion dur="40s" repeatCount="indefinite">
                      <mpath xlinkHref="#motion1" />
                    </animateMotion>
                  </circle>
                  <circle
                    class="circle"
                    cx="0"
                    cy="0"
                    r="1"
                    stroke="#f7faf9"
                    fill="#0ec289"
                    stroke-width="0"
                  >
                    <animateMotion dur="30s" repeatCount="indefinite">
                      <mpath xlinkHref="#motion2" />
                    </animateMotion>
                  </circle>
                  <line
                    x1="284.3733028649552"
                    x2="284.3733028649552"
                    y1="241.81299508566542"
                    y2="245.5"
                    stroke="#0ec289"
                    stroke-width="1.5"
                  />
                  <line
                    x1="284.3733028649552"
                    x2="288"
                    y1="241.81299508566542"
                    y2="241.81299508566542"
                    stroke="#0ec289"
                    stroke-width="1.5"
                  />
                  <line
                    x1="284.3733028649552"
                    x2="284.3733028649552"
                    y1="241.81299508566542"
                    y2="238"
                    stroke="#0ec289"
                    stroke-width="1.5"
                  />
                  <line
                    x1="284.3733028649552"
                    x2="281"
                    y1="241.81299508566542"
                    y2="241.81299508566542"
                    stroke="#0ec289"
                    stroke-width="1.5"
                  />
                </svg>
                <img
                  src={GRT}
                  alt={"APP-icon"}
                  style={{
                    height: "50px",
                    marginTop: "50px",
                    verticalAlign: "middle",
                    animation: "fadeIn 4s"
                  }}
                />
              </Paper>
            </div>
          )}
          {this.state.tab_index === 2 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "margin-right": "0px",
                "margin-left": "0px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  {this.renderComplianceDashboard()}
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 3 && (
            <div
              style={{
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "margin-right": "0px",
                "margin-left": "0px",
                height: "auto",
                "box-shadow": "none",
                background: "#f7faf9",
                marginTop: "0px"
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={0}>
                  {this.renderInspectorDashboard()}
                  <svg viewBox="0 0 800 800">
                    <path
                      class="path"
                      d="M2,295 C363,348 395,4 800,47"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      stroke-dashoffset="1000"
                      id="motion1"
                    />
                    <path
                      d="M0,204 C377,367 504,22 797,225"
                      fill="none"
                      stroke="#0ec289"
                      stroke-width="0.5"
                      id="motion2"
                    />
                    <circle
                      class="circle"
                      cx="284.3733028649552"
                      cy="241.81299508566542"
                      r="5"
                      stroke="#f7faf9"
                      fill="#f7faf9"
                      stroke-width="0"
                    />
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="40s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion1" />
                      </animateMotion>
                    </circle>
                    <circle
                      class="circle"
                      cx="0"
                      cy="0"
                      r="1"
                      stroke="#f7faf9"
                      fill="#0ec289"
                      stroke-width="0"
                    >
                      <animateMotion dur="30s" repeatCount="indefinite">
                        <mpath xlinkHref="#motion2" />
                      </animateMotion>
                    </circle>
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="245.5"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="288"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="284.3733028649552"
                      y1="241.81299508566542"
                      y2="238"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                    <line
                      x1="284.3733028649552"
                      x2="281"
                      y1="241.81299508566542"
                      y2="241.81299508566542"
                      stroke="#0ec289"
                      stroke-width="1.5"
                    />
                  </svg>
                  <img
                    src={GRT}
                    alt={"APP-icon"}
                    style={{
                      height: "50px",
                      marginTop: "50px",
                      verticalAlign: "middle",
                      animation: "fadeIn 4s"
                    }}
                  />
                </Paper>
              </Grid>
            </div>
          )}
        </div>
      );
    } else {
      return <Redirect to="/alerte-apps" />;
    }
  }
}

export default withRouter(
  withSnackbar(withStyles(styles)(ComplianceDashboard))
);
