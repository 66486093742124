import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import { getCurrentUserProfile } from "../../Services/AuthService";
import TextField from "@material-ui/core/TextField";
import { addPerimeter } from "../../Services/PerimetersService";
import Grid from "@material-ui/core/Grid";

const PerimetersManagementCreate = ({
  open,
  onClose,
  onValidate,
  applications
}) => {
  const [perimeterNameToCreate, setPerimeterNameToCreate] = useState("");
  const [
    perimeterDutyPhoneNumberToCreate,
    setPerimeterDutyPhoneNumberToCreate
  ] = useState("+33111111111");
  const [perimeterEmailToCreate, setPerimeterEmailToCreate] = useState("");
  const [
    perimeterApplicationsToCreate,
    setPerimeterApplicationsToCreate
  ] = useState([]);
  const [isCreateInProgress, setIsCreateInProgress] = useState(false);

  const handleApplicationListChange = event => {
    const selectedApplication = event.target.value;
    if (event.target.checked) {
      setPerimeterApplicationsToCreate([
        ...perimeterApplicationsToCreate,
        selectedApplication
      ]);
    } else {
      setPerimeterApplicationsToCreate(
        perimeterApplicationsToCreate.filter(
          application => application !== selectedApplication
        )
      );
    }
  };

  const handleClose = () => {
    cleanState();
    onClose();
  };

  const handleValidate = async () => {
    setIsCreateInProgress(true);
    await addPerimeter(
      perimeterNameToCreate,
      JSON.stringify(perimeterApplicationsToCreate),
      perimeterDutyPhoneNumberToCreate,
      perimeterEmailToCreate
    );
    const currentUser = await getCurrentUserProfile();
    logCreate(currentUser);
    cleanState();
    onValidate();
  };

  const logCreate = currentUser => {
    const currentTime = moment().unix();
    const actionDescription =
      "Création d'un nouveau périmètre : " + perimeterNameToCreate;
    addLog(currentUser.UserName, currentTime, actionDescription);
  };

  const cleanState = () => {
    setPerimeterNameToCreate("");
    setPerimeterEmailToCreate("");
    setPerimeterDutyPhoneNumberToCreate("+33111111111");
    setPerimeterApplicationsToCreate([]);
    setIsCreateInProgress(false);
    setIsCreateInProgress(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 30,
          fontFamily: "sans-serif",
          fontSize: 12,
          fontWeight: 600,
          color: "#009999"
        }
      }}
    >
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          textAlign: "center"
        }}
      >
        <div style={{ color: "white" }}>Ajouter un nouveau périmètre</div>
      </DialogTitle>
      {!isCreateInProgress ? (
        <DialogContent>
          <TextField
            id={"perimeterName"}
            label={"Nom du périmètre"}
            variant="outlined"
            defaultValue={""}
            error={!/[a-zA-Z-]$/g.test(perimeterNameToCreate)}
            onChange={event => {
              setPerimeterNameToCreate(event.target.value);
            }}
            fullWidth
            margin="dense"
          />
          <TextField
            id={"perimeterDutyPhoneNumber"}
            label={
              "Numéro de téléphone d'astreinte du périmètre. Format= +33XXXXXXXXX"
            }
            variant="outlined"
            defaultValue={perimeterDutyPhoneNumberToCreate}
            error={!/\+33[0-9]{9}$/g.test(perimeterDutyPhoneNumberToCreate)}
            onChange={event => {
              setPerimeterDutyPhoneNumberToCreate(event.target.value);
            }}
            fullWidth
            margin="dense"
          />
          <TextField
            id={"perimeterEmail"}
            label={"Email du périmètre"}
            variant="outlined"
            defaultValue={""}
            onChange={event => {
              setPerimeterEmailToCreate(event.target.value);
            }}
            fullWidth
            margin="dense"
          />
          {applications.map(application => (
            <Grid item key={application}>
              <label key={application}>
                <input
                  name={application}
                  value={application}
                  type="checkbox"
                  checked={
                    perimeterApplicationsToCreate.indexOf(application) > -1
                  }
                  onChange={handleApplicationListChange}
                />
                {application}
              </label>
            </Grid>
          ))}
        </DialogContent>
      ) : (
        <CircularLoading />
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          variant={"contained"}
          disabled={isCreateInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Annuler
          </div>
        </Button>
        <Button
          onClick={handleValidate}
          variant={"contained"}
          style={{
            backgroundColor: "#32CD32"
          }}
          disabled={
            isCreateInProgress ||
            !/[a-zA-Z-]$/g.test(perimeterNameToCreate) ||
            !/\+33[0-9]{9}$/g.test(perimeterDutyPhoneNumberToCreate)
          }
        >
          <div
            style={{
              color: "white"
            }}
          >
            Valider
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerimetersManagementCreate;
