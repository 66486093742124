import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { styles } from "../../Styles/ComponentStyles";

class UserSelectorGenericMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: "",
      open: false,
      selectedValue: this.props.selectedValue
    };
  }

  handleChange = event => {
    this.setState({ selectedValue: event.target.value });
    this.props.onApplicationChange(event.target.value, this.props);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <form autoComplete="off">
        <FormControl className={classes.formControlUserSelector}>
          <Select
            native
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.selectedValue}
            onChange={this.handleChange}
          >
            {this.props.userList.length > 0 &&
              this.props.userList.map(
                user =>
                  user.UserName.endsWith("smtp_rule00") && (
                    <option value={user.UserName} key={user.UserName}>
                      {user.UserName} - {user.FirstName} {user.LastName}
                    </option>
                  )
              )}
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(UserSelectorGenericMail);
