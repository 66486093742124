import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
import moment from "moment";
import "moment/locale/fr";
import { CircularLoadingButton, styles } from "../../Styles/ComponentStyles";
import history from "../../history";
import Grid from "@material-ui/core/Grid";
import green from "@material-ui/core/colors/green";
import { getAccountState } from "../../Services/AccountService";

//Set the fr language for moment
moment.locale("fr");

const ButtonTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

class AppCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      app: props.app,
      environment: props.environment,
      account_id: props.account_id,
      classes: props.classes,
      redirect: false,
      status: "GATHERING",
      app_clickable: props.app_clickable,
      listAlarmsInProgress: false,
      applicationState: undefined
    };

    if (props.account_id !== undefined) {
      getAccountState(props.account_id).then(result => {
        if (result === "up") {
          this.listAlarms(props.account_id);
        } else {
          this.setState({
            status: result,
            listAlarmsInProgress: false
          });
        }
      });
    }
  }

  listAlarms(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "cloudwatch",
        boto_method: "describe_alarms",
        account_id: account_id
      }
    }).then(response => {
      let status = "OK";
      for (let i = 0; i < response["MetricAlarms"].length; i++) {
        if (response["MetricAlarms"][i].StateValue === "ALARM") {
          status = "ALARM";
        }
      }
      this.setState({
        status: status,
        listAlarmsInProgress: false
      });
    });
  }

  render() {
    const { app, environment } = this.state;

    let bgColor = "default";
    if (this.state.app_clickable) {
      if (this.state.status === "OK") {
        bgColor = "primary";
      } else if (this.state.status === "ALARM") {
        bgColor = "secondary";
      } else if (this.state.status.toUpperCase() === "DOWN") {
        bgColor = "black";
      } else if (this.state.status === "GATHERING") {
        bgColor = "default";
      } else {
        bgColor = "default";
      }
    }

    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify="flex-start"
        className={this.state.classes.gridContainer}
      >
        <Grid item xs={2} elevation={0}>
          <MuiThemeProvider theme={ButtonTheme}>
            <Button
              size="small"
              color={bgColor}
              variant="contained"
              style={{
                backgroundColor: bgColor === "black" ? "#000000" : "",
                boxShadow: "none",
                borderRadius: "20px",
                border: "none",
                fontWeight: "bold",
                paddingTop: "5px",
                fontSize: "12px",
                marginTop: "45px",
                color: "#ffffff"
              }}
              onClick={() => {
                history.push({
                  pathname: "/application",
                  search: "?app=" + app + "&env=" + environment
                });
              }}
            >
              {environment}
              {this.state.status === "GATHERING" ? (
                <CircularLoadingButton />
              ) : (
                false
              )}
            </Button>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AppCard);
