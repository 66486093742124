import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContentText,
  ListItem,
  TextField
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { putApplicationHeadline } from "../../Services/ApplicationService";
import QMARK from "../../Styles/Icons/questionmark.png";

const HeadlineUpdate = memo(
  ({ open, onClose, onValidate, headlineInformation, selectedApplication }) => {
    const [
      updatedHeadlineInformation,
      setUpdatedHeadlineInformation
    ] = useState(headlineInformation);
    const [displayNotice, setDisplayNotice] = useState(false);
    const [disableValidate, setDisableValidate] = useState(false);

    const updateRow = (updatedValue, indexToUpdate, field) => {
      setUpdatedHeadlineInformation(
        updatedHeadlineInformation.map((row, index) =>
          indexToUpdate !== index
            ? row
            : {
                ...row,
                [field]: updatedValue
              }
        )
      );
    };

    useEffect(() => {
      const effectCheckAtLeastOneFilledField = index =>
        updatedHeadlineInformation[index].title ||
        updatedHeadlineInformation[index].linkName ||
        updatedHeadlineInformation[index].linkValue;

      const effectCheckLinkValueError = index =>
        !effectCheckAtLeastOneFilledField(index) ||
        (updatedHeadlineInformation[index].linkName &&
          !updatedHeadlineInformation[index].linkValue);

      const effectCheckLinkNameError = index =>
        !effectCheckAtLeastOneFilledField(index) ||
        (!updatedHeadlineInformation[index].linkName &&
          updatedHeadlineInformation[index].linkValue);

      const checkDisableValidate = () =>
        updatedHeadlineInformation.filter(
          (row, index) =>
            !effectCheckAtLeastOneFilledField(index) ||
            effectCheckLinkValueError(index) ||
            effectCheckLinkNameError(index)
        ).length > 0;
      if (updatedHeadlineInformation.length > 0) {
        setDisableValidate(checkDisableValidate());
      }
    }, [updatedHeadlineInformation]);

    const removeRow = indexToDelete => {
      setUpdatedHeadlineInformation(
        updatedHeadlineInformation
          .map((row, index) => (indexToDelete !== index ? row : null))
          .filter(row => row !== null)
      );
    };

    const addRow = () => {
      const newRow = {
        title: "",
        linkName: "",
        linkValue: ""
      };
      setUpdatedHeadlineInformation(
        updatedHeadlineInformation.length > 0
          ? [...updatedHeadlineInformation, newRow]
          : [newRow]
      );
    };

    const checkAtLeastOneFilledField = index =>
      updatedHeadlineInformation[index].title ||
      updatedHeadlineInformation[index].linkName ||
      updatedHeadlineInformation[index].linkValue;

    const checkLinkValueError = index =>
      !checkAtLeastOneFilledField(index) ||
      (updatedHeadlineInformation[index].linkName &&
        !updatedHeadlineInformation[index].linkValue);

    const checkLinkNameError = index =>
      !checkAtLeastOneFilledField(index) ||
      (!updatedHeadlineInformation[index].linkName &&
        updatedHeadlineInformation[index].linkValue);

    const handleValidate = async () => {
      await putApplicationHeadline(
        selectedApplication,
        updatedHeadlineInformation
      );
      onValidate();
    };

    const handleClose = () => {
      setDisableValidate(false);
      setDisplayNotice(false);
      setUpdatedHeadlineInformation(headlineInformation);
      onClose();
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        PaperProps={{
          style: {
            borderRadius: 30,
            border: "none",
            fontFamily: "sans-serif",
            fontSize: 12,
            fontWeight: 600,
            color: "#009999"
          }
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#3b90d1",
            marginBottom: "10px",
            fontFamily: "sans-serif",
            "text-transform": "uppercase"
          }}
        >
          <section
            style={{
              "font-weight": "bold",
              fontSize: 16,
              color: "white"
            }}
          >
            Informations sur l'application
          </section>
        </DialogTitle>
        <DialogContent>
          <Button
            style={{ textAlign: "center", backgroundColor: "white" }}
            onClick={() => setDisplayNotice(!displayNotice)}
          >
            <b>
              {displayNotice ? (
                <section>
                  <img
                    src={QMARK}
                    alt={"QMARK-DESCRIPTION"}
                    style={{
                      height: "40px",
                      width: "40px",
                      "margin-right": "10px",
                      "margin-left": "0px",
                      "padding-top": "10px",
                      "vertical-align": "-50%"
                    }}
                  />
                  <span style={{ textAlign: "center", color: "#5867ad" }}>
                    Fermer le manuel
                  </span>
                </section>
              ) : (
                <section style={{ textAlign: "center", "max-width": "300px" }}>
                  <img
                    src={QMARK}
                    alt={"QMARK-DESCRIPTION"}
                    style={{
                      height: "40px",
                      width: "40px",
                      "margin-right": "10px",
                      "margin-left": "0px",
                      "padding-top": "10px",
                      "vertical-align": "-50%"
                    }}
                  />
                  <span style={{ textAlign: "center", color: "#5867ad" }}>
                    Comment ça marche ?
                  </span>
                </section>
              )}
            </b>
          </Button>
          {displayNotice && (
            <DialogContentText
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                "overflow-x": "hidden",
                fontSize: 14,
                fontWeight: 600,
                color: "#009999"
              }}
            >
              Vous pouvez ajouter ou modifier une ligne d'information sur
              l'application. <br />
              Si vous décidez d'ajouter un lien à l'information, vous devrez
              remplir son nom et sa valeur.
              <br />
              Vous devez renseigner un titre et/ou un lien.
              <br />
              <br />
              Exemples d'information valide:
              <br />
              <br />
              Titre: Documentation ExSI -<br />
              Nom du lien: Confluence
              <br />
              Valeur du lien:
              https://confluence-grtgaz.atlassian.net/wiki/spaces/CCOE/pages/286818823/ExSI
              <br />
              <br />
              Titre: Documentation ExSI -<br />
              Nom du lien:
              <br />
              Valeur du lien:
              <br />
              <br />
              Titre:
              <br />
              Nom du lien: Confluence
              <br />
              Valeur du lien:
              https://confluence-grtgaz.atlassian.net/wiki/spaces/CCOE/pages/286818823/ExSI
              <br />
            </DialogContentText>
          )}
          <section style={{ textAlign: "center" }}>
            <List>
              {updatedHeadlineInformation.length > 0 &&
                updatedHeadlineInformation.map((row, index) => (
                  <ListItem key={index}>
                    <TextField
                      id={"title" + index}
                      label={"Titre"}
                      variant="outlined"
                      defaultValue={row.title}
                      onChange={event => {
                        updateRow(event.target.value, index, "title");
                      }}
                      fullWidth
                      error={!checkAtLeastOneFilledField(index)}
                      margin="dense"
                      style={{
                        "text-transform": "uppercase",
                        "font-size": "16px",
                        "font-weight": "bold",
                        "text-align": "center",
                        "border-radius": "30px",
                        "border-color": "#2d98eb",
                        color: "#009999"
                      }}
                    />
                    <TextField
                      id={"linkName" + index}
                      label={"Nom du lien"}
                      variant="outlined"
                      defaultValue={row.linkName}
                      onChange={event => {
                        updateRow(event.target.value, index, "linkName");
                      }}
                      error={checkLinkNameError(index)}
                      fullWidth
                      margin="dense"
                    />
                    <TextField
                      id={"linkValue" + index}
                      label={"Valeur du lien"}
                      variant="outlined"
                      defaultValue={row.linkValue}
                      onChange={event => {
                        updateRow(event.target.value, index, "linkValue");
                      }}
                      error={checkLinkValueError(index)}
                      fullWidth
                      margin="dense"
                    />
                    <Button
                      onClick={() => {
                        removeRow(index);
                      }}
                    >
                      {" "}
                      <DeleteForeverOutlinedIcon color="primary" />
                    </Button>
                  </ListItem>
                ))}
            </List>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2d98eb",
                "box-shadow": "none",
                "border-radius": "20px",
                border: "none",
                "font-weight": "bold",
                color: "#ffffff",
                margin: "10px"
              }}
              onClick={() => {
                addRow();
              }}
            >
              <PlaylistAddIcon />
              <section>
                <span> cliquer pour ajouter votre description </span>
              </section>
            </Button>
          </section>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant={"contained"}
            style={{
              backgroundColor: "#8a99e3",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
          >
            ANNULER
          </Button>
          <Button
            onClick={handleValidate}
            disabled={disableValidate}
            variant={"contained"}
            style={{
              backgroundColor: "#69f0b1",
              "box-shadow": "none",
              "border-radius": "20px",
              border: "none",
              "font-weight": "bold",
              color: "#ffffff",
              margin: "10px"
            }}
          >
            VALIDER
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default HeadlineUpdate;
