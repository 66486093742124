/* eslint-disable */
import React, { useEffect, useState } from "react";
import { retrieveAdmins } from "../../Services/AdminService";
//import { regenerateMfaCode, verifyMfaCode } from '../../Services/AdminService';
//import { getUserProfile } from "../../Services/UserProfilesService";
import PerimetersManagementDashboard from "./PerimetersManagementDashboard";
import Card from "@material-ui/core/Card";
import GRT from "../../Styles/Icons/GRTtrans.png";
//import MfaForm from './MfaForm';

const PerimetersManagementGeneral = ({ userName, accountList }) => {
  const [isUserAdmin, setIsUserAdmin] = useState(undefined);
  //const [isMfaVerified, setIsMfaVerified] = useState(false);
  //const [mfaRequested, setMfaRequested] = useState(false);
  //const [userEmail, setUserEmail] = useState(undefined);

  useEffect(() => {
    const retrievePerimetersAdmins = async () => {
      const userAdmins = await retrieveAdmins("perimeters");
      setIsUserAdmin(userAdmins.indexOf(userName) > -1);
    };
    retrievePerimetersAdmins();
    //const fetchUserEmail = async () => {
      //try {
        //console.log("Début de la récupération de l'email pour l'utilisateur:", userName);
        //const emailResponse = await getUserProfile(userName);
        //console.log("Email récupéré:", emailResponse.Email);
        //setUserEmail({ userEmail: emailResponse.Email });
        //setUserEmail({ userEmail: "matthieu.diawara@external.grtgaz.com" });
      //} catch (error) {
        //console.error("Erreur lors de la récupération de l'email: ", error);
      //}
    //};
    //fetchUserEmail();
  }, [userName]);
/*
  const handleMfaVerification = (isValid) => {
    setIsMfaVerified(isValid);
    if (!isValid) {
      handleRequestNewMfaCode();
    }
  };

  const handleRequestNewMfaCode = async () => {
    setMfaRequested(true);
  
    try {
      console.log("Préparation des données à chiffrer.");
      const dataToEncrypt = userEmail.userEmail;
      console.log("Données avant chiffrement:", dataToEncrypt);
      console.log("Envoi de la demande de régénération du code MFA.");
      await regenerateMfaCode(dataToEncrypt);
      console.log("Demande de régénération du code MFA envoyée avec succès.");
      // Gérer la réponse ici
    } catch (error) {
      console.error("Erreur lors de la régénération du code MFA:", error);
      // Gérer les erreurs ici
    }
  };
*/
  return isUserAdmin !== undefined ? (
    isUserAdmin ? (
        <div>
          <div
            style={{
              fontFamily: "sans-serif",
              fontSize: "x-large",
              marginTop: "1em",
              color: "#139aa1"
            }}
          >
            <b>GESTION DES PÉRIMÈTRES</b>
          </div>
          <Card
            style={{
              padding: "1em",
              margin: "3em",
              marginLeft: "10em",
              marginRight: "10em",
              border: "none",
              boxShadow: "none",
              borderRadius: "30px",
              backgroundColor: "#ffffff"
            }}
          >
            <PerimetersManagementDashboard accountList={accountList} />
          </Card>
          <img
            src={GRT}
            alt={"APP-icon"}
            style={{
              height: "50px",
              marginTop: "50px",
              marginBottom: "50px",
              verticalAlign: "middle",
              animation: "fadeIn 4s"
            }}
          />
        </div>
    ) : (
      <div>Vous n'avez pas le droit d'accéder à cette page. Contacter un administrateur</div>
    )
  ) : (
    <div>Loading...</div>
  );
};

export default PerimetersManagementGeneral;
