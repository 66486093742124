import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

const LogsActionDialog = ({ open, onClose, log }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="xl"
    PaperProps={{
      style: {
        borderRadius: 30,
        fontFamily: "sans-serif",
        fontSize: 12,
        fontWeight: 600,
        color: "#009999"
      }
    }}
  >
    <DialogTitle
      style={{
        marginBottom: "10px",
        "text-transform": "uppercase",
        "font-size": "12px",
        "font-weight": "bold"
      }}
    >
      <Typography
        variant="body1"
        style={{
          "text-transform": "uppercase",
          "font-size": "16px",
          "font-weight": "bold",
          "text-align": "center",
          "border-radius": "30px",
          marginBottom: "10px",
          marginTop: "10px",
          color: "#009999"
        }}
      >
        {log.logDescription}
      </Typography>
    </DialogTitle>
    <DialogContent
      style={{
        marginLeft: "50px",
        marginRight: "50px",
        "overflow-x": "hidden",
        color: "#009999"
      }}
    >
      <br />
      <div>Status: {log.response.StatusCode}</div>
      <br />
      <div style={{ whiteSpace: "pre-line" }}>
        Résultats: {log.response.Payload}
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant={"contained"}
        style={{
          backgroundColor: "secondary",
          "box-shadow": "none",
          "border-radius": "20px",
          border: "none",
          "font-weight": "bold",
          color: "#ffffff",
          margin: "10px"
        }}
      >
        <div
          style={{
            color: "white"
          }}
        >
          OK
        </div>
      </Button>
    </DialogActions>
  </Dialog>
);

export default LogsActionDialog;
