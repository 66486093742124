import React from "react";
import { ListItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const HeadlineRow = ({ headlineRowInformation }) => (
  <ListItem>
    {headlineRowInformation.title}
    {headlineRowInformation.linkValue && headlineRowInformation.linkName && (
      <Button
        size="small"
        color="primary"
        style={{
          backgroundColor: "#7bb8db",
          "box-shadow": "none",
          "border-radius": "20px",
          border: "none",
          "font-weight": "bold",
          color: "#ffffff",
          margin: "10px"
        }}
        onClick={() => {
          window.open(headlineRowInformation.linkValue, "_blank");
        }}
      >
        {headlineRowInformation.linkName}
      </Button>
    )}
  </ListItem>
);

export default HeadlineRow;
