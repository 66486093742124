import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import GRT from "../../Styles/Icons/LogoGRT.png";

import { actionsStyles, styles } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import LogsActionDialog from "../Logs/LogsActionDialog";

const reactStringReplace = require("react-string-replace");

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

class CustomPaginationActionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 10,
      loadData: true,
      searchInputValue: "",
      openAction: {}
    };
  }

  createData() {
    let db_array_items = [];
    if (this.state.searchInputValue.length === 0) {
      this.props.dbItems.map(item => {
        db_array_items.push({
          USER_NAME: item.USER_NAME,
          USER_ACTION_TIME: item.USER_ACTION_TIME,
          USER_ACTION_DESCRIPTION: item.USER_ACTION_DESCRIPTION
        });
        this.setState({
          rows: db_array_items,
          loadData: false
        });
        return null;
      });
      return db_array_items;
    } else if (this.state.searchInputValue.length > 0) {
      this.props.dbItems.map(item => {
        if (
          item.USER_NAME.toLowerCase().includes(
            this.state.searchInputValue.toLowerCase()
          ) ||
          item.USER_ACTION_TIME.toLowerCase().includes(
            this.state.searchInputValue.toLowerCase()
          ) ||
          item.USER_ACTION_DESCRIPTION.toLowerCase().includes(
            this.state.searchInputValue.toLowerCase()
          )
        ) {
          db_array_items.push({
            USER_NAME: item.USER_NAME,
            USER_ACTION_TIME: item.USER_ACTION_TIME,
            USER_ACTION_DESCRIPTION: item.USER_ACTION_DESCRIPTION
          });
          this.setState({
            rows: db_array_items,
            loadData: false
          });
        }
        return null;
      });
      return db_array_items;
    }
  }

  componentDidUpdate() {
    if (this.state.loadData === true) {
      this.createData();
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
    //console.log(this.state.rowsPerPage)
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      page: 0
    });
  };

  handleOnChangeInputSearch = event => {
    this.setState({
      searchInputValue: event.target.value,
      loadData: true
    });
  };

  formatActionRow = (logDescription, index) => (
    <div>
      {this.formatRow(logDescription)}
      <br />
      <Button onClick={() => this.handleOpenAction(index)}>
        afficher les résultats
      </Button>
    </div>
  );

  formatRow = content =>
    reactStringReplace(content, this.state.searchInputValue, (match, i) => (
      <span key={i} style={{ backgroundColor: "yellow" }}>
        {match}
      </span>
    ));

  handleCloseAction = index => {
    let newOpenAction = this.state.openAction;
    newOpenAction[index] = false;
    this.setState({
      openAction: newOpenAction
    });
  };
  handleOpenAction = index => {
    let newOpenAction = this.state.openAction;
    newOpenAction[index] = true;
    this.setState({
      openAction: newOpenAction
    });
  };

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    let sortedRows = rows.sort((a, b) =>
      parseInt(a.USER_ACTION_TIME) < parseInt(b.USER_ACTION_TIME) ? 1 : -1
    );

    return (
      <Paper className={classes.rootPaginate}>
        <div
          style={{ height: "90vh", "overflow-y": "hidden" }}
          className={classes.tableWrapperPaginate}
        >
          <Table className={classes.tablePaginate}>
            <TableHead>
              <TableRow style={{ height: "40px" }}>
                <TableCell
                  style={{
                    border: "none",
                    "border-radius": "30px 0 0 0",
                    "margin-top": "0px",
                    "margin-bottom": "0px",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                    "margin-right": "0px",
                    "margin-left": "0px",
                    background: "#dff2ed",
                    color: "#139aa1",
                    "font-weight": "bold",
                    "font-size": "14px",
                    maxWidth: "60px",
                    textTransform: "lowercase",
                    textAlign: "center"
                  }}
                >
                  User Name
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    "border-radius": "0 0 0 0",
                    "margin-top": "0px",
                    "margin-bottom": "0px",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                    "margin-right": "0px",
                    "margin-left": "0px",
                    background: "#dff2ed",
                    color: "#139aa1",
                    "font-weight": "bold",
                    "font-size": "14px",
                    maxWidth: "60px",
                    textTransform: "lowercase",
                    textAlign: "center"
                  }}
                >
                  Action Time
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    "border-radius": "0 30px 0 0",
                    "margin-top": "0px",
                    "margin-bottom": "0px",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                    "margin-right": "0px",
                    "margin-left": "0px",
                    background: "#dff2ed",
                    color: "#139aa1",
                    "font-weight": "bold",
                    "font-size": "14px",
                    maxWidth: "60px",
                    textTransform: "lowercase",
                    textAlign: "center"
                  }}
                >
                  Action Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={row.USER_ACTION_TIME}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        border: "none",
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "font-weight": "bold",
                        background: "white",
                        color: "#3c577a"
                      }}
                    >
                      {this.formatRow(row.USER_NAME)}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "font-weight": "bold",
                        background: "white",
                        color: "#3c577a"
                      }}
                    >
                      {" "}
                      {this.formatRow(
                        new Date(row.USER_ACTION_TIME * 1000).toString()
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "font-weight": "bold",
                        background: "white",
                        color: "#3c577a"
                      }}
                    >
                      {this.IsJsonString(row.USER_ACTION_DESCRIPTION)
                        ? this.formatActionRow(
                            JSON.parse(row.USER_ACTION_DESCRIPTION)
                              .logDescription,
                            index
                          )
                        : this.formatRow(row.USER_ACTION_DESCRIPTION)}
                    </TableCell>
                    {this.IsJsonString(row.USER_ACTION_DESCRIPTION) && (
                      <LogsActionDialog
                        open={this.state.openAction[index] || false}
                        onClose={() => {
                          this.handleCloseAction(index);
                        }}
                        log={JSON.parse(row.USER_ACTION_DESCRIPTION)}
                      />
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={
                    {
                      /*height: 48 * emptyRows*/
                    }
                  }
                >
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{
                      border: "none",
                      "font-family": "sans-serif",
                      "font-size": "14px",
                      "font-weight": "bold",
                      background: "white",
                      color: "#3c577a"
                    }}
                  >
                    aucune consignation
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  style={{
                    border: "none",
                    "font-family": "sans-serif",
                    "font-size": "14px",
                    "font-weight": "bold",
                    background: "white",
                    color: "#3c577a"
                  }}
                >
                  <InputBase
                    placeholder="Recherche…"
                    style={{ marginTop: 15, marginLeft: 50 }}
                    onChange={this.handleOnChangeInputSearch}
                  />
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[] /*[5, 10, 25, 50]*/}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Lignes par page"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Logs de ${from} à ${to} sur un total de ${count} enregistrements`
                  }
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  style={{ paddingRight: "20%" }}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <svg viewBox="0 0 800 800">
            <path
              d="M2,295 C363,348 395,4 800,47"
              fill="none"
              stroke="#0ec289"
              stroke-width="0.5"
              stroke-dashoffset="1000"
            />
            <path
              d="M0,204 C377,367 504,22 797,225"
              fill="none"
              stroke="#0ec289"
              stroke-width="0.5"
            />
          </svg>
        </div>
        <img
          src={GRT}
          alt={"APP-icon"}
          style={{
            height: "50px",
            marginTop: "0px",
            verticalAlign: "middle",
            animation: "fadeIn 4s"
          }}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(CustomPaginationActionsTable);
