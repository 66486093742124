import React, { Component } from "react";
import * as d3 from "d3";
//const colors = [ '#8ce8ad', '#57e188', '#27acaa', '#ff736a', '#f95d54', '#ff4136', '#c4c4cd' ];
const colors = [
  "#725ae8",
  "#ff4136",
  "#ff8800",
  "#f2fa05",
  "#27acaa",
  "#57e188"
];

class DonutChart extends Component {
  constructor(props) {
    super(props);
    this.chRef = React.createRef();
  }

  // Chart load after component Mount
  componentDidMount() {
    this.drawChart();
  }

  // DrawChart
  drawChart() {
    const { data } = this.props;
    const svgContainer = d3.select(this.chRef.current).node();
    const width = svgContainer.getBoundingClientRect().width;
    const height = width;
    const margin = 15;
    let radius = Math.min(width, height) / 2.5 - margin;
    // legend Position
    let legendPosition = d3
      .arc()
      .innerRadius(radius / 1.75)
      .outerRadius(radius);

    // Create SVG
    const svg = d3
      .select(this.chRef.current)
      .append("svg")
      .attr("width", "60%")
      .attr("height", "60%")
      .attr("viewBox", "0 0 " + width + " " + width)
      //.attr('preserveAspectRatio','xMinYMin')
      .append("g")
      .attr(
        "transform",
        "translate(" +
          Math.min(width, height) / 2 +
          "," +
          Math.min(width, height) / 2 +
          ")"
      );

    let pie = d3.pie().value(d => d.value);
    let data_ready = pie(data);

    // Donut partition
    svg
      .selectAll("whatever")
      .data(data_ready)
      .enter()
      .append("path")
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(radius / 1.8) // This is the size of the donut hole
          .outerRadius(radius)
      )
      .attr("fill", d => colors[d.index])
      .attr("stroke", "#fff")
      .style("stroke-width", "1")
      .style("opacity", "0.6");

    // Legend group and legend name
    svg
      .selectAll("mySlices")
      .data(data_ready)
      .enter()
      .append("g")
      .attr("transform", d => `translate(${legendPosition.centroid(d)})`)
      .attr("class", "legend-g")
      .style("user-select", "none")
      .append("text")
      //.text(function(d) {
      //return d.data.value === 0 ? "" : d.data.name;
      //return d.data.name;
      //})
      .text(function(d) {
        if (d.data.value === 0) {
          return "";
        } else {
          return d.data.name;
        }
      })
      .style("text-anchor", "middle")
      .style("font-weight", 900)
      .style("height", height + margin.top + margin.bottom)
      //.style("fill", "grey")
      .style("fill", function(d) {
        if (d.data.value === 0) {
          return "#6c6b6e";
        } else {
          return "white";
        }
      })
      .style("font-size", function(d) {
        if (d.data.value === 0) {
          return 12;
        } else {
          return 12;
        }
      });

    //Label for value
    svg
      .selectAll(".legend-g")
      .append("text")
      //.text(function(d) { return d.data.value == 0 ? "" : d.data.value.toFixed(1) + " " + "P" ; })
      .text(function(d) {
        if (d.data.value === 0) {
          return "";
        } else {
          return d.data.value;
        }
      })
      .style("fill", function(d) {
        if (d.data.value === 0) {
          return "grey";
        } else {
          return "white";
        }
      })
      //.style("font-size", 16)
      .style("font-size", function(d) {
        if (d.data.value === 0) {
          return 12;
        } else {
          return 16;
        }
      })
      .style("text-anchor", "middle")
      .style("font-weight", 900)
      .attr("y", 16);
  }

  render() {
    return (
      <>
        <div ref={this.chRef}></div>{" "}
      </>
    );
  }
}

export default DonutChart;
