import API from "@aws-amplify/api/lib";

import { retrieveAllPerimeters } from "./PerimetersService";
import { getUserProfile, getUserProfiles } from "./UserProfilesService";

export const retrieveUserApplications = async userName => {
  const userProfile = await getUserProfile(userName);
  const perimeters = await retrieveAllPerimeters();
  return perimeters
    .filter(
      perimeter =>
        userProfile.Perimeters.indexOf(perimeter["Perimetre Name"]) > -1
    )
    .map(userPerimeter => JSON.parse(userPerimeter["Application Name"]))
    .flat(1);
};

export const deleteUser = async userName => {
  API.del("api-exsi-backend-v2", "/userProfiles/" + userName, {});
};

export const getAllUsers = async () => {
  const userProfiles = await getUserProfiles();
  return userProfiles;
};

export const exportByUserName = async () => {
  const userProfiles = await getUserProfiles();
  return userProfiles
    .filter(profile => profile.Perimeters.includes("tran"))
    .map(el => el.UserName);
};

export const exportByFirstName = async () => {
  const userProfiles = await getUserProfiles();
  return userProfiles.map(el => el.FirstName);
};

export const exportCollabsByUserName = async perimeters => {
  const userProfiles = await getUserProfiles();
  return userProfiles
    .filter(profile => profile.Perimeters.includes(perimeters))
    .map(el => el.UserName);
};

export const exportCollabsByEmail = async perimeters => {
  const userProfiles = await getUserProfiles();
  return userProfiles
    .filter(profile => profile.Perimeters.includes(perimeters))
    .map(el => el.Email)
    .filter(el => el !== "matthieu.diawara@external.grtgaz.com")
    .filter(el => el !== "mohamed.lababidi@external.grtgaz.com");
};
