export const styles = theme => ({
  // For ProfilUtilisateur.js
  rootProfilScreen: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    minHeight: 1100,
    background: "#f7faf9",
    overflowY: "hidden",
    overflowX: "hidden"
  },

  rootProfilUtilisateur: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    background: "#f7faf9"
  },

  rootProfilName: {
    ...theme.mixins.gutters(),
    "font-family": "sans-serif",
    "font-size": "14px",
    "font-weight": "bold",
    "border-radius": "30px",
    marginTop: "30px",
    "padding-top": "30px",
    "padding-bottom": "0px",
    background: "white",
    marginLeft: "100px",
    textAlign: "left",
    color: "#3c577a",
    width: 1000,
    height: 50
  },

  rootProfilInfo: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginLeft: "80px",
    textAlign: "left",
    background: "#f7faf9"
  },

  rootUserManagement: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "scroll"
  },

  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});
