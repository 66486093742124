import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { renderStatus } from "../../Services/PipelinesService";
import PipelineRevisions from "./PipelineRevisions";

const PipelineStageSucceeded = ({
  revisionId,
  actionName,
  revisionSummary,
  lastStatusChange,
  status,
  sourceRevisions
}) => {
  const [openAction, handleOpenAction] = useState(false);

  return (
    <div>
      <Card
        elevation={0}
        style={{
          backgroundColor: renderStatus(status),
          margin: "0.5em",
          padding: "0.5em",
          textAlign: "left",
          color: "white",
          "text-transform": "uppercase",
          "font-family": "sans-serif",
          "font-size": "16px",
          "font-weight": "600",
          "border-radius": "10px"
        }}
      >
        <div style={{ marginLeft: "20px" }}>
          {revisionId} - {actionName}: {revisionSummary}
        </div>
        {sourceRevisions.length > 1 ? (
          <span>
            <Button
              size="small"
              style={{
                color: "white",
                padding: 0,
                minWidth: "auto",
                "box-shadow": "none"
              }}
              onClick={() => handleOpenAction(!openAction)}
            >
              ...
            </Button>
            <PipelineRevisions
              open={openAction}
              onClose={() => handleOpenAction(!openAction)}
              sourceRevisions={sourceRevisions}
            />
          </span>
        ) : (
          <br />
        )}
        <br />
        <div
          style={{
            fontSize: "small",
            textAlign: "right",
            marginRight: "30px"
          }}
        >
          Dernière exécution:{" "}
          {moment(lastStatusChange).format("ddd Do MMMM YYYY, HH:mm:ss")}
        </div>
      </Card>
      <Button disabled={true}>Aucune livraison en attente</Button>
    </div>
  );
};

export default PipelineStageSucceeded;
