/* eslint-disable */
import React from "react";
import Typography from '@material-ui/core/Typography';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Grid from '@material-ui/core/Grid';

type ChartProps = {
  chartOptions: Highcharts.Options;
};

export default function ChartStg({ chartOptions }: ChartProps) {
  return (
    <div className="custom-chart">     
      <Grid container spacing={4}>
        <Grid item xs>
        <Typography
          style={{
              margin: "40px"
            }}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: "100%" } }}
          />
        </Typography> 
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textTransform: "uppercase",
              color: "#7084FB",
              fontWeight: "bold",
              fontSize: "24px",
              marginTop: "40px",
              marginLeft: "40px"
            }}
          >
            {"STAGING ENV : BUILDER"}
          </Typography>
          <Typography
            style={{
              textTransform: "uppercase",
              color: "#7084FB",
              fontWeight: "bold",
              fontSize: "14px",
              marginTop: "40px",
              marginLeft: "40px"
            }}
          >
            {"Global analysis"}
          </Typography>
          <Typography
            style={{
              textTransform: "uppercase",
              color: "balck",
              fontWeight: "bold",
              fontSize: "12px",
              margin: "40px"
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised
            in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus PageMaker 
            including versions of Lorem Ipsum.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}