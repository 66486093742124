import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ScreenStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "@material-ui/core";
import ViewList from "@material-ui/icons/ViewList";
import { CircularLoading } from "../../Styles/ComponentStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import HuntGroupVerticalList from "../List/HuntGroupVerticalList";
import {
  retrieveAllPerimeters,
  updatePerimeterHuntGroupPriority
} from "../../Services/PerimetersService";

class HuntGroupsManagementTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: this.props.classes,
      perimetersList: [],
      isPerimetersListFetch: false,
      isPerimeterHuntGroupDialogOpened: false,
      perimeterNameHuntGroupDialog: "",
      updatedHuntGroupList: []
    };
  }

  componentDidMount() {
    retrieveAllPerimeters().then(result => {
      this.setState({
        perimetersList: result,
        isPerimetersListFetch: true
      });
    });
  }

  updatedHuntListCallback = updatedHuntGroupList => {
    this.setState({ updatedHuntGroupList });
  };

  renderTableHeaders() {
    return ["Perimetre", "Applications", "Gérer"].map(value => {
      return (
        <TableCell
          key={value}
          style={{
            border: "none",
            "border-radius": "0px 0 0 0",
            "margin-top": "0px",
            "margin-bottom": "0px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            "margin-right": "0px",
            "margin-left": "0px",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "center"
          }}
        >
          {value}
        </TableCell>
      );
    });
  }

  renderTableBody() {
    return this.state.perimetersList.map((perimeter, index) => {
      return (
        <TableRow key={index}>
          <TableCell
            style={{
              border: "none",
              "font-family": "sans-serif",
              "font-size": "14px",
              "font-weight": "bold",
              background: "white",
              color: "#3c577a",
              textAlign: "center"
            }}
          >
            {perimeter["Perimetre Name"]}
          </TableCell>
          <TableCell
            style={{
              border: "none",
              "font-family": "sans-serif",
              "font-size": "14px",
              "font-weight": "bold",
              background: "white",
              color: "#3c577a",
              textAlign: "center"
            }}
          >
            {perimeter["Application Name"]
              .split("[")
              .join("")
              .split("]")
              .join("")
              .split('"')}
          </TableCell>
          <TableCell
            style={{
              border: "none",
              "font-family": "sans-serif",
              "font-size": "14px",
              "font-weight": "bold",
              background: "white",
              color: "#3c577a",
              textAlign: "center"
            }}
          >
            <Button
              onClick={() =>
                this.setState({
                  isPerimeterHuntGroupDialogOpened: true,
                  perimeterNameHuntGroupDialog: perimeter["Perimetre Name"]
                })
              }
              color="primary"
              key="perimeter-hunt-group"
            >
              <ViewList className={this.state.classes.icon} />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  renderPerimeterHuntGroupDialog() {
    if (this.state.isPerimeterHuntGroupDialogOpened) {
      return (
        <Dialog
          open={this.state.isPerimeterHuntGroupDialogOpened}
          onClose={() =>
            this.setState({
              isPerimeterHuntGroupDialogOpened: false,
              perimeterNameHuntGroupDialog: ""
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {this.state.perimeterNameHuntGroupDialog} - Gestion des prioriétés
            du hunt group
          </DialogTitle>
          <HuntGroupVerticalList
            perimeterName={this.state.perimeterNameHuntGroupDialog}
            dialogHuntListCallback={this.updatedHuntListCallback}
          ></HuntGroupVerticalList>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({
                  isPerimeterHuntGroupDialogOpened: false,
                  perimeterNameHuntGroupDialog: ""
                })
              }
              color="primary"
            >
              Annuler
            </Button>
            <Button
              onClick={() => {
                updatePerimeterHuntGroupPriority(
                  this.state.perimeterNameHuntGroupDialog,
                  this.state.updatedHuntGroupList
                ).then(() =>
                  this.setState({
                    isPerimeterHuntGroupDialogOpened: false,
                    perimeterNameHuntGroupDialog: "",
                    updatedHuntGroupList: []
                  })
                );
              }}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  render() {
    return this.state.isPerimetersListFetch ? (
      <div>
        <Table
          className="tableTable"
          style={{
            background: "#f7faf9",
            "border-radius": "30px",
            "margin-left": "auto",
            "margin-right": "auto",
            "margin-bottom": "40px",
            overflowX: "hidden",
            maxWidth: "1300px",
            "box-shadow": "none"
          }}
        >
          <TableHead>
            <TableRow>{this.renderTableHeaders()}</TableRow>
          </TableHead>
          <TableBody>{this.renderTableBody()}</TableBody>
        </Table>
        <div>{this.renderPerimeterHuntGroupDialog()}</div>
        <svg viewBox="0 0 800 800">
          <path
            class="path"
            d="M2,295 C363,348 395,4 800,47"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            stroke-dashoffset="1000"
            id="motion1"
          />
          <path
            d="M0,204 C377,367 504,22 797,225"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            id="motion2"
          />
          <circle
            class="circle"
            cx="284.3733028649552"
            cy="241.81299508566542"
            r="5"
            stroke="#f7faf9"
            fill="#f7faf9"
            stroke-width="0"
          />
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="40s" repeatCount="indefinite">
              <mpath xlinkHref="#motion1" />
            </animateMotion>
          </circle>
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="30s" repeatCount="indefinite">
              <mpath xlinkHref="#motion2" />
            </animateMotion>
          </circle>
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="245.5"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="288"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="238"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="281"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
        </svg>
      </div>
    ) : (
      <CircularLoading> </CircularLoading>
    );
  }
}

export default withStyles(styles)(HuntGroupsManagementTable);
