import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
import moment from "moment";
import "moment/locale/fr";
import { CircularLoadingButton, styles } from "../../Styles/ComponentStyles";
import history from "../../history";
import Grid from "@material-ui/core/Grid";
import green from "@material-ui/core/colors/green";
import { getAccountState } from "../../Services/AccountService";

//Set the fr language for moment
moment.locale("fr");
//const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD hh:mm');
//const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');// eslint-disable-line
//const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD');// eslint-disable-line
const startOfMonth = moment().clone().subtract(2, "month").startOf('month').format('YYYY-MM-DD');// eslint-disable-line
const endOfMonth = moment().clone().add(1, 'months').startOf('month').format('YYYY-MM-DD');// eslint-disable-line

const ButtonTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

class AppCardFinops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      app: props.app,
      environment: props.environment,
      account_id: props.account_id,
      classes: props.classes,
      redirect: false,
      status: "GATHERING",
      app_clickable: props.app_clickable,
      listFinopsInProgress: false,
      cost: 0,
      applicationState: undefined
    };

    if (props.account_id !== undefined) {
      getAccountState(props.account_id).then(result => {
        if (result === "up") {
          this.listCost(props.account_id);
        } else {
          this.setState({
            status: result,
            listFinopsInProgress: false
          });
        }
      });
    }
  }

  listCost(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ce",
        boto_method: "get_cost_and_usage",
        account_id: account_id
      },
      body: {
        TimePeriod: {
          Start: startOfMonth,
          End: endOfMonth
        },
        Granularity: "MONTHLY",
        Metrics: ["AmortizedCost"]
      }
    }).then(response => {
      let result = 0;
      let cost = 0;
      if (response["ResultsByTime"].length > 0) {
        cost = response["ResultsByTime"][1].Total.AmortizedCost.Amount;
        //result = cost.toString().match(/\d+\.\d{3}/)[0];
        result = parseFloat(cost).toFixed();
      }
      this.setState({
        cost: result,
        listFinopsInProgress: false
      });
    });
  }

  render() {
    const { app, environment } = this.state;

    let bgColor = "default";
    let finops = "finops";
    let mgLeft = "0px";
    let btVariant = "contained";
    let btTextColor = "#ffffff";
    let ftWeight = "bold";
    let btBorder = "2px solid gray";
    if (this.state.app_clickable) {
      if (this.state.cost === 0) {
        this.setState({ cost: finops });
      } else if (this.state.cost > 0) {
        btVariant = "contained";
        btBorder = "none";
        bgColor = "#5a82e0";
        ftWeight = "bold";
        btTextColor = "#ffffff";
        mgLeft = "0px";
      } else {
        btVariant = "outlined";
        btBorder = "2px solid #9de0b2";
        bgColor = "primary";
        ftWeight = "bold";
        mgLeft = "-4px";
        btTextColor = "#7e9fed";
      }
    }

    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify="flex-start"
        className={this.state.classes.gridContainer}
      >
        <Grid item xs={2} elevation={0}>
          <MuiThemeProvider theme={ButtonTheme}>
            {this.state.cost > 0 && (
              <Button
                size="small"
                color={bgColor}
                variant={btVariant}
                style={{
                  backgroundColor: bgColor,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: btBorder,
                  marginLeft: mgLeft,
                  marginTop: "-35px",
                  fontWeight: ftWeight,
                  fontSize: 14,
                  color: btTextColor,
                  height: "20px"
                }}
                onClick={() => {
                  history.push({
                    pathname: "/finops",
                    search: "?app=" + app + "&env=" + environment
                  });
                }}
              >
                {this.state.cost}
                {this.state.status === "GATHERING" &&
                this.state.cost === "undefined" ? (
                  <CircularLoadingButton />
                ) : (
                  false
                )}
              </Button>
            )}
            {this.state.cost === "finops" && (
              <Button
                size="small"
                disabled="true"
                color={bgColor}
                variant={btVariant}
                style={{
                  backgroundColor: bgColor,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: btBorder,
                  marginLeft: mgLeft,
                  marginTop: "-35px",
                  fontWeight: ftWeight,
                  fontSize: 12,
                  color: btTextColor,
                  height: "20px"
                }}
              >
                {this.state.cost}
                {this.state.status === "GATHERING" &&
                this.state.cost === "undefined" ? (
                  <CircularLoadingButton />
                ) : (
                  false
                )}
              </Button>
            )}
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AppCardFinops);
