import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { circularLoading } from "@yami-beta/react-circular-loading";

export const ListInputStyles = {
  formControl: {
    minWidth: 120,
    minHeight: 40
  },
  magic: {
    minWidth: 220,
    minHeight: 20
  },
  selectEmpty: {}
};

export const circleColor = {
  green: "#3de39b",
  blue: "#3d96e3",
  amber: "#faa200",
  red: "#d6423a",
  gray: "#f0f0f0",
  yellow: "#ffff00",
  black: "#000000"
};

export const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export const CircularLoading = circularLoading({
  // Distance of dot from center `em`
  distance: 1,
  // Dot's size `em`
  dotSize: 0.5,
  // Dot's color (base color)
  dotColor0: `rgba(0, 169, 132, 0.1)`,
  // Dot's color (semi highlight color)
  dotColor1: `rgba(0, 169, 132, 0.2)`,
  // Dot's color (highlight color)
  dotColor2: `rgba(0, 169, 132, 1.0)`,
  // A number of dots
  num: 8,
  // Animation speed `ms`
  speed: 1000
});

export const CircularLoadingButton = circularLoading({
  // Distance of dot from center `em`
  distance: 0.6,
  // Dot's size `em`
  dotSize: 0.4,
  // Dot's color (base color)
  dotColor0: `rgba(0, 169, 132, 0.1)`,
  // Dot's color (semi highlight color)
  dotColor1: `rgba(0, 169, 132, 0.2)`,
  // Dot's color (highlight color)
  dotColor2: `rgba(0, 169, 132, 1.0)`,
  // A number of dots
  num: 8,
  // Animation speed `ms`
  speed: 1000,

  size: "large"
});

export const styles = theme => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  },

  paperModalSurveillance: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    width: "80%"
  },

  // For AppCards.js & AWSResourcesAppCards.js
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  imgCard: {
    maxHeight: 140,
    maintainAspectRatio: true
  },

  // For EsRedirect.js
  rootEsRedirect: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For NoApps.js
  rootNoApps: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For DefaultWarning.js
  rootDefaultWarning: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For Progress.js
  progressInstanceProgress: {
    margin: theme.spacing.unit * 2
  },

  // For AppInfoMessage.js & EnvInfoMessage.js
  rootInfoMessage: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For AWSResourcesDashboardExpansionPanel.js & DashboardExpansionPanel.js
  rootPanel: {
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden"
  },
  headingPanel: {
    //fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightBold,
    "font-family": "sans-serif",
    "font-size": "16px",
    "font-weight": "600",
    "text-align": "center",
    border: "none"
  },

  //For SimpleMenu.js
  menuPanel: {
    "border-radius": "20px"
  },
  menuItem: {
    background: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#ffffff !important",
      color: "#08bfb0"
    },
    textTransform: "uppercase",
    fontFamily: "sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    color: "#6c80ba"
  },
  simpleButton: {
    "&:hover": {
      background: "none"
    }
  },

  //SimpleAlarmDashboardTable.js
  rootSimpleAlarmDashboardTable: {
    flexGrow: 1
  },
  typography: {
    flexGrow: 1,
    align: "center"
  },
  typographyComp: {
    "text-align": "center"
  },

  // For PrimarySearchAppBar.js
  rootSearchBar: {
    width: "100%"
  },
  growSearchBar: {
    flexGrow: 1
  },
  menuButtonSearchBar: {
    marginLeft: 20,
    marginRight: 0,
    marginTop: 8,
    "& svg": {
      fontSize: 40
    }
  },
  UserInfoSearchBar: {
    display: "none",
    marginTop: 8,
    [theme.breakpoints.up("xs")]: {
      display: "block"
    }
  },
  titleSearchBar: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block"
    }
  },
  sectionDesktopSearchBar: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobileSearchBar: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },

  // For LowLevelDashboard Tabs
  dashboardAppBar: {
    width: "100%",
    background: "#f7faf9"
  },
  dashboardTabArrow: {
    fontSize: "8px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#10519c"
  },
  dashboardTab: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#10519c"
  },

  // For ApplicationSelector.js
  formControlAppSelector: {
    padding: theme.spacing.unit * 3,
    minWidth: 120,
    width: 30,
    margin: "auto",
    textAlign: "center"
  },

  // For ChannelMethodSelector.js
  formChannelMethodSelector: {
    padding: theme.spacing.unit * 3,
    minWidth: 120,
    width: 30,
    margin: "auto",
    textAlign: "center"
  },

  paperAppSelect: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: "100%",
    "min-height": "800px",
    overflowX: "auto",
    overflowY: "hidden",
    "box-shadow": "none",
    background: "#f7faf9"
  },

  paperAppSelectLogs: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    width: "100%",
    textAlign: "center",
    "min-height": "100px",
    overflowX: "auto",
    overflowY: "hidden",
    "box-shadow": "none",
    background: "#f7faf9"
  },

  paperAppSelectFirstLogs: {
    marginTop: "50px",
    marginBottom: theme.spacing.unit * 1,
    width: "100%",
    textAlign: "center",
    "min-height": "100px",
    overflowX: "auto",
    overflowY: "hidden",
    "box-shadow": "none",
    background: "#f7faf9"
  },

  paperAppSelectLastLogs: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    width: "100%",
    textAlign: "center",
    "min-height": "400px",
    overflowX: "auto",
    overflowY: "hidden",
    "box-shadow": "none",
    background: "#f7faf9"
  },

  paperAppSelectAlarmDashboar: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    "box-shadow": "none",
    background: "#f7faf9"
  },

  paperAlarmList: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    "box-shadow": "none",
    background: "#f7faf9",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "border-color": "red",
    "border-radius": "30px 30px 30px 30px",
    "border-width": "5px"
  },

  paperActionList: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    "box-shadow": "none",
    background: "#f7faf9",
    width: "100%",
    height: "auto",
    "border-color": "red",
    "border-radius": "30px 30px 30px 30px",
    "border-width": "5px"
  },

  paperChannelList: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    "box-shadow": "none",
    background: "#f7faf9",
    height: "auto",
    overflow: "auto",
    "border-radius": "30px 30px 30px 30px"
  },

  gridContainer: {
    padding: theme.spacing.unit,
    "box-shadow": "none"
  },

  headlines: {
    padding: theme.spacing.unit
  },

  envSelector: {
    padding: theme.spacing.unit * 3,
    minWidth: 120
  },

  // For AccountSelector.js
  formControlAccountSelector: {
    margin: theme.spacing.unit,
    minWidth: 120
  },

  // For Table.js
  paperTable: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    "box-shadow": "none",
    overflowX: "auto"
  },
  tableTable: {
    minWidth: 700,
    marginTop: theme.spacing.unit * 3,
    "box-shadow": "none",
    background: "#f7faf9",
    width: "100%",
    height: "auto",
    overflow: "auto",
    "border-color": "red",
    "border-radius": "30px 30px 30px 30px",
    "border-width": "5px"
  },
  rowTable: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },

  pre: {
    display: "block",
    padding: "10px 30px",
    margin: "0",
    overflow: "scroll"
  },

  // For TablePaginateLogActions.js
  rootPaginate: {
    width: "auto",
    marginTop: theme.spacing.unit * 3,
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 30,
    "box-shadow": "none",
    "border-radius": 30
  },
  tablePaginate: {
    minWidth: 500
  },
  tableWrapperPaginate: {
    overflowX: "auto"
  },

  dutyButton: {
    display: "flex",
    justify: "center"
  }
});

export const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
    background: "#f7faf9"
  }
});
