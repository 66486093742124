/* eslint-disable */
import React, { useEffect, useState } from "react";
import { getPipelineExecutions } from "../../Services/PipelinesService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import moment from "moment";
import EventNoteIcon from '@material-ui/icons/EventNote';
import BuildIcon from '@material-ui/icons/Build';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import UpdateIcon from '@material-ui/icons/Update';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';


const PipelineHistoryDashboard = ({ pipelineName, accountId }) => {
  const [executions, setExecutions] = useState(undefined);

  useEffect(() => {
    const fetchPipelineExecutions = async () => {
      if (!executions) {
        const fetchedExecutions = await getPipelineExecutions(pipelineName, accountId);
        setExecutions(fetchedExecutions.pipelineExecutionSummaries);
      }
    };

    fetchPipelineExecutions();
  }, [pipelineName, accountId, executions]);

  const handleRefresh = () => {
    setExecutions(undefined);
  };

  const formatStatus = (status) => {
    return status === 'Failed' ? `❌` : `✅`;
  };

  const formatDateTime = (dateTime) => {
    return dateTime ? moment(dateTime).format("MMM Do YYYY, h:mm:ss a") : 'N/A';
  };

  const formatSourceRevisions = (revisions) => {
    // Pas de changements nécessaires ici
    if (!revisions || revisions.length === 0) return 'N/A';
  
    return revisions.map((rev, index) => (
      `#${index}: actionName: ${rev.actionName},\nrevisionId: ${rev.revisionId},\n✨ revisionSummary: ${rev.revisionSummary || 'N/A'}`
    )).join('\n\n');
  };
  
  const formatTriggerDetail = (triggerDetail) => {
    // Enlève 'arn:...' jusqu'au '/' inclus
    const cleanedTriggerDetail = triggerDetail.replace(/arn:[^\/]*\//, '');
  
    const detailMatch = cleanedTriggerDetail.match(/Account ID: (\d+), Rule: (.+)$/);
    if (detailMatch) {
      // Ajoute des retours à la ligne après les virgules et les barres obliques
      return `${detailMatch[1].replace(/,|\//g, '$&\n')}, ${detailMatch[2].replace(/,|\//g, '$&\n')}`;
    }
    return cleanedTriggerDetail.replace(/,|\//g, '$&\n'); // Remplacement pour les valeurs non conformes
  };
  
  const formatObjectForDisplay = (obj) => {
    if (!obj) return 'N/A';
  
    const formatValue = (key, value) => {
      if (key === 'triggerType') {
        return value; // retourne juste la valeur pour triggerType
      } else if (key === 'triggerDetail') {
        return formatTriggerDetail(value); // traite et retourne triggerDetail
      }
      return ''; // ignorer les autres clés
    };
  
    return Object.entries(obj)
      .map(([key, value]) => formatValue(key, value))
      .filter(part => part !== '') // filtre les parties vides
      .join(', ').replace(/,|\//g, '$&\n'); // Ajoute des retours à la ligne après chaque ',' et '/'
  };  

  return (
    <div style={{ marginTop: "0px", fontSize: "16px", marginLeft: "20px", marginRight: "20px" }}>
      <h1 style={{ color: "white", fontSize: "20px" }}>
      <Button
          size="small"
          variant="contained"
          style={{ 
            fontSize: "13px",
            backgroundColor: "#00BFFF", // Vert-bleu
            borderRadius: "20px", // Bords arrondis
            color: "white", // Texte en blanc
            boxShadow: "none",
            fontWeight: 'bold', // Pas d'ombre
          }}
          onClick={handleRefresh}
        >
          {pipelineName} | history
          <ReplayIcon style={{ width: "30px", height: "20px", fontSize: "16px" }} />
        </Button>
      </h1>
  
      {executions ? (
        <Paper style={{ borderRadius: "25px", boxShadow: "none", overflowX: "auto" }}> {/* Ajout de overflowX pour la réactivité */}
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#dcf7f1" }}>
                <TableCell style={{ fontSize: "16px", color: "#16a697", fontWeight: 'bold', borderBottom: "none" }}>
                  Execution ID
                </TableCell>
                <TableCell style={{ maxWidth: "20px", fontSize: "16px", color: "#16a697", fontWeight: 'bold', borderBottom: "none" }}>
                  Status
                </TableCell>
                <TableCell style={{ minWidth: "60px", fontSize: "16px", color: "#16a697", fontWeight: 'bold', borderBottom: "none" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AccessTimeIcon style={{ color: "gray" }} /><span>Start</span>
                  </div>
                </TableCell>
                <TableCell style={{ minWidth: "60px", fontSize: "16px", color: "#16a697", fontWeight: 'bold', borderBottom: "none" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UpdateIcon style={{ color: "gray" }} /><span>Update</span>
                  </div>
                </TableCell>
                <TableCell style={{ fontSize: "16px", color: "#16a697", fontWeight: 'bold', borderBottom: "none" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <EventNoteIcon style={{ color: "gray" }} /><span>Source Revisions</span>
                  </div>
                </TableCell>
                <TableCell style={{ fontSize: "16px", color: "#16a697", fontWeight: 'bold', borderBottom: "none" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BuildIcon style={{ color: "gray" }} /><span>Trigger</span>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {executions.map((execution, index) => (
                <TableRow key={index} style={{ borderBottom: "none" }}> {/* Enlève les lignes entre les rangées */}
                  <TableCell style={{ fontWeight: 'bold', fontSize: "12px" }}>{execution.pipelineExecutionId}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: "16px" }}>{formatStatus(execution.status)}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: "12px" }}>{formatDateTime(execution.startTime)}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: "12px" }}>{formatDateTime(execution.lastUpdateTime)}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: "12px" }}>
                    <pre style={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {formatSourceRevisions(execution.sourceRevisions)}
                    </pre>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: "12px" }}>{formatObjectForDisplay(execution.trigger)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <CircularLoading />
      )}
    </div>
  );  
};

export default PipelineHistoryDashboard;
