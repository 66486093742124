/* eslint-disable indent */
import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import { withSnackbar } from "notistack";
import moment from "moment";
import Moment from "moment";
import PropTypes from "prop-types";

import AddChannelDialog from "../Dialogs/AddChannelDialog";
import Circle from "../Geometric/Circle";
import {
  circleColor,
  CircularLoading,
  styles
} from "../../Styles/ComponentStyles";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import {
  getAccountAlarmLogs,
  getAccountState
} from "../../Services/AccountService";

import {
  LineChart,
  Line,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area
} from "recharts";

//Set the fr language for moment
moment.locale("fr");
const month = moment().format("MMMM");// eslint-disable-line
const prevMonth1 = moment().subtract(1, "month").format("MMMM");// eslint-disable-line
const prevMonth2 = moment().subtract(2, "month").format("MMMM");// eslint-disable-line
const prevMonth3 = moment().subtract(3, "month").format("MMMM");// eslint-disable-line
const prevMonth4 = moment().subtract(4, "month").format("MMMM");// eslint-disable-line
const prevMonth5 = moment().subtract(5, "month").format("MMMM");// eslint-disable-line
const prevMonth6 = moment().subtract(6, "month").format("MMMM");// eslint-disable-line
const prevMonth7 = moment().subtract(7, "month").format("MMMM");// eslint-disable-line
const prevMonth8 = moment().subtract(8, "month").format("MMMM");// eslint-disable-line
const prevMonth9 = moment().subtract(9, "month").format("MMMM");// eslint-disable-line
const prevMonth10 = moment().subtract(10, "month").format("MMMM");// eslint-disable-line
const prevMonth11 = moment().subtract(11, "month").format("MMMM");// eslint-disable-line
//const endOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
const startOfMonth = moment().clone().subtract(11, "month").startOf('month').format('YYYY-MM-DD');// eslint-disable-line
const endOfMonth = moment().clone().add(1, 'months').startOf('month').format('YYYY-MM-DD');// eslint-disable-line
//const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD');// eslint-disable-line
//const startOfMonth = moment().clone().subtract(1, "month").startOf('month').format('YYYY-MM-DD');// eslint-disable-line
//const endOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
//const startOfMonth = moment().clone().subtract(12, "month").startOf('month').format('YYYY-MM-DD');// eslint-disable-line
//const endOfMonth = moment().clone().subtract(10, "month").startOf('month').format('YYYY-MM-DD');// eslint-disable-line

class FinopsDashboardTableYear extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      app: undefined,
      env: undefined,
      compliances: undefined,
      alarms: undefined,
      alarmsWithHistory: undefined,
      listAlarmInProgress: false,
      loading: false,
      surveillance: undefined,
      modalSurveillanceOpen: false,
      modalAddChannelOpen: false,
      modalHistoryOpen: false,
      current_alarm: undefined,
      form_account_id_error: false,
      seconds: 0,
      applicationState: undefined,
      listComplianceInProgress: false,
      listCostInProgress: false,
      CostService: undefined,
      chartHeight: 400,
      chartWidth: 600,
      MonthlyTotalLine: [],
      MonthlyTotal: [],
      TwelveMonthlyTotal: 0,
      AmortizedCost: [],
      LambdaCost: 0,
      CloudTCost: 0,
      ConfigCost: 0,
      DirCntCost: 0,
      GlueCost: 0,
      KeyMgtCost: 0,
      SecMgrCost: 0,
      SecHubCost: 0,
      ShieldCost: 0,
      WafCost: 0,
      DynaCost: 0,
      RDSCost: 0,
      EC2OtCost: 0,
      ECCCost: 0,
      ELBCost: 0,
      EFSCost: 0,
      FSXCost: 0,
      GDCost: 0,
      InspCost: 0,
      SESCost: 0,
      SNSCost: 0,
      SQSCost: 0,
      SSSCost: 0,
      VPCCost: 0,
      WSCost: 0,
      CWCost: 0,
      ASCost: 0,
      barData: [12, 5, 6, 6, 9, 10],
      barWidth: 700,
      barHeight: 500,
      criticalNonCompliantCount: 0,
      highNonCompliantCount: 0,
      mediumNonCompliantCount: 0,
      lowNonCompliantCount: 0,
      informationalNonCompliantCount: 0,
      unspecifiedNonCompliantCount: 0,
      resourceId: [],
      resourceIdExecution: [],
      secopsDonutData: [],
      cost: 0
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.handleSurveillanceClose = this.handleSurveillanceClose.bind(this);
    this.handleHistoryClose = this.handleHistoryClose.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
    this.listCost = this.listCost.bind(this);
    this.listMonthlyCost = this.listMonthlyCost.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getModalStyle() {
    return {
      margin: "auto"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });

    if (nextProps.account_id !== undefined) {
      if (
        this.state.alarms === undefined ||
        this.state.account_id !== nextProps.account_id
      ) {
        this.setState({
          loading: true,
          listComplianceInProgress: true,
          listCostInProgress: true
        });
        getAccountState(this.state.account_id).then(result => {
          this.setState({
            applicationState: result
          });
        });
        this.listAlarms(nextProps.account_id);
        this.listCost(nextProps.account_id, "ALL");
        this.listMonthlyCost(nextProps.account_id, "ALL");
      }
    }
  }

  listMonthlyCost(account_id, serviceName) {
    //let service = serviceName;
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ce",
        boto_method: "get_cost_and_usage",
        account_id: account_id
      },
      body: {
        TimePeriod: {
          Start: startOfMonth,
          End: endOfMonth
        },
        Granularity: "MONTHLY",
        Metrics: ["AmortizedCost"]
      }
    }).then(response => {
      let MonthlyTotalLine = [];
      let ThresholdArray = [];
      let op_Threshold = 0;
      let TotalCost = [];
      let op_TwelveMonthly = 0;
      let TwelveMonthlyTotal = 0;

      function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
      }

      if (response["ResultsByTime"].length > 0) {
        MonthlyTotalLine = [// eslint-disable-line
          parseFloat(response["ResultsByTime"][0].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][1].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][2].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][3].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][4].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][5].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][6].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][7].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][8].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][9].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][10].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][11].Total.AmortizedCost.Amount).toFixed(2)// eslint-disable-line
        ];// eslint-disable-line
        ThresholdArray = [// eslint-disable-line
          parseFloat(response["ResultsByTime"][0].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][1].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][2].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][3].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][4].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][5].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][6].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][7].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][8].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][9].Total.AmortizedCost.Amount).toFixed(2),// eslint-disable-line
          parseFloat(response["ResultsByTime"][10].Total.AmortizedCost.Amount).toFixed(2)// eslint-disable-line
        ];// eslint-disable-line
        op_Threshold = getMaxOfArray(ThresholdArray);
        TotalCost[0] = {
          name: prevMonth11,
          total: parseFloat(
            response["ResultsByTime"][0].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][0].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[1] = {
          name: prevMonth10,
          total: parseFloat(
            response["ResultsByTime"][1].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][1].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[2] = {
          name: prevMonth9,
          total: parseFloat(
            response["ResultsByTime"][2].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][2].Total.AmortizedCost.Amount
          ).toFixed(),
          "max par mois": op_Threshold
        };
        TotalCost[3] = {
          name: prevMonth8,
          total: parseFloat(
            response["ResultsByTime"][3].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][3].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[4] = {
          name: prevMonth7,
          total: parseFloat(
            response["ResultsByTime"][4].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][4].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[5] = {
          name: prevMonth6,
          total: parseFloat(
            response["ResultsByTime"][5].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][5].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[6] = {
          name: prevMonth5,
          total: parseFloat(
            response["ResultsByTime"][6].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][6].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[7] = {
          name: prevMonth4,
          total: parseFloat(
            response["ResultsByTime"][7].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][7].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[8] = {
          name: prevMonth3,
          total: parseFloat(
            response["ResultsByTime"][8].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][8].Total.AmortizedCost.Amount
          ).toFixed(),
          "max par mois": op_Threshold
        };
        TotalCost[9] = {
          name: prevMonth2,
          total: parseFloat(
            response["ResultsByTime"][9].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][9].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[10] = {
          name: prevMonth1,
          total: parseFloat(
            response["ResultsByTime"][10].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][10].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        TotalCost[11] = {
          name: month,
          total: parseFloat(
            response["ResultsByTime"][11].Total.AmortizedCost.Amount
          ).toFixed(2),
          "total du mois": parseFloat(
            response["ResultsByTime"][11].Total.AmortizedCost.Amount
          ).toFixed(2),
          "max par mois": op_Threshold
        };
        op_TwelveMonthly =
          parseFloat(response["ResultsByTime"][0].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][1].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][2].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][3].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][4].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][5].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][6].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][7].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][8].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][9].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][10].Total.AmortizedCost.Amount) +
          parseFloat(response["ResultsByTime"][11].Total.AmortizedCost.Amount);
        TwelveMonthlyTotal = op_TwelveMonthly.toFixed(2);
      }
      this.setState({
        MonthlyTotal: TotalCost,
        MonthlyTotalLine: MonthlyTotalLine,
        TwelveMonthlyTotal: TwelveMonthlyTotal,
        listFinopsInProgress: false
      });
    });
  }

  listCost(account_id, serviceName) {
    let service = serviceName;
    let costObj = {};
    let costObj1 = {};
    let costObj2 = {};
    let costObj3 = {};
    let costObj4 = {};
    let costObj5 = {};
    let costObj6 = {};
    let costObj7 = {};
    let costObj8 = {};
    let costObj9 = {};
    let costObj10 = {};
    let costObj11 = {};
    let cost,
      cost1,
      cost2,
      cost3,
      cost4,
      cost5,
      cost6,
      cost7,
      cost8,
      cost9,
      cost10,
      cost11 = 0;
    let costLambda,
      costLambda1,
      costLambda2,
      costLambda3,
      costLambda4,
      costLambda5,
      costLambda6,
      costLambda7,
      costLambda8,
      costLambda9,
      costLambda10,
      costLambda11 = 0;
    let costCloudT,
      costCloudT1,
      costCloudT2,
      costCloudT3,
      costCloudT4,
      costCloudT5,
      costCloudT6,
      costCloudT7,
      costCloudT8,
      costCloudT9,
      costCloudT10,
      costCloudT11 = 0;
    let costConfig,
      costConfig1,
      costConfig2,
      costConfig3,
      costConfig4,
      costConfig5,
      costConfig6,
      costConfig7,
      costConfig8,
      costConfig9,
      costConfig10,
      costConfig11 = 0;
    let costDirCnt,
      costDirCnt1,
      costDirCnt2,
      costDirCnt3,
      costDirCnt4,
      costDirCnt5,
      costDirCnt6,
      costDirCnt7,
      costDirCnt8,
      costDirCnt9,
      costDirCnt10,
      costDirCnt11 = 0;
    let costGlue,
      costGlue1,
      costGlue2,
      costGlue3,
      costGlue4,
      costGlue5,
      costGlue6,
      costGlue7,
      costGlue8,
      costGlue9,
      costGlue10,
      costGlue11 = 0;
    let costKeyMgt,
      costKeyMgt1,
      costKeyMgt2,
      costKeyMgt3,
      costKeyMgt4,
      costKeyMgt5,
      costKeyMgt6,
      costKeyMgt7,
      costKeyMgt8,
      costKeyMgt9,
      costKeyMgt10,
      costKeyMgt11 = 0;
    let costSecMgr,
      costSecMgr1,
      costSecMgr2,
      costSecMgr3,
      costSecMgr4,
      costSecMgr5,
      costSecMgr6,
      costSecMgr7,
      costSecMgr8,
      costSecMgr9,
      costSecMgr10,
      costSecMgr11 = 0;
    let costSecHub,
      costSecHub1,
      costSecHub2,
      costSecHub3,
      costSecHub4,
      costSecHub5,
      costSecHub6,
      costSecHub7,
      costSecHub8,
      costSecHub9,
      costSecHub10,
      costSecHub11 = 0;
    let costShield,
      costShield1,
      costShield2,
      costShield3,
      costShield4,
      costShield5,
      costShield6,
      costShield7,
      costShield8,
      costShield9,
      costShield10,
      costShield11 = 0;
    let costWaf,
      costWaf1,
      costWaf2,
      costWaf3,
      costWaf4,
      costWaf5,
      costWaf6,
      costWaf7,
      costWaf8,
      costWaf9,
      costWaf10,
      costWaf11 = 0;
    let costDyna,
      costDyna1,
      costDyna2,
      costDyna3,
      costDyna4,
      costDyna5,
      costDyna6,
      costDyna7,
      costDyna8,
      costDyna9,
      costDyna10,
      costDyna11 = 0;
    let costRDS,
      costRDS1,
      costRDS2,
      costRDS3,
      costRDS4,
      costRDS5,
      costRDS6,
      costRDS7,
      costRDS8,
      costRDS9,
      costRDS10,
      costRDS11 = 0;
    let costEC2Ot,
      costEC2Ot1,
      costEC2Ot2,
      costEC2Ot3,
      costEC2Ot4,
      costEC2Ot5,
      costEC2Ot6,
      costEC2Ot7,
      costEC2Ot8,
      costEC2Ot9,
      costEC2Ot10,
      costEC2Ot11 = 0;
    let costECC,
      costECC1,
      costECC2,
      costECC3,
      costECC4,
      costECC5,
      costECC6,
      costECC7,
      costECC8,
      costECC9,
      costECC10,
      costECC11 = 0;
    let costELB,
      costELB1,
      costELB2,
      costELB3,
      costELB4,
      costELB5,
      costELB6,
      costELB7,
      costELB8,
      costELB9,
      costELB10,
      costELB11 = 0;
    let costEFS,
      costEFS1,
      costEFS2,
      costEFS3,
      costEFS4,
      costEFS5,
      costEFS6,
      costEFS7,
      costEFS8,
      costEFS9,
      costEFS10,
      costEFS11 = 0;
    let costFSX,
      costFSX1,
      costFSX2,
      costFSX3,
      costFSX4,
      costFSX5,
      costFSX6,
      costFSX7,
      costFSX8,
      costFSX9,
      costFSX10,
      costFSX11 = 0;
    let costGD,
      costGD1,
      costGD2,
      costGD3,
      costGD4,
      costGD5,
      costGD6,
      costGD7,
      costGD8,
      costGD9,
      costGD10,
      costGD11 = 0;
    let costInsp,
      costInsp1,
      costInsp2,
      costInsp3,
      costInsp4,
      costInsp5,
      costInsp6,
      costInsp7,
      costInsp8,
      costInsp9,
      costInsp10,
      costInsp11 = 0;
    let costSES,
      costSES1,
      costSES2,
      costSES3,
      costSES4,
      costSES5,
      costSES6,
      costSES7,
      costSES8,
      costSES9,
      costSES10,
      costSES11 = 0;
    let costSNS,
      costSNS1,
      costSNS2,
      costSNS3,
      costSNS4,
      costSNS5,
      costSNS6,
      costSNS7,
      costSNS8,
      costSNS9,
      costSNS10,
      costSNS11 = 0;
    let costSQS,
      costSQS1,
      costSQS2,
      costSQS3,
      costSQS4,
      costSQS5,
      costSQS6,
      costSQS7,
      costSQS8,
      costSQS9,
      costSQS10,
      costSQS11 = 0;
    let costSSS,
      costSSS1,
      costSSS2,
      costSSS3,
      costSSS4,
      costSSS5,
      costSSS6,
      costSSS7,
      costSSS8,
      costSSS9,
      costSSS10,
      costSSS11 = 0;
    let costVPC,
      costVPC1,
      costVPC2,
      costVPC3,
      costVPC4,
      costVPC5,
      costVPC6,
      costVPC7,
      costVPC8,
      costVPC9,
      costVPC10,
      costVPC11 = 0;
    let costWS,
      costWS1,
      costWS2,
      costWS3,
      costWS4,
      costWS5,
      costWS6,
      costWS7,
      costWS8,
      costWS9,
      costWS10,
      costWS11 = 0;
    let costCW,
      costCW1,
      costCW2,
      costCW3,
      costCW4,
      costCW5,
      costCW6,
      costCW7,
      costCW8,
      costCW9,
      costCW10,
      costCW11 = 0;
    let costCWE,
      costCWE1,
      costCWE2,
      costCWE3,
      costCWE4,
      costCWE5,
      costCWE6,
      costCWE7,
      costCWE8,
      costCWE9,
      costCWE10,
      costCWE11 = 0;
    let costCA,
      costCA1,
      costCA2,
      costCA3,
      costCA4,
      costCA5,
      costCA6,
      costCA7,
      costCA8,
      costCA9,
      costCA10,
      costCA11 = 0;
    let costSF,
      costSF1,
      costSF2,
      costSF3,
      costSF4,
      costSF5,
      costSF6,
      costSF7,
      costSF8,
      costSF9,
      costSF10,
      costSF11 = 0;
    let costECR,
      costECR1,
      costECR2,
      costECR3,
      costECR4,
      costECR5,
      costECR6,
      costECR7,
      costECR8,
      costECR9,
      costECR10,
      costECR11 = 0;
    let costTF,
      costTF1,
      costTF2,
      costTF3,
      costTF4,
      costTF5,
      costTF6,
      costTF7,
      costTF8,
      costTF9,
      costTF10,
      costTF11 = 0;
    let costAG,
      costAG1,
      costAG2,
      costAG3,
      costAG4,
      costAG5,
      costAG6,
      costAG7,
      costAG8,
      costAG9,
      costAG10,
      costAG11 = 0;
    let costAS,
      costAS1,
      costAS2,
      costAS3,
      costAS4,
      costAS5,
      costAS6,
      costAS7,
      costAS8,
      costAS9,
      costAS10,
      costAS11 = 0;
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ce",
        boto_method: "get_cost_and_usage",
        account_id: account_id
      },
      body: {
        TimePeriod: {
          Start: startOfMonth,
          End: endOfMonth
        },
        Granularity: "MONTHLY",
        GroupBy: [
          {
            Key: "SERVICE",
            Type: "DIMENSION"
          }
        ],
        Filter: {
          Dimensions: {
            Key: "REGION",
            MatchOptions: ["EQUALS"],
            Values: ["eu-west-1"]
          }
        },
        Metrics: ["AmortizedCost"]
      }
    }).then(async response => {
      if (response["ResultsByTime"].length > 0) {
        cost = response["ResultsByTime"][0].Groups;
        cost.forEach((element, index, array) => {
          //console.log(element.Keys);
          if (element.Keys[0] === "AWS Lambda") {
            costLambda = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Lambda"] = costLambda;
            //console.log(costLambda);
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT = element.Metrics.AmortizedCost.Amount;
            costObj["AWS CloudTrail"] = costCloudT;
            //console.log(costCloudT);
          } else if (element.Keys[0] === "AWS Config") {
            costConfig = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Config"] = costConfig;
            //console.log(costConfig);
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Direct Connect"] = costDirCnt;
            //console.log(costDirCnt);
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Glue"] = costGlue;
            //console.log(costGlue);
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Key Management Service"] = costKeyMgt;
            //console.log(costKeyMgt);
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Secrets Manager"] = costSecMgr;
            //console.log(costSecMgr);
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Security Hub"] = costSecHub;
            //console.log(costSecHub);
          } else if (element.Keys[0] === "AWS Shield") {
            costShield = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Shield"] = costShield;
            //console.log(costShield);
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf = element.Metrics.AmortizedCost.Amount;
            costObj["AWS WAF"] = costWaf;
            //console.log(costWaf);
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon DynamoDB"] = costDyna;
            //console.log(costDyna);
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Relational Database Service"] = costRDS;
            //console.log(costDyna);
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot = element.Metrics.AmortizedCost.Amount;
            costObj["EC2 - Other"] = costEC2Ot;
            //console.log(costEC2Ot);
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Elastic Compute Cloud - Compute"] = costECC;
            //console.log(costECC);
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Elastic Load Balancing"] = costELB;
            //console.log(costELB);
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Elastic File System"] = costEFS;
            //console.log(costEFS);
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon FSx"] = costFSX;
            //console.log(costFSX);
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon GuardDuty"] = costGD;
            //console.log(costGD);
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Inspector"] = costInsp;
            //console.log(costInsp);
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Email Service"] = costSES;
            //console.log(costSNS);
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Notification Service"] = costSNS;
            //console.log(costSNS);
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Queue Service"] = costSQS;
            //console.log(costSQS);
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Simple Storage Service"] = costSSS;
            //console.log(costSSS);
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon Virtual Private Cloud"] = costVPC;
            //console.log(costVPC);
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon WorkSpaces"] = costWS;
            //console.log(costWS);
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW = element.Metrics.AmortizedCost.Amount;
            costObj["AmazonCloudWatch"] = costCW;
            //console.log(costCW);
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE = element.Metrics.AmortizedCost.Amount;
            costObj["CloudWatch Events"] = costCWE;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA = element.Metrics.AmortizedCost.Amount;
            costObj["AWS CodeArtifact"] = costCA;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Step Functions"] = costSF;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon EC2 Container Registry (ECR)"] = costECR;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF = element.Metrics.AmortizedCost.Amount;
            costObj["AWS Transfer Family"] = costTF;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon API Gateway"] = costAG;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS = element.Metrics.AmortizedCost.Amount;
            costObj["Amazon AppStream"] = costAS;
          }
        });
        cost1 = response["ResultsByTime"][1].Groups;
        cost1.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Lambda"] = costLambda1;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS CloudTrail"] = costCloudT1;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Config"] = costConfig1;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Direct Connect"] = costDirCnt1;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Glue"] = costGlue1;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Key Management Service"] = costKeyMgt1;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Secrets Manager"] = costSecMgr1;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Security Hub"] = costSecHub1;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Shield"] = costShield1;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS WAF"] = costWaf1;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon DynamoDB"] = costDyna1;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Relational Database Service"] = costRDS1;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot1 = element.Metrics.AmortizedCost.Amount;
            costObj1["EC2 - Other"] = costEC2Ot1;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Elastic Compute Cloud - Compute"] = costECC1;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Elastic Load Balancing"] = costELB1;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Elastic File System"] = costEFS1;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon FSx"] = costFSX1;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon GuardDuty"] = costGD1;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Inspector"] = costInsp1;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Email Service"] = costSES1;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Notification Service"] = costSNS1;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Queue Service"] = costSQS1;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Simple Storage Service"] = costSSS1;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon Virtual Private Cloud"] = costVPC1;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon WorkSpaces"] = costWS1;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AmazonCloudWatch"] = costCW1;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE1 = element.Metrics.AmortizedCost.Amount;
            costObj1["CloudWatch Events"] = costCWE1;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS CodeArtifact"] = costCA1;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Step Functions"] = costSF1;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon EC2 Container Registry (ECR)"] = costECR1;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF1 = element.Metrics.AmortizedCost.Amount;
            costObj1["AWS Transfer Family"] = costTF1;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon API Gateway"] = costAG1;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS1 = element.Metrics.AmortizedCost.Amount;
            costObj1["Amazon AppStream"] = costAS1;
          }
        });
        cost2 = response["ResultsByTime"][2].Groups;
        cost2.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Lambda"] = costLambda2;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS CloudTrail"] = costCloudT2;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Config"] = costConfig2;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Direct Connect"] = costDirCnt2;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Glue"] = costGlue2;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Key Management Service"] = costKeyMgt2;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Secrets Manager"] = costSecMgr2;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Security Hub"] = costSecHub2;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Shield"] = costShield2;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS WAF"] = costWaf2;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon DynamoDB"] = costDyna2;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Relational Database Service"] = costRDS2;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot2 = element.Metrics.AmortizedCost.Amount;
            costObj2["EC2 - Other"] = costEC2Ot2;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Elastic Compute Cloud - Compute"] = costECC2;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Elastic Load Balancing"] = costELB2;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Elastic File System"] = costEFS2;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon FSx"] = costFSX2;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon GuardDuty"] = costGD2;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Inspector"] = costInsp2;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Email Service"] = costSES2;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Notification Service"] = costSNS2;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Queue Service"] = costSQS2;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Simple Storage Service"] = costSSS2;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon Virtual Private Cloud"] = costVPC2;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon WorkSpaces"] = costWS2;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AmazonCloudWatch"] = costCW2;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE2 = element.Metrics.AmortizedCost.Amount;
            costObj2["CloudWatch Events"] = costCWE2;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS CodeArtifact"] = costCA2;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Step Functions"] = costSF2;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon EC2 Container Registry (ECR)"] = costECR2;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF2 = element.Metrics.AmortizedCost.Amount;
            costObj2["AWS Transfer Family"] = costTF2;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon API Gateway"] = costAG2;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS2 = element.Metrics.AmortizedCost.Amount;
            costObj2["Amazon AppStream"] = costAS2;
          }
        });
        cost3 = response["ResultsByTime"][3].Groups;
        cost3.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Lambda"] = costLambda3;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS CloudTrail"] = costCloudT3;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Config"] = costConfig3;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Direct Connect"] = costDirCnt3;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Glue"] = costGlue3;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Key Management Service"] = costKeyMgt3;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Secrets Manager"] = costSecMgr3;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Security Hub"] = costSecHub3;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Shield"] = costShield3;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS WAF"] = costWaf3;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon DynamoDB"] = costDyna3;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Relational Database Service"] = costRDS3;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot3 = element.Metrics.AmortizedCost.Amount;
            costObj3["EC2 - Other"] = costEC2Ot3;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Elastic Compute Cloud - Compute"] = costECC3;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Elastic Load Balancing"] = costELB3;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Elastic File System"] = costEFS3;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon FSx"] = costFSX3;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon GuardDuty"] = costGD3;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Inspector"] = costInsp3;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Email Service"] = costSES3;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Notification Service"] = costSNS3;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Queue Service"] = costSQS3;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Simple Storage Service"] = costSSS3;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon Virtual Private Cloud"] = costVPC3;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon WorkSpaces"] = costWS3;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AmazonCloudWatch"] = costCW3;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE3 = element.Metrics.AmortizedCost.Amount;
            costObj3["CloudWatch Events"] = costCWE3;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS CodeArtifact"] = costCA3;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Step Functions"] = costSF3;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon EC2 Container Registry (ECR)"] = costECR3;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF3 = element.Metrics.AmortizedCost.Amount;
            costObj3["AWS Transfer Family"] = costTF3;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon API Gateway"] = costAG3;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS3 = element.Metrics.AmortizedCost.Amount;
            costObj3["Amazon AppStream"] = costAS3;
          }
        });
        cost4 = response["ResultsByTime"][4].Groups;
        cost4.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Lambda"] = costLambda4;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS CloudTrail"] = costCloudT4;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Config"] = costConfig4;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Direct Connect"] = costDirCnt4;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Glue"] = costGlue4;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Key Management Service"] = costKeyMgt4;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Secrets Manager"] = costSecMgr4;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Security Hub"] = costSecHub4;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Shield"] = costShield4;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS WAF"] = costWaf4;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon DynamoDB"] = costDyna4;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Relational Database Service"] = costRDS4;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot4 = element.Metrics.AmortizedCost.Amount;
            costObj4["EC2 - Other"] = costEC2Ot4;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Elastic Compute Cloud - Compute"] = costECC4;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Elastic Load Balancing"] = costELB4;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Elastic File System"] = costEFS4;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon FSx"] = costFSX4;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon GuardDuty"] = costGD4;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Inspector"] = costInsp4;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Email Service"] = costSES4;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Notification Service"] = costSNS4;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Queue Service"] = costSQS4;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Simple Storage Service"] = costSSS4;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon Virtual Private Cloud"] = costVPC4;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon WorkSpaces"] = costWS4;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AmazonCloudWatch"] = costCW4;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE4 = element.Metrics.AmortizedCost.Amount;
            costObj4["CloudWatch Events"] = costCWE4;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS CodeArtifact"] = costCA4;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Step Functions"] = costSF4;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon EC2 Container Registry (ECR)"] = costECR4;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF4 = element.Metrics.AmortizedCost.Amount;
            costObj4["AWS Transfer Family"] = costTF4;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon API Gateway"] = costAG4;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS4 = element.Metrics.AmortizedCost.Amount;
            costObj4["Amazon AppStream"] = costAS4;
          }
        });
        cost5 = response["ResultsByTime"][5].Groups;
        cost5.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Lambda"] = costLambda5;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS CloudTrail"] = costCloudT5;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Config"] = costConfig5;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Direct Connect"] = costDirCnt5;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Glue"] = costGlue5;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Key Management Service"] = costKeyMgt5;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Secrets Manager"] = costSecMgr5;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Security Hub"] = costSecHub5;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Shield"] = costShield5;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS WAF"] = costWaf5;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon DynamoDB"] = costDyna5;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Relational Database Service"] = costRDS5;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot5 = element.Metrics.AmortizedCost.Amount;
            costObj5["EC2 - Other"] = costEC2Ot5;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Elastic Compute Cloud - Compute"] = costECC5;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Elastic Load Balancing"] = costELB5;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Elastic File System"] = costEFS5;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon FSx"] = costFSX5;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon GuardDuty"] = costGD5;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Inspector"] = costInsp5;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Email Service"] = costSES5;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Notification Service"] = costSNS5;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Queue Service"] = costSQS5;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Simple Storage Service"] = costSSS5;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon Virtual Private Cloud"] = costVPC5;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon WorkSpaces"] = costWS5;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AmazonCloudWatch"] = costCW5;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE5 = element.Metrics.AmortizedCost.Amount;
            costObj5["CloudWatch Events"] = costCWE5;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS CodeArtifact"] = costCA5;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Step Functions"] = costSF5;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon EC2 Container Registry (ECR)"] = costECR5;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF5 = element.Metrics.AmortizedCost.Amount;
            costObj5["AWS Transfer Family"] = costTF5;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon API Gateway"] = costAG5;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS5 = element.Metrics.AmortizedCost.Amount;
            costObj5["Amazon AppStream"] = costAS5;
          }
        });
        cost6 = response["ResultsByTime"][6].Groups;
        cost6.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Lambda"] = costLambda6;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS CloudTrail"] = costCloudT6;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Config"] = costConfig6;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Direct Connect"] = costDirCnt6;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Glue"] = costGlue6;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Key Management Service"] = costKeyMgt6;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Secrets Manager"] = costSecMgr6;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Security Hub"] = costSecHub6;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Shield"] = costShield6;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS WAF"] = costWaf6;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon DynamoDB"] = costDyna6;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Relational Database Service"] = costRDS6;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot6 = element.Metrics.AmortizedCost.Amount;
            costObj6["EC2 - Other"] = costEC2Ot6;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Elastic Compute Cloud - Compute"] = costECC6;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Elastic Load Balancing"] = costELB6;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Elastic File System"] = costEFS6;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon FSx"] = costFSX6;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon GuardDuty"] = costGD6;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Inspector"] = costInsp6;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Email Service"] = costSES6;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Notification Service"] = costSNS6;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Queue Service"] = costSQS6;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Simple Storage Service"] = costSSS6;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon Virtual Private Cloud"] = costVPC6;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon WorkSpaces"] = costWS6;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AmazonCloudWatch"] = costCW6;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE6 = element.Metrics.AmortizedCost.Amount;
            costObj6["CloudWatch Events"] = costCWE6;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS CodeArtifact"] = costCA6;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Step Functions"] = costSF6;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon EC2 Container Registry (ECR)"] = costECR6;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF6 = element.Metrics.AmortizedCost.Amount;
            costObj6["AWS Transfer Family"] = costTF6;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon API Gateway"] = costAG6;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS6 = element.Metrics.AmortizedCost.Amount;
            costObj6["Amazon AppStream"] = costAS6;
          }
        });
        cost7 = response["ResultsByTime"][7].Groups;
        cost7.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Lambda"] = costLambda7;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS CloudTrail"] = costCloudT7;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Config"] = costConfig7;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Direct Connect"] = costDirCnt7;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Glue"] = costGlue7;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Key Management Service"] = costKeyMgt7;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Secrets Manager"] = costSecMgr7;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Security Hub"] = costSecHub7;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Shield"] = costShield7;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS WAF"] = costWaf7;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon DynamoDB"] = costDyna7;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Relational Database Service"] = costRDS7;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot7 = element.Metrics.AmortizedCost.Amount;
            costObj7["EC2 - Other"] = costEC2Ot7;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Elastic Compute Cloud - Compute"] = costECC7;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Elastic Load Balancing"] = costELB7;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Elastic File System"] = costEFS7;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon FSx"] = costFSX7;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon GuardDuty"] = costGD7;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Inspector"] = costInsp7;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Email Service"] = costSES7;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Notification Service"] = costSNS7;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Queue Service"] = costSQS7;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Simple Storage Service"] = costSSS7;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon Virtual Private Cloud"] = costVPC7;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon WorkSpaces"] = costWS7;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AmazonCloudWatch"] = costCW7;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE7 = element.Metrics.AmortizedCost.Amount;
            costObj7["CloudWatch Events"] = costCWE7;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS CodeArtifact"] = costCA7;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Step Functions"] = costSF7;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon EC2 Container Registry (ECR)"] = costECR7;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF7 = element.Metrics.AmortizedCost.Amount;
            costObj7["AWS Transfer Family"] = costTF7;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon API Gateway"] = costAG7;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS7 = element.Metrics.AmortizedCost.Amount;
            costObj7["Amazon AppStream"] = costAS7;
          }
        });
        cost8 = response["ResultsByTime"][8].Groups;
        cost8.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Lambda"] = costLambda8;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS CloudTrail"] = costCloudT8;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Config"] = costConfig8;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Direct Connect"] = costDirCnt8;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Glue"] = costGlue8;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Key Management Service"] = costKeyMgt8;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Secrets Manager"] = costSecMgr8;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Security Hub"] = costSecHub8;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Shield"] = costShield8;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS WAF"] = costWaf8;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon DynamoDB"] = costDyna8;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Relational Database Service"] = costRDS8;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot8 = element.Metrics.AmortizedCost.Amount;
            costObj8["EC2 - Other"] = costEC2Ot8;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Elastic Compute Cloud - Compute"] = costECC8;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Elastic Load Balancing"] = costELB8;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Elastic File System"] = costEFS8;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon FSx"] = costFSX8;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon GuardDuty"] = costGD8;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Inspector"] = costInsp8;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Email Service"] = costSES8;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Notification Service"] = costSNS8;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Queue Service"] = costSQS8;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Simple Storage Service"] = costSSS8;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon Virtual Private Cloud"] = costVPC8;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon WorkSpaces"] = costWS8;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AmazonCloudWatch"] = costCW8;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE8 = element.Metrics.AmortizedCost.Amount;
            costObj8["CloudWatch Events"] = costCWE8;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS CodeArtifact"] = costCA8;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Step Functions"] = costSF8;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon EC2 Container Registry (ECR)"] = costECR8;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF8 = element.Metrics.AmortizedCost.Amount;
            costObj8["AWS Transfer Family"] = costTF8;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon API Gateway"] = costAG8;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS8 = element.Metrics.AmortizedCost.Amount;
            costObj8["Amazon AppStream"] = costAS8;
          }
        });
        cost9 = response["ResultsByTime"][9].Groups;
        cost9.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Lambda"] = costLambda9;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS CloudTrail"] = costCloudT9;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Config"] = costConfig9;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Direct Connect"] = costDirCnt9;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Glue"] = costGlue9;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Key Management Service"] = costKeyMgt9;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Secrets Manager"] = costSecMgr9;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Security Hub"] = costSecHub9;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Shield"] = costShield9;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS WAF"] = costWaf9;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon DynamoDB"] = costDyna9;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Relational Database Service"] = costRDS9;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot9 = element.Metrics.AmortizedCost.Amount;
            costObj9["EC2 - Other"] = costEC2Ot9;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Elastic Compute Cloud - Compute"] = costECC9;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Elastic Load Balancing"] = costELB9;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Elastic File System"] = costEFS9;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon FSx"] = costFSX9;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon GuardDuty"] = costGD9;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Inspector"] = costInsp9;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Email Service"] = costSES9;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Notification Service"] = costSNS9;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Queue Service"] = costSQS9;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Simple Storage Service"] = costSSS9;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon Virtual Private Cloud"] = costVPC9;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon WorkSpaces"] = costWS9;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AmazonCloudWatch"] = costCW9;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE9 = element.Metrics.AmortizedCost.Amount;
            costObj9["CloudWatch Events"] = costCWE9;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS CodeArtifact"] = costCA9;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Step Functions"] = costSF9;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon EC2 Container Registry (ECR)"] = costECR9;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF9 = element.Metrics.AmortizedCost.Amount;
            costObj9["AWS Transfer Family"] = costTF9;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon API Gateway"] = costAG9;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS9 = element.Metrics.AmortizedCost.Amount;
            costObj9["Amazon AppStream"] = costAS9;
          }
        });
        cost10 = response["ResultsByTime"][10].Groups;
        cost10.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Lambda"] = costLambda10;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS CloudTrail"] = costCloudT10;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Config"] = costConfig10;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Direct Connect"] = costDirCnt10;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Glue"] = costGlue10;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Key Management Service"] = costKeyMgt10;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Secrets Manager"] = costSecMgr10;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Security Hub"] = costSecHub10;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Shield"] = costShield10;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS WAF"] = costWaf10;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon DynamoDB"] = costDyna10;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Relational Database Service"] = costRDS10;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot10 = element.Metrics.AmortizedCost.Amount;
            costObj10["EC2 - Other"] = costEC2Ot10;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Elastic Compute Cloud - Compute"] = costECC10;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Elastic Load Balancing"] = costELB10;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Elastic File System"] = costEFS10;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon FSx"] = costFSX10;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon GuardDuty"] = costGD10;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Inspector"] = costInsp10;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Email Service"] = costSES10;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Notification Service"] = costSNS10;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Queue Service"] = costSQS10;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Simple Storage Service"] = costSSS10;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon Virtual Private Cloud"] = costVPC10;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon WorkSpaces"] = costWS10;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AmazonCloudWatch"] = costCW10;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE10 = element.Metrics.AmortizedCost.Amount;
            costObj10["CloudWatch Events"] = costCWE10;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS CodeArtifact"] = costCA10;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Step Functions"] = costSF10;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon EC2 Container Registry (ECR)"] = costECR10;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF10 = element.Metrics.AmortizedCost.Amount;
            costObj10["AWS Transfer Family"] = costTF10;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon API Gateway"] = costAG10;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS10 = element.Metrics.AmortizedCost.Amount;
            costObj10["Amazon AppStream"] = costAS10;
          }
        });
        cost11 = response["ResultsByTime"][11].Groups;
        cost11.forEach((element, index, array) => {
          if (element.Keys[0] === "AWS Lambda") {
            costLambda11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Lambda"] = costLambda11;
          } else if (element.Keys[0] === "AWS CloudTrail") {
            costCloudT11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS CloudTrail"] = costCloudT11;
          } else if (element.Keys[0] === "AWS Config") {
            costConfig11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Config"] = costConfig11;
          } else if (element.Keys[0] === "AWS Direct Connect") {
            costDirCnt11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Direct Connect"] = costDirCnt11;
          } else if (element.Keys[0] === "AWS Glue") {
            costGlue11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Glue"] = costGlue11;
          } else if (element.Keys[0] === "AWS Key Management Service") {
            costKeyMgt11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Key Management Service"] = costKeyMgt11;
          } else if (element.Keys[0] === "AWS Secrets Manager") {
            costSecMgr11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Secrets Manager"] = costSecMgr11;
          } else if (element.Keys[0] === "AWS Security Hub") {
            costSecHub11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Security Hub"] = costSecHub11;
          } else if (element.Keys[0] === "AWS Shield") {
            costShield11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Shield"] = costShield11;
          } else if (element.Keys[0] === "AWS WAF") {
            costWaf11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS WAF"] = costWaf11;
          } else if (element.Keys[0] === "Amazon DynamoDB") {
            costDyna11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon DynamoDB"] = costDyna11;
          } else if (element.Keys[0] === "Amazon Relational Database Service") {
            costRDS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Relational Database Service"] = costRDS11;
          } else if (element.Keys[0] === "EC2 - Other") {
            costEC2Ot11 = element.Metrics.AmortizedCost.Amount;
            costObj11["EC2 - Other"] = costEC2Ot11;
          } else if (
            element.Keys[0] === "Amazon Elastic Compute Cloud - Compute"
          ) {
            costECC11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Elastic Compute Cloud - Compute"] = costECC11;
          } else if (element.Keys[0] === "Amazon Elastic Load Balancing") {
            costELB11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Elastic Load Balancing"] = costELB11;
          } else if (element.Keys[0] === "Amazon Elastic File System") {
            costEFS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Elastic File System"] = costEFS11;
          } else if (element.Keys[0] === "Amazon FSx") {
            costFSX11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon FSx"] = costFSX11;
          } else if (element.Keys[0] === "Amazon GuardDuty") {
            costGD11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon GuardDuty"] = costGD11;
          } else if (element.Keys[0] === "Amazon Inspector") {
            costInsp11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Inspector"] = costInsp11;
          } else if (element.Keys[0] === "Amazon Simple Email Service") {
            costSES11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Email Service"] = costSES11;
          } else if (element.Keys[0] === "Amazon Simple Notification Service") {
            costSNS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Notification Service"] = costSNS11;
          } else if (element.Keys[0] === "Amazon Simple Queue Service") {
            costSQS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Queue Service"] = costSQS11;
          } else if (element.Keys[0] === "Amazon Simple Storage Service") {
            costSSS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Simple Storage Service"] = costSSS11;
          } else if (element.Keys[0] === "Amazon Virtual Private Cloud") {
            costVPC11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon Virtual Private Cloud"] = costVPC11;
          } else if (element.Keys[0] === "Amazon WorkSpaces") {
            costWS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon WorkSpaces"] = costWS11;
          } else if (element.Keys[0] === "AmazonCloudWatch") {
            costCW11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AmazonCloudWatch"] = costCW11;
          } else if (element.Keys[0] === "CloudWatch Events") {
            costCWE11 = element.Metrics.AmortizedCost.Amount;
            costObj11["CloudWatch Events"] = costCWE11;
          } else if (element.Keys[0] === "AWS CodeArtifact") {
            costCA11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS CodeArtifact"] = costCA11;
          } else if (element.Keys[0] === "AWS Step Functions") {
            costSF11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Step Functions"] = costSF11;
          } else if (
            element.Keys[0] === "Amazon EC2 Container Registry (ECR)"
          ) {
            costECR11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon EC2 Container Registry (ECR)"] = costECR11;
          } else if (element.Keys[0] === "AWS Transfer Family") {
            costTF11 = element.Metrics.AmortizedCost.Amount;
            costObj11["AWS Transfer Family"] = costTF11;
          } else if (element.Keys[0] === "Amazon API Gateway") {
            costAG11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon API Gateway"] = costAG11;
          } else if (element.Keys[0] === "Amazon AppStream") {
            costAS11 = element.Metrics.AmortizedCost.Amount;
            costObj11["Amazon AppStream"] = costAS11;
          }
        });
      }
      this.setState({
        CostService: service,
        LambdaCost: costLambda,
        CloudTCost: costCloudT,
        ConfigCost: costConfig,
        DirCntCost: costDirCnt,
        GlueCost: costGlue,
        KeyMgtCost: costKeyMgt,
        SecMgrCost: costSecMgr,
        SecHubCost: costSecHub,
        ShieldCost: costShield,
        WafCost: costWaf,
        DynaCost: costDyna,
        RDSCost: costRDS,
        EC2OtCost: costEC2Ot,
        ECCCost: costECC,
        ELBCost: costELB,
        EFSCost: costEFS,
        FSXCost: costFSX,
        GDCost: costGD,
        InspCost: costInsp,
        SESCost: costSES,
        SNSCost: costSNS,
        SQSCost: costSQS,
        SSSCost: costSSS,
        VPCCost: costVPC,
        WSCost: costWS,
        CWCost: costCW,
        ASCost: costAS,
        AmortizedCost: [
          {
            RId: "0",
            RType: "Lambda",
            TotalAmount: (parseFloat(costLambda) + parseFloat(costLambda1) + parseFloat(costLambda2) + parseFloat(costLambda3) + parseFloat(costLambda4) + parseFloat(costLambda5) + parseFloat(costLambda6) + parseFloat(costLambda7) + parseFloat(costLambda8) + parseFloat(costLambda9) + parseFloat(costLambda10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costLambda).toFixed(2),
            Amount1: parseFloat(costLambda1).toFixed(2),
            Amount2: parseFloat(costLambda2).toFixed(2),
            Amount3: parseFloat(costLambda3).toFixed(2),
            Amount4: parseFloat(costLambda4).toFixed(2),
            Amount5: parseFloat(costLambda5).toFixed(2),
            Amount6: parseFloat(costLambda6).toFixed(2),
            Amount7: parseFloat(costLambda7).toFixed(2),
            Amount8: parseFloat(costLambda8).toFixed(2),
            Amount9: parseFloat(costLambda9).toFixed(2),
            Amount10: parseFloat(costLambda10).toFixed(2),
            Amount11: parseFloat(costLambda11).toFixed(2)
          },
          {
            RId: "1",
            RType: "Config",
            TotalAmount: (parseFloat(costConfig) + parseFloat(costConfig1) + parseFloat(costConfig2) + parseFloat(costConfig3) + parseFloat(costConfig4) + parseFloat(costConfig5) + parseFloat(costConfig6) + parseFloat(costConfig7) + parseFloat(costConfig8) + parseFloat(costConfig9) + parseFloat(costConfig10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costConfig).toFixed(2),
            Amount1: parseFloat(costConfig1).toFixed(2),
            Amount2: parseFloat(costConfig2).toFixed(2),
            Amount3: parseFloat(costConfig3).toFixed(2),
            Amount4: parseFloat(costConfig4).toFixed(2),
            Amount5: parseFloat(costConfig5).toFixed(2),
            Amount6: parseFloat(costConfig6).toFixed(2),
            Amount7: parseFloat(costConfig7).toFixed(2),
            Amount8: parseFloat(costConfig8).toFixed(2),
            Amount9: parseFloat(costConfig9).toFixed(2),
            Amount10: parseFloat(costConfig10).toFixed(2),
            Amount11: parseFloat(costConfig11).toFixed(2)
          },
          {
            RId: "2",
            RType: "CloudTrail",
            TotalAmount: (parseFloat(costCloudT) + parseFloat(costCloudT1) + parseFloat(costCloudT2) + parseFloat(costCloudT3) + parseFloat(costCloudT4) + parseFloat(costCloudT5) + parseFloat(costCloudT6) + parseFloat(costCloudT7) + parseFloat(costCloudT8) + parseFloat(costCloudT9) + parseFloat(costCloudT10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCloudT).toFixed(2),
            Amount1: parseFloat(costCloudT1).toFixed(2),
            Amount2: parseFloat(costCloudT2).toFixed(2),
            Amount3: parseFloat(costCloudT3).toFixed(2),
            Amount4: parseFloat(costCloudT4).toFixed(2),
            Amount5: parseFloat(costCloudT5).toFixed(2),
            Amount6: parseFloat(costCloudT6).toFixed(2),
            Amount7: parseFloat(costCloudT7).toFixed(2),
            Amount8: parseFloat(costCloudT8).toFixed(2),
            Amount9: parseFloat(costCloudT9).toFixed(2),
            Amount10: parseFloat(costCloudT10).toFixed(2),
            Amount11: parseFloat(costCloudT11).toFixed(2)
          },
          {
            RId: "3",
            RType: "Dir Connect",
            TotalAmount: (parseFloat(costDirCnt) + parseFloat(costDirCnt1) + parseFloat(costDirCnt2) + parseFloat(costDirCnt3) + parseFloat(costDirCnt4) + parseFloat(costDirCnt5) + parseFloat(costDirCnt6) + parseFloat(costDirCnt7) + parseFloat(costDirCnt8) + parseFloat(costDirCnt9) + parseFloat(costDirCnt10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costDirCnt).toFixed(2),
            Amount1: parseFloat(costDirCnt1).toFixed(2),
            Amount2: parseFloat(costDirCnt2).toFixed(2),
            Amount3: parseFloat(costDirCnt3).toFixed(2),
            Amount4: parseFloat(costDirCnt4).toFixed(2),
            Amount5: parseFloat(costDirCnt5).toFixed(2),
            Amount6: parseFloat(costDirCnt6).toFixed(2),
            Amount7: parseFloat(costDirCnt7).toFixed(2),
            Amount8: parseFloat(costDirCnt8).toFixed(2),
            Amount9: parseFloat(costDirCnt9).toFixed(2),
            Amount10: parseFloat(costDirCnt10).toFixed(2),
            Amount11: parseFloat(costDirCnt11).toFixed(2)
          },
          {
            RId: "4",
            RType: "Glue",
            TotalAmount: (parseFloat(costGlue) + parseFloat(costGlue1) + parseFloat(costGlue2) + parseFloat(costGlue3) + parseFloat(costGlue4) + parseFloat(costGlue5) + parseFloat(costGlue6) + parseFloat(costGlue7) + parseFloat(costGlue8) + parseFloat(costGlue9) + parseFloat(costGlue10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costGlue).toFixed(2),
            Amount1: parseFloat(costGlue1).toFixed(2),
            Amount2: parseFloat(costGlue2).toFixed(2),
            Amount3: parseFloat(costGlue3).toFixed(2),
            Amount4: parseFloat(costGlue4).toFixed(2),
            Amount5: parseFloat(costGlue5).toFixed(2),
            Amount6: parseFloat(costGlue6).toFixed(2),
            Amount7: parseFloat(costGlue7).toFixed(2),
            Amount8: parseFloat(costGlue8).toFixed(2),
            Amount9: parseFloat(costGlue9).toFixed(2),
            Amount10: parseFloat(costGlue10).toFixed(2),
            Amount11: parseFloat(costGlue11).toFixed(2)
          },
          {
            RId: "5",
            RType: "KMS",
            TotalAmount: (parseFloat(costKeyMgt) + parseFloat(costKeyMgt1) + parseFloat(costKeyMgt2) + parseFloat(costKeyMgt3) + parseFloat(costKeyMgt4) + parseFloat(costKeyMgt5) + parseFloat(costKeyMgt6) + parseFloat(costKeyMgt7) + parseFloat(costKeyMgt8) + parseFloat(costKeyMgt9) + parseFloat(costKeyMgt10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costKeyMgt).toFixed(2),
            Amount1: parseFloat(costKeyMgt1).toFixed(2),
            Amount2: parseFloat(costKeyMgt2).toFixed(2),
            Amount3: parseFloat(costKeyMgt3).toFixed(2),
            Amount4: parseFloat(costKeyMgt4).toFixed(2),
            Amount5: parseFloat(costKeyMgt5).toFixed(2),
            Amount6: parseFloat(costKeyMgt6).toFixed(2),
            Amount7: parseFloat(costKeyMgt7).toFixed(2),
            Amount8: parseFloat(costKeyMgt8).toFixed(2),
            Amount9: parseFloat(costKeyMgt9).toFixed(2),
            Amount10: parseFloat(costKeyMgt10).toFixed(2),
            Amount11: parseFloat(costKeyMgt11).toFixed(2)
          },
          {
            RId: "6",
            RType: "Sec Manager",
            TotalAmount: (parseFloat(costSecMgr) + parseFloat(costSecMgr1) + parseFloat(costSecMgr2) + parseFloat(costSecMgr3) + parseFloat(costSecMgr4) + parseFloat(costSecMgr5) + parseFloat(costSecMgr6) + parseFloat(costSecMgr7) + parseFloat(costSecMgr8) + parseFloat(costSecMgr9) + parseFloat(costSecMgr10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSecMgr).toFixed(2),
            Amount1: parseFloat(costSecMgr1).toFixed(2),
            Amount2: parseFloat(costSecMgr2).toFixed(2),
            Amount3: parseFloat(costSecMgr3).toFixed(2),
            Amount4: parseFloat(costSecMgr4).toFixed(2),
            Amount5: parseFloat(costSecMgr5).toFixed(2),
            Amount6: parseFloat(costSecMgr6).toFixed(2),
            Amount7: parseFloat(costSecMgr7).toFixed(2),
            Amount8: parseFloat(costSecMgr8).toFixed(2),
            Amount9: parseFloat(costSecMgr9).toFixed(2),
            Amount10: parseFloat(costSecMgr10).toFixed(2),
            Amount11: parseFloat(costSecMgr11).toFixed(2)
          },
          {
            RId: "7",
            RType: "Sec Hub",
            TotalAmount: (parseFloat(costSecHub) + parseFloat(costSecHub1) + parseFloat(costSecHub2) + parseFloat(costSecHub3) + parseFloat(costSecHub4) + parseFloat(costSecHub5) + parseFloat(costSecHub6) + parseFloat(costSecHub7) + parseFloat(costSecHub8) + parseFloat(costSecHub9) + parseFloat(costSecHub10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSecHub).toFixed(2),
            Amount1: parseFloat(costSecHub1).toFixed(2),
            Amount2: parseFloat(costSecHub2).toFixed(2),
            Amount3: parseFloat(costSecHub3).toFixed(2),
            Amount4: parseFloat(costSecHub4).toFixed(2),
            Amount5: parseFloat(costSecHub5).toFixed(2),
            Amount6: parseFloat(costSecHub6).toFixed(2),
            Amount7: parseFloat(costSecHub7).toFixed(2),
            Amount8: parseFloat(costSecHub8).toFixed(2),
            Amount9: parseFloat(costSecHub9).toFixed(2),
            Amount10: parseFloat(costSecHub10).toFixed(2),
            Amount11: parseFloat(costSecHub11).toFixed(2)
          },
          {
            RId: "8",
            RType: "Shield",
            TotalAmount: (parseFloat(costShield) + parseFloat(costShield1) + parseFloat(costShield2) + parseFloat(costShield3) + parseFloat(costShield4) + parseFloat(costShield5) + parseFloat(costShield6) + parseFloat(costShield7) + parseFloat(costShield8) + parseFloat(costShield9) + parseFloat(costShield10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costShield).toFixed(2),
            Amount1: parseFloat(costShield1).toFixed(2),
            Amount2: parseFloat(costShield2).toFixed(2),
            Amount3: parseFloat(costShield3).toFixed(2),
            Amount4: parseFloat(costShield4).toFixed(2),
            Amount5: parseFloat(costShield5).toFixed(2),
            Amount6: parseFloat(costShield6).toFixed(2),
            Amount7: parseFloat(costShield7).toFixed(2),
            Amount8: parseFloat(costShield8).toFixed(2),
            Amount9: parseFloat(costShield9).toFixed(2),
            Amount10: parseFloat(costShield10).toFixed(2),
            Amount11: parseFloat(costShield11).toFixed(2)
          },
          {
            RId: "9",
            RType: "WAF",
            TotalAmount: (parseFloat(costLambda) + parseFloat(costLambda1) + parseFloat(costLambda2) + parseFloat(costLambda3) + parseFloat(costLambda4) + parseFloat(costLambda5) + parseFloat(costLambda6) + parseFloat(costLambda7) + parseFloat(costLambda8) + parseFloat(costLambda9) + parseFloat(costLambda10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costLambda).toFixed(2),
            Amount1: parseFloat(costLambda1).toFixed(2),
            Amount2: parseFloat(costLambda2).toFixed(2),
            Amount3: parseFloat(costLambda3).toFixed(2),
            Amount4: parseFloat(costLambda4).toFixed(2),
            Amount5: parseFloat(costLambda5).toFixed(2),
            Amount6: parseFloat(costLambda6).toFixed(2),
            Amount7: parseFloat(costLambda7).toFixed(2),
            Amount8: parseFloat(costLambda8).toFixed(2),
            Amount9: parseFloat(costLambda9).toFixed(2),
            Amount10: parseFloat(costLambda10).toFixed(2),
            Amount11: parseFloat(costLambda11).toFixed(2)
          },
          {
            RId: "10",
            RType: "DynamoDB",
            TotalAmount: (parseFloat(costDyna) + parseFloat(costDyna1) + parseFloat(costDyna2) + parseFloat(costDyna3) + parseFloat(costDyna4) + parseFloat(costDyna5) + parseFloat(costDyna6) + parseFloat(costDyna7) + parseFloat(costDyna8) + parseFloat(costDyna9) + parseFloat(costDyna10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costDyna).toFixed(2),
            Amount1: parseFloat(costDyna1).toFixed(2),
            Amount2: parseFloat(costDyna2).toFixed(2),
            Amount3: parseFloat(costDyna3).toFixed(2),
            Amount4: parseFloat(costDyna4).toFixed(2),
            Amount5: parseFloat(costDyna5).toFixed(2),
            Amount6: parseFloat(costDyna6).toFixed(2),
            Amount7: parseFloat(costDyna7).toFixed(2),
            Amount8: parseFloat(costDyna8).toFixed(2),
            Amount9: parseFloat(costDyna9).toFixed(2),
            Amount10: parseFloat(costDyna10).toFixed(2),
            Amount11: parseFloat(costDyna11).toFixed(2)
          },
          {
            RId: "11",
            RType: "RDS",
            TotalAmount: (parseFloat(costRDS) + parseFloat(costRDS1) + parseFloat(costRDS2) + parseFloat(costRDS3) + parseFloat(costRDS4) + parseFloat(costRDS5) + parseFloat(costRDS6) + parseFloat(costRDS7) + parseFloat(costRDS8) + parseFloat(costRDS9) + parseFloat(costRDS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costRDS).toFixed(2),
            Amount1: parseFloat(costRDS1).toFixed(2),
            Amount2: parseFloat(costRDS2).toFixed(2),
            Amount3: parseFloat(costRDS3).toFixed(2),
            Amount4: parseFloat(costRDS4).toFixed(2),
            Amount5: parseFloat(costRDS5).toFixed(2),
            Amount6: parseFloat(costRDS6).toFixed(2),
            Amount7: parseFloat(costRDS7).toFixed(2),
            Amount8: parseFloat(costRDS8).toFixed(2),
            Amount9: parseFloat(costRDS9).toFixed(2),
            Amount10: parseFloat(costRDS10).toFixed(2),
            Amount11: parseFloat(costRDS11).toFixed(2)
          },
          {
            RId: "12",
            RType: "EC2-Other",
            TotalAmount: (parseFloat(costEC2Ot) + parseFloat(costEC2Ot1) + parseFloat(costEC2Ot2) + parseFloat(costEC2Ot3) + parseFloat(costEC2Ot4) + parseFloat(costEC2Ot5) + parseFloat(costEC2Ot6) + parseFloat(costEC2Ot7) + parseFloat(costEC2Ot8) + parseFloat(costEC2Ot9) + parseFloat(costEC2Ot10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costEC2Ot).toFixed(2),
            Amount1: parseFloat(costEC2Ot1).toFixed(2),
            Amount2: parseFloat(costEC2Ot2).toFixed(2),
            Amount3: parseFloat(costEC2Ot3).toFixed(2),
            Amount4: parseFloat(costEC2Ot4).toFixed(2),
            Amount5: parseFloat(costEC2Ot5).toFixed(2),
            Amount6: parseFloat(costEC2Ot6).toFixed(2),
            Amount7: parseFloat(costEC2Ot7).toFixed(2),
            Amount8: parseFloat(costEC2Ot8).toFixed(2),
            Amount9: parseFloat(costEC2Ot9).toFixed(2),
            Amount10: parseFloat(costEC2Ot10).toFixed(2),
            Amount11: parseFloat(costEC2Ot11).toFixed(2)
          },
          {
            RId: "13",
            RType: "EC2-Compute",
            TotalAmount: (parseFloat(costECC) + parseFloat(costECC1) + parseFloat(costECC2) + parseFloat(costECC3) + parseFloat(costECC4) + parseFloat(costECC5) + parseFloat(costECC6) + parseFloat(costECC7) + parseFloat(costECC8) + parseFloat(costECC9) + parseFloat(costECC10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costECC).toFixed(2),
            Amount1: parseFloat(costECC1).toFixed(2),
            Amount2: parseFloat(costECC2).toFixed(2),
            Amount3: parseFloat(costECC3).toFixed(2),
            Amount4: parseFloat(costECC4).toFixed(2),
            Amount5: parseFloat(costECC5).toFixed(2),
            Amount6: parseFloat(costECC6).toFixed(2),
            Amount7: parseFloat(costECC7).toFixed(2),
            Amount8: parseFloat(costECC8).toFixed(2),
            Amount9: parseFloat(costECC9).toFixed(2),
            Amount10: parseFloat(costECC10).toFixed(2),
            Amount11: parseFloat(costECC11).toFixed(2)
          },
          {
            RId: "14",
            RType: "ELB",
            TotalAmount: (parseFloat(costELB) + parseFloat(costELB1) + parseFloat(costELB2) + parseFloat(costELB3) + parseFloat(costELB4) + parseFloat(costELB5) + parseFloat(costELB6) + parseFloat(costELB7) + parseFloat(costELB8) + parseFloat(costELB9) + parseFloat(costELB10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costELB).toFixed(2),
            Amount1: parseFloat(costELB1).toFixed(2),
            Amount2: parseFloat(costELB2).toFixed(2),
            Amount3: parseFloat(costELB3).toFixed(2),
            Amount4: parseFloat(costELB4).toFixed(2),
            Amount5: parseFloat(costELB5).toFixed(2),
            Amount6: parseFloat(costELB6).toFixed(2),
            Amount7: parseFloat(costELB7).toFixed(2),
            Amount8: parseFloat(costELB8).toFixed(2),
            Amount9: parseFloat(costELB9).toFixed(2),
            Amount10: parseFloat(costELB10).toFixed(2),
            Amount11: parseFloat(costELB11).toFixed(2)
          },
          {
            RId: "15",
            RType: "File System",
            TotalAmount: (parseFloat(costEFS) + parseFloat(costEFS1) + parseFloat(costEFS2) + parseFloat(costEFS3) + parseFloat(costEFS4) + parseFloat(costEFS5) + parseFloat(costEFS6) + parseFloat(costEFS7) + parseFloat(costEFS8) + parseFloat(costEFS9) + parseFloat(costEFS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costEFS).toFixed(2),
            Amount1: parseFloat(costEFS1).toFixed(2),
            Amount2: parseFloat(costEFS2).toFixed(2),
            Amount3: parseFloat(costEFS3).toFixed(2),
            Amount4: parseFloat(costEFS4).toFixed(2),
            Amount5: parseFloat(costEFS5).toFixed(2),
            Amount6: parseFloat(costEFS6).toFixed(2),
            Amount7: parseFloat(costEFS7).toFixed(2),
            Amount8: parseFloat(costEFS8).toFixed(2),
            Amount9: parseFloat(costEFS9).toFixed(2),
            Amount10: parseFloat(costEFS10).toFixed(2),
            Amount11: parseFloat(costEFS11).toFixed(2)
          },
          {
            RId: "16",
            RType: "FSx",
            TotalAmount: (parseFloat(costFSX) + parseFloat(costFSX1) + parseFloat(costFSX2) + parseFloat(costFSX3) + parseFloat(costFSX4) + parseFloat(costFSX5) + parseFloat(costFSX6) + parseFloat(costFSX7) + parseFloat(costFSX8) + parseFloat(costFSX9) + parseFloat(costFSX10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costFSX).toFixed(2),
            Amount1: parseFloat(costFSX1).toFixed(2),
            Amount2: parseFloat(costFSX2).toFixed(2),
            Amount3: parseFloat(costFSX3).toFixed(2),
            Amount4: parseFloat(costFSX4).toFixed(2),
            Amount5: parseFloat(costFSX5).toFixed(2),
            Amount6: parseFloat(costFSX6).toFixed(2),
            Amount7: parseFloat(costFSX7).toFixed(2),
            Amount8: parseFloat(costFSX8).toFixed(2),
            Amount9: parseFloat(costFSX9).toFixed(2),
            Amount10: parseFloat(costFSX10).toFixed(2),
            Amount11: parseFloat(costFSX11).toFixed(2)
          },
          {
            RId: "17",
            RType: "Guard Duty",
            TotalAmount: (parseFloat(costGD) + parseFloat(costGD1) + parseFloat(costGD2) + parseFloat(costGD3) + parseFloat(costGD4) + parseFloat(costGD5) + parseFloat(costGD6) + parseFloat(costGD7) + parseFloat(costGD8) + parseFloat(costGD9) + parseFloat(costGD10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costGD).toFixed(2),
            Amount1: parseFloat(costGD1).toFixed(2),
            Amount2: parseFloat(costGD2).toFixed(2),
            Amount3: parseFloat(costGD3).toFixed(2),
            Amount4: parseFloat(costGD4).toFixed(2),
            Amount5: parseFloat(costGD5).toFixed(2),
            Amount6: parseFloat(costGD6).toFixed(2),
            Amount7: parseFloat(costGD7).toFixed(2),
            Amount8: parseFloat(costGD8).toFixed(2),
            Amount9: parseFloat(costGD9).toFixed(2),
            Amount10: parseFloat(costGD10).toFixed(2),
            Amount11: parseFloat(costGD11).toFixed(2)
          },
          {
            RId: "18",
            RType: "Inspector",
            TotalAmount: (parseFloat(costInsp) + parseFloat(costInsp1) + parseFloat(costInsp2) + parseFloat(costInsp3) + parseFloat(costInsp4) + parseFloat(costInsp5) + parseFloat(costInsp6) + parseFloat(costInsp7) + parseFloat(costInsp8) + parseFloat(costInsp9) + parseFloat(costInsp10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costInsp).toFixed(2),
            Amount1: parseFloat(costInsp1).toFixed(2),
            Amount2: parseFloat(costInsp2).toFixed(2),
            Amount3: parseFloat(costInsp3).toFixed(2),
            Amount4: parseFloat(costInsp4).toFixed(2),
            Amount5: parseFloat(costInsp5).toFixed(2),
            Amount6: parseFloat(costInsp6).toFixed(2),
            Amount7: parseFloat(costInsp7).toFixed(2),
            Amount8: parseFloat(costInsp8).toFixed(2),
            Amount9: parseFloat(costInsp9).toFixed(2),
            Amount10: parseFloat(costInsp10).toFixed(2),
            Amount11: parseFloat(costInsp11).toFixed(2)
          },
          {
            RId: "19",
            RType: "SES",
            TotalAmount: (parseFloat(costSES) + parseFloat(costSES1) + parseFloat(costSES2) + parseFloat(costSES3) + parseFloat(costSES4) + parseFloat(costSES5) + parseFloat(costSES6) + parseFloat(costSES7) + parseFloat(costSES8) + parseFloat(costSES9) + parseFloat(costSES10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSES).toFixed(2),
            Amount1: parseFloat(costSES1).toFixed(2),
            Amount2: parseFloat(costSES2).toFixed(2),
            Amount3: parseFloat(costSES3).toFixed(2),
            Amount4: parseFloat(costSES4).toFixed(2),
            Amount5: parseFloat(costSES5).toFixed(2),
            Amount6: parseFloat(costSES6).toFixed(2),
            Amount7: parseFloat(costSES7).toFixed(2),
            Amount8: parseFloat(costSES8).toFixed(2),
            Amount9: parseFloat(costSES9).toFixed(2),
            Amount10: parseFloat(costSES10).toFixed(2),
            Amount11: parseFloat(costSES11).toFixed(2)
          },
          {
            RId: "20",
            RType: "SNS",
            TotalAmount: (parseFloat(costSNS) + parseFloat(costSNS1) + parseFloat(costSNS2) + parseFloat(costSNS3) + parseFloat(costSNS4) + parseFloat(costSNS5) + parseFloat(costSNS6) + parseFloat(costSNS7) + parseFloat(costSNS8) + parseFloat(costSNS9) + parseFloat(costSNS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSNS).toFixed(2),
            Amount1: parseFloat(costSNS1).toFixed(2),
            Amount2: parseFloat(costSNS2).toFixed(2),
            Amount3: parseFloat(costSNS3).toFixed(2),
            Amount4: parseFloat(costSNS4).toFixed(2),
            Amount5: parseFloat(costSNS5).toFixed(2),
            Amount6: parseFloat(costSNS6).toFixed(2),
            Amount7: parseFloat(costSNS7).toFixed(2),
            Amount8: parseFloat(costSNS8).toFixed(2),
            Amount9: parseFloat(costSNS9).toFixed(2),
            Amount10: parseFloat(costSNS10).toFixed(2),
            Amount11: parseFloat(costSNS11).toFixed(2)
          },
          {
            RId: "21",
            RType: "SQS",
            TotalAmount: (parseFloat(costSQS) + parseFloat(costSQS1) + parseFloat(costSQS2) + parseFloat(costSQS3) + parseFloat(costSQS4) + parseFloat(costSQS5) + parseFloat(costSQS6) + parseFloat(costSQS7) + parseFloat(costSQS8) + parseFloat(costSQS9) + parseFloat(costSQS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSQS).toFixed(2),
            Amount1: parseFloat(costSQS1).toFixed(2),
            Amount2: parseFloat(costSQS2).toFixed(2),
            Amount3: parseFloat(costSQS3).toFixed(2),
            Amount4: parseFloat(costSQS4).toFixed(2),
            Amount5: parseFloat(costSQS5).toFixed(2),
            Amount6: parseFloat(costSQS6).toFixed(2),
            Amount7: parseFloat(costSQS7).toFixed(2),
            Amount8: parseFloat(costSQS8).toFixed(2),
            Amount9: parseFloat(costSQS9).toFixed(2),
            Amount10: parseFloat(costSQS10).toFixed(2),
            Amount11: parseFloat(costSQS11).toFixed(2)
          },
          {
            RId: "22",
            RType: "S3",
            TotalAmount: (parseFloat(costSSS) + parseFloat(costSSS1) + parseFloat(costSSS2) + parseFloat(costSSS3) + parseFloat(costSSS4) + parseFloat(costSSS5) + parseFloat(costSSS6) + parseFloat(costSSS7) + parseFloat(costSSS8) + parseFloat(costSSS9) + parseFloat(costSSS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSSS).toFixed(2),
            Amount1: parseFloat(costSSS1).toFixed(2),
            Amount2: parseFloat(costSSS2).toFixed(2),
            Amount3: parseFloat(costSSS3).toFixed(2),
            Amount4: parseFloat(costSSS4).toFixed(2),
            Amount5: parseFloat(costSSS5).toFixed(2),
            Amount6: parseFloat(costSSS6).toFixed(2),
            Amount7: parseFloat(costSSS7).toFixed(2),
            Amount8: parseFloat(costSSS8).toFixed(2),
            Amount9: parseFloat(costSSS9).toFixed(2),
            Amount10: parseFloat(costSSS10).toFixed(2),
            Amount11: parseFloat(costSSS11).toFixed(2)
          },
          {
            RId: "23",
            RType: "VPC",
            TotalAmount: (parseFloat(costVPC) + parseFloat(costVPC1) + parseFloat(costVPC2) + parseFloat(costVPC3) + parseFloat(costVPC4) + parseFloat(costVPC5) + parseFloat(costVPC6) + parseFloat(costVPC7) + parseFloat(costVPC8) + parseFloat(costVPC9) + parseFloat(costVPC10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costVPC).toFixed(2),
            Amount1: parseFloat(costVPC1).toFixed(2),
            Amount2: parseFloat(costVPC2).toFixed(2),
            Amount3: parseFloat(costVPC3).toFixed(2),
            Amount4: parseFloat(costVPC4).toFixed(2),
            Amount5: parseFloat(costVPC5).toFixed(2),
            Amount6: parseFloat(costVPC6).toFixed(2),
            Amount7: parseFloat(costVPC7).toFixed(2),
            Amount8: parseFloat(costVPC8).toFixed(2),
            Amount9: parseFloat(costVPC9).toFixed(2),
            Amount10: parseFloat(costVPC10).toFixed(2),
            Amount11: parseFloat(costVPC11).toFixed(2)
          },
          {
            RId: "24",
            RType: "WorkSpaces",
            TotalAmount: (parseFloat(costWS) + parseFloat(costWS1) + parseFloat(costWS2) + parseFloat(costWS3) + parseFloat(costWS4) + parseFloat(costWS5) + parseFloat(costWS6) + parseFloat(costWS7) + parseFloat(costWS8) + parseFloat(costWS9) + parseFloat(costWS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costWS).toFixed(2),
            Amount1: parseFloat(costWS1).toFixed(2),
            Amount2: parseFloat(costWS2).toFixed(2),
            Amount3: parseFloat(costWS3).toFixed(2),
            Amount4: parseFloat(costWS4).toFixed(2),
            Amount5: parseFloat(costWS5).toFixed(2),
            Amount6: parseFloat(costWS6).toFixed(2),
            Amount7: parseFloat(costWS7).toFixed(2),
            Amount8: parseFloat(costWS8).toFixed(2),
            Amount9: parseFloat(costWS9).toFixed(2),
            Amount10: parseFloat(costWS10).toFixed(2),
            Amount11: parseFloat(costWS11).toFixed(2)
          },
          {
            RId: "25",
            RType: "CloudWatch",
            TotalAmount: (parseFloat(costCW) + parseFloat(costCW1) + parseFloat(costCW2) + parseFloat(costCW3) + parseFloat(costCW4) + parseFloat(costCW5) + parseFloat(costCW6) + parseFloat(costCW7) + parseFloat(costCW8) + parseFloat(costCW9) + parseFloat(costCW10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCW).toFixed(2),
            Amount1: parseFloat(costCW1).toFixed(2),
            Amount2: parseFloat(costCW2).toFixed(2),
            Amount3: parseFloat(costCW3).toFixed(2),
            Amount4: parseFloat(costCW4).toFixed(2),
            Amount5: parseFloat(costCW5).toFixed(2),
            Amount6: parseFloat(costCW6).toFixed(2),
            Amount7: parseFloat(costCW7).toFixed(2),
            Amount8: parseFloat(costCW8).toFixed(2),
            Amount9: parseFloat(costCW9).toFixed(2),
            Amount10: parseFloat(costCW10).toFixed(2),
            Amount11: parseFloat(costCW11).toFixed(2)
          },
          {
            RId: "26",
            RType: "CWatch Events",
            TotalAmount: (parseFloat(costCWE) + parseFloat(costCWE1) + parseFloat(costCWE2) + parseFloat(costCWE3) + parseFloat(costCWE4) + parseFloat(costCWE5) + parseFloat(costCWE6) + parseFloat(costCWE7) + parseFloat(costCWE8) + parseFloat(costCWE9) + parseFloat(costCWE10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCWE).toFixed(2),
            Amount1: parseFloat(costCWE1).toFixed(2),
            Amount2: parseFloat(costCWE2).toFixed(2),
            Amount3: parseFloat(costCWE3).toFixed(2),
            Amount4: parseFloat(costCWE4).toFixed(2),
            Amount5: parseFloat(costCWE5).toFixed(2),
            Amount6: parseFloat(costCWE6).toFixed(2),
            Amount7: parseFloat(costCWE7).toFixed(2),
            Amount8: parseFloat(costCWE8).toFixed(2),
            Amount9: parseFloat(costCWE9).toFixed(2),
            Amount10: parseFloat(costCWE10).toFixed(2),
            Amount11: parseFloat(costCWE11).toFixed(2)
          },
          {
            RId: "27",
            RType: "Code Artifact",
            TotalAmount: (parseFloat(costCA) + parseFloat(costCA1) + parseFloat(costCA2) + parseFloat(costCA3) + parseFloat(costCA4) + parseFloat(costCA5) + parseFloat(costCA6) + parseFloat(costCA7) + parseFloat(costCA8) + parseFloat(costCA9) + parseFloat(costCA10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costCA).toFixed(2),
            Amount1: parseFloat(costCA1).toFixed(2),
            Amount2: parseFloat(costCA2).toFixed(2),
            Amount3: parseFloat(costCA3).toFixed(2),
            Amount4: parseFloat(costCA4).toFixed(2),
            Amount5: parseFloat(costCA5).toFixed(2),
            Amount6: parseFloat(costCA6).toFixed(2),
            Amount7: parseFloat(costCA7).toFixed(2),
            Amount8: parseFloat(costCA8).toFixed(2),
            Amount9: parseFloat(costCA9).toFixed(2),
            Amount10: parseFloat(costCA10).toFixed(2),
            Amount11: parseFloat(costCA11).toFixed(2)
          },
          {
            RId: "28",
            RType: "Step Functions",
            TotalAmount: (parseFloat(costSF) + parseFloat(costSF1) + parseFloat(costSF2) + parseFloat(costSF3) + parseFloat(costSF4) + parseFloat(costSF5) + parseFloat(costSF6) + parseFloat(costSF7) + parseFloat(costSF8) + parseFloat(costSF9) + parseFloat(costSF10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costSF).toFixed(2),
            Amount1: parseFloat(costSF1).toFixed(2),
            Amount2: parseFloat(costSF2).toFixed(2),
            Amount3: parseFloat(costSF3).toFixed(2),
            Amount4: parseFloat(costSF4).toFixed(2),
            Amount5: parseFloat(costSF5).toFixed(2),
            Amount6: parseFloat(costSF6).toFixed(2),
            Amount7: parseFloat(costSF7).toFixed(2),
            Amount8: parseFloat(costSF8).toFixed(2),
            Amount9: parseFloat(costSF9).toFixed(2),
            Amount10: parseFloat(costSF10).toFixed(2),
            Amount11: parseFloat(costSF11).toFixed(2)
          },
          {
            RId: "29",
            RType: "ECR",
            TotalAmount: (parseFloat(costECR) + parseFloat(costECR1) + parseFloat(costECR2) + parseFloat(costECR3) + parseFloat(costECR4) + parseFloat(costECR5) + parseFloat(costECR6) + parseFloat(costECR7) + parseFloat(costECR8) + parseFloat(costECR9) + parseFloat(costECR10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costECR).toFixed(2),
            Amount1: parseFloat(costECR1).toFixed(2),
            Amount2: parseFloat(costECR2).toFixed(2),
            Amount3: parseFloat(costECR3).toFixed(2),
            Amount4: parseFloat(costECR4).toFixed(2),
            Amount5: parseFloat(costECR5).toFixed(2),
            Amount6: parseFloat(costECR6).toFixed(2),
            Amount7: parseFloat(costECR7).toFixed(2),
            Amount8: parseFloat(costECR8).toFixed(2),
            Amount9: parseFloat(costECR9).toFixed(2),
            Amount10: parseFloat(costECR10).toFixed(2),
            Amount11: parseFloat(costECR11).toFixed(2)
          },
          {
            RId: "30",
            RType: "Transfer Family",
            TotalAmount: (parseFloat(costTF) + parseFloat(costTF1) + parseFloat(costTF2) + parseFloat(costTF3) + parseFloat(costTF4) + parseFloat(costTF5) + parseFloat(costTF6) + parseFloat(costTF7) + parseFloat(costTF8) + parseFloat(costTF9) + parseFloat(costTF10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costTF).toFixed(2),
            Amount1: parseFloat(costTF1).toFixed(2),
            Amount2: parseFloat(costTF2).toFixed(2),
            Amount3: parseFloat(costTF3).toFixed(2),
            Amount4: parseFloat(costTF4).toFixed(2),
            Amount5: parseFloat(costTF5).toFixed(2),
            Amount6: parseFloat(costTF6).toFixed(2),
            Amount7: parseFloat(costTF7).toFixed(2),
            Amount8: parseFloat(costTF8).toFixed(2),
            Amount9: parseFloat(costTF9).toFixed(2),
            Amount10: parseFloat(costTF10).toFixed(2),
            Amount11: parseFloat(costTF11).toFixed(2)
          },
          {
            RId: "31",
            RType: "API Gateway",
            TotalAmount: (parseFloat(costAG) + parseFloat(costAG1) + parseFloat(costAG2) + parseFloat(costAG3) + parseFloat(costAG4) + parseFloat(costAG5) + parseFloat(costAG6) + parseFloat(costAG7) + parseFloat(costAG8) + parseFloat(costAG9) + parseFloat(costAG10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costAG).toFixed(2),
            Amount1: parseFloat(costAG1).toFixed(2),
            Amount2: parseFloat(costAG2).toFixed(2),
            Amount3: parseFloat(costAG3).toFixed(2),
            Amount4: parseFloat(costAG4).toFixed(2),
            Amount5: parseFloat(costAG5).toFixed(2),
            Amount6: parseFloat(costAG6).toFixed(2),
            Amount7: parseFloat(costAG7).toFixed(2),
            Amount8: parseFloat(costAG8).toFixed(2),
            Amount9: parseFloat(costAG9).toFixed(2),
            Amount10: parseFloat(costAG10).toFixed(2),
            Amount11: parseFloat(costAG11).toFixed(2)
          },
          {
            RId: "32",
            RType: "App Stream",
            TotalAmount: (parseFloat(costAS) + parseFloat(costAS1) + parseFloat(costAS2) + parseFloat(costAS3) + parseFloat(costAS4) +  + parseFloat(costAS5) +  + parseFloat(costAS6) +  + parseFloat(costAS7) + + parseFloat(costAS8) + + parseFloat(costAS9) + parseFloat(costAS10)).toFixed(2), // eslint-disable-line
            Amount: parseFloat(costAS).toFixed(2),
            Amount1: parseFloat(costAS1).toFixed(2),
            Amount2: parseFloat(costAS2).toFixed(2),
            Amount3: parseFloat(costAS3).toFixed(2),
            Amount4: parseFloat(costAS4).toFixed(2),
            Amount5: parseFloat(costAS5).toFixed(2),
            Amount6: parseFloat(costAS6).toFixed(2),
            Amount7: parseFloat(costAS7).toFixed(2),
            Amount8: parseFloat(costAS8).toFixed(2),
            Amount9: parseFloat(costAS9).toFixed(2),
            Amount10: parseFloat(costAS10).toFixed(2),
            Amount11: parseFloat(costAS11).toFixed(2)
          }
        ],
        listCostInProgress: false
      });
    });
  }

  resfreshAllData() {
    this.listAlarms(this.state.account_id);
    this.listCost(this.state.account_id, "ALL");
  }

  getAlarmsListCallback(response) {
    //debugger;
    this.setState({
      alarms: response["MetricAlarms"],
      listAlarmInProgress: false,
      seconds: 0
    });
    this.getHistory(this.state.account_id);
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          null,
          null,
          null,
          null,
          null,
          "eu-west-1",
          {
            MaxRecords: 100
          }
        );
      }
    );
  }

  isAlarmSetUp = (alarm_action, ok_action) =>
    [
      "arn:aws:sns:eu-west-1:182726513909:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:815704857845:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:726054927466:snt-exsi-notifications"
    ]
      .map(
        sns_arn => alarm_action.includes(sns_arn) && ok_action.includes(sns_arn)
      )
      .find(value => !value) === undefined;

  renderCircle(status, alarm_action, ok_action, classes) {
    if (!this.isAlarmSetUp(alarm_action, ok_action)) {
      return (
        <Grid container spacing={8} className={classes.gridContainer}>
          <Grid item xs={3}>
            <Circle bgColor={circleColor.yellow} circleSize={20} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                "font-family": "sans-serif",
                "font-size": "14px",
                "font-weight": "bold",
                color: "#3c577a"
              }}
            >
              aucune configuration
            </Typography>
          </Grid>
        </Grid>
      );
    }

    switch (status) {
      case "ALARM":
        return <Circle bgColor={circleColor.red} circleSize={20} />;

      case "OK":
        return <Circle bgColor={circleColor.green} circleSize={20} />;

      default:
        return <Circle bgColor={circleColor.gray} circleSize={20} />;
    }
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
  }

  handleSurveillanceClickOpen(alarm_name) {
    this.setState({
      modalSurveillanceOpen: true,
      current_alarm: alarm_name
    });
  }

  handleSurveillanceClose() {
    this.setState({
      modalSurveillanceOpen: false
    });
  }

  handleHistoryClickOpen(alarm_name, history) {
    this.setState({
      modalHistoryOpen: true,
      current_alarm: alarm_name,
      current_history: history
    });
  }

  handleHistoryClose() {
    this.setState({
      modalHistoryOpen: false,
      current_history: undefined
    });
  }

  getHistory(account_id) {
    if (this.state.getHistoryInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        getHistoryInProgress: true
      },
      () => {
        getAccountAlarmLogs(account_id).then(response => {
          const alarmsWithHistory = this.state.alarms
            .map(alarm => ({
              alarm,
              history: response
                .filter(
                  item =>
                    item["AlarmName"] && item["AlarmName"] === alarm.AlarmName
                )
                .sort((a, b) => {
                  const hasAGoodTimeStamp = a["ts"] && a["ts"];
                  const hasBGoodTimeStamp = b["ts"] && b["ts"];
                  if (!hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return 0;
                  }

                  if (hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return -1;
                  }

                  if (!hasAGoodTimeStamp && hasBGoodTimeStamp) {
                    return 1;
                  }
                  return a["ts"] > b["ts"] ? -1 : 1;
                })
            }))
            .sort((a, b) => {
              const hasAHistory = a.history.length > 0;
              const hasBHistory = b.history.length > 0;

              if (!hasAHistory && !hasBHistory) {
                return 0;
              }

              if (hasAHistory && !hasBHistory) {
                return -1;
              }

              if (!hasAHistory && hasBHistory) {
                return 1;
              }

              return a.history[0]["ts"] > b.history[0]["ts"] ? -1 : 1;
            });
          this.setState({
            alarmsWithHistory: alarmsWithHistory,
            getHistoryInProgress: false,
            loading: false
          });
        });
      }
    );
  }

  renderSurveillance(alarm_name) {
    let channel_counter = 0;
    if (this.state.surveillance) {
      if ("Items" in this.state.surveillance) {
        for (let i = 0; i < this.state.surveillance["Items"].length; i++) {
          let regex = this.state.surveillance.Items[i]["AlarmName"].replace(
            "*",
            ".*"
          );
          if (alarm_name.match(regex)) {
            channel_counter++;
          }
        }
      }
    }
    if (channel_counter === 0) {
      return "Aucun canal";
    } else if (channel_counter === 1) {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canal
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canaux
        </Button>
      );
    }
  }

  renderHistory(alarm_name, history) {
    return history.length > 0 ? (
      <Button
        onClick={() => this.handleHistoryClickOpen(alarm_name, history)}
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          "border-radius": "30px",
          "padding-top": "0px",
          "padding-bottom": "0px",
          textTransform: "lowercase",
          background: "#edfaf7",
          color: "#3c577a"
        }}
      >
        {Moment(new Date(history[0].ts * 1000)).format(
          "ddd Do MMMM YYYY, HH:mm:ss"
        )}{" "}
        ({history.length} évenements)
      </Button>
    ) : (
      <Button
        disabled
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          textTransform: "lowercase",
          "background-color": "white",
          color: "#3c577a"
        }}
      >
        Aucun évenement
      </Button>
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account_id}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.applicationState) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else if (this.state.applicationState === "down") {
      return (
        <div>
          <Circle bgColor={circleColor.black} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement a été éteint, vous pouvez le rallumer avec l'action
            laf-env-controller-start.
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.alarms &&
      this.state.alarms.length > 0 &&
      this.state.alarmsWithHistory
    ) {
      return (
        <div>
          <Paper
            className={classes.root}
            style={{
              background: "#f7faf9",
              "margin-left": "20px",
              "margin-right": "20px",
              "margin-bottom": "20px",
              "box-shadow": "none"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh"
              }}
            >
              <AreaChart
                width={1350}
                height={640}
                data={this.state.MonthlyTotal}
                margin={{ top: 120, right: 100, left: 0, bottom: 150 }}
              >
                <defs>
                  <linearGradient id="colorMax" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorTv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" hide="true" />
                <YAxis domain={[0, dataMax => dataMax * 2]} />
                <CartesianGrid strokeDasharray="1 1" />
                <Tooltip
                  offset={-100}
                  cursor={{ stroke: "#99ccff", strokeWidth: 2 }}
                  itemStyle={{ border: "none" }}
                  contentStyle={{ "font-weight": "600" }}
                  wrapperStyle={{ border: "none" }}
                  labelStyle={{ border: "none" }}
                />
                <Area
                  type="monotone"
                  dataKey="total du mois"
                  stroke="#009999"
                  fillOpacity={1}
                  fill="url(#colorTv)"
                  dot={(true, { stroke: "#006666", strokeWidth: 2 })}
                />
                <Area
                  type="monotone"
                  dataKey="max par mois"
                  stroke="#8884d8"
                  fillOpacity={0.1}
                  fill="url(#colorMax)"
                />
              </AreaChart>
            </div>
            <Button
              size="small"
              color="primary"
              style={{
                "font-family": "sans-serif",
                "font-size": "12px",
                "font-weight": "600",
                background: "#edfaf7",
                color: "#009999",
                "box-shadow": "none",
                "border-radius": "30px",
                border: "none",
                marginBottom: "30px"
              }}
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                    this.listCost(this.state.account_id, "ALL");
                  }
                );
              }}
            >
              <Cached /> Refresh ({this.state.seconds} s)
            </Button>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    border: "none",
                    height: "40px"
                  }}
                >
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "30px 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "10px",
                      "margin-left": "10px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>Services ($)</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth11}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth10}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth9}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth8}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth7}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth6}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth5}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth4}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth3}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth2}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 0 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{prevMonth1}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 30px 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "lowercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>{month}</span>
                  </TableCell>
                  {/*<TableCell
                    style={{
                      border: "none",
                      "border-radius": "0 30px 0 0",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      background: "#dff2ed",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "uppercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#139aa1" }}>Total</span>
                  </TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                      "padding-top": "0px",
                      "padding-bottom": "0px",
                      "margin-right": "0px",
                      "margin-left": "0px",
                      color: "#48596e",
                      "font-weight": "bold",
                      "font-size": "14px",
                      textTransform: "uppercase",
                      textAlign: "center"
                    }}
                  >
                    <span style={{ color: "#3c577a" }}>Total</span>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[0]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[1]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[2]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[3]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[4]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[5]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[6]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[7]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[8]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[9]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      paddingRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[10]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      marginRight: "54px"
                    }}
                  >
                    {this.state.MonthlyTotalLine[11]}
                  </TableCell>
                  {/*<TableCell
                    align="center"
                    style={{
                      border: "none",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#3c577a",
                      textAlign: "center",
                      marginRight: "54px"
                    }}
                  >
                    {this.state.TwelveMonthlyTotal}
                  </TableCell>*/}
                </TableRow>
                {this.state.AmortizedCost.sort((a, b) => (a.RId < b.RId ? 1 : -1)) // eslint-disable-line
                  .sort((a, b) => (parseFloat(a.Amount11) < parseFloat(b.Amount11) ? 1 : -1)) // eslint-disable-line
                  .map(resourceType => (
                    <TableRow key={resourceType.RId}>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {resourceType.RType}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount)
                          ? "0.00"
                          : resourceType.Amount}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount1)
                          ? "0.00"
                          : resourceType.Amount1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount2)
                          ? "0.00"
                          : resourceType.Amount2}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount3)
                          ? "0.00"
                          : resourceType.Amount3}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount4)
                          ? "0.00"
                          : resourceType.Amount4}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount5)
                          ? "0.00"
                          : resourceType.Amount5}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount6)
                          ? "0.00"
                          : resourceType.Amount6}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount7)
                          ? "0.00"
                          : resourceType.Amount7}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount8)
                          ? "0.00"
                          : resourceType.Amount8}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount9)
                          ? "0.00"
                          : resourceType.Amount9}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount10)
                          ? "0.00"
                          : resourceType.Amount10}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {isNaN(resourceType.Amount11)
                          ? "0.00"
                          : resourceType.Amount11}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return "";
    }
  }
}

FinopsDashboardTableYear.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(FinopsDashboardTableYear));
