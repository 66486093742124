import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CircularLoading } from "../../Styles/ComponentStyles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import IDCARD from "../../Styles/Icons/idcard.png";
import USER from "../../Styles/Icons/user.png";
import BELL from "../../Styles/Icons/bell.png";
import MEGAPHONE from "../../Styles/Icons/megaphone.png";
import HEURED from "../../Styles/Icons/clockD.png";
import HEUREF from "../../Styles/Icons/clockF.png";
import ASTREINTE from "../../Styles/Icons/oncall.png";

import { styles } from "../../Styles/ComponentStyles";
import { CustomTableCell } from "../../Styles/ComponentStyles";

import Config from "../../config.js";
import PropTypes from "prop-types";

// The order and what we decide to show to the user
const header_names = [
  "AccountID",
  "UserID",
  "AlarmName",
  "Channel",
  "TimeBegin",
  "TimeEnd",
  "OnCall"
];

class AlarmChannelsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      current_account_id: "",
      current_UUID: "",
      current_alarm_name: ""
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  renderResourceRow(row) {
    let element = [];

    for (let header_name of header_names) {
      let row_value = "";
      if (header_name === "OnCall") {
        if (row.HO) {
          row_value = "HO";
        } else if (row.HNO) {
          row_value = "HNO";
        }
      }
      if (row.hasOwnProperty(header_name)) {
        row_value = row[header_name];
      }
      element.push(
        <CustomTableCell
          key={header_name}
          align="center"
          style={{
            border: "none",
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            background: "white",
            color: "#3c577a"
          }}
        >
          {row_value}
        </CustomTableCell>
      );
    }

    element.push(
      <CustomTableCell
        key="delete-icon"
        align="center"
        style={{
          border: "none",
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          background: "white",
          color: "#3c577a"
        }}
      >
        <Button
          onClick={() =>
            this.setState({
              dialogOpen: true,
              current_account_id: row["AccountID"],
              current_UUID: row["UUID"],
              current_alarm_name: row["AlarmName"]
            })
          }
          color="primary"
          key="delete-channel"
        >
          <DeleteOutlinedIcon className={this.state.classes.icon} />
        </Button>
      </CustomTableCell>
    );
    return element;
  }

  renderResourceRows() {
    let element = [];
    if (this.state.surveillance && this.state.surveillance.Items.length > 0) {
      for (let key in this.state.surveillance.Items) {
        let regex = this.state.surveillance.Items[key]["AlarmName"]
          .replace(/[[\]{}()*+?.\\^$|]/g, "\\$&")
          .replace("*", ".*");

        // By default show all alarms unless we want to match the regex
        if (this.state.match_alarm) {
          if (this.state.alarm_name.match(regex)) {
            element.push(
              <TableRow key={key}>
                {this.renderResourceRow(this.state.surveillance.Items[key])}
              </TableRow>
            );
          }
        } else {
          element.push(
            <TableRow key={key}>
              {this.renderResourceRow(this.state.surveillance.Items[key])}
            </TableRow>
          );
        }
      }
    }
    return element;
  }

  /**
   * Render the header of the alarm channel tables
   * The order of the columns (and what header to show) are defined by the header_names array
   */
  renderResourceHeader() {
    let element = [];
    if (this.state.surveillance && this.state.surveillance.Items.length > 0) {
      for (let key in header_names) {
        let header = Config.translation[header_names[key]];
        element.push(
          <TableCell
            key={header}
            style={{
              border: "none",
              "border-radius": "0 0 0 0",
              "margin-top": "0px",
              "margin-bottom": "0px",
              "padding-top": "0px",
              "padding-bottom": "0px",
              "margin-right": "0px",
              "margin-left": "0px",
              background: "#dff2ed",
              color: "#139aa1",
              "font-weight": "bold",
              "font-size": "14px",
              maxWidth: "60px",
              textTransform: "lowercase",
              textAlign: "center"
            }}
          >
            {header === "Compte" && (
              <img
                src={IDCARD}
                alt={"IDCARD-CANAUX"}
                style={{
                  height: "22px",
                  width: "22px",
                  "margin-right": "10px",
                  "padding-left": "0px",
                  "padding-top": "10px",
                  "vertical-align": "-50%"
                }}
              />
            )}
            {header === "Utilisateur" && (
              <img
                src={USER}
                alt={"USER-CANAUX"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "5px",
                  "padding-left": "0px",
                  "vertical-align": "-20%"
                }}
              />
            )}
            {header === "Alarme" && (
              <img
                src={BELL}
                alt={"ALARME-CANAUX"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "8px",
                  "padding-left": "0px",
                  "vertical-align": "-20%"
                }}
              />
            )}
            {header === "Canal" && (
              <img
                src={MEGAPHONE}
                alt={"ALERTE-CANAUX"}
                style={{
                  height: "22px",
                  width: "22px",
                  "margin-right": "10px",
                  "padding-left": "10px",
                  "vertical-align": "-50%"
                }}
              />
            )}
            {header === "début" && (
              <img
                src={HEURED}
                alt={"HEURE_DEBUT-CANAUX"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "10px",
                  "padding-left": "10px",
                  "vertical-align": "-50%"
                }}
              />
            )}
            {header === "fin" && (
              <img
                src={HEUREF}
                alt={"HEURE_FIN-CANAUX"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "10px",
                  "padding-left": "10px",
                  "vertical-align": "-50%"
                }}
              />
            )}
            {header === "Astreinte" && (
              <img
                src={ASTREINTE}
                alt={"ASTREINTE-CANAUX"}
                style={{
                  height: "30px",
                  width: "30px",
                  "margin-right": "0px",
                  "padding-left": "0px",
                  "margin-top": "5px",
                  "margin-bottom": "-5px",
                  "vertical-align": "-40%"
                }}
              />
            )}
            {header === "Compte" && (
              <span
                style={{
                  minWidth: "60px"
                }}
              >
                {header}
              </span>
            )}
            {header === "Utilisateur" && (
              <span
                style={{
                  minWidth: "60px"
                }}
              >
                {header}
              </span>
            )}
            {header === "Alarme" && (
              <span
                style={{
                  minWidth: "60px"
                }}
              >
                {header}
              </span>
            )}
            {header === "Canal" && (
              <span
                style={{
                  minWidth: "60px"
                }}
              >
                {header}
              </span>
            )}
            {header === "début" && (
              <span
                style={{
                  minWidth: "60px"
                }}
              >
                {header}
              </span>
            )}
            {header === "fin" && (
              <span
                style={{
                  minWidth: "60px"
                }}
              >
                {header}
              </span>
            )}
            {header === "Astreinte" && (
              <span
                style={{
                  minWidth: "60px",
                  "vertical-align": "50%"
                }}
              >
                {header}
              </span>
            )}
          </TableCell>
        );
      }
      element.push(
        <TableCell
          key="Actions"
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            "margin-top": "0px",
            "margin-bottom": "0px",
            "padding-top": "10px",
            "padding-bottom": "10px",
            "margin-right": "10px",
            "margin-left": "10px",
            background: "#dff2ed",
            color: "#48596e",
            "font-weight": "bold",
            "font-size": "14px",
            textTransform: "uppercase",
            width: "30px",
            textAlign: "center"
          }}
        >
          {""}
        </TableCell>
      );
    } else {
      element.push(
        <TableCell variant="head" align="center" size="large" key="noChannel">
          Aucun canal trouvé
        </TableCell>
      );
    }
    return element;
  }

  handleCloseDialog() {
    this.setState({
      dialogOpen: false
    });
  }

  renderDialog() {
    if (this.state.dialogOpen) {
      return (
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              borderRadius: 30,
              fontFamily: "sans-serif",
              fontSize: 12,
              fontWeight: 600,
              color: "#009999"
            }
          }}
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              marginBottom: "10px",
              "text-transform": "uppercase",
              "font-size": "12px",
              "font-weight": "bold"
            }}
          >
            <Typography
              variant="body1"
              style={{
                "text-transform": "uppercase",
                "font-size": "16px",
                "font-weight": "bold",
                "text-align": "center",
                "border-radius": "30px",
                marginBottom: "10px",
                marginTop: "10px",
                color: "#009999"
              }}
            >
              Suppression canal
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              ATTENTION - Vous êtes sur le point de supprimer un canal
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              color="secondary"
              size="medium"
              variant="contained"
              style={{
                backgroundColor: "secondary",
                "box-shadow": "none",
                "border-radius": "20px",
                border: "none",
                "font-weight": "bold",
                color: "#ffffff",
                margin: "10px"
              }}
            >
              Annuler
            </Button>
            <Button
              onClick={() =>
                this.state.delete_channel_callback(
                  this.state.current_account_id,
                  this.state.current_alarm_name,
                  this.state.current_UUID
                )
              }
              color="primary"
              size="medium"
              variant="contained"
              style={{
                backgroundColor: "secondary",
                "box-shadow": "none",
                "border-radius": "20px",
                border: "none",
                "font-weight": "bold",
                color: "#ffffff",
                margin: "10px"
              }}
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.surveillance !== null) {
      return (
        <div style={{ overflowX: "hidden" }}>
          {this.renderDialog()}
          <Paper
            className={classes.paperChannelList}
            style={{
              background: "#f7faf9",
              "margin-left": "auto",
              "margin-right": "auto",
              "margin-bottom": "40px",
              overflowX: "hidden",
              maxWidth: "1300px",
              "box-shadow": "none"
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    border: "none",
                    height: "10px"
                  }}
                >
                  {this.renderResourceHeader()}
                </TableRow>
              </TableHead>
              <TableBody>{this.renderResourceRows()}</TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else {
      return <CircularLoading></CircularLoading>;
    }
  }
}

AlarmChannelsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlarmChannelsTable);
