/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import ReportModelSwitch from "../Switches/ReportModelSwitch";

import Chart from "../Reports/Chart";
import { lineChartOptions } from "../Reports/Options";

import ChartDev from "../Reports/ChartDev";
import { lineChartOptionsDev } from "../Reports/OptionsDev";
import ChartStg from "../Reports/ChartStg";
import { lineChartOptionsStg } from "../Reports/OptionsStg";
import ChartPrd from "../Reports/ChartPrd";
import { lineChartOptionsPrd } from "../Reports/OptionsPrd";
import ChartTrends from "../Reports/ChartTrends";
import { lineChartOptionsTrends } from "../Reports/OptionsTrends";

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    width: 'fit-content',
    padding:"0px",
    color: theme.palette.text.secondary,
  },
});

class AddReportModelDialog extends React.Component {
  state = {
    open: false,
    fullWidth: true,
    maxWidth: 'xl',
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Button
          onClick={this.handleClickOpen}
          color="primary"
          size="medium"
          variant="contained"
          style={{
            backgroundColor: "secondary",
            "box-shadow": "none",
            "border-radius": "20px",
            border: "none",
            "font-weight": "bold",
            "color": "#ffffff",
            "margin": "10px"
          }}
        >
          EDITER UN MODELE
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            <Typography
              style={{
                textTransform: "uppercase",
                color: "#139aa1",
                "font-weight": "bold",
                textAlign: "center",
                fontSize: "24px",
                marginLeft: "40px"
              }}
            >
            MODELE BUILDER
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Paper>
            <ReportModelSwitch />
            <ChartDev chartOptions={lineChartOptionsDev} />
            <ReportModelSwitch />
            <ChartStg chartOptions={lineChartOptionsStg} />
            <ReportModelSwitch />
            <ChartPrd chartOptions={lineChartOptionsPrd} />
            <ChartTrends chartOptions={lineChartOptionsTrends} />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              size="medium"
              variant="contained"
              style={{
                backgroundColor: "secondary",
                "box-shadow": "none",
                "border-radius": "20px",
                border: "none",
                "font-weight": "bold",
                "color": "#ffffff",
                "margin": "10px"
              }}
            >
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

AddReportModelDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddReportModelDialog);