import React, { useCallback, useEffect, useState } from "react";
import { getUserProfiles } from "../../Services/UserProfilesService";
import { retrieveAllPerimeters } from "../../Services/PerimetersService";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import UsersManagementRow from "./UsersManagementRow";
//import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import UsersManagementCreate from "./UsersManagementCreate";
import withStyles from "@material-ui/core/styles/withStyles";

const useMediaQuery = query => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

const styles = {
  container: isRowBased => ({
    display: isRowBased ? "table-cell" : "none"
  })
};

const UsersManagementDashboard = classes => {
  const [userProfiles, setUserProfiles] = useState(undefined);
  const [perimeters, setPerimeters] = useState(undefined);
  const [isCreateUserOpened, setIsCreateUserOpened] = useState(false);

  const handleCreateUserClose = () => setIsCreateUserOpened(false);

  useEffect(() => {
    const retrieveUserProfiles = async () => {
      setUserProfiles(await getUserProfiles());
    };

    retrieveUserProfiles();
  }, []);
  const isRowBased = useMediaQuery("(min-width: 500px)");

  useEffect(() => {
    const retrievePerimeters = async () => {
      setPerimeters(
        (await retrieveAllPerimeters()).map(
          perimeter => perimeter["Perimetre Name"]
        )
      );
    };

    retrievePerimeters();
  }, []);

  const userProfileReloadCallBack = useCallback(async () => {
    setIsCreateUserOpened(false);
    setUserProfiles(undefined);
    setUserProfiles(await getUserProfiles());
  }, []);

  return (
    <div style={{ overflowX: "scroll" }}>
      {!(userProfiles && perimeters) && (
        <div>
          <svg viewBox="0 0 800 800">
            <path
              d="M2,295 C363,348 395,4 800,47"
              fill="none"
              stroke="#0ec289"
              stroke-width="0.5"
              stroke-dashoffset="1000"
            />
            <path
              d="M0,204 C377,367 504,22 797,225"
              fill="none"
              stroke="#0ec289"
              stroke-width="0.5"
            />
          </svg>
        </div>
      )}
      <Button
        color="primary"
        size="medium"
        variant="contained"
        style={{
          backgroundColor: "secondary",
          boxShadow: "none",
          borderRadius: "20px",
          border: "none",
          fontWeight: "bold",
          color: "#ffffff",
          margin: "10px"
        }}
        onClick={() => setIsCreateUserOpened(true)}
      >
        Ajouter un utilisateur
      </Button>
      <UsersManagementCreate
        open={isCreateUserOpened}
        onClose={handleCreateUserClose}
        onValidate={userProfileReloadCallBack}
      ></UsersManagementCreate>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>UserName</b>
            </TableCell>
            <TableCell
              align="center"
              style={styles.container(isRowBased)} // eslint-disable-line
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Mail</b>
            </TableCell>
            <TableCell
              align="center"
              style={styles.container(isRowBased)} // eslint-disable-line
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>FirstName</b>
            </TableCell>
            <TableCell
              align="center"
              style={styles.container(isRowBased)} // eslint-disable-line
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>LastName</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Perimeters</b>
            </TableCell>
            <TableCell
              align="center"
              style={{ fontSize: "large", color: "#566fcc" }}
            >
              <b>Supprimer</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userProfiles &&
            perimeters &&
            userProfiles.map((userProfile, index) => (
              <UsersManagementRow
                key={index}
                userProfile={userProfile}
                perimeters={perimeters}
                userProfileUpdateCallBack={userProfileReloadCallBack}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(withStyles)(UsersManagementDashboard);
