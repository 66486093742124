import React, { useEffect, useState } from "react";
import {
  computeAppBilling,
  getAccountBilling
} from "../../Services/AccountService";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import BillingEnv from "./BillingEnv";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ENVS from "../../Styles/Icons/Applis.png";
import CALEND from "../../Styles/Icons/calendar.png";
import CALENDF from "../../Styles/Icons/calendar1st.png";
import FORECAST from "../../Styles/Icons/forecast.png";

const BillingApp = ({ appName, environments, classes }) => {
  const [environmentBillings, setEnvironmentBillings] = useState(undefined);
  const [appBilling, setAppBilling] = useState(undefined);
  const [displayEnvironments, setDisplayEnvironments] = useState(false);

  useEffect(() => {
    Promise.all(
      Object.entries(environments).map(([envName, accountId]) =>
        getAccountBilling(envName, accountId)
      )
    ).then(results =>
      setEnvironmentBillings(
        Object.fromEntries(
          results.map(result => [
            Object.keys(result)[0],
            Object.values(result)[0]
          ])
        )
      )
    );
  }, [environments]);

  useEffect(() => {
    if (environmentBillings) {
      setAppBilling(computeAppBilling(environmentBillings));
    }
  }, [environmentBillings]);

  return (
    <div>
      <div
        style={{
          fontSize: "x-large",
          float: "left",
          "padding-left": "20px",
          color: "#566fcc"
        }}
      >
        <b>{appName.toUpperCase()}</b>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{
                border: "none",
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                fontSize: "14px",
                fontWeight: "100",
                color: "#00838f"
              }}
            >
              {" "}
              <Button
                onClick={() => setDisplayEnvironments(!displayEnvironments)}
              >
                <b>
                  {displayEnvironments ? (
                    <ArrowDropDown fontSize="large" nativeColor="grey" />
                  ) : (
                    <ArrowDropUp fontSize="large" nativeColor="grey" />
                  )}
                </b>
                <img
                  src={ENVS}
                  alt={"ENVIRONNEMENTS"}
                  style={{
                    height: "18px",
                    width: "18px",
                    "margin-right": "10px",
                    "padding-left": "10px",
                    "vertical-align": "-50%"
                  }}
                />
              </Button>
              <Tooltip
                title={"Liste des environnements de l'application"}
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>Environnements</b>
              </Tooltip>
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: "none",
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                fontSize: "14px",
                fontWeight: "100",
                color: "#00838f"
              }}
            >
              {" "}
              <img
                src={CALEND}
                alt={"CALENDARS"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "10px",
                  "padding-left": "10px",
                  "vertical-align": "-50%"
                }}
              />
              <Tooltip
                title={"Dépenses totales du mois dernier"}
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>Mois dernier</b>
              </Tooltip>
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: "none",
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                fontSize: "14px",
                fontWeight: "100",
                color: "#00838f"
              }}
            >
              {" "}
              <img
                src={CALENDF}
                alt={"CALENDARS"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "10px",
                  "padding-left": "10px",
                  "vertical-align": "-50%"
                }}
              />
              <Tooltip
                title={"Dépenses totales du mois en cours"}
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>Mois courant</b>
              </Tooltip>
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: "none",
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                fontSize: "14px",
                fontWeight: "100",
                color: "#00838f"
              }}
            >
              {" "}
              <img
                src={FORECAST}
                alt={"FORECAST"}
                style={{
                  height: "18px",
                  width: "18px",
                  "margin-right": "10px",
                  "padding-left": "10px",
                  "vertical-align": "-50%"
                }}
              />
              <Tooltip
                title={
                  "Evolution des dépenses prévues pour le mois en cours par rapport au mois dernier"
                }
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>% Prévision</b>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        {environmentBillings && appBilling && (
          <TableBody>
            <BillingEnv
              style={{
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                fontSize: "8px",
                fontWeight: "100",
                color: "#00838f"
              }}
              key={"total"}
              envName={"TOTAL"}
              envBilling={appBilling}
            />
            {displayEnvironments &&
              Object.entries(environmentBillings).map(([envName, envBilling]) => (// eslint-disable-line
                <BillingEnv // eslint-disable-line
                  style={{ // eslint-disable-line
                    textTransform: "uppercase", // eslint-disable-line
                    fontFamily: "sans-serif", // eslint-disable-line
                    fontSize: "8px", // eslint-disable-line
                    fontWeight: "100", // eslint-disable-line
                    color: "#00838f" // eslint-disable-line
                  }} // eslint-disable-line
                  key={envName} // eslint-disable-line
                  envName={envName} // eslint-disable-line
                  envBilling={envBilling} // eslint-disable-line
                /> // eslint-disable-line
              ))// eslint-disable-line
            }
          </TableBody>
        )}
      </Table>
      {!(environmentBillings && appBilling) && <CircularLoading />}
    </div>
  );
};

BillingApp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BillingApp);
