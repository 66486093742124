import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { withSnackbar } from "notistack";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button, Input } from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";
import UserSelectorGenericMail from "../Selectors/UserSelectorGenericMail";
import ChannelMethodSelectorGenMail from "../Selectors/ChannelMethodSelectorGenMail";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import { retrieveAllPerimeters } from "../../Services/PerimetersService";
import { getUserProfiles } from "../../Services/UserProfilesService";
import { addChannel } from "../../Services/ChannelsService";
import { addLog } from "../../Services/LogsService";
import AlarmSelector from "../Selectors/AlarmSelector";

//Set the fr language for moment
moment.locale("fr");

class AddChannelDialogGenMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      modalAddChannelOpen: this.props.visible,
      form_alarm_error: true,
      form_alarm: "",
      form_user: "",
      form_channel: "email",
      user_list: [],
      HO: false,
      form_beg_hour: new Date("2000-01-01T19:00:00"),
      form_end_hour: new Date("2000-01-01T09:00:00"),
      form_error: true,
      perimeter_list: []
    };
    this.updateGlobalFormError = this.updateGlobalFormError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.modalAddChannelOpen !== nextProps.visible) {
      this.setState({
        modalAddChannelOpen: nextProps.visible
      });
    }
  }

  handleSelectorChangeFormAccountId = e => {
    this.setState({
      form_account_id: e
    });
  };

  handleTextFieldChangeFormAlarmName = e => {
    if (e.target.value.length > 0) {
      this.setState(
        {
          form_alarm_error: false
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    } else {
      this.setState(
        {
          form_alarm_error: true
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    }
    this.setState({
      form_alarm: e.target.value
    });
  };

  handleSelectorChangeFormChannel = e => {
    this.setState({
      form_channel: e
    });
  };

  handleSelectorChangeFormUser = e => {
    this.setState({
      form_user: e
    });
  };

  handleHOChangeFormMethod = e => {
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      HO: value,
      HNO: false
    });
  };

  handleHNOChangeFormMethod = e => {
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      HNO: value,
      HO: false
    });
  };

  handleTextFieldChangeFormBegHour = e => {
    this.setState({
      form_beg_hour: e
    });
  };

  handleTextFieldChangeFormEndHour = e => {
    this.setState({
      form_end_hour: e
    });
  };

  getAllPerimeters() {
    retrieveAllPerimeters()
      .then(response => {
        this.setState({
          perimeter_list: response
        });
        this.searchUserwithAppInPerimeter(this.props.app, this.props.env);
      })
      .catch(error =>
        this.props.enqueueSnackbar("Impossible de récupérer les périmètres", {
          variant: "error",
          autoHideDuration: 3000
        })
      );
  }

  searchUserwithAppInPerimeter(app, env) {
    let ls_key = Object.keys(localStorage).filter(key =>
      key.endsWith("LastAuthUser")
    );
    let ls_value = localStorage.getItem(ls_key);
    getUserProfiles().then(response => {
      const appName = app + "-" + env;
      const perimeterApp = this.state.perimeter_list.find(perimeter =>
        perimeter["Application Name"].includes(appName)
      );
      const user_list = response
        .filter(user => user.UserName.startsWith(`${ls_value}`+ "_smtp_rule")) // eslint-disable-line
        .filter(user => user.hasOwnProperty("Perimeters"))
        .filter(filtered_user =>
          filtered_user.Perimeters.includes(
            perimeterApp && perimeterApp["Perimetre Name"]
          )
        );

      this.setState({
        user_list: user_list,
        form_user: user_list && user_list[0] && user_list[0].UserName
      });
    });
  }

  componentDidMount() {
    this.getAllPerimeters();
  }

  handleValidateChannelClickOpen() {
    let account_id;

    if (this.state.form_account_id) {
      account_id = this.state.form_account_id;
    } else {
      account_id = this.state.account_id;
    }

    let time_parameters = {};
    let time_description = "";
    if (this.state.HO) {
      time_parameters = {
        ho: this.state.HO
      };
      time_description = " avec la période astreinte HO";
    } else if (this.state.HNO) {
      time_parameters = {
        hno: this.state.HNO
      };
      time_description = " avec la période astreinte HNO";
    } else {
      time_parameters = {
        time_beg:
          this.pad(this.state.form_beg_hour.getHours(), 2) +
          ":" +
          this.pad(this.state.form_beg_hour.getMinutes(), 2),
        time_end:
          this.pad(this.state.form_end_hour.getHours(), 2) +
          ":" +
          this.pad(this.state.form_end_hour.getMinutes(), 2)
      };
      time_description =
        " avec la période " +
        this.pad(this.state.form_beg_hour.getHours(), 2) +
        ":" +
        this.pad(this.state.form_beg_hour.getMinutes(), 2) +
        "/" +
        this.pad(this.state.form_end_hour.getHours(), 2) +
        ":" +
        this.pad(this.state.form_end_hour.getMinutes(), 2);
    }
    addChannel(
      account_id,
      this.state.form_alarm,
      this.state.form_channel,
      this.state.form_user,
      time_parameters
    ).then(() => {
      const action_description =
        "Ajout d'un channel sur le compte : " +
        account_id +
        " avec le nom " +
        this.state.form_alarm +
        " avec le canal " +
        this.state.form_channel +
        " avec la règle " +
        this.state.form_user +
        time_description;

      const current_time = moment().format("dddd Do MMMM YYYY H: mm: ss");
      addLog(this.props.user_name, current_time, action_description);
      this.props.onClose();
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 10000
      });
    });
  }

  handleAlarmsFetched(alarms) {
    this.setState({ alarms });
  }

  handleAlarmChange(event) {
    this.setState({ selectedAlarm: event.target.value });
  }

  updateGlobalFormError() {
    if (
      this.state.form_account_id_error === true ||
      this.state.form_alarm_error === true ||
      this.state.user_list.length === 0
    ) {
      this.setState({
        form_error: true
      });
    } else {
      this.setState({
        form_error: false
      });
    }
  }

  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  closeModal = () => {
    // Update the user list before closing so we can have new value without reloading the page
    this.searchUserwithAppInPerimeter(this.props.app, this.props.env);
    this.props.onClose();
  };

  render() {
    const { classes } = this.props;
    const appFQ = this.state.app + "-" + this.state.env;
    return (
      <Dialog
        open={this.state.modalAddChannelOpen}
        onClose={this.closeModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            borderRadius: 30,
            fontFamily: "sans-serif",
            fontSize: 12,
            fontWeight: 600,
            color: "#009999"
          }
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            marginBottom: "10px",
            "text-transform": "uppercase",
            "font-size": "12px",
            "font-weight": "bold"
          }}
        >
          <Typography
            variant="body1"
            style={{
              "text-transform": "uppercase",
              "font-size": "16px",
              "font-weight": "bold",
              "text-align": "center",
              "border-radius": "30px",
              marginBottom: "10px",
              marginTop: "10px",
              color: "#009999"
            }}
          >
            Ajouter un canal proxy à {this.props.app}-{this.props.env} avec un
            email générique
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            "overflow-x": "hidden",
            color: "#009999"
          }}
        >
          {/*<AccountSelector*/}
          {/*  id="alarm-dashboard-account-selector"*/}
          {/*  onApplicationChange={this.handleSelectorChangeFormAccountId}*/}
          {/*  selectedAccount={this.state.account_id}*/}
          {/*  accountList={this.state.account_list}*/}
          {/*/>*/}
          <Input readOnly={true} value={this.state.account_id} />
          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            Le numéro à 12 chiffres du compte AWS
          </FormHelperText>

          <AlarmSelector
            account_id={this.state.account_id}
            userIdToken={this.state.userIdToken}
            onAlarmsFetched={this.handleAlarmsFetched.bind(this)}
            alarms={this.state.alarms}
            handleAlarmChange={this.handleAlarmChange.bind(this)}
            selectedAlarm={this.state.selectedAlarm}
            onChanges={this.handleTextFieldChangeFormAlarmName}
          />
          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            Le nom de l'alarme Cloudwatch à superviser. Ce nom peut contenir une
            étoile (*) pour une sélection multiple
          </FormHelperText>

          <ChannelMethodSelectorGenMail
            id="alarm-dashboard-channel-method-selector"
            onApplicationChange={this.handleSelectorChangeFormChannel}
            selectedValue={this.state.form_channel}
          />

          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            Le canal proxy de communication utilisé pour l'alerte
          </FormHelperText>

          <UserSelectorGenericMail
            id="alarm-dashboard-user-selector"
            onApplicationChange={this.handleSelectorChangeFormUser}
            selectedValue={this.state.form_user}
            userList={this.state.user_list}
          />

          <FormHelperText
            id="component-helper-text"
            style={{ marginBottom: "10px" }}
          >
            {this.state.user_list.length === 0
              ? "Aucune règle email générique utilisateur ne possède " +
                appFQ +
                " dans un de ses périmètres"
              : "Une règle email générique d'un utilisateur ayant " +
                appFQ +
                " dans un de ses périmètres"}{" "}
          </FormHelperText>

          <label
            key="HO"
            style={{
              "font-size": "16px",
              marginRight: "10px"
            }}
          >
            <input
              name="HO"
              type="checkbox"
              checked={this.state.HO}
              onChange={this.handleHOChangeFormMethod}
              style={{ marginTop: "10px" }}
            />
            Astreinte heures ouvrables
          </label>

          <label key="HNO" style={{ "font-size": "16px" }}>
            <input
              name="HNO"
              type="checkbox"
              checked={this.state.HNO}
              onChange={this.handleHNOChangeFormMethod}
              style={{ marginTop: "10px" }}
            />
            heures non-ouvrables
          </label>

          {this.state.HO ? (
            <div>
              <Typography variant="body1">
                Les heures 'astreinte heures ouvrables' sont tous les jours
                travaillés de 09h00 à 18h00
              </Typography>
            </div>
          ) : (
            <div></div>
          )}

          {this.state.HNO ? (
            <div>
              <Typography variant="body1">
                Les heures 'astreinte heures non ouvrables' sont tous les jours
                de 18h00 à 09h00 et 24/24h les week-ends et les jours fériés.
              </Typography>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  let location =
                    "https://fr.wikipedia.org/wiki/F%C3%AAtes_et_jours_f%C3%A9ri%C3%A9s_en_France#F%C3%AAtes_civiles";
                  window.open(location, "_blank");
                }}
              >
                Voir les jours fériés
              </Button>
            </div>
          ) : (
            <div></div>
          )}

          {!this.state.HO && !this.state.HNO ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container className={classes.grid} justify="space-around">
                <TimePicker
                  margin="normal"
                  label="Heure début"
                  value={this.state.form_beg_hour}
                  onChange={this.handleTextFieldChangeFormBegHour}
                />
                <TimePicker
                  margin="normal"
                  label="Heure fin"
                  value={this.state.form_end_hour}
                  onChange={this.handleTextFieldChangeFormEndHour}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : (
            <div></div>
          )}

          <Paper
            className={this.state.classes.rootProfilUtilisateur}
            elevation={0}
          >
            <Grid
              container
              className={classes.grid}
              justify="flex-end"
              style={{ marginTop: "30px" }}
            >
              <Button
                onClick={this.closeModal.bind(this)}
                color="secondary"
                size="medium"
                variant="contained"
                style={{
                  backgroundColor: "secondary",
                  "box-shadow": "none",
                  "border-radius": "20px",
                  border: "none",
                  "font-weight": "bold",
                  color: "#ffffff",
                  margin: "10px"
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={() => this.handleValidateChannelClickOpen()}
                color="primary"
                disabled={this.state.form_error}
                key="add"
                size="medium"
                variant="contained"
                style={{
                  backgroundColor: "secondary",
                  "box-shadow": "none",
                  "border-radius": "20px",
                  border: "none",
                  "font-weight": "bold",
                  color: "#ffffff",
                  margin: "10px"
                }}
              >
                Ajouter ce canal
              </Button>
            </Grid>
          </Paper>

          {this.state.channel_add_reponse ? (
            <Grid container className={classes.grid} justify="space-around">
              <Paper>
                <Typography>{this.state.channel_add_reponse}</Typography>
              </Paper>
            </Grid>
          ) : (
            false
          )}
        </DialogContent>
        <DialogContent>
          {this.state.popup_loading ? (
            <CircularLoading> </CircularLoading>
          ) : (
            false
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(AddChannelDialogGenMail));
