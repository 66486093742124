import React from "react";
import Card from "@material-ui/core/Card";
import PipelineStageSucceeded from "./PipelineStageSucceeded";
import PipelineStageInProgress from "./PipelineStageInProgress";
import PipelineStageFailed from "./PipelineStageFailed";
import { findCorrectSourceRevision } from "../../Services/PipelinesService";

const PipelineStage = ({
  pipelineName,
  stageState,
  lastTwoExecutions,
  userName,
  app,
  accountId
}) => (
  <Card
    elevation={0}
    style={{
      padding: "2em",
      margin: "2em",
      maxWidth: "40%",
      minWidth: "40%",
      "border-radius": "30px"
    }}
  >
    <div
      style={{
        textAlign: "left",
        "text-transform": "uppercase",
        "font-family": "sans-serif",
        "font-size": "12px",
        "font-weight": "normal",
        color: "#6c80ba",
        "margin-left": "30px"
      }}
    >
      {pipelineName}
    </div>
    <div
      style={{
        fontSize: "large",
        textAlign: "left",
        "text-transform": "uppercase",
        "font-family": "sans-serif",
        "font-size": "14px",
        "font-weight": "normal",
        color: "#6c80ba",
        "margin-left": "30px"
      }}
    >
      <b>{stageState.stageName}</b>
    </div>
    <div
      style={{
        padding: "1em",
        margin: "0.2em",
        "margin-right": "30px",
        //border: "1px solid #b2dfdb",
        border: "none",
        "border-radius": "30px",
        "background-color": "#fafafa"
      }}
    >
      {stageState.latestExecution &&
        stageState.latestExecution.status === "Succeeded" &&
        stageState.actionStates[stageState.actionStates.length - 1]
          .latestExecution && (
          <PipelineStageSucceeded
            revisionId={findCorrectSourceRevision(
              lastTwoExecutions[0].sourceRevisions
            ).revisionId.slice(0, 8)}
            actionName={
              findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
                .actionName
            }
            revisionSummary={
              findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
                .revisionSummary
            }
            status={stageState.latestExecution.status}
            lastStatusChange={
              stageState.actionStates[stageState.actionStates.length - 1]
                .latestExecution.lastStatusChange
            }
            sourceRevisions={lastTwoExecutions[0].sourceRevisions}
          />
        )}
      {stageState.latestExecution &&
        stageState.latestExecution.status === "InProgress" && (
          <PipelineStageInProgress
            actionStates={stageState.actionStates}
            lastTwoExecutions={lastTwoExecutions}
            stageName={stageState.stageName}
            pipelineName={pipelineName}
            userName={userName}
            status={stageState.latestExecution.status}
            app={app}
            accountId={accountId}
          />
        )}
      {stageState.latestExecution &&
        (stageState.latestExecution.status === "Failed" ||
          stageState.latestExecution.status === "Stopped") && (
          <PipelineStageFailed
            stageName={stageState.stageName}
            pipelineName={pipelineName}
            actionStates={stageState.actionStates}
            lastTwoExecutions={lastTwoExecutions}
            userName={userName}
            status={stageState.latestExecution.status}
            app={app}
            accountId={accountId}
          />
        )}
    </div>
  </Card>
);

export default PipelineStage;
