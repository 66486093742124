import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import AppCardListReport from "../Cards/AppCardListReport";

import AppCardList from "../Cards/AppCardList";
import green from "@material-ui/core/colors/green";
import GRTLines from "../../Styles/Icons/GRTLines.svg";
import SHIELD from "../../Styles/Icons/shield.png";
import ALARMEENV from "../../Styles/Icons/bellmini.png";
import FINOPS from "../../Styles/Icons/piggyBank.png";

const TotalChipTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

class DashboardExpansionPanel extends React.Component {
  constructor(props) {
    super(props);
    let app_list = [];
    let app_status = {};
    let app_spent = {};
    for (let key in props.account_list) {
      let app_name = key.split("-")[0];
      if (!app_list.includes(app_name)) {
        app_list.push(app_name);
        app_status[app_name] = "GATHERING";
        app_spent[app_name] = "GATHERING";
      }
    }
    this.state = {
      app_list: app_list,
      account_list: props.account_list,
      classes: props.classes,
      app_status: app_status,
      app_spent: app_spent,
      userIdToken: this.props.userIdToken,
      grid_direction: props.grid_direction,
      app_clickable: props.app_clickable
    };
  }

  render() {
    const { classes } = this.props;
    if (this.state.app_list.length < 1) {
      return (
        <div
          className="loading"
          style={{
            textAlign: "center",
            paddingTop: "20px",
            overflowX: "hidden",
            overflowY: "hidden"
          }}
        >
          <CircularLoading />
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: "auto",
            marginBottom: 0,
            background: "#f7faf9",
            backgroundImage: `url(${GRTLines})`,
            backgroundRepeat: "no-repeat"
          }}
          className={this.state.classes.rootPanel}
        >
          {this.state.app_list.map(row => {
            return (
              <Card
                variant="outlined"
                elevation={0}
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  marginRight: 20,
                  marginLeft: 20,
                  borderRadius: "20px",
                  opacity: 1
                }}
                key={row}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    className={classes.gridContainer}
                    key={row}
                  >
                    <MuiThemeProvider theme={TotalChipTheme}>
                      <Grid item xs={2}>
                        <Typography
                          variant="h5"
                          style={{
                            textTransform: "uppercase",
                            fontFamily: "sans-serif",
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginTop: "35px",
                            color: "#6c80ba"
                          }}
                        >
                          {row}
                          <AppCardListReport
                            app={row}
                            account_list={this.state.account_list}
                            user_name={this.props.user_name}
                            userIdToken={this.state.userIdToken}
                            classes={this.state.classes}
                            grid_direction={this.state.grid_direction}
                            app_clickable={this.state.app_clickable}
                          />
                        </Typography>
                      </Grid>
                    </MuiThemeProvider>
                    <Grid item xs={8}>
                      <Grid container spacing={8} justify="center">
                        <Typography
                          variant="h5"
                          style={{
                            textTransform: "uppercase",
                            fontFamily: "sans-serif",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#6c80ba"
                          }}
                        >
                          <Table
                            style={{
                              marginTop: "48px",
                              border: "none",
                              height: "10px"
                            }}
                          >
                            <TableBody>
                              <TableRow>
                                <img
                                  src={ALARMEENV}
                                  alt={"ALARMES-ENV"}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    marginRight: "10px",
                                    paddingLeft: "0px",
                                    paddingTop: "12px",
                                    verticalAlign: "-50%"
                                  }}
                                />
                              </TableRow>
                              <TableRow>
                                <img
                                  src={SHIELD}
                                  alt={"SECOPS"}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    marginRight: "10px",
                                    paddingLeft: "0px",
                                    paddingTop: "10px",
                                    verticalAlign: "-50%"
                                  }}
                                />
                              </TableRow>
                              <TableRow>
                                <img
                                  src={FINOPS}
                                  alt={"FINOPS"}
                                  style={{
                                    height: "26px",
                                    width: "26px",
                                    marginRight: "10px",
                                    paddingLeft: "0px",
                                    paddingTop: "0px",
                                    verticalAlign: "-50%"
                                  }}
                                />
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Typography>
                        <AppCardList
                          app={row}
                          account_list={this.state.account_list}
                          user_name={this.props.user_name}
                          userIdToken={this.state.userIdToken}
                          classes={this.state.classes}
                          grid_direction={this.state.grid_direction}
                          app_clickable={this.state.app_clickable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </div>
      );
    }
  }
}

export default withStyles(styles)(DashboardExpansionPanel);
