/* eslint-disable */
import Highcharts from "highcharts";

export const lineChartOptionsTrends: Highcharts.Options = {
  title: {
    text: "ENVS TRENDS ",
  },
  credits: {
      enabled: false
  },
  series: [
    {
      type: "line",
      data: [1, 2, 3, 8, 4, 7],
    },
    {
      type: "line",
      data: [5, 7, 6, 9, 5, 4],
    },
  ],
};