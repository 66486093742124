const csvExporter = {
  HEADERS: [{ label: "Liste des utilisateurs - Emails", key: "email" }],
  DATA: [
    { email: "thibault.montbrun@grtgaz.com" },
    { email: "julien.jonard@grtgaz.com" },
    { email: "samy.fortmann@external.grtgaz.com" },
    { email: "marion.poissonneau@grtgaz.com" },
    { email: "alexandre.melo@external.grtgaz.com" },
    { email: "etienne.prost@grtgaz.com" },
    { email: "nabil.boubakeur@grtgaz.com" },
    { email: "francoisxavier.collomb@grtgaz.com" },
    { email: "christophe.karnas@grtgaz.com" },
    { email: "christophe.cherrier@external.grtgaz.com" },
    { email: "benoit.maire@external.grtgaz.com" },
    { email: "nicolas.giraud@external.grtgaz.com" },
    { email: "francois.bourdel@external.grtgaz.com" },
    { email: "gregory.lecoq@grtgaz.com" },
    { email: "stephane.abellard@grtgaz.com" },
    { email: "ramy.sahnoun@grtgaz.com" },
    { email: "adrien.beaussier@grtgaz.com" },
    { email: "cedric.gauthier@external.grtgaz.com" },
    { email: "mazen.elmaarabani@grtgaz.com" },
    { email: "carine.dikoume@external.grtgaz.com" },
    { email: "flavien.lefebvre@grtgaz.com" },
    { email: "gnielealpha.niang@grtgaz.com" },
    { email: "quentin.renaud@grtgaz.com" },
    { email: "celine.brun@grtgaz.com" },
    { email: "louis.cordelle@external.grtgaz.com" },
    { email: "phone.cam@grtgaz.com" },
    { email: "marc.gherardi@external.grtgaz.com" },
    { email: "malik.kahil@grtgaz.com" },
    { email: "karen.zadworna@grtgaz.com" },
    { email: "sebastien.bretnacher@grtgaz.com" },
    { email: "lionel.dreux@grtgaz.com" },
    { email: "olivier.keith@external.grtgaz.com" },
    { email: "hamdi.bouhanef@external.grtgaz.com" },
    { email: "benoit.cattie@grtgaz.com" },
    { email: "franck.vandelanoitte@external.grtgaz.com" },
    { email: "zeidane.agbanrin@external.grtgaz.com" },
    { email: "franck.pienne@grtgaz.com" },
    { email: "eva.seck@external.grtgaz.com" },
    { email: "william.ruth@external.grtgaz.com" },
    { email: "jerome.beltel@grtgaz.com" },
    { email: "eric.sablonnieres@grtgaz.com" },
    { email: "alexandre.grondin@external.grtgaz.com" },
    { email: "laurence.delhaye@grtgaz.com" },
    { email: "xavier.deschamps@external.grtgaz.com" },
    { email: "francois.giret@external.grtgaz.com" },
    { email: "loic.guennec@grtgaz.com" },
    { email: "edith.kaufmann@grtgaz.com" },
    { email: "henri.georges@grtgaz.com" },
    { email: "karim.satsou@external.grtgaz.com" },
    { email: "abderrahmane.kaciaissa@external.grtgaz.com" },
    { email: "silvere.bruel@grtgaz.com" },
    { email: "johanna.dantas@external.grtgaz.com" },
    { email: "fatima.tayab@external.grtgaz.com" },
    { email: "imen.mdallel@external.grtgaz.com" },
    { email: "thomas.quaranta@external.grtgaz.com" },
    { email: "jules.lecoeur@grtgaz.com" },
    { email: "cedric.laurent@external.grtgaz.com" },
    { email: "vitor.claro-marinho@grtgaz.com" },
    { email: "sophie.tirlot@grtgaz.com" },
    { email: "tony.walter@grtgaz.com" },
    { email: "ludovic.chedotal@grtgaz.com" },
    { email: "ronan.trannoy@external.grtgaz.com" },
    { email: "jerome.lubrez@grtgaz.com" },
    { email: "selma.msefer@external.grtgaz.com" },
    { email: "celine.flamant@grtgaz.com" },
    { email: "fanny.gauthier@external.grtgaz.com" },
    { email: "leonie.bornet@external.grtgaz.com" },
    { email: "lakhdar.mezouari@external.grtgaz.com" },
    { email: "francois.houel@grtgaz.com" },
    { email: "hugo.malaise@grtgaz.com" },
    { email: "yann.deungoue@external.grtgaz.com" },
    { email: "zakaria.laachir@grtgaz.com" },
    { email: "fabien.derachinois@external.grtgaz.com" },
    { email: "guillaume.le-guen@grtgaz.com" },
    { email: "mathilde.djahel@grtgaz.com" },
    { email: "amina.affes@grtgaz.com" },
    { email: "ghislaine.marot@grtgaz.com" },
    { email: "julien.raison@external.grtgaz.com" },
    { email: "thomas.genest@external.grtgaz.com" },
    { email: "guillaume.gaugain@external.grtgaz.com" },
    { email: "rania.ferjani@external.grtgaz.com" },
    { email: "antoine.raynaud@external.grtgaz.com" },
    { email: "nicolas.valici@external.grtgaz.com" },
    { email: "laurent.maillol@grtgaz.com" },
    { email: "guillaume.bailleux@grtgaz.com" },
    { email: "jaime.martinez@grtgaz.com" },
    { email: "karima.belghazi@grtgaz.com" },
    { email: "yosri.arrami@external.grtgaz.com" },
    { email: "david.khor@grtgaz.com" },
    { email: "jeanclaude.simonin@external.grtgaz.com" },
    { email: "romain.ranzatto@grtgaz.com" },
    { email: "remi.klajn@external.grtgaz.com" },
    { email: "mathilde.verrier@external.grtgaz.com" },
    { email: "christophe.ngo@external.grtgaz.com" },
    { email: "julien.sauvard@grtgaz.com" },
    { email: "marc.duhamel@grtgaz.com" },
    { email: "mohamed.taieb@external.grtgaz.com" },
    { email: "inkaran.thuraiyappah@grtgaz.com" },
    { email: "patrice.mussard@grtgaz.com" },
    { email: "nicolas.gavard@grtgaz.com" },
    { email: "sebastien.riguidel@external.grtgaz.com" },
    { email: "samuel.bouttier@grtgaz.com" },
    { email: "sonia.chamakh@grtgaz.com" },
    { email: "caroline.le-flohic@grtgaz.com" },
    { email: "fatma.tayeg@grtgaz.com" },
    { email: "tong.ngo@external.grtgaz.com" },
    { email: "stephane.rannou@external.grtgaz.com" },
    { email: "dominique.pyot@grtgaz.com" },
    { email: "guillaume.cros@external.grtgaz.com" },
    { email: "antoine.kernoa@external.grtgaz.com" },
    { email: "thomas.lumineau@grtgaz.com" },
    { email: "pierremarie.couthouis@grtgaz.com" },
    { email: "lola.bouju@external.grtgaz.com" },
    { email: "landry.kateu@grtgaz.com" },
    { email: "david.cerqueira@grtgaz.com" },
    { email: "lilianerokia.andeme@external.grtgaz.com" },
    { email: "sundaralogeswaran.thavaseelan@external.grtgaz.com" },
    { email: "adrien.jacques@grtgaz.com" },
    { email: "brian.nicolasnelson@external.grtgaz.com" },
    { email: "nora.jout@grtgaz.com" },
    { email: "paul.godouet@grtgaz.com" },
    { email: "serge.vescovi@grtgaz.com" },
    { email: "antoine.raynaud@accenture.com" },
    { email: "julienne.banmene@external.grtgaz.com" },
    { email: "typhaine.lemenn@grtgaz.com" },
    { email: "gael.soeur@grtgaz.com" },
    { email: "massil.taguemout@grtgaz.com" },
    { email: "david.lecarpentier@grtgaz.com" },
    { email: "ouwais.chaoui@external.grtgaz.com" },
    { email: "jeremy.trochu@external.grtgaz.com" },
    { email: "arnaud.gay@external.grtgaz.com" },
    { email: "jeremy-christian.louvet@grtgaz.com" }
  ]
};

export default {
  ...csvExporter
};
