/* eslint-disable */
import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { API } from "aws-amplify";
import history from "../../history";
import green from "@material-ui/core/colors/green";
import IconButton from '@material-ui/core/IconButton';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import Typography from "@material-ui/core/Typography";
import { CircularLoadingButton, styles } from "../../Styles/ComponentStyles";

const ButtonTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

class AppCardListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_list: props.account_list,
      app: props.app,
      //environment: props.environment,
      account_id: props.account_id,
      classes: props.classes,
      redirect: false,
      app_clickable: props.app_clickable,
      applicationState: undefined
    };
  }

  render() {
    const { app, environment } = this.state;

    return (
      <>
        {/*
          <IconButton
            size="small"
            aria-label="Delete"
            style={{
              marginBottom: "5px",
            }}
            onClick={() => {
              history.push({
                pathname: "/rapports",
                search: "?app=" + app
              });
            }}
          >
            <CloudQueueIcon
              style={{
              fontSize: "30px",
              color: "#35a682"
            }}
            />
          </IconButton>
        */}
      </>
    );
  }
}

export default withStyles(styles)(AppCardListReport);












