/* eslint-disable */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CircularLoading } from "../../Styles/ComponentStyles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Button } from "@material-ui/core";
import Assignment from "@material-ui/icons/Assignment";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddReportModelDialog from "../Dialogs/AddReportModelDialog";

import IDCARD from "../../Styles/Icons/idcard.png";
import USER from "../../Styles/Icons/user.png";
import BELL from "../../Styles/Icons/bell.png";
import MEGAPHONE from "../../Styles/Icons/megaphone.png";
import HEURED from "../../Styles/Icons/clockD.png";
import HEUREF from "../../Styles/Icons/clockF.png";
import ASTREINTE from "../../Styles/Icons/oncall.png";

import { styles } from "../../Styles/ComponentStyles";
import { CustomTableCell } from "../../Styles/ComponentStyles";

import Config from "../../config.js";
import PropTypes from "prop-types";

// The order and what we decide to show to the user
const header_names = [
  "AccountID",
  "UserID",
  "AlarmName",
  "Channel",
  "TimeBegin",
  "TimeEnd",
  "OnCall"
];

class ReportDashboardModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      account_list: props.account_list,
      app: props.app,
      flow: props.flow,
      report_count: 3,
      reports: {
        "creator": ["lulu"],
        "name": ["mon rapport"],
        "creationDate": ["2023-01-28"],
        "nextDate": ["2023-01-28"],
        "freq": ["weekly"],
        "stepper": ["dev,stg,prd"],
        "theme": ["builder"]
      }
    };
  }

  renderModelDialog(){
    return (
      <AddReportModelDialog />
    );
  }

  renderEnvReportModel(){
    if (this.state.app !== "undefined") {
      return (
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
          {this.state.app + " " + ":" + " " + "flux de modele"}
        </TableCell>
      );
    } else {
      return(
        <TableCell>
          {"no" + "-" + "env"}
        </TableCell>
      );  
    }
  }

  renderCellTableHeader(){
    return(
      <TableRow
        style={{
          border: "none",
          height: "10px"
        }}
      >
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
        {" "}
        </TableCell>
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
        {"nom du modele"}
        </TableCell>
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
        {"theme"}
        </TableCell>
        {this.renderEnvReportModel()}
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
        {"éditeur"}
        </TableCell>
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
        {"créé le"}
        </TableCell>
        <TableCell
          style={{
            border: "none",
            "border-radius": "0 0 0 0",
            background: "#dff2ed",
            color: "#139aa1",
            "font-weight": "bold",
            "font-size": "14px",
            maxWidth: "60px",
            textTransform: "lowercase",
            textAlign: "left",
            height: "40px"
          }}
        >
        {" "}
        </TableCell>
      </TableRow>
    );
  }

  renderCellCreatorReport(){
    return(
      this.state.reports.creator.map( isCreator => {
        return(
            <TableCell>{isCreator}</TableCell>
        )})
    );
  }

  renderCellNameReport(){
    return(
      this.state.reports.name.map( isName => {
        return(
          <>
            <Assignment
              style={{
                marginTop: "10px"
              }}
            />
            <TableCell>{isName}</TableCell>
          </>
        )})
    );
  }

  renderCellThemeReport(){
    return(
      this.state.reports.theme.map( isTheme => {
        return(
            <TableCell>{isTheme}</TableCell>
        )})
    );
  }

  renderCellDeleteReport(){
    return(
      this.state.reports.name.map( isName => {
        return(
          <TableCell>
            <DeleteOutlined
              style={{
                marginTop: "10px"
              }}
            />
          </TableCell>
        )})
    );
  }


  renderCellCreatedReport(){
    return(
      this.state.reports.creationDate.map( isCreated => {
        return(
            <TableCell>{isCreated}</TableCell>
        )})
    );
  }


  renderCellFlowReport(){
    return(
            <TableCell>{this.state.flow}</TableCell>
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.report_count >= 0) {
      return (
        <div style={{ overflowX: "hidden" }}>
          <Paper
            className={classes.paperChannelList}
            style={{
              background: "#f7faf9",
              "margin-left": "auto",
              "margin-right": "auto",
              "margin-bottom": "40px",
              overflowX: "hidden",
              maxWidth: "1300px",
              "box-shadow": "none"
            }}
          >
            <Table>
              <TableHead>
                {this.renderCellTableHeader()}            
              </TableHead>
              <TableBody>
                <TableRow>
                  {this.renderCellNameReport()}
                  {this.renderCellThemeReport()}
                  {this.renderCellFlowReport()}
                  {this.renderCellCreatorReport()}
                  {this.renderCellCreatedReport()}
                  {this.renderCellDeleteReport()}
                </TableRow>
                <TableRow>
                  {this.renderCellNameReport()}
                  {this.renderCellThemeReport()}
                  {this.renderCellFlowReport()}
                  {this.renderCellCreatorReport()}
                  {this.renderCellCreatedReport()}
                  {this.renderCellDeleteReport()}
                </TableRow>
                <TableRow>
                  {this.renderCellNameReport()}
                  {this.renderCellThemeReport()}
                  {this.renderCellFlowReport()}
                  {this.renderCellCreatorReport()}
                  {this.renderCellCreatedReport()}
                  {this.renderCellDeleteReport()}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          {this.renderModelDialog()}
        </div>
      );
    } else {
      return <CircularLoading></CircularLoading>;
    }
  }
}

ReportDashboardModel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReportDashboardModel);