import React, { useEffect, useState } from "react";
import { retrieveAdmins } from "../../Services/AdminService";
//import { CircularLoading } from "../../Styles/ComponentStyles";
import GRT from "../../Styles/Icons/GRTtrans.png";
import UsersManagementDashboard from "./UsersManagementDashboard";
import Card from "@material-ui/core/Card";

const UsersManagementGeneral = ({ userName }) => {
  const [isUserAdmin, setIsUserAdmin] = useState(undefined);

  useEffect(() => {
    const retrieveUserAdmins = async () => {
      const userAdmins = await retrieveAdmins("user");
      setIsUserAdmin(userAdmins.indexOf(userName) > -1);
    };

    retrieveUserAdmins();
  }, [userName]);
  return isUserAdmin !== undefined ? (
    isUserAdmin ? (
      <div style={{ background: "#f7faf9" }}>
        <div
          style={{
            fontFamily: "sans-serif",
            fontSize: "x-large",
            marginTop: "1em",
            color: "#139aa1"
          }}
        >
          <b>GESTION DES UTILISATEURS</b>
        </div>
        <Card
          style={{
            padding: "1em",
            margin: "3em",
            marginLeft: "10em",
            marginRight: "10em",
            border: "none",
            boxShadow: "none",
            borderRadius: "30px",
            backgroundColor: "#ffffff"
          }}
        >
          <UsersManagementDashboard />
        </Card>
        <svg viewBox="0 0 800 800">
          <path
            class="path"
            d="M2,295 C363,348 395,4 800,47"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            stroke-dashoffset="1000"
            id="motion1"
          />
          <path
            d="M0,204 C377,367 504,22 797,225"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            id="motion2"
          />
          <circle
            class="circle"
            cx="284.3733028649552"
            cy="241.81299508566542"
            r="5"
            stroke="#f7faf9"
            fill="#f7faf9"
            stroke-width="0"
          />
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="40s" repeatCount="indefinite">
              <mpath xlinkHref="#motion1" />
            </animateMotion>
          </circle>
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="30s" repeatCount="indefinite">
              <mpath xlinkHref="#motion2" />
            </animateMotion>
          </circle>
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="245.5"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="288"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="238"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="281"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
        </svg>
        <img
          src={GRT}
          alt={"APP-icon"}
          style={{
            height: "50px",
            marginTop: "50px",
            marginBottom: "50px",
            verticalAlign: "middle",
            animation: "fadeIn 4s"
          }}
        />
      </div>
    ) : (
      <div>
        {" "}
        Vous n'avez pas le droit d'accéder à cette page. Contacter un
        administrateur
        <svg viewBox="0 0 800 800">
          <path
            class="path"
            d="M2,295 C363,348 395,4 800,47"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            stroke-dashoffset="1000"
            id="motion1"
          />
          <path
            d="M0,204 C377,367 504,22 797,225"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            id="motion2"
          />
          <circle
            class="circle"
            cx="284.3733028649552"
            cy="241.81299508566542"
            r="5"
            stroke="#f7faf9"
            fill="#f7faf9"
            stroke-width="0"
          />
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="40s" repeatCount="indefinite">
              <mpath xlinkHref="#motion1" />
            </animateMotion>
          </circle>
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="30s" repeatCount="indefinite">
              <mpath xlinkHref="#motion2" />
            </animateMotion>
          </circle>
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="245.5"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="288"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="238"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="281"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
        </svg>
      </div>
    )
  ) : (
    <svg viewBox="0 0 800 800">
      <path
        class="path"
        d="M2,295 C363,348 395,4 800,47"
        fill="none"
        stroke="#0ec289"
        stroke-width="0.5"
        stroke-dashoffset="1000"
        id="motion1"
      />
      <path
        d="M0,204 C377,367 504,22 797,225"
        fill="none"
        stroke="#0ec289"
        stroke-width="0.5"
        id="motion2"
      />
      <circle
        class="circle"
        cx="284.3733028649552"
        cy="241.81299508566542"
        r="5"
        stroke="#f7faf9"
        fill="#f7faf9"
        stroke-width="0"
      />
      <circle
        class="circle"
        cx="0"
        cy="0"
        r="1"
        stroke="#f7faf9"
        fill="#0ec289"
        stroke-width="0"
      >
        <animateMotion dur="40s" repeatCount="indefinite">
          <mpath xlinkHref="#motion1" />
        </animateMotion>
      </circle>
      <circle
        class="circle"
        cx="0"
        cy="0"
        r="1"
        stroke="#f7faf9"
        fill="#0ec289"
        stroke-width="0"
      >
        <animateMotion dur="30s" repeatCount="indefinite">
          <mpath xlinkHref="#motion2" />
        </animateMotion>
      </circle>
      <line
        x1="284.3733028649552"
        x2="284.3733028649552"
        y1="241.81299508566542"
        y2="245.5"
        stroke="#0ec289"
        stroke-width="1.5"
      />
      <line
        x1="284.3733028649552"
        x2="288"
        y1="241.81299508566542"
        y2="241.81299508566542"
        stroke="#0ec289"
        stroke-width="1.5"
      />
      <line
        x1="284.3733028649552"
        x2="284.3733028649552"
        y1="241.81299508566542"
        y2="238"
        stroke="#0ec289"
        stroke-width="1.5"
      />
      <line
        x1="284.3733028649552"
        x2="281"
        y1="241.81299508566542"
        y2="241.81299508566542"
        stroke="#0ec289"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default UsersManagementGeneral;
