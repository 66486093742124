import React from "react";
import { realizeAction } from "../../Helpers/realize_action";
// import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class AlarmSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_id: props.account_id,
      userIdToken: props.userIdToken,
      alarms: undefined,
      listAlarmInProgress: false
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.listAlarms(this.props.account_id);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getAlarmsListCallback(response) {
    this.setState({
      alarms: response["MetricAlarms"],
      listAlarmInProgress: false,
      seconds: 0
    });
    this.props.onAlarmsFetched(response["MetricAlarms"]);
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          null,
          null,
          null,
          null,
          null,
          "eu-west-1",
          {
            MaxRecords: 100
          }
        );
      }
    );
  }

  handleAlarmsFetched(alarms) {
    this.setState({ alarms });
  }

  render() {
    const { handleAlarmChange, selectedAlarm, alarms, onChanges } = this.props;
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="alarm-selector">
          Sélectionner une alarme
        </InputLabel>
        <Select
          value={selectedAlarm}
          onChange={event => {
            if (handleAlarmChange) {
              handleAlarmChange(event);
            }
            if (onChanges) {
              onChanges(event);
            }
          }}
          label="Sélectionner une alarme"
          inputProps={{
            name: "alarm",
            id: "alarm-selector"
          }}
        >
          {alarms &&
            alarms.map(alarm => (
              <MenuItem key={alarm.AlarmName} value={alarm.AlarmName}>
                {alarm.AlarmName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

export default AlarmSelector;
