import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ComponentStyles";
import APPS from "../../Styles/Icons/application.png";
import FACT from "../../Styles/Icons/facture.png";
import ACTIONS from "../../Styles/Icons/taches.png";
import PERI from "../../Styles/Icons/perimetre.png";
import ASTR from "../../Styles/Icons/settings.png";
import UTIL from "../../Styles/Icons/usergestion.png";
import HUNT from "../../Styles/Icons/hunts.png";
import moment from "moment";
import "moment/locale/fr";

//Extra navigation lib
import history from "../../history";
import { retrieveAdmins } from "../../Services/AdminService";

//Set the fr language for moment
moment.locale("fr");

class SimpleMenu extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = {
      classes: classes,
      user_admins: [],
      perimeters_admins: []
    };
    this.setUserAdminList();
    this.setPerimetersAdminList();
  }

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentWillReceiveProps(props) {
    this.setState({
      user_name: this.props.user_name
    });
  }

  setUserAdminList() {
    retrieveAdmins("user").then(response => {
      this.setState({
        user_admins: response
      });
    });
  }

  setPerimetersAdminList() {
    retrieveAdmins("perimeters").then(response => {
      this.setState({
        perimeters_admins: response
      });
    });
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <MenuIcon
          className={this.state.classes.simpleButton}
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ color: "#08bfb0" }}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={this.state.classes.menuPanel}
          style={{ background: "none !important" }}
          PaperProps={{
            style: {
              borderRadius: 30,
              fontFamily: "sans-serif",
              fontSize: 12,
              fontWeight: 600,
              color: "#08bfb0"
            }
          }}
        >
          <div>
            <MenuItem
              className={this.state.classes.menuItem}
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/mes-applications"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              <img
                src={APPS}
                alt={"APP-icon"}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                  paddingLeft: "4px",
                  verticalAlign: "-50%",
                  color: "#08bfb0"
                }}
              />
              Mes applications
            </MenuItem>

            <MenuItem
              className={this.state.classes.menuItem}
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/logs"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              <img
                src={ACTIONS}
                alt={"ACTIONS-icon"}
                style={{
                  height: "25px",
                  width: "25px",
                  "margin-right": "10px",
                  "vertical-align": "-50%",
                  color: "#08bfb0"
                }}
              />
              Consignation des actions réalisées
            </MenuItem>
            {/*
            <MenuItem
              className={this.state.classes.menuItem}
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/facturation"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              <img
                src={FACT}
                alt={"FACTURATION-icon"}
                style={{
                  height: "30px",
                  width: "30px",
                  "margin-right": "10px",
                  "padding-left": "0px",
                  "vertical-align": "-50%",
                  color: "#08bfb0"
                }}
              />
              Facturation
            </MenuItem>
            */}
            {this.state.perimeters_admins.indexOf(this.state.user_name) > -1 ? (
              <MenuItem
                className={this.state.classes.menuItem}
                onClick={() => {
                  this.handleClose();
                  history.push({
                    pathname: "/gestion-perimetres"
                  });
                  this.props.handleApplicationChange("", this.props);
                }}
              >
                <img
                  src={PERI}
                  alt={"PERIMETRES-icon"}
                  style={{
                    height: "30px",
                    width: "30px",
                    "margin-right": "10px",
                    "padding-left": "0px",
                    "vertical-align": "-50%",
                    color: "#08bfb0"
                  }}
                />
                Gestion des perimetres
              </MenuItem>
            ) : (
              false
            )}

            <MenuItem
              className={this.state.classes.menuItem}
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/astreintes"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              <img
                src={ASTR}
                alt={"ASTREINTES-icon"}
                style={{
                  height: "28px",
                  width: "28px",
                  "margin-right": "10px",
                  "padding-left": "0px",
                  "vertical-align": "-50%",
                  color: "#08bfb0"
                }}
              />
              Gestion des astreintes
            </MenuItem>

            {this.state.user_admins.indexOf(this.state.user_name) > -1 ? (
              <MenuItem
                className={this.state.classes.menuItem}
                onClick={() => {
                  this.handleClose();
                  history.push({
                    pathname: "/gestion-utilisateurs"
                  });
                  this.props.handleApplicationChange("", this.props);
                }}
              >
                <img
                  src={UTIL}
                  alt={"UTILISTAEURS-icon"}
                  style={{
                    height: "28px",
                    width: "28px",
                    "margin-right": "10px",
                    "padding-left": "0px",
                    "vertical-align": "-50%",
                    color: "#08bfb0"
                  }}
                />
                Gestion des utilisateurs
              </MenuItem>
            ) : (
              false
            )}

            {this.state.user_admins.indexOf(this.state.user_name) > -1 ? (
              <MenuItem
                className={this.state.classes.menuItem}
                onClick={() => {
                  this.handleClose();
                  history.push({
                    pathname: "/gestion-hunt-group"
                  });
                  this.props.handleApplicationChange("", this.props);
                }}
              >
                <img
                  src={HUNT}
                  alt={"HUNTGROUPS-icon"}
                  style={{
                    height: "28px",
                    width: "28px",
                    "margin-right": "10px",
                    "padding-left": "0px",
                    "vertical-align": "-50%",
                    color: "#08bfb0"
                  }}
                />
                Gestion des hunt groups
              </MenuItem>
            ) : (
              false
            )}
          </div>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleMenu);
