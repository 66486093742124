import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { withSnackbar } from "notistack";
import moment from "moment";
import Moment from "moment";
import PropTypes from "prop-types";

import AlarmChannelsTable from "../Tables/AlarmChannelsTable";
import AlarmHistoryTable from "../Tables/AlarmHistoryTable";
import AddChannelDialog from "../Dialogs/AddChannelDialog";
import Circle from "../Geometric/Circle";
import Tooltip from "@material-ui/core/Tooltip";
import SURVE from "../../Styles/Icons/binoculars.png";
import MALWARE from "../../Styles/Icons/malware.png";
import PADLOCK from "../../Styles/Icons/padlock.png";
import REFER from "../../Styles/Icons/refer.png";

import {
  circleColor,
  CircularLoading,
  styles
} from "../../Styles/ComponentStyles";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import {
  deleteChannel,
  retrieveChannelsWithParameters
} from "../../Services/ChannelsService";
import { addLog } from "../../Services/LogsService";
import {
  getAccountAlarmLogs,
  getAccountState
} from "../../Services/AccountService";

//Set the fr language for moment
moment.locale("fr");

class InspectorDashboardTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      app: undefined,
      env: undefined,
      compliances: undefined,
      alarms: undefined,
      alarmsWithHistory: undefined,
      listAlarmInProgress: false,
      loading: false,
      surveillance: undefined,
      getActualSurveillanceInProgress: false,
      modalSurveillanceOpen: false,
      modalAddChannelOpen: false,
      modalHistoryOpen: false,
      current_alarm: undefined,
      form_account_id_error: false,
      seconds: 0,
      applicationState: undefined,
      listComplianceInProgress: false,
      nonCompliantCount: 0,
      resourceId: [],
      resourceIdExecution: [],
      findingsId: [],
      findingsIdExecution: [],
      findings: [],
      findingsKey: [],
      severityFindings: undefined
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.handleSurveillanceClose = this.handleSurveillanceClose.bind(this);
    this.delete_channel_callback = this.delete_channel_callback.bind(this);
    this.handleHistoryClose = this.handleHistoryClose.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.getActualSurveillance = this.getActualSurveillance.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
    this.listResourceCompliance = this.listResourceCompliance.bind(this);
    this.listFindingsCompliance = this.listFindingsCompliance.bind(this);
    this.getFindingsCompliance = this.getFindingsCompliance.bind(this);
    this.setSeverity = this.setSeverity.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getModalStyle() {
    return {
      margin: "auto"
    };
  }

  sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });

    if (nextProps.account_id !== undefined) {
      if (
        this.state.alarms === undefined ||
        this.state.account_id !== nextProps.account_id
      ) {
        this.setState({
          loading: true,
          listComplianceInProgress: true
        });
        getAccountState(this.state.account_id).then(result => {
          this.setState({
            applicationState: result
          });
        });
        this.listAlarms(nextProps.account_id);
        this.getActualSurveillance(nextProps.account_id);
        this.listResourceCompliance(nextProps.account_id);
        this.listFindingsCompliance(nextProps.account_id);
        this.getFindingsCompliance(nextProps.account_id);
      }
    }
  }

  listResourceCompliance(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "ssm",
        boto_method: "list_resource_compliance_summaries",
        account_id: account_id
      }
    }).then(response => {
      let resourceId = [];
      for (
        let i = 0;
        i < response["ResourceComplianceSummaryItems"].length;
        i++
      ) {
        if (
          response["ResourceComplianceSummaryItems"][i].ComplianceType ===
            "Patch" &&
          response["ResourceComplianceSummaryItems"][i].Status ===
            "NON_COMPLIANT"
        ) {
          resourceId.push(response["ResourceComplianceSummaryItems"][i]);
        }
      }
      this.setState({
        resourceId: [...resourceId],
        listComplianceInProgress: false
      });
    });
  }

  listFindingsCompliance(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "inspector",
        boto_method: "list_findings",
        account_id: account_id
      },
      body: {
        filter: {
          agentIds: [...this.state.resourceId]
        }
      }
    }).then(response => {
      let findingsId = [];
      for (let i = 0; i < response["findingArns"].length; i++) {
        findingsId.push(response["findingArns"][i]);
      }
      this.setState({
        findingsId: [...findingsId],
        listComplianceInProgress: false
      });
    });
  }

  getFindingsCompliance(account_id) {
    if (this.state.listAlarmsInProgress) {
      return;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: "inspector",
        boto_method: "describe_findings",
        account_id: account_id
      },
      body: {
        findingArns: [...this.state.findingsId]
      }
    }).then(response => {
      let findingsIdx = 0;
      let findings = [];
      for (let i = 0; i < response["findings"].length; i++) {
        findingsIdx++;
        findings.push(response["findings"][i]);
      }
      this.setState({
        findings: [...findings, ("findingsIdx": findingsIdx)],
        listComplianceInProgress: false
      });
    });
  }

  setSeverity(severity) {
    this.setState({
      severityFindings: severity
    });
  }

  getAlarmsListCallback(response) {
    debugger;
    this.setState({
      alarms: response["MetricAlarms"],
      listAlarmInProgress: false,
      seconds: 0
    });
    this.getHistory(this.state.account_id);
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          null,
          null,
          null,
          null,
          null,
          "eu-west-1",
          {
            MaxRecords: 100
          }
        );
      }
    );
  }

  isAlarmSetUp = (alarm_action, ok_action) =>
    [
      "arn:aws:sns:eu-west-1:182726513909:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:815704857845:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:726054927466:snt-exsi-notifications"
    ]
      .map(
        sns_arn => alarm_action.includes(sns_arn) && ok_action.includes(sns_arn)
      )
      .find(value => !value) === undefined;

  renderCircle(status, alarm_action, ok_action, classes) {
    if (!this.isAlarmSetUp(alarm_action, ok_action)) {
      return (
        <Grid container spacing={8} className={classes.gridContainer}>
          <Grid item xs={3}>
            <Circle bgColor={circleColor.yellow} circleSize={20} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                "font-family": "sans-serif",
                "font-size": "14px",
                "font-weight": "bold",
                color: "#3c577a"
              }}
            >
              aucune configuration
            </Typography>
          </Grid>
        </Grid>
      );
    }

    switch (status) {
      case "ALARM":
        return <Circle bgColor={circleColor.red} circleSize={20} />;

      case "OK":
        return <Circle bgColor={circleColor.green} circleSize={20} />;

      default:
        return <Circle bgColor={circleColor.gray} circleSize={20} />;
    }
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
  }

  handleSurveillanceClickOpen(alarm_name) {
    this.setState({
      modalSurveillanceOpen: true,
      current_alarm: alarm_name
    });
  }

  handleSurveillanceClose() {
    this.setState({
      modalSurveillanceOpen: false
    });
  }

  handleHistoryClickOpen(alarm_name, history) {
    this.setState({
      modalHistoryOpen: true,
      current_alarm: alarm_name,
      current_history: history
    });
  }

  handleHistoryClose() {
    this.setState({
      modalHistoryOpen: false,
      current_history: undefined
    });
  }

  getActualSurveillance(accountId) {
    if (this.state.getActualSurveillanceInProgress || !accountId) {
      return;
    }
    this.setState(
      {
        alarms: undefined,
        getActualSurveillanceInProgress: true
      },
      () => {
        retrieveChannelsWithParameters({ accountId }).then(response => {
          this.setState({
            surveillance: response,
            getActualSurveillanceInProgress: false
          });
        });
      }
    );
  }

  getHistory(account_id) {
    if (this.state.getHistoryInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        getHistoryInProgress: true
      },
      () => {
        getAccountAlarmLogs(account_id).then(response => {
          const alarmsWithHistory = this.state.alarms
            .map(alarm => ({
              alarm,
              history: response
                .filter(
                  item =>
                    item["AlarmName"] && item["AlarmName"] === alarm.AlarmName
                )
                .sort((a, b) => {
                  const hasAGoodTimeStamp = a["ts"] && a["ts"];
                  const hasBGoodTimeStamp = b["ts"] && b["ts"];
                  if (!hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return 0;
                  }

                  if (hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return -1;
                  }

                  if (!hasAGoodTimeStamp && hasBGoodTimeStamp) {
                    return 1;
                  }
                  return a["ts"] > b["ts"] ? -1 : 1;
                })
            }))
            .sort((a, b) => {
              const hasAHistory = a.history.length > 0;
              const hasBHistory = b.history.length > 0;

              if (!hasAHistory && !hasBHistory) {
                return 0;
              }

              if (hasAHistory && !hasBHistory) {
                return -1;
              }

              if (!hasAHistory && hasBHistory) {
                return 1;
              }

              return a.history[0]["ts"] > b.history[0]["ts"] ? -1 : 1;
            });
          this.setState({
            alarmsWithHistory: alarmsWithHistory,
            getHistoryInProgress: false,
            loading: false
          });
        });
      }
    );
  }

  delete_channel_callback(account_id, alarm_name, uuid) {
    deleteChannel(account_id, uuid).then(() => {
      const action_description =
        "Suppression d'un channel sur le compte : " +
        account_id +
        " avec le nom " +
        alarm_name +
        " et UUID:" +
        uuid;
      const current_time = moment().unix();
      addLog(this.props.user_name, current_time, action_description);
      this.listAlarms(this.state.account_id);
      this.getActualSurveillance(this.state.account_id);
      this.handleSurveillanceClose();
      this.props.enqueueSnackbar(action_description, {
        variant: "error",
        autoHideDuration: 5000
      });
    });
  }

  renderSurveillance(alarm_name) {
    let channel_counter = 0;
    if (this.state.surveillance) {
      if ("Items" in this.state.surveillance) {
        for (let i = 0; i < this.state.surveillance["Items"].length; i++) {
          let regex = this.state.surveillance.Items[i]["AlarmName"].replace(
            "*",
            ".*"
          );
          if (alarm_name.match(regex)) {
            channel_counter++;
          }
        }
      }
    }
    if (channel_counter === 0) {
      return "Aucun canal";
    } else if (channel_counter === 1) {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canal
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
          style={{
            "font-family": "sans-serif",
            "font-size": "14px",
            "font-weight": "bold",
            "border-radius": "30px",
            "padding-top": "0px",
            "padding-bottom": "0px",
            textTransform: "lowercase",
            background: "#edfaf7",
            color: "#3c577a"
          }}
        >
          {channel_counter} canaux
        </Button>
      );
    }
  }

  renderHistory(alarm_name, history) {
    return history.length > 0 ? (
      <Button
        onClick={() => this.handleHistoryClickOpen(alarm_name, history)}
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          "border-radius": "30px",
          "padding-top": "0px",
          "padding-bottom": "0px",
          textTransform: "lowercase",
          background: "#edfaf7",
          color: "#3c577a"
        }}
      >
        {Moment(new Date(history[0].ts * 1000)).format(
          "ddd Do MMMM YYYY, HH:mm:ss"
        )}{" "}
        ({history.length} évenements)
      </Button>
    ) : (
      <Button
        disabled
        color="primary"
        key={alarm_name}
        style={{
          "font-family": "sans-serif",
          "font-size": "14px",
          "font-weight": "bold",
          textTransform: "lowercase",
          "background-color": "white",
          color: "#3c577a"
        }}
      >
        Aucun évenement
      </Button>
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account_id}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.applicationState) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else if (this.state.applicationState === "down") {
      return (
        <div>
          <Circle bgColor={circleColor.black} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement a été éteint, vous pouvez le rallumer avec l'action
            laf-env-controller-start.
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.alarms &&
      this.state.alarms.length > 0 &&
      this.state.alarmsWithHistory
    ) {
      return (
        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalSurveillanceOpen}
            onClose={this.handleSurveillanceClose}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Slide
              direction="up"
              in={this.state.modalSurveillanceOpen}
              mountOnEnter
              unmountOnExit
            >
              <div
                style={this.getModalStyle()}
                className={classes.paperModalSurveillance}
              >
                <Typography variant="h6" id="modal-title">
                  Les canaux de l'alarme
                </Typography>
                <AlarmChannelsTable
                  surveillance={this.state.surveillance}
                  alarm_name={this.state.current_alarm}
                  delete_channel_callback={this.delete_channel_callback}
                  match_alarm={true}
                ></AlarmChannelsTable>
              </div>
            </Slide>
          </Modal>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalHistoryOpen}
            onClose={this.handleHistoryClose}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Slide
              direction="up"
              in={this.state.modalHistoryOpen}
              mountOnEnter
              unmountOnExit
            >
              <div
                style={this.getModalStyle()}
                className={classes.paperModalSurveillance}
              >
                <Typography
                  variant="h6"
                  id="modal-title"
                  style={{
                    "text-transform": "uppercase",
                    "font-size": "16px",
                    "font-weight": "bold",
                    "text-align": "center",
                    color: "#009999"
                  }}
                >
                  Historique des changements d'état
                </Typography>
                <AlarmHistoryTable
                  history={this.state.current_history}
                  alarm_name={this.state.current_alarm}
                ></AlarmHistoryTable>
              </div>
            </Slide>
          </Modal>

          {this.renderAddChannelDialog()}

          <Paper
            className={classes.root}
            style={{
              background: "#f7faf9",
              "margin-left": "20px",
              "margin-right": "20px",
              "margin-bottom": "20px",
              "box-shadow": "none"
            }}
          >
            <Button
              size="small"
              color="primary"
              style={{
                "font-family": "sans-serif",
                "font-size": "12px",
                "font-weight": "600",
                background: "none",
                color: "#415e8a",
                "box-shadow": "none",
                "border-radius": "30px",
                border: "2px solid #03fcb1"
              }}
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                    this.getActualSurveillance(this.state.account_id);
                    this.listResourceCompliance(this.state.account_id);
                    this.listFindingsCompliance(this.state.account_id);
                    this.getFindingsCompliance(this.state.account_id);
                    this.setSeverity("High");
                  }
                );
              }}
            >
              <img
                src={MALWARE}
                alt={"Engine-icon"}
                style={{
                  height: "20px",
                  width: "20px",
                  "margin-right": "0px",
                  "padding-left": "0px",
                  "vertical-align": "-25%"
                }}
              />
              <span
                style={{
                  "padding-left": "10px"
                }}
              >
                Search HIGH Vulnerabilities
              </span>
            </Button>
            <span
              style={{
                "margin-right": "30px"
              }}
            ></span>
            <Button
              size="small"
              color="primary"
              style={{
                "font-family": "sans-serif",
                "font-size": "12px",
                "font-weight": "600",
                background: "none",
                color: "#415e8a",
                "box-shadow": "none",
                "border-radius": "30px",
                border: "2px solid #03fcb1"
              }}
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                    this.getActualSurveillance(this.state.account_id);
                    this.listResourceCompliance(this.state.account_id);
                    this.listFindingsCompliance(this.state.account_id);
                    this.getFindingsCompliance(this.state.account_id);
                    this.setSeverity("Medium");
                  }
                );
              }}
            >
              <img
                src={MALWARE}
                alt={"Engine-icon"}
                style={{
                  height: "20px",
                  width: "20px",
                  "margin-right": "0px",
                  "padding-left": "0px",
                  "vertical-align": "-25%"
                }}
              />
              <span
                style={{
                  "padding-left": "10px"
                }}
              >
                Search MEDIUM Vulnerabilities
              </span>
            </Button>
            <Paper className={classes.paperAlarmList}>
              <Table
                className={classes.table}
                style={{
                  "font-family": "sans-serif",
                  "font-size": "12px",
                  "font-weight": "600",
                  color: "#009999",
                  "box-shadow": "none",
                  border: "none"
                }}
              >
                <TableHead>
                  <TableRow
                    style={{
                      border: "none",
                      height: "10px"
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                        "border-radius": "30px 0 0 0",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                        "padding-top": "0px",
                        "padding-bottom": "0px",
                        "margin-right": "10px",
                        "margin-left": "10px",
                        background: "#dff2ed",
                        color: "#48596e",
                        "font-weight": "bold",
                        "font-size": "14px",
                        textTransform: "lowercase",
                        textAlign: "center",
                        minWidth: "145px"
                      }}
                    >
                      <img
                        src={REFER}
                        alt={"REFER-agent"}
                        style={{
                          height: "20px",
                          width: "20px",
                          "margin-right": "0px",
                          "padding-left": "0px",
                          "vertical-align": "-45%"
                        }}
                      />
                      <span style={{ color: "#139aa1" }}> agent </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        "border-radius": "0px",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                        "padding-top": "0px",
                        "padding-bottom": "0px",
                        "margin-right": "0px",
                        "margin-left": "0px",
                        background: "#dff2ed",
                        color: "#48596e",
                        "font-weight": "bold",
                        "font-size": "14px",
                        textTransform: "lowercase",
                        textAlign: "center"
                      }}
                    >
                      <img
                        src={SURVE}
                        alt={"SURVEILLANCE-alarme"}
                        style={{
                          height: "25px",
                          width: "25px",
                          "margin-right": "0px",
                          "padding-left": "0px",
                          "vertical-align": "-45%"
                        }}
                      />
                      <span style={{ color: "#139aa1" }}>
                        {" "}
                        Similutudes detection{" "}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        "border-radius": "0px",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                        "padding-top": "0px",
                        "padding-bottom": "0px",
                        "margin-right": "0px",
                        "margin-left": "0px",
                        background: "#dff2ed",
                        color: "#48596e",
                        "font-weight": "bold",
                        "font-size": "14px",
                        textTransform: "lowercase",
                        textAlign: "center"
                      }}
                    >
                      <span style={{ color: "#139aa1" }}> vuln. refs </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        "border-radius": "0 0 0 0",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                        "padding-top": "0px",
                        "padding-bottom": "0px",
                        "margin-right": "10px",
                        "margin-left": "10px",
                        background: "#dff2ed",
                        color: "#48596e",
                        "font-weight": "bold",
                        "font-size": "14px",
                        textTransform: "lowercase",
                        textAlign: "center",
                        minWidth: "145px"
                      }}
                    >
                      <span style={{ color: "#139aa1" }}> Last Update </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        "border-radius": "0 0 0 0",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                        "padding-top": "0px",
                        "padding-bottom": "0px",
                        "margin-right": "10px",
                        "margin-left": "10px",
                        background: "#dff2ed",
                        color: "#48596e",
                        "font-weight": "bold",
                        "font-size": "14px",
                        textTransform: "lowercase",
                        textAlign: "center",
                        minWidth: "145px"
                      }}
                    >
                      <img
                        src={PADLOCK}
                        alt={"PATCH-recommendation"}
                        style={{
                          height: "22px",
                          width: "22px",
                          "margin-right": "10px",
                          "padding-left": "0px",
                          "vertical-align": "-45%"
                        }}
                      />
                      <span style={{ color: "#139aa1" }}>
                        Patch recommendation
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {this.state.severityFindings === "High" && (
                  <TableBody>
                    {this.state.findings
                      .sort((a, b) => (a.arn < b.arn ? 1 : -1)) // eslint-disable-line
                      .filter((a) => a.severity === "High") // eslint-disable-line
                      .map(findingsRow => (
                        <TableRow key={findingsRow.findingsIdx}>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            {findingsRow.assetAttributes.agentId}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Typography
                              style={{
                                "font-family": "sans-serif",
                                "font-size": "14px",
                                "font-weight": "bold",
                                "border-radius": "30px",
                                "padding-top": "2px",
                                "padding-bottom": "2px",
                                background: "white",
                                color: "#3c577a"
                              }}
                            >
                              {findingsRow.description}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Tooltip
                              title={"Vulnérabilité de référence"}
                              classes={{ tooltip: classes.lightTooltip }}
                            >
                              <Typography
                                style={{
                                  "font-family": "sans-serif",
                                  "font-size": "14px",
                                  "font-weight": "bold",
                                  "border-radius": "30px",
                                  "padding-top": "2px",
                                  "padding-bottom": "2px",
                                  background: "white",
                                  color: "#3c577a"
                                }}
                              >
                                {findingsRow.title.split(" ").pop()}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Typography
                              style={{
                                "font-family": "sans-serif",
                                "font-size": "14px",
                                "font-weight": "bold",
                                "border-radius": "30px",
                                "padding-top": "2px",
                                "padding-bottom": "2px",
                                background: "white",
                                color: "#3c577a"
                              }}
                            >
                              {findingsRow.updatedAt}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Typography
                              style={{
                                "font-family": "sans-serif",
                                "font-size": "14px",
                                "font-weight": "bold",
                                "border-radius": "30px",
                                "padding-top": "2px",
                                "padding-bottom": "2px",
                                background: "white",
                                color: "#3c577a"
                              }}
                            >
                              {findingsRow.recommendation}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
                {this.state.severityFindings === "Medium" && (
                  <TableBody>
                    {this.state.findings
                      .sort((a, b) => (a.arn < b.arn ? 1 : -1)) // eslint-disable-line
                      .filter((a) => a.severity === "Medium") // eslint-disable-line
                      .map(findingsRow => (
                        <TableRow key={findingsRow.findingsIdx}>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            {findingsRow.assetAttributes.agentId}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Typography
                              style={{
                                "font-family": "sans-serif",
                                "font-size": "14px",
                                "font-weight": "bold",
                                "border-radius": "30px",
                                "padding-top": "2px",
                                "padding-bottom": "2px",
                                background: "white",
                                color: "#3c577a"
                              }}
                            >
                              {findingsRow.description}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Tooltip
                              title={"Vulnérabilité de référence"}
                              classes={{ tooltip: classes.lightTooltip }}
                            >
                              <Typography
                                style={{
                                  "font-family": "sans-serif",
                                  "font-size": "14px",
                                  "font-weight": "bold",
                                  "border-radius": "30px",
                                  "padding-top": "2px",
                                  "padding-bottom": "2px",
                                  background: "white",
                                  color: "#3c577a"
                                }}
                              >
                                {findingsRow.title.split(" ").pop()}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Typography
                              style={{
                                "font-family": "sans-serif",
                                "font-size": "14px",
                                "font-weight": "bold",
                                "border-radius": "30px",
                                "padding-top": "2px",
                                "padding-bottom": "2px",
                                background: "white",
                                color: "#3c577a"
                              }}
                            >
                              {findingsRow.updatedAt}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "2px solid lightgrey",
                              "font-family": "sans-serif",
                              "font-size": "14px",
                              "font-weight": "bold",
                              background: "white",
                              color: "#3c577a"
                            }}
                          >
                            <Typography
                              style={{
                                "font-family": "sans-serif",
                                "font-size": "14px",
                                "font-weight": "bold",
                                "border-radius": "30px",
                                "padding-top": "2px",
                                "padding-bottom": "2px",
                                background: "white",
                                color: "#3c577a"
                              }}
                            >
                              {findingsRow.recommendation}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
                {this.state.findings.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      >
                        {"Choose Request Engine"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "none",
                          "font-family": "sans-serif",
                          "font-size": "14px",
                          "font-weight": "bold",
                          background: "white",
                          color: "#3c577a"
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Paper>
          </Paper>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return "";
    }
  }
}

InspectorDashboardTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(InspectorDashboardTable));
