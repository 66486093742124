import React, { useCallback, useEffect, useState } from "react";
import List from "@material-ui/core/List";
import HeadlineRow from "./HeadlineRow";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import HeadlineUpdate from "./HeadlineUpdate";
import { getApplicationHeadline } from "../../Services/ApplicationService";

const HeadlineGeneral = ({ selectedApplication }) => {
  const [headlineInformation, setHeadlineInformation] = useState(undefined);
  const [isHeadlineUpdateOpened, setIsHeadlineUpdateOpened] = useState(false);

  useEffect(() => {
    const retrieveAccountHeadline = async () => {
      setHeadlineInformation(await getApplicationHeadline(selectedApplication));
    };

    retrieveAccountHeadline();
  }, [selectedApplication]);

  const closeHeadlineUpdate = () => setIsHeadlineUpdateOpened(false);
  const validateHeadlineUpdate = useCallback(async () => {
    setIsHeadlineUpdateOpened(false);
    setHeadlineInformation(undefined);
    setHeadlineInformation(await getApplicationHeadline(selectedApplication));
  }, [selectedApplication]);

  return headlineInformation ? (
    <div
      style={{
        backgroundColor: "white",
        maxWidth: "40%",
        minWidth: "25%",
        border: "none",
        borderRadius: "30px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "hidden"
      }}
    >
      <section style={{ float: "center" }}>
        <Button
          size="medium"
          color="primary"
          onClick={() => setIsHeadlineUpdateOpened(true)}
          style={{
            backgroundColor: "#54bfa6",
            "box-shadow": "none",
            "border-radius": "20px",
            border: "none",
            "font-weight": "bold",
            color: "#ffffff",
            marginTop: "20px"
          }}
        >
          modifier
        </Button>
      </section>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          "font-weight": "bold",
          color: "#59608f"
        }}
      >
        <span> Description de l'application </span>
      </div>
      <List
        style={{
          color: "grey",
          "font-weight": "bold",
          marginLeft: "100px"
        }}
      >
        {headlineInformation.length > 0 &&
          headlineInformation.map((row, index) => (
            <HeadlineRow key={index} headlineRowInformation={row} />
          ))}
      </List>
      {headlineInformation.length === 0 && (
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            "font-weight": "bold",
            color: "#59608f",
            textTransform: "lowercase"
          }}
        >
          <i>Appuyer sur "Modifier" pour ajouter une description</i>
        </div>
      )}
      <HeadlineUpdate
        open={isHeadlineUpdateOpened}
        onClose={closeHeadlineUpdate}
        onValidate={validateHeadlineUpdate}
        selectedApplication={selectedApplication}
        headlineInformation={headlineInformation}
      />
    </div>
  ) : (
    <CircularLoading />
  );
};

export default HeadlineGeneral;
