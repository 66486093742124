import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Amplify from "aws-amplify";

import { styles } from "../Styles/ScreenStyles";
import GRT from "../Styles/Icons/GRTtrans.png";
import Button from "@material-ui/core/Button";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ProfileDialog from "../Components/Dialogs/ProfileDialog";
import DoNotDisturbDialog from "../Components/Dialogs/DoNotDisturbDialog";
import {
  getUserProfile,
  getUserProfiles
} from "../Services/UserProfilesService";
import { CSVLink } from "react-csv";
import csvExporter from "../csvExporter.js";
import { DelayedRenderer } from "react-delayed-renderer";

//let pckg = require("../../package.json");

let csvData = [];
let unFormatedData = [];
let formatedData = [];
let FinalArray = [];

class ProfilUtilisateur extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = {
      classes: classes,
      profilesExport: [],
      userExportPerimeterOnly: [],
      modalPhoneNumbersProfileOpen: false,
      modalDoNoDisturbProfileOpen: false
    };
  }

  gatherSessionInfo() {
    Amplify.Auth.currentSession().then(data => {
      getUserProfile(data.idToken.payload["cognito:username"]).then(
        responseUserProfile =>
          this.setState({
            userName: responseUserProfile.UserName,
            userFirstName: responseUserProfile.FirstName,
            userLastName: responseUserProfile.LastName,
            userPerimeters: responseUserProfile.Perimeters,
            cognitoUserRole: data.idToken.payload["cognito:roles"]
          })
      );
      getUserProfiles().then(responseProfilesExport =>
        this.setState({
          profilesExport: [
            ...this.state.profilesExport,
            ...responseProfilesExport
          ]
        })
      );
    });
  }

  componentDidMount() {
    this.gatherSessionInfo();
  }

  exportStatus = () => {
    console.log("status-ept : loaded");
  };

  refreshPage = () => {
    //window.onload = function() {
    //if (!window.location.hash) {
    //window.location = window.location + "#perimeter-export-authenticated";
    //window.location.reload();
    //}
    //};
    //window.onload();
    console.log("status-ept : init");
  };

  handleExportIterator = () => {
    let userExportPerimeterOnly = [];

    if (this.state.userPerimeters !== undefined) {
      console.log(this.state.userPerimeters.length);
      for (let i = 0; i < this.state.userPerimeters.length; i++) {
        userExportPerimeterOnly.push(
          this.state.profilesExport.filter(
            Items =>
              Items.Perimeters.includes(`${this.state.userPerimeters[i]}`) ===
              true
          )
        );
        if (userExportPerimeterOnly[i].length > 0) {
          csvData.push(userExportPerimeterOnly[i]);
        }
      }
      var my_obj = JSON.stringify(csvData);
      sessionStorage.setItem("authorized_export", my_obj);
      var storedArray = JSON.parse(sessionStorage.getItem("authorized_export"));
      for (let j = 0; j < storedArray.length; j++) {
        unFormatedData.push(storedArray[j]);
      }
    }

    if (
      unFormatedData[0] !== undefined &&
      unFormatedData[0].length > 0 &&
      this.state.userPerimeters !== undefined
    ) {
      FinalArray.push("");

      for (let k = 0; k < unFormatedData.length; k++) {
        [...formatedData] = [
          unFormatedData[k].map(
            el =>
              "{" + " " + "email" + ":" + " " + `"` + el.Email + `"` + " " + "}" // eslint-disable-line
          ) + "\n"
        ];
        FinalArray.push(
          "{" +
            " " +
            "Perimètre: " +
            `"` +
            `${this.state.userPerimeters[k]}` +
            `"` +
            " " +
            "}"
        );
        FinalArray.push(...formatedData);
      }
      sessionStorage.setItem("authorized_MailExport", FinalArray);
    }
  };

  handleEditPhoneNumberProfileClickOpen = () => {
    this.setState({ modalPhoneNumbersProfileOpen: true });
  };

  handleEditDoNotDisturbProfileClickOpen = () => {
    this.setState({ modalDoNoDisturbProfileOpen: true });
  };

  editDoNotDisturbProfileCallback = dialogState => {
    this.setState({ modalDoNoDisturbProfileOpen: dialogState });
  };

  editPhoneNumberProfileCallback = dialogState => {
    this.setState({ modalPhoneNumbersProfileOpen: dialogState });
  };

  renderEditDoNotDisturbProfileDialog = () => {
    if (this.state.modalDoNoDisturbProfileOpen) {
      return (
        <DoNotDisturbDialog
          userName={this.state.userName}
          editDoNotDisturbProfileCallback={this.editDoNotDisturbProfileCallback}
        />
      );
    }
  };

  renderEditPhoneNumberProfileDialog = () => {
    if (this.state.modalPhoneNumbersProfileOpen) {
      return (
        <ProfileDialog
          userName={this.state.userName}
          editPhoneNumberProfileCallback={this.editPhoneNumberProfileCallback}
        />
      );
    }
  };

  render() {
    return (
      <div className={this.state.classes.rootProfilScreen}>
        <Paper
          className={this.state.classes.rootProfilUtilisateur}
          elevation={0}
        >
          <Typography
            variant="h5"
            component="h3"
            style={{
              "font-family": "sans-serif",
              "font-size": "16px",
              "font-weight": "bold",
              "border-radius": "30px",
              "padding-top": "2px",
              "padding-bottom": "2px",
              background: "#f7faf9",
              color: "#6c80ba"
            }}
          >
            <span style={{ float: "left", marginLeft: "100px" }}>Profil</span>
            {/*<span style={{ float: "right", marginRight: "100px" }}>
                Version de ExSI {pckg.version} ({process.env.REACT_APP_STAGE})
              </span>*/}
          </Typography>
        </Paper>
        <Paper className={this.state.classes.rootProfilName} elevation={0}>
          <span style={{ float: "left", "vertical-align": "-50%" }}>
            {this.state.userFirstName} {this.state.userLastName}
          </span>
        </Paper>
        <div>{this.renderEditPhoneNumberProfileDialog()}</div>
        <div>{this.renderEditDoNotDisturbProfileDialog()}</div>
        <div className="{this.state.classes.rootProfilUtilisateur}">
          <DelayedRenderer delay={100} onRender={() => this.refreshPage()}>
            <Paper className={this.state.classes.rootProfilInfo} elevation={0}>
              <Button
                onClick={this.handleEditPhoneNumberProfileClickOpen}
                key="add"
                size="medium"
                variant="contained"
                style={{
                  backgroundColor: "#5dc2b4",
                  "box-shadow": "none",
                  "border-radius": "20px",
                  border: "none",
                  "font-weight": "bold",
                  color: "#ffffff",
                  marginTop: "10px"
                }}
              >
                Gérer mes numéros de téléphone
              </Button>
            </Paper>
            <Paper className={this.state.classes.rootProfilInfo} elevation={0}>
              <Button
                onClick={this.handleEditDoNotDisturbProfileClickOpen}
                color="primary"
                key="do-not-disturb"
                size="medium"
                variant="contained"
                style={{
                  backgroundColor: "#5dc2b4",
                  "box-shadow": "none",
                  "border-radius": "20px",
                  border: "none",
                  "font-weight": "bold",
                  color: "#ffffff",
                  marginTop: "0px"
                }}
              >
                Gérer mon accessibilité
              </Button>
            </Paper>
            {this.state.userName === "faro_0906RC" && (
              <Paper
                className={this.state.classes.rootProfilInfo}
                elevation={0}
              >
                <Button
                  onClick={this.exportStatus()}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{
                    backgroundColor: "#5dc2b4",
                    "box-shadow": "none",
                    "border-radius": "20px",
                    border: "none",
                    "font-weight": "bold",
                    color: "#ffffff",
                    marginTop: "0px"
                  }}
                >
                  <CSVLink
                    style={{ textDecoration: "none", color: "white" }}
                    data={this.state.profilesExport}
                  >
                    {"EXPORTER LES UTILISATEURS (.csv)"}
                  </CSVLink>
                  <SaveAltIcon style={{ fontSize: 18 }} />
                </Button>
              </Paper>
            )}
            {this.state.userName === "faro_8104DM" && (
              <Paper
                className={this.state.classes.rootProfilInfo}
                elevation={0}
              >
                <Button
                  onClick={this.exportStatus()}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{
                    backgroundColor: "#5dc2b4",
                    "box-shadow": "none",
                    "border-radius": "20px",
                    border: "none",
                    "font-weight": "bold",
                    color: "#ffffff",
                    marginTop: "0px"
                  }}
                >
                  <CSVLink
                    style={{ textDecoration: "none", color: "white" }}
                    data={this.state.profilesExport}
                  >
                    {"EXPORTER LES UTILISATEURS (.csv)"}
                  </CSVLink>
                  <SaveAltIcon style={{ fontSize: 18 }} />
                </Button>
              </Paper>
            )}
            {this.state.userName !== "faro_8104DM" && (
              <Paper
                className={this.state.classes.rootProfilInfo}
                elevation={0}
              >
                <Button
                  onClick={this.exportStatus()}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{
                    backgroundColor: "#5dc2b4",
                    "box-shadow": "none",
                    "border-radius": "20px",
                    border: "none",
                    "font-weight": "bold",
                    color: "#ffffff",
                    marginTop: "0px"
                  }}
                >
                  <CSVLink
                    style={{ textDecoration: "none", color: "white" }}
                    data={csvExporter.DATA}
                    headers={csvExporter.HEADERS}
                  >
                    {"EXPORTER LES UTILISATEURS (.csv)"}
                  </CSVLink>
                  <SaveAltIcon style={{ fontSize: 18 }} />
                </Button>
              </Paper>
            )}
          </DelayedRenderer>
        </div>
        <div
          style={{
            display: "flex",
            "flex-direction": "row",
            "justify-content": "center",
            "text-align": "left"
          }}
        >
          {/*this.handleExportIterator()*/}
        </div>
        <svg viewBox="0 0 800 800">
          <path
            class="path"
            d="M2,295 C363,348 395,4 800,47"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            stroke-dashoffset="1000"
            id="motion1"
          />
          <path
            d="M0,204 C377,367 504,22 797,225"
            fill="none"
            stroke="#0ec289"
            stroke-width="0.5"
            id="motion2"
          />
          <circle
            class="circle"
            cx="284.3733028649552"
            cy="241.81299508566542"
            r="5"
            stroke="#f7faf9"
            fill="#f7faf9"
            stroke-width="0"
          />
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="40s" repeatCount="indefinite">
              <mpath xlinkHref="#motion1" />
            </animateMotion>
          </circle>
          <circle
            class="circle"
            cx="0"
            cy="0"
            r="1"
            stroke="#f7faf9"
            fill="#0ec289"
            stroke-width="0"
          >
            <animateMotion dur="30s" repeatCount="indefinite">
              <mpath xlinkHref="#motion2" />
            </animateMotion>
          </circle>
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="245.5"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="288"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="284.3733028649552"
            y1="241.81299508566542"
            y2="238"
            stroke="#0ec289"
            stroke-width="1.5"
          />
          <line
            x1="284.3733028649552"
            x2="281"
            y1="241.81299508566542"
            y2="241.81299508566542"
            stroke="#0ec289"
            stroke-width="1.5"
          />
        </svg>
        <img
          src={GRT}
          alt={"APP-icon"}
          style={{
            height: "50px",
            marginTop: "50px",
            marginBottom: "50px",
            verticalAlign: "middle",
            animation: "fadeIn 4s"
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ProfilUtilisateur);
