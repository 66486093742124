export const getColorFromStatus = status => {
  switch (status) {
    case "running":
    case "available":
      return "#b3f5ca";
    case "stopping":
    case "pending":
    case "renaming":
    case "creating":
    case "deleting":
    case "upgrading":
    case "starting":
    case "rebooting":
    case "modifying":
    case "shutting-down":
    case "backing-up":
    case "backtracking":
      return "#f1f794";
    case "terminated":
    case "failed":
      return "#ebcbc3";
    case "stopped":
      return "#d3e3e6";
    default:
      return "None";
  }
};
