import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import moment from "moment";
import Button from "@material-ui/core/Button";
import PipelineStageFailedRetry from "./PipelineStageFailedRetry";
import {
  findCorrectSourceRevision,
  renderStatus
} from "../../Services/PipelinesService";
import PipelineRevisions from "./PipelineRevisions";

const PipelineStageFailed = ({
  stageName,
  pipelineName,
  actionStates,
  lastTwoExecutions,
  userName,
  app,
  accountId
}) => {
  const [
    isPipelineStageFailedRetryOpened,
    setIsPipelineStageFailedRetryOpened
  ] = useState(false);
  const closePipelineStageFailedRetry = () =>
    setIsPipelineStageFailedRetryOpened(false);
  const [openAction, handleOpenAction] = useState(false);
  const [openErrorAction, handleOpenErrorAction] = useState(false);

  return (
    <div>
      {lastTwoExecutions.length === 2 && (
        <Card
          elevation={0}
          style={{
            backgroundColor: renderStatus(lastTwoExecutions[1].status),
            margin: "0.5em",
            padding: "0.5em",
            textAlign: "left",
            color: "white",
            "text-transform": "uppercase",
            "font-family": "sans-serif",
            "font-size": "16px",
            "font-weight": "600",
            "border-radius": "10px"
          }}
        >
          <div>
            {findCorrectSourceRevision(
              lastTwoExecutions[1].sourceRevisions
            ).revisionId.slice(0, 8)}{" "}
            -{" "}
            {
              findCorrectSourceRevision(lastTwoExecutions[1].sourceRevisions)
                .actionName
            }
            :{" "}
            {
              findCorrectSourceRevision(lastTwoExecutions[1].sourceRevisions)
                .revisionSummary
            }
          </div>
          {lastTwoExecutions[1].sourceRevisions.length > 1 ? (
            <span>
              <Button
                size="small"
                style={{
                  color: "white",
                  padding: 0,
                  minWidth: "auto"
                }}
                onClick={() => handleOpenAction(!openAction)}
              >
                ...
              </Button>
              <PipelineRevisions
                open={openAction}
                onClose={() => handleOpenAction(!openAction)}
                sourceRevisions={lastTwoExecutions[1].sourceRevisions}
              />
            </span>
          ) : (
            <br />
          )}
          <br />
          <div
            style={{
              fontSize: "small",
              textAlign: "right"
            }}
          >
            Dernière exécution:{" "}
            {moment(lastTwoExecutions[1].lastUpdateTime).format(
              "ddd Do MMMM YYYY, HH:mm:ss"
            )}
          </div>
        </Card>
      )}
      <Card
        style={{
          backgroundColor: renderStatus(lastTwoExecutions[0].status),
          margin: "0.5em",
          padding: "0.5em",
          textAlign: "left",
          color: "white"
        }}
      >
        <div>
          {findCorrectSourceRevision(
            lastTwoExecutions[0].sourceRevisions
          ).revisionId.slice(0, 8)}{" "}
          -{" "}
          {
            findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
              .actionName
          }
          :{" "}
          {
            findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
              .revisionSummary
          }
        </div>
        {lastTwoExecutions[0].sourceRevisions.length > 1 ? (
          <span>
            <Button
              size="small"
              style={{
                color: "white",
                padding: 0,
                minWidth: "auto"
              }}
              onClick={() => handleOpenErrorAction(!openErrorAction)}
            >
              ...
            </Button>
            <PipelineRevisions
              open={openErrorAction}
              onClose={() => handleOpenErrorAction(!openErrorAction)}
              sourceRevisions={lastTwoExecutions[0].sourceRevisions}
            />
          </span>
        ) : (
          <br />
        )}
        <br />
        <div
          style={{
            fontSize: "small",
            textAlign: "right"
          }}
        >
          Dernière exécution:{" "}
          {moment(
            actionStates[actionStates.length - 1].latestExecution &&
              actionStates[actionStates.length - 1].latestExecution
                .lastStatusChange
              ? actionStates[actionStates.length - 1].latestExecution
                  .lastStatusChange
              : lastTwoExecutions[0].lastUpdateTime
          ).format("ddd Do MMMM YYYY, HH:mm:ss")}
        </div>
      </Card>
      <Button
        color="secondary"
        variant="contained"
        elevation={0}
        style={{
          "font-weight": "600",
          "box-shadow": "none",
          "border-radius": "30px",
          border: "none",
          color: "#ffffff",
          margin: "10px"
        }}
        onClick={() => setIsPipelineStageFailedRetryOpened(true)}
      >
        Réaliser une nouvelle tentative de livraison
      </Button>
      <PipelineStageFailedRetry
        open={isPipelineStageFailedRetryOpened}
        onClose={closePipelineStageFailedRetry}
        pipelineName={pipelineName}
        stageName={stageName}
        pipelineExecutionId={lastTwoExecutions[0].pipelineExecutionId}
        userName={userName}
        revisionId={findCorrectSourceRevision(
          lastTwoExecutions[0].sourceRevisions
        ).revisionId.slice(0, 8)}
        app={app}
        accountId={accountId}
      />
    </div>
  );
};

export default PipelineStageFailed;
